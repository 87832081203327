$app-header-min-height: 48px;

.main-menu-component-nav-item-messaage-center {
    width: 200px;
    .udesk-qa-ui-badge {
        float: right;
        margin-top: 4px;
    }
}
.main-menu-component {
    height: $app-header-min-height;
    line-height: $app-header-min-height;
    padding: 0px;

    .main-menu-component-navbar-nav {
        height: $app-header-min-height;
        margin-left: 24px;
        .udesk-qa-ui-space-item {
            display: inline-flex;
            align-items: center;
        }
        .udesk-qa-web-product-matrix-component {
            .udesk-qa-ui-dropdown-trigger {
                display: flex;
                align-items: center;
            }
        }
        .main-menu-component-nav-item {
            height: $app-header-min-height;
            line-height: $app-header-min-height;
            // margin-left: 24px;
            float: left;
            .main-menu-component-nav-link {
                height: $app-header-min-height;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover,
                &:focus {
                    background-color: #fff;
                    border-color: #fff;
                    color: rgba(27, 109, 255, 1);
                }
            }
        }
    }
}
.qa-update-change-log-list-notification {
    max-width: 400px;
    .udesk-qa-ui-notification-notice-message {
        width: 100%;
    }
    .qa-update-change-log-title {
        font-size: 18px;
        flex: 1;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .qa-update-change-log-list {
        margin: 0 13px 4px 30px;
        .qa-update-change-log-list-content {
            overflow-y: hidden;
            height: 66px;
            display: block;
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.45);
                margin: 0;
                font-size: 14px !important;
                height: 22px !important;
                line-height: 22px !important;
            }
        }
        .qa-update-change-log-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.45);
        }
    }
}
