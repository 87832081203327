.task-center-audit-page-left {
    width: 240px;
    border-right: 1px solid #eee;

    > div,
    > div> div,
    > div> div> div,
    > div> div> div> div,
    > div> div> div> div> div {
        height: 100%;
    }

    & > div> div> div> div {
        height: calc(100% - 30px);
    }

    .udesk-qa-ui-spin-nested-loading {
        > div {
            height: auto;
        }
        > div.udesk-qa-ui-spin-container{
            height: 100%;
        }
    }
    .udesk-qa-ui-table-thead > tr > th {
        background-color: #fff;
        border-color: #fff;

        &.udesk-qa-ui-table-cell-scrollbar {
            box-shadow : none;
        }
    }

    .udesk-qa-ui-table-tbody > tr {
        > td {
            border-color: transparent;
        }
    }

    .table-row-item {
        display: flex;
        justify-content: space-between;
        padding-left: 8px;
        
        > span:first-child {
            width: 222px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
        }

        > .toolkit-button {
            .udesk-qa-ui-scroll-number {
                box-shadow: none;
            }
            .udesk-qa-ui-scroll-number-only-unit {
                color: #8f8f8f;
            }
        }
    }

    .column-header {
        text-align: left;
        padding: 8px 8px 0;

        .udesk-qa-ui-space-item:first-child {
            width: 195px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.dissent-menu-dropdown {
    > ul {
        max-height: 300px; 
        width: 350px; 
        overflow: auto;

        > li {
            display: inline-block;
            width: 345px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}