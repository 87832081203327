.concept-word-detail-page {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    height: 100%;
    .concept-word-detail-page-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .udesk-qa-ui-btn-primary {
            background: rgba(27, 109, 255, 1);
            border-color: rgba(27, 109, 255, 1);
        }
        .concept-word-detail-page-content {
            padding: 40px 40px 0 40px;
            .concept-word-detail-page-content-form {
                display: flex;
                line-height: 32px;
                flex-direction: column;
                width: 800px;
                .udesk-qa-ui-form-item-children {
                    position: relative;
                }
                .has-error {
                    border-color: rgba(251, 81, 39, 1) !important;
                }
                .udesk-qa-ui-form-explain {
                    color: rgba(251, 81, 39, 1);
                }
                .concept-word-detail-page-content-form-name-input {
                    position: relative;
                    .udesk-qa-ui-input {
                        padding-right: 35px;
                    }
                    .concept-word-detail-page-content-form-name-input-word-count {
                        font-size: 12px;
                        position: absolute;
                        top: 0px;
                        right: 9px;
                    }
                }
            }
            .concept-word-detail-page-content-form-bottom {
                display: flex;
                line-height: 32px;
                flex-direction: column;
                padding-bottom: 70px;
                .concept-word-detail-page-icon {
                    margin: 0 5px 0 4px;
                    position: relative;
                    display: inline-block;
                    width: 14px;
                    top: 5px;
                    height: 16px;
                }
                .concept-word-detail-page-content-form-item-label {
                    min-width: 98px;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 5px;
                }
                .concept-word-detail-page-content-form-name {
                    display: flex;
                    .concept-word-detail-page-content-form-name-input {
                        width: 200px;
                        position: relative;
                        .udesk-qa-ui-input {
                            padding-right: 35px;
                        }
                        .concept-word-detail-page-content-form-name-input-word-count {
                            font-size: 12px;
                            position: absolute;
                            top: 0px;
                            right: 9px;
                        }
                    }
                }
                .concept-word-detail-page-content-form-useful {
                    display: flex;
                    margin-top: 24px;
                }
                .concept-word-detail-page-content-form-description {
                    display: flex;
                    margin-top: 5px;
                    .concept-word-detail-page-content-form-description-input {
                        width: 200px;
                        margin-right: 8px;
                        position: relative;
                        .udesk-qa-ui-input {
                            padding-right: 35px;
                        }
                        .concept-word-detail-page-content-form-description-input-word-count {
                            font-size: 12px;
                            position: absolute;
                            top: 0px;
                            right: 9px;
                        }
                    }
                }
                .concept-word-detail-page-content-form-description-repeat {
                    display: none;
                    margin: 4px 0 0 99px;
                    color: rgba(251, 81, 39, 1);
                }
                .concept-word-detail-page-content-form-recommend {
                    margin: 8px 0 0 99px;
                    max-width: 760px;
                    .concept-word-detail-page-content-form-recommend-head {
                        display: flex;
                        justify-content: space-between;
                        .concept-word-detail-page-content-form-recommend-head-left {
                            color: rgba(27, 109, 255, 1);
                            cursor: pointer;
                            user-select: none;
                        }
                        .concept-word-detail-page-content-form-recommend-head-right {
                            span {
                                user-select: none;
                                cursor: pointer;
                                color: rgba(0, 0, 0, 0.45);
                                margin-left: 8px;
                            }
                        }
                    }
                    .concept-word-detail-page-content-form-recommend-body {
                        margin-top: 8px;
                        background-color: rgba(0, 0, 0, 0.03);
                        border-radius: 2px;
                        padding: 16px 16px 4px;
                    }
                }
                .concept-word-detail-page-content-form-words-selected-table {
                    margin-top: 29px;
                    display: flex;
                    .concept-word-detail-page-content-form-words-selected-table-body {
                        max-width: 760px;
                        padding-left: 16px;
                        display: flex;
                        .udesk-qa-ui-row {
                            width: 726px;
                            .concept-word-detail-page-content-form-words-selected-table-body-item {
                                position: relative;
                                i {
                                    position: absolute;
                                    top: 3px;
                                    margin-left: 8px;
                                    cursor: pointer;
                                    color: rgba(0, 0, 0, 0.65);
                                }
                            }
                        }
                    }
                }
            }
        }
        .concept-word-detail-page-content-bottom {
            height: 48px;
            background-color: #fff;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            line-height: 48px;
            padding-left: 40px;
            .udesk-qa-ui-btn {
                margin-right: 8px;
            }
        }
    }
}
