.component-change-list {
    .change-list-title {
        font-weight: bold;
        padding: 10px;
    }

    .change-list-item-container {
        padding: 10px;
        border-top: 1px solid #ddd;

        .change-list-item-avata-url {
            display: inline-block;
            width: 40px;
            height: 40px;

            .change-list-item-avata-img {
                width: 100%;
                border-radius: 50%;
            }

            .change-list-item-avata-iconfont {
                font-size: 30px;
            }
        }

        .change-list-item-info {
            display: inline-block;
            vertical-align: top;
            padding: 0 10px;

            .change-list-item-info-row {
                margin: 0;
                display: flex;
                flex-wrap: wrap;

                .change-list-item-info-col {
                    margin-right: 30px;
                }
            }
        }
    }
}