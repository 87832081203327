.udesk-qa-img-upload-component {
    .udesk-qa-ui-upload-select {
        width: 128px;
        height: 128px;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        transition: border-color 0.3s;
        align-items: center;
        display: flex;
        justify-content: center;
        &:hover {
            border-color: #1890ff;
        }
    }
    .udesk-qa-ui-upload-list-picture-card-container {
        width: 128px;
        height: 128px;
    }
    .udesk-qa-ui-upload-list-item-actions {
        .anticon-eye {
            display: none;
        }
    }
}
