.wave-audio-component-talk {
    margin-bottom: 10px;
    .demo-inspection-iframe {
        display: none;
    }
    .wave-audio-container {
        position: relative;
        .wave-audio-container-cursor {
            position: absolute;
            height: 100%;
            background: #000;
            margin: 0;
        }
        .wave-audio-container-no-wave-message {
            position: absolute;
            top: 50%;
            font-size: 12px;
            color: #888;
            margin-top: -9px;
        }
    }
    .wave-audio-operation-panel {
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.15);
        color: rgba(0, 0, 0, 0.65);
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
        .wave-audio-button-box {
            display: inline-block;
            cursor: pointer;
            // height: 38px;
            // line-height: 38px;
            margin: 0 30px;
        }
        // .wave-audio-emotion-button {
        //     margin: 0 10px;
        // }
        .wave-audio-volumn-button {
            margin: 0 15px;
            position: relative;
            display: inline-flex;
            align-items: center;
            .wave-audio-volumn-input-box {
                display: inline-block;
                z-index: 3;

                input[type='range']::-webkit-slider-runnable-track {
                    height: 8px;
                    border-radius: 10px;
                    background-color: rgba(27, 109, 255, 1);
                }

                input[type='range']:focus {
                    outline: none;
                }

                input[type='range']::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 12px;
                    width: 12px;
                    margin-top: -2px;
                    background: #ffffff;
                    border-radius: 50%;
                    border: 1px solid #000;
                }
            }
        }
        .wave-audio-rate-button {
            width: 35px;
            margin: 0 15px 0 0;
            position: relative;
            vertical-align: top;

            .wave-audio-rate-options {
                position: absolute;
                background-color: #1b6dff;
                bottom: 42px;
                border-radius: 5px;
                z-index: 3;
                color: #fff;

                .wave-audio-rate-option {
                    height: 20px;
                    line-height: 20px;
                    padding: 0 5px;

                    &:hover {
                        background-color: rgba(232, 244, 255, 1);
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }
        .wave-audio-time-box {
            display: inline-block;
            // height: 38px;
            // line-height: 38px;
            margin-right: 15px;
            vertical-align: top;
        }
        .wave-audio-custom-button {
            display: inline-block;
            margin-right: 20px;
            cursor: default;
            .wave-audio-custom-button-num {
                margin-left: 5px;
                vertical-align: 1px;
            }
            .wave-audio-custom-button-show {
                display: inline-block;
                width: 12px;
                height: 4px;
                background: rgba(191, 223, 255, 1);
                vertical-align: 3px;
                margin-right: 5px;
            }
        }
        .wave-audio-agent-button {
            display: inline-block;
            vertical-align: top;
            cursor: default;
            .wave-audio-agent-button-num {
                margin-left: 5px;
                vertical-align: -1px;
            }
            .wave-audio-agent-button-show {
                display: inline-block;
                width: 12px;
                height: 4px;
                background: rgba(217, 217, 217, 1);
                vertical-align: 2px;
                margin-right: 5px;
            }
        }
    }
}
