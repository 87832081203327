.business-more-customer {
    .form-title {
        display: flex;
        justify-content: space-between;

        > h2 {
            font-size: 16px;
            font-weight: 700;
        }
    }
}