.custom-card-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    padding: 12px;
    border-radius: 3px;
    .custom-card-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .custom-card-header-icon {
            width: 6px;
            height: 20px;
            background: #5597e5;
            margin-right: 8px;
        }
        .custom-card-header-title {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;