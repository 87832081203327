.udesk-qa-web-page-body-root {
    .udesk-qa-web-table-common-page-header {
        margin-bottom: 16px;
        display: flex;
        justify-content: flex-end;
        .udesk-qa-web-table-common-page-header-search-form {
            flex: 1;
            margin-right: 8px;
        }
        .udesk-qa-ui-form-builder-container {
            margin: -4px -8px;
            .udesk-qa-ui-form-item {
                margin: 4px 8px;
            }
        }
        .udesk-qa-web-table-common-page-header-left {
            flex: 1;
            display: flex;
            align-items: center;
            .udesk-qa-ui-form-inline {
                margin: -4px;
                .udesk-qa-ui-form-item {
                    margin: 4px;
                }
            }
        }
    }
    .udesk-qa-web-table-common-page-classification {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        border-right: 1px solid #f0f0f0;
        margin-left: -16px;
        max-width: 350px;
        .udesk-qa-ui-tree-node-content-wrapper {
            flex: 1;
            overflow: hidden;
            .tree-node-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 16px;
            }
        }
        .udesk-qa-ui-tree-indent-unit {
            width: 12px;
        }
        .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .udesk-qa-web-table-common-page-classification-title {
            padding: 5px 0px 16px 16px;
            font-weight: 600;
        }
        .udesk-qa-web-table-common-page-classification-body {
            overflow-y: auto;
            .udesk-qa-ui-tree {
                padding-left: 16px;
                padding-right: 16px;
            }
            .udesk-qa-ui-tree-switcher {
                &:hover {
                    background: rgba(0, 0, 0, 0.018);
                }
            }
            .udesk-qa-ui-menu-inline {
                border-right: none;
                .udesk-qa-ui-menu-item {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    max-height: 64px;
                    min-height: 40px;
                    height: unset;
                    line-height: unset;
                    padding: 11px 16px;
                    width: 100%;
                    .classification-title {
                        height: 24px;
                        line-height: 24px;
                    }
                    .classification-description {
                        height: 20px;
                        line-height: 20px;
                        color: rgba(0, 0, 0, 0.45) !important;
                    }
                    .classification-item {
                        width: 100%;
                        // flex: 1;
                        justify-content: space-between;
                        display: flex;
                        .classification-item-content {
                            width: 100%;
                        }
                        &:hover {
                            .classification-actions {
                                display: flex;
                                .anticon {
                                    margin-right: 0px;
                                }
                            }
                        }
                        .classification-actions {
                            display: none;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
    .udesk-qa-web-table-common-page-with-classification {
        display: flex;
        min-height: 100%;
        height: 100%;
        .udesk-qa-web-table-common-page-main {
            flex: 1;
            overflow-x: hidden;
        }
        ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
        ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
            background: #e0e5eb;
        }
        ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            // background: rgba(0,0,0,0.1);
        }
    }
}
