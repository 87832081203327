.learning-center-exercise {
  .other-toolbar {
    position: absolute;
    right: 16px;
    top: 9px;
    display: flex;
    justify-content: space-between;

    > div:first-child {
      line-height: 35px;
      font-size: 16px;

      > span:nth-child(1) {
        color: #555;

        &::after {
          content: "：";
        }
      }

      > span:nth-child(2) {
        margin: 0 16px 0 2px;
        font-size: 24px;
        color: #1b6dff
      }
    }
  }
}

.answer-remaining-time {
  position: relative;
  top: -3px;
  
  .udesk-qa-ui-progress-inner {
    width: 40px!important;
    height: 40px!important;
    font-size: 14px!important;
    font-weight: 600;
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;