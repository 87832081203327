.audio-text-list-item-content {
    em {
        color: red;
    }

    .audio-text-list-item-text-hit {
        background-color: #fffb49;
    }
    .message-tools-bar-remark {
        margin-top: 4px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        min-width: 223px;
        padding: 2px 16px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.45);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        text-align: start !important;
        max-width: 280px;
    }
    .audio-text-list-item-text-video {
        max-width: 299px;
        max-height: 274px;
    }
}
