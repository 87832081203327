 $app--pages-quality-inspection-form-basic-information--border-color:#e6e6e6;
 .common-page-header{
        padding-bottom:20px;
        margin-bottom:18px;
        border-bottom:1px solid $app--pages-quality-inspection-form-basic-information--border-color;
        .title{
            font-size:18px;
            color:$app-link-color;
            .icon-qc-left-arrow{
                font-weight: bold;
                margin-right:5px;
            }
        }
        .page-header-nav{
            margin-top:6px;
            .report-nav-bar-item{
                color:$app-link-color;
                &:hover{
                    color:$app-link-hover-color;
                }
                &.link-active{
                    color:$app-link-active-color;
                }

            }
        }
    }