.filter-manage-component-container {
    border-right: 1px solid #ddd;

    .filter-manage-component-header {
        overflow: hidden;
        height: 30px;
        line-height: 30px;
        padding: 0 5px;

        .filter-manage-component-header-title {
            color: #888;
            text-transform: capitalize;
        }

        .filter-manage-component-header-actions {
            color: #2878ff;
            cursor: pointer;
        }
    }

    .filter-manage-component-body {
        height: calc(100vh - 64px - 35px - 57px - 30px);
        min-height: 240px;
        overflow-y: auto;

        .filter-manage-component-body-has-value {
            .filter-manage-item {
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                overflow: hidden;
                border-top: 1px solid #ddd;

                &:last-child {
                    border-bottom: 1px solid #ddd;
                }

                .filter-manage-item-name {
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 50%;
                }

                .filter-manage-item-count {
                    display: inline-block;
                }

                .filter-manage-item-action {
                    color: #2878ff;
                    display: inline-block;
                    margin-left: 5px;
                }

                &:hover {
                    background: #ddd;
                }

                &.active {
                    color: #2878ff;
                    background: #eee;
                }
            }
        }
    }

    .filter-manage-component-footer {
        padding: 0 5px;
        .udesk-qa-ui-pagination-prev {
            min-width: 30px;
        }
        .udesk-qa-ui-pagination-next {
            min-width: 30px;
        }
    }

    .udesk-qa-ui-menu-inline {
        border-right: none;
        .udesk-qa-ui-menu-item {
            margin-top: 0px;
            margin-bottom: 0px;
            max-height: 64px;
            min-height: 40px;
            height: unset;
            line-height: unset;
            padding: 11px 16px;
            width: 100%;
            .classification-title {
                height: 24px;
                line-height: 24px;
                text-transform: capitalize;
            }
            .classification-description {
                height: 20px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.45) !important;
            }
            .classification-item {
                width: 100%;
                // flex: 1;
                justify-content: space-between;
                display: flex;
                .classification-item-content {
                    width: 100%;
                }
                &:hover {
                    .classification-actions {
                        display: flex;
                        .anticon {
                            margin-right: 0px;
                        }
                    }
                }
                .classification-actions {
                    display: none;
                    transition: all 0.5s;
                }
            }
        }
    }
}
