@mixin global-bg {
    background-color: $loader-css-primary-color;
}

@mixin global-animation {
    animation-fill-mode: both;
}

@mixin balls {
    @include global-bg;

    width: $loader-css-ball-size;
    height: $loader-css-ball-size;
    border-radius: 100%;
    margin: $loader-css-margin;
}

@mixin lines {
    @include global-bg;

    width: $loader-css-line-width;
    height: $loader-css-line-height;
    border-radius: 2px;
    margin: $loader-css-margin;
}
