.wave-audio-component-talk-v2 {
    position: relative;
    border-radius: 2px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    // .udesk-qa-ui-dropdown-trigger {
    //     display: flex;
    //     align-items: center;
    // }
    .udesk-qa-ui-space-item-split {
        margin-right: 0;
    }
    .demo-inspection-iframe {
        display: none;
    }
    .play-speed-buttons {
        display: inline-flex;
        align-items: center;
    }
    .volume-buttons {
        display: inline-flex;
        align-items: center;
        width: 150px;
    }
    .wave-audio-container {
        position: absolute;
        // top: -17px;
        width: calc(100% - 40px);
        position: relative;
        margin-left: 40px;

        canvas {
            display: none;
        }
        &:hover {
            & > wave {
                cursor: pointer;
                box-shadow: inset 0px -10px 0px 0px rgba(0, 0, 0, 0.05);
            }
            wave {
                wave {
                    cursor: pointer;
                    border-bottom: 10px solid #1a6eff;
                }
            }
        }
        & > wave {
            // border: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            border-bottom: none;
            box-shadow: inset 0px -10px 0px 0px rgba(0, 0, 0, 0.05);
            border-radius: 2px 2px 0px 0px;
        }
        wave {
            wave {
                cursor: pointer;
                border-bottom: 10px solid #1a6eff;
                border-right: 1px solid rgba(0, 0, 0, 0.25) !important;
            }
        }
        .wave-audio-container-cursor {
            position: absolute;
            height: 100%;
            background: #000;
            margin: 0;
        }
        .wave-audio-container-no-wave-message {
            position: absolute;
            top: 50%;
            font-size: 12px;
            color: #888;
            margin-top: -9px;
        }
    }
    .wave-audio-operation-panel {
        width: 100%;
        height: 40px;
        // line-height: 30px;
        background: rgba(255, 255, 255, 1);
        // border: 1px solid rgba(0, 0, 0, 0.15);
        border-top: none;
        border-radius: 2px;
        color: rgba(0, 0, 0, 0.65);
        display: flex;
        justify-content: space-between;
        // padding-right: 30px;
        padding: 0 16px;
        align-items: center;
        background: rgba(0, 0, 0, 0.03);
        .wave-audio-button-box {
            display: flex;
            .speak-rate-show-item {
                display: flex;
                align-items: center;
                margin-left: 16px;
                .speak-rate-show {
                    width: 16px;
                    height: 8px;
                    border-radius: 1px;
                    margin-right: 4px;

                    &.agent {
                        background: #bddeff;
                    }
                    &.customer {
                        background: #abedab;
                    }
                }
            }
        }
        // .wave-audio-emotion-button {
        //     margin: 0 10px;
        // }
        .wave-audio-volumn-button {
            margin: 0 15px;
            position: relative;
            display: inline-flex;
            align-items: center;
            .wave-audio-volumn-input-box {
                display: inline-block;
                z-index: 3;

                input[type='range']::-webkit-slider-runnable-track {
                    height: 8px;
                    border-radius: 10px;
                    background-color: rgba(27, 109, 255, 1);
                }

                input[type='range']:focus {
                    outline: none;
                }

                input[type='range']::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 12px;
                    width: 12px;
                    margin-top: -2px;
                    background: #ffffff;
                    border-radius: 50%;
                    border: 1px solid #000;
                }
            }
        }
        .wave-audio-rate-button {
            width: 35px;
            margin: 0 15px 0 0;
            position: relative;

            .wave-audio-rate-options {
                position: absolute;
                background-color: #1b6dff;
                bottom: 42px;
                border-radius: 5px;
                z-index: 3;
                color: #fff;

                .wave-audio-rate-option {
                    height: 20px;
                    line-height: 20px;
                    padding: 0 5px;

                    &:hover {
                        background-color: rgba(232, 244, 255, 1);
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }
        .wave-audio-time-box {
            display: inline-block;
            // height: 38px;
            // line-height: 38px;
            margin-right: 15px;
        }
    }
}
