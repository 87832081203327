.quality-inspection-demo {
    height: 100vh;
    .demo-header {
        height: 64px;
        line-height: 64px;
        padding: 0 16px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        position: relative;
        
        .demo-logo {
            height: 32px;
            top: 0;
        }

        .demo-title {
            font-weight: 500;
            font-size: 16px;
            display: inline-block;
            vertical-align: top;
            color: rgba(0, 0, 0, 0.85);
            margin-left: 8px;
        }
    }

    .demo-body {
        font-size: 15px;
        margin: 0px;
        display: flex;
        background: rgb(240, 242, 245);
        height: calc(100% - 64px);

        .demo-body-left-react {
            width: 264px;
            height: 100%;
            background-color: #fff;
            box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.1);

            .demo-body-left-content {
                overflow: auto;
                margin: 16px;

                .demo-body-left-content-title {
                    padding-bottom: 4px;
                }

                .demo-body-left-content-select {
                    margin-bottom: 24px;
                }

                .demo-body-left-content-upload {
                    border: 1px dashed #ddd;
                    margin: 24px 0 8px;
                    padding: 10px;
                    text-align: center;

                    .upload-tip {
                        margin: 10px 0 0 0;
                    }
                }

                .demo-body-left-content-upload-result-tip {
                    word-break: break-all;
                    color: rgba(0, 0, 0, 0.65);
                    .icon-udesk-qa-react-web-xiazaishibai{
                        display:inline-block;
                        margin-right:5px;
                        color:#E03C32;
                    }
                    .icon-udesk-qa-react-web-chenggong{
                        display:inline-block;
                        margin-right:5px;
                        color:#36864A;
                    }
                }

                .btn-start {
                    display: block;
                    width: 100%;
                    margin: 40px 0 8px;
                    border: 1px solid #2878ff;
                    background: #2878ff;
                    color: #fff;
                    height: 40px;
                }

                .btn-download {
                    display: block;
                    width: 100%;
                    margin: 0;
                    border: 1px solid #2878ff;
                    background: #fff;
                    color: #2878ff;
                    height: 40px;
                }
            }
        }

        .demo-body-right-react {
            padding: 16px;
            flex: 1;
            height: 100%;

            .demo-body-right-content {
                overflow: auto;
                background: #fff;
                height: 100%;
                padding: 16px;
                font-size: 14px;

                .qa-react-inspection-detail-page {
                    height: calc(100% - 120px);
                    .qa-react-inspection-detail-content {
                        height: 100%;
                        .qa-react-inspection-detail-basic-info {
                            height: 100%;
                        }
                        .qa-react-inspection-detail-content-info {
                            height: 100%;
                        }
                        .qa-react-inspection-detail-content-point {
                            height: 100%;
                        }
                    }
                }

                .demo-body-right-content-checking-tip {
                    text-align: center;
                    font-size: 25px;
                    margin-top: 10%;
                }

                .quality-check-list-detail-header {
                    font-size: 14px;
                    .wave-audio-component {
                        .wave-audio-operation-panel {
                            font-size: 13px;

                            .wave-audio-button-box,
                            .wave-audio-time-box {
                                height: 24px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }

                .quality-check-list-detail-body {
                    height: calc(100vh - 305px);
                    margin-top: 16px;

                    .audio-call-details-container {
                        height: 100%;
                        padding: 0;

                        .audio-call-details {
                            height: 100%;
                            border: 0px;
                            overflow: auto;

                            .audio-call-info-navs {
                                height: 100%;

                                .udesk-qa-ui-tabs-content {
                                    height: calc(100% - 45px);
                                }

                                .audio-text-view {
                                    height: 100%;

                                    .audio-text-list-container {
                                        height: 100%;

                                        .audio-text-list{
                                            height: calc(100% - 48px);
                                            overflow-x: hidden;
                                            overflow-y: auto;
                                        }
                                    }

                                    .work-order-number-href {
                                        color: #2878ff;
                                    }
                                }
                            }
                        }
                    }

                    .quality-check-score-container {
                        height: 100%;
                        min-height: 100%;
                        padding: 0px;
                        margin: 0px;
                        border: 0px;

                        .quality-check-score {
                            height: 100%;
                            overflow-y: auto;
                            padding-left: 10px;
                            border: 0px;

                            .quality-check-score-container {
                                border: 1px solid #ddd;

                                .quality-check-score-body {
                                    position: relative;
                                    top: 44px;
                                    height: calc(100% - 44px);
                                    overflow: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.quality-inspection-demo-modal {
    .udesk-qa-ui-modal {
        .udesk-qa-ui-modal-content {
            width: 672px;
            text-align: start;
        }
    }
}

.demo-inspection-iframe {
    display: none;
}
