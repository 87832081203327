.coach-template-manage-page {
    height: 100%;
    
    .coach-template-manage {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .template-manage-content {
        flex: 1;
        overflow: auto;
        background: #fff;
    }

    .search-area {
        padding: 24px 24px 0;
        background: #fff;
        border-radius: 2px;
    }

    .table-area {
        padding: 24px;
        background: #fff;
        border-radius: 2px;

        .table-header {
            margin-bottom: 16px;
        }
    }

    // 返回按钮样式
    .ant-btn-link {
        padding: 0;
        height: auto;
        line-height: 1;
        
        &:hover {
            background: transparent;
        }
    }
} 