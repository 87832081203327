.semantic-mark-component-info {
    height: 100%;
    .semantic-mark-component-entry {
        display: inline-block;
        margin-top: 44px;
        padding: 5px 0;
        width: 30px;
        height: 70px;
        text-align: center;
        border: 1px solid #cccccc;
        background-color: white;
        border-right: 0px;
        vertical-align: top;

        .semantic-mark-component-entry-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-bottom: 12px;
        }
    }

    .semantic-mark-component-body {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px;
        border: 1px solid #cccccc;
        background-color: white;
        overflow-y: auto;

        .semantic-mark-component-content {
            width: 100%;
            height: 440px;

            .semantic-mark-component-content-title {
                margin-bottom: 10px;
                height: 20px;
                font-size: 14px;
                font-weight: bold;
            }

            .semantic-mark-component-content-row {
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .semantic-mark-component-content-row-tag {
                    width: 80px;
                    margin-right: 10px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .semantic-mark-component-content-row-standard {
                    margin-right: 10px;
                    flex-grow: 1;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 140px;
                }

                .semantic-mark-component-content-row-action {
                    width: 120px;
                    text-align: right;

                    .semantic-mark-action {
                        display: inline-block;
                        cursor: pointer;
                        color: #2878ff;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
