.udesk-qa-web-permission-management-role-detail-page-header {
    .udesk-qa-ui-page-header-heading-extra {
        display: flex;
        align-items: center;
        .role-detail-page-header-nav-item {
            color: #333;
            margin: 10px 0;
            padding: 0 10px;
        }

        .role-detail-page-header-nav-item-active {
            color: #4285ff;
        }
    }
}
