.dashboard-page-index {
    .dashboard-page-card {
        background-color: #fff;
        padding: 16px;
        display: flex;
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px;
        &.space-between {
            justify-content: space-between;
        }
        &.header {
            padding: 21px 16px;
        }
        .billing-module {
            .udesk-qa-ui-divider {
                height: 40px;
                margin: 0 16px;
            }
            .billing-module-warning {
                color: rgb(251, 81, 39);
            }
            .balance-card {
                .balance-card-title {
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 14px;
                    line-height: 22px;
                }
                .balance-card-value {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    margin-top: 4px;
                }
            }
        }
        .edition {
            background: rgba(17, 168, 72, 0.1);
            border-radius: 42px;
            padding: 2px 8px;
            color: #11a848;
            font-size: 12px;
            line-height: 20px;
        }
        .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }
        .company {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            line-height: 20px;
        }
        .logo {
            width: 64px;
            height: 64px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .dashboard-page-card-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 8px;
            padding: 0 0 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.85);
        }
        .udesk-wechat-qa-dashboard {
            margin: -8px;

            display: grid;

            @media screen and (min-width: 1650px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media screen and (min-width: 1359px) and (max-width: 1649px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media screen and (min-width: 1069px) and (max-width: 1358px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 779px) and (max-width: 1068px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 778px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .task-card-box-fake {
            height: 170px;
            .task-card-fake {
                height: 154px !important;
                .udesk-qa-ui-skeleton-title {
                    margin-top: 6px;
                }
            }
        }
        .task-card {
            background: #f8faff;
        }
        .table-item {
            width: 50%;
        }
        .numerical-card {
            background: rgba(25, 110, 255, 0.03);
            border-radius: 2px;
            width: calc(25% - 16px);
            margin: 4px 8px;
            height: 122px;
            padding: 16px;
            position: relative;
            .numerical-card-tooltip {
                position: absolute;
                top: 16px;
                right: 16px;
            }
            .numerical-card-main {
                display: flex;
                .numerical-card-main-icon {
                    width: 48px;
                    height: 48px;
                    background: #1a6eff;
                    border-radius: 50%;
                    margin-right: 20px;
                }
                .numerical-card-main-count {
                    .title {
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.45);
                        text-transform: capitalize;
                    }
                    .count {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
            }
            .numerical-card-desc {
                font-size: 14px;
                line-height: 22px;
                color: #1a6eff;
                margin-left: 68px;
                margin-top: 8px;
                text-transform: capitalize;
            }
        }
        .operational-tatistics-box-right {
            height: 280px;
            background: #ffffff;
            border-radius: 2px;
            padding: 16px;
            display: flex;
            .operational-tatistics-box-average-item {
                flex: 1;
            }
        }
        .operational-tatistics-box-content {
            height: calc(100% - 22px);
            margin-top: 16px;
        }
        .progress-component {
            padding: 24px 24px 0px;
            // width: calc(244px + 48px);
            width: calc(100% - 40px);
            position: relative;
            .progress-component-title {
                color: rgba(0, 0, 0, 0.45);
                margin-bottom: 8px;
            }
            .progress-component-percent {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
            }
            .progress-component-percent-count {
                position: absolute;
                bottom: -10px;
                // left: calc(244px + 12px);
                color: rgba(0, 0, 0, 0.3);
                cursor: pointer;
                transform: translateX(-50%);
            }
        }
    }
}
