.dialogue-manage-entry {
    padding: 0 16px;

    .create-dialogue {
        padding: 24px;
        background: #fff;
        border-radius: 2px;

        .create-dialogue-form {

            max-width: 800px;
            .udesk-qa-ui-form-item-control {
                width: 600px;
                flex: none;
            }
        }
    }
} 