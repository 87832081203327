.tasks-index {
    .x_panel {
        background-color: transparent;
    }
}
.bs-modal-base {
    .content-box {
        .con-body {
            .action {
                padding: 0 20px;
            }
        }
    }
}
.udesk-wechat-qa-dashboard {
    .x_panel {
        background-color: transparent;
    }
    font-size: 14px;
    padding-bottom: 1px;
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent !important;
    .task-card-box {
        .task-card {
            height: unset !important;
        }
    }
}
.tasks-index,
.udesk-wechat-qa-dashboard {
    .tasks-filter-control {
        padding: 16px;
        background-color: #fff;
        margin-bottom: 16px;
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
    }
    .tasks-container {
        overflow: hidden;
        margin: -8px;
        min-height: 400px;
    }
}
