.check-point-test-page-index {
    height: 100%;
    .udesk-qa-web-page-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .check-point-test-page-index-foot {
            display: flex;
            width: 100%;
            &-left {
                flex: 1;
                display: flex;
                .udesk-qa-ui-btn {
                    margin-left: 8px;
                }
            }
            &-right {
                flex: 1;
                max-width: 444px;
                display: flex;
                flex-direction: column;
                .udesk-qa-ui-btn {
                    margin-right: 8px;
                    margin-left: 74px;
                }
            }
        }
    }
}
