$udesk-components--iziToast-theme-red-background: rgba(231, 76, 60, 0.88);
$udesk-components--iziToast-theme-red-border-color: rgba(231, 76, 60, 0.88);
$udesk-components--iziToast-theme-red-message-color: #E9EDEF;
$udesk-components--iziToast-theme-yellow-background: rgba(243, 156, 18, 0.88);
$udesk-components--iziToast-theme-yellow-border-color: rgba(243, 156, 18, 0.88);
$udesk-components--iziToast-theme-yellow-message-color: #E9EDEF;
$udesk-components--iziToast-theme-blue-background: rgba(52, 152, 219, 0.88);
$udesk-components--iziToast-theme-blue-border-color: rgba(52, 152, 219, 0.88);
$udesk-components--iziToast-theme-blue-message-color: #E9EDEF;
$udesk-components--iziToast-theme-green-background: rgba(38, 185, 154, 0.88);
$udesk-components--iziToast-theme-green-border-color: rgba(38, 185, 154, 0.88);
$udesk-components--iziToast-theme-green-message-color: #E9EDEF;

.iziToast {
    &.non-draggable {
        user-select: text;
        cursor: unset;
    }

    .iziToast-body {
        .iziToast-message {
            word-break: break-word;
        }
    }
}

.iziToast.iziToast-theme-default {
    &.iziToast-color-red {
        background: $udesk-components--iziToast-theme-red-background;
        border-color: $udesk-components--iziToast-theme-red-border-color;

        .iziToast-body {
            .iziToast-message,
            .iziToast-icon {
                color: $udesk-components--iziToast-theme-red-message-color;
            }
        }
    }

    &.iziToast-color-yellow {
        background: $udesk-components--iziToast-theme-yellow-background;
        border-color: $udesk-components--iziToast-theme-yellow-border-color;

        .iziToast-body {
            .iziToast-message,
            .iziToast-icon {
                color: $udesk-components--iziToast-theme-yellow-message-color;
            }
        }
    }

    &.iziToast-color-blue {
        background: $udesk-components--iziToast-theme-blue-background;
        border-color: $udesk-components--iziToast-theme-blue-border-color;

        .iziToast-body {
            .iziToast-message,
            .iziToast-icon {
                color: $udesk-components--iziToast-theme-blue-message-color;
            }
        }
    }

    &.iziToast-color-green {
        background: $udesk-components--iziToast-theme-green-background;
        border-color: $udesk-components--iziToast-theme-green-border-color;

        .iziToast-body {
            .iziToast-message,
            .iziToast-icon {
                color: $udesk-components--iziToast-theme-green-message-color;
            }
        }
    }
}
