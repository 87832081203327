.data-preprocess-index-page-body {
    padding: 16px;
    .data-preprocess-index-page-body-search-container {
        overflow: hidden;
        margin-bottom: 16px;
        .qa-react-page-body-search-container-left-part {
            display: flex;
            align-items: center;

            .next-select-single {
                margin-right: 5px;
            }
        }
    }

    .data-preprocess-index-page-body-content-container {}
}