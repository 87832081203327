.select-with-search {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 120px;
    .icon-qc-ArtboardCopy1,
    .icon-qc-ArtboardCopy3 {
        float: right;
        line-height: inherit;
        font-size: 13px;
    }
    .dropdown-menu-left {
        position: absolute;
        background: #fff;
        border: 1px solid #ddd;
        margin-top: 1px;
        z-index: 2;
        min-width: 200px;
    }
    .dropdown-menu-search {
        margin-right: 25px;
        input {
            border: none;
            outline: none;
            padding: 3px 10px;
        }
    }
    .icon-qc-delete {
        float: right;
        margin-right: 5px;
        margin-top: 2px;
    }
    .select-multiple-ul {
        max-height: 200px;
        overflow-y: auto;
        .select-multiple-li {
            padding: 0 10px;
            input {
                vertical-align: sub;
            }
            .select-multiple-li-content {
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
}