.qa-react-record-detail-page {
    padding: 16px;
    display: flex;
    flex-direction: column;
    .qa-react-call-manage-detail-title {
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 10px;
        height: 48px;
        line-height: 48px;

        .qa-react-call-manage-detail-span {
            display: inline-block;
            cursor: pointer;
        }
    }
    .label-tabs-list-noeLabel {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 0px;
        padding: 10px;
        width: 100%;
        font-size: 12px;
        .label-field-info {
            padding: 2px 8px;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            cursor: pointer;
        }
        .label-field-info-active {
            color: rgba(255, 255, 255, 1);
            background: rgba(27, 109, 255, 1);
        }
    }
    .label-tabs-list-progress {
        display: flex;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 0px;
        padding: 10px;
        .label-tabs-list-progress-line {
            width: 330px;
            display: flex;
            align-items: center;
            .label-tabs-list-progress-line-title {
                display: inline-block;
                width: 75px;
            }
        }
    }
    .qa-react-record-detail-emotion-analysis-container {
        position: relative;

        .qa-react-record-detail-emotion-button {
            cursor: pointer;
            position: absolute;
            right: 0px;
            z-index: 1;
        }

        .qa-react-record-detail-emotion-analysis {
            height: 180px;
        }
    }

    .qa-react-record-detail-content {
        margin: 0;
        flex: 1;
        .qa-react-record-detail-content-left-part {
            padding-left: 0;
            height: 100%;
        }

        .qa-react-record-detail-content-right-part {
            padding-right: 0;

            .qa-react-record-detail-content-templates {
                .apply-templates-component {
                    min-height: 44px;
                }
            }

            .qa-react-record-detail-content-word-cloud {
                .key-words-cloud {
                    min-height: 300px;
                }
            }
        }
    }

    .qa-react-record-detail-page-no-value {
        text-align: center;
    }
}
.classified-training-detail {
    .audio-call-info-component .audio-text-view {
        height: calc(100vh - 342px);
    }
}
