.tasks-manage-template-check-poibt-index {
    display: flex;
    flex-direction: column;
    padding: 16px;
    min-width: 900px;
    a {
        &:hover {
            color: #6887ff !important;
        }
    }
    .page-content {
        .x_panel {
            .x_content {
                height: calc(100vh - 58px - 60px - 38px - 48px) !important;
                .row {
                    margin-left: 0px;
                    margin-right: 0px;
                }

                .check-point-tip {
                    height: 40px;
                    line-height: 40px;
                    font-weight: bold;

                    .check-point-icon-tishi {
                        color: #d42b2b;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .tasks-manage-template-check-poibt-index-inner {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        .tasks-manage-template-basic-info-index-conduct {
            height: 48px;
            padding: 0 16px 0 20px;
            line-height: 48px;
            background: #fff;
            border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
            display: flex;
            justify-content: space-between;
            .tasks-manage-template-basic-info-index-conduct-right {
                cursor: pointer;
                color: rgba(0, 0, 0, 0.45);
            }
        }

        .tasks-manage-template-check-point-content-left {
            width: 260px;
            min-width: 260px;
            background: #ffffff;
            .tasks-manage-template-check-point-content-left-inner {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                width: 258px;
                position: fixed;
                height: calc(100vh - 186px);
            }
            .udesk-qa-ui-anchor-wrapper {
                padding-left: 0;
                margin-left: 0;
            }
            .udesk-qa-ui-anchor {
                padding-left: 0;
                margin-left: 0;
                .udesk-qa-ui-anchor-ink {
                    display: none;
                }
            }
        }
        .tasks-manage-template-check-point-content-right {
            flex: 1;
            margin-left: 16px;
            .tasks-manage-template-check-point-content-right-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
    .tasks-manage-template-check-point-first-class-collapse {
        .udesk-qa-ui-collapse-header {
            font-weight: 600;
        }
        .udesk-qa-ui-collapse-content-box {
            padding: 0;
            .check-point-value-list {
                display: flex;
                margin-bottom: 16px;
                flex-wrap: wrap;
                .check-point-value-item {
                    // width: 254px;
                    display: flex;
                    align-items: center;
                    .check-point-value-item-input {
                        width: 212px;
                    }
                    .check-point-value-item-label {
                        display: block;
                        white-space: nowrap;
                    }
                }
                .check-point-value-item-middle {
                    margin: 0 24px;
                }
                .check-point-value-item-score-tip {
                    display: flex;
                    width: 295px;
                    align-items: center;
                }
            }
        }
    }
    .tasks-manage-template-check-point-second-class-collapse {
        // border: none;
        .udesk-qa-ui-collapse-item {
            // border: none;
            .udesk-qa-ui-collapse-content {
                // border: none;
                .udesk-qa-ui-collapse-content-box {
                    padding: 0;
                    background-color: #fff;
                    .check-point-value-list {
                        display: flex;
                        margin-bottom: 16px;
                        flex-wrap: wrap;
                        .check-point-value-item {
                            // width: 254px;
                            display: flex;
                            align-items: center;
                            .check-point-value-item-input {
                                width: 212px;
                            }
                            .check-point-value-item-label {
                                display: block;
                                white-space: nowrap;
                            }
                        }
                        .check-point-value-item-middle {
                            margin: 0 24px;
                        }
                        .check-point-value-item-score-tip {
                            display: flex;
                            width: 295px;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .udesk-qa-ui-collapse-header {
            background: #fff;
            color: rgba(0, 0, 0, 0.75);
            // padding-top: 16px !important;
            // padding-bottom: 16px !important;
            line-height: 22px;
        }
        .udesk-qa-ui-collapse-content {
            border: none;
        }
    }
}
.check-point-index-tree-modal {
    .check-point-index-tree-modal-first {
        padding: 2px 0 0;
    }
    .check-point-index-tree-modal-item {
        border: 1px solid #fff;
        border-radius: 2px;
        padding: 2px 0 0;
        &:hover {
            border: 1px solid #d9d9d9;
            transition: border 0.5s;
        }
    }
}
.check-point-index-create-modal {
    .check-point-index-create-modal-body {
        margin: -24px;
        background: rgba(0, 0, 0, 0.03);
        padding: 16px 32px;
        height: 512px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        .check-point-index-create-modal-tabs {
            text-align: center;
        }
        .check-point-index-create-modal-templates {
            margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
            .check-point-index-create-modal-templates-item {
                width: 160px;
                background-color: #fff;
                margin: 0 8px 16px;
                padding-top: 23px;
                padding-bottom: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                user-select: none;
                &:hover {
                    transform: translate3d(0, -2px, 0);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    transition: all 0.2s linear;
                }
                .check-point-index-create-modal-templates-item-name {
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
    }
}

.udesk-qa-modal-body-content .form-group .upload-file-button-container {
    width: inherit;
}
