.audio-call-basic-info-page-v2 {
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-right: none;
    transition: width 0.3s;
    -moz-transition: width 0.3s; /* Firefox 4 */
    -webkit-transition: width 0.3s; /* Safari and Chrome */
    -o-transition: width 0.3s;
    overflow: hidden;
    .udesk-qa-ui-tabs-nav {
        padding-left: 16px;
        height: 48px;
        margin-bottom: 0px;
    }
    .udesk-qa-ui-tabs-content-holder {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
    }
    .udesk-qa-ui-tabs-extra-content {
        padding-right: 5px;
    }
    .udesk-qa-ui-tabs {
        height: 100%;
    }
    .udesk-qa-ui-tabs-content {
        height: calc(100% - 45px);
    }
    .audio-text-view {
        height: 100%;
        overflow-y: auto;
    }
    .timely-scene-view {
        height: 100%;
        overflow-y: auto;
        padding: 16px;

        .timely-scene-list {
            display: flex;
            justify-content: space-between;
            .timely-scene-list-title {
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 80%;
                .timely-scene-list-score {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                }
            }
        }
        .timely-scene-list-item {
            .timely-scene-list-title {
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                .timely-scene-list-score {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }
    }
    // 滚动背景
    ::-webkit-scrollbar {
        width: 0.3rem;
        height: 0.3rem;
        background-color: rgba(0, 0, 0, 0.1);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    // 滚动条
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(27, 109, 255, 0.5);
        transition: all 0.2s;
        border-radius: 0.5rem;
    }
    .udesk-qa-ui-tabs-bar {
        margin: 0;
    }
    .call-text-list-container {
        .call-text-list {
            .call-text-list-item {
                .call-text-list-item-title {
                    position: relative;

                    &::after {
                        content: ':';
                        right: 6px;
                        position: absolute;
                    }
                }
                .call-text-list-item-content {
                    color: rgba(0, 0, 0, 0.65);
                    .call-text-list-item-content-with-copy {
                        overflow: unset;
                        text-overflow: unset;
                        white-space: unset;
                    }
                }
            }
        }
    }
}
// .aduio-call-basic-animate-small {
//     animation: myBasic 1s infinite;
//     -webkit-animation: myBasic 1s infinite;
// }
