$bg-custom: rgba(251,81,39,0.5);
$bg-custom2: rgba(48,199,63,0.5);
$bg-user: rgba(26,110,255,0.7);
$bg-size: #1b6dff;
$bg-common-color: #EFEFEF;

.pages-gong-sale-client-center-detail-container {
    .row-header {
        & > li {
            line-height: 34px;
            padding: 4px 14px 4px 0;
            position: relative;
            color: rgba(0, 0, 0, 0.85);
        }

        &.row-title-1 .label-name::before {
            background: #30C73F;
        }
        &.row-title-2 .label-name::before {
            background: #F5816F;
        }
        &.row-3 .label-name::before {
            background: #6F9FF5;
        }

        &.row-title-1,
        &.row-title-2,
        &.row-title-3 {
            >li:nth-child(2) {
                line-height: 5px;
            }
        }

        &.row-title-4 {
            >li {
                line-height: 18px;
                padding: 12px 0 10px 0;
            }
        }

        .num {
            color: #1A6EFF;
        }

        .label-name {
            display: block;
            width: 105px;
            padding-left: 14px;
            font-weight: 600;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;

            &::before {
                content: '';
                display: inline-block;
                width: 6px;
                height: 16px;
                background: #1A6EFF;
                border-radius: 20px;
                position: absolute;
                left: 0;
                top: 12px;
            }
        }
    }

    .row-data-2 {
        color: #F5816F;
        div:first-child {
            font-size: 18px;
        }
    }
    
    .row-data-3,
    .row-data-4 {
        color: #6F9FF5;
        div:first-child {
            font-size: 20px;
        }
    }


    .hover-content {
        &.empty {
            height: 50px;
            line-height: 50px;    
        }

        .hover-show-content {
            height: 50px;
            line-height: 50px; 
            display: none;
        }

        &:hover {
            .show-content {
                display: none;
            }
            .hover-show-content {
                display: block;
            }
        }
    }

    .user-comparison-proportion,
    .company-name {
        flex: 1;
        & > li {
            &:last-child {
                border-bottom-color: transparent;
            }
            white-space: nowrap;
            height: 42px;
            line-height: 31px;
            text-align: center;
            border-bottom: 1px solid #f0f2f5;
        }
        & > li.contrast-bg {
            width: 20px;
            height: 64px;
            background: rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 18px;
            left: calc(50% - 9px);
            border-radius: 20px;
            padding: 0;
        }
    }

    .user-comparison-proportion {
        position: relative;
        i {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
        .bg-custom {
            background: $bg-custom;
        }
        .bg-user {
            background: $bg-user;
        }
    }

    .customer-dynamic-chart {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        padding: 20px 0;

        > li {
            line-height: 0.9;
            i {
                position: relative;
                display: inline-block;
                width: 1px;
                height: 1px;
                border-radius: 50%;
                background: $bg-custom2;
    
                &::after {
                    position: absolute;
                    top: -7px;
                    left: -7px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: $bg-custom2;
                    content: '';
                }
            }
        }
    }

    .sales-comparison {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 20px;
        display: flex;
        border-top: 1px solid #f0f2f5;

        .udesk-qa-ui-table-thead > tr > th {
            padding: 10px 20px;
            background-color: rgba(0, 0, 0, 0.03);
            &::before {
                display: none;
            }
            &:nth-child(n + 2) {
                padding: 10px;
                color: #555;
                font-weight: 400;
                text-align: center;
            }
            &:nth-child(2n + 3) {
                background-color: rgba(0, 0, 0, 0.06);
            }
        }
        .udesk-qa-ui-table-tbody > tr > td {
            padding: 0;
            background-color: #fff;
            border-bottom-color: rgba(0, 0, 0, 0.1);
            text-align: center;

            &:nth-child(2n + 3) {
                background-color: #f7f7f7;
            }
        }

        tr td:first-child ul {
            padding-left: 20px;
            border-right: 1px solid #f0f2f5;

            &::before {
                display: none;
            }
            & > li {
                width: 100%;
                text-align: left;
            }
        }

        .user-name.left-line {
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .user-name.left-line::before {
            background: #FF7A52;
        }
    }
}