.udesk-qa-web-page {
    font-size: 14px;
    background: rgba(240, 242, 245, 1);
    .udesk-qa-web-page-body {
        .tasks-manage-template-configuration-detail-body-content {
            padding: 40px 40px 0;
            background-color: #fff;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            label {
                display: inline-flex;
            }
            // border-bottom: rgba(0,0,0,0.1) 1px solid;
            .tasks-manage-template-configuration-detail-body-footer {
                margin-top: 40px;
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 34px;
                border-top: rgba(0, 0, 0, 0.1) 1px solid;
            }
            .tasks-manage-template-configuration-detail-form {
                width: 700px;
            }
            .tasks-manage-template-configuration-detail-logic {
                margin-left: 76px;
                margin-right: 40px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                padding-top: 40px;
                margin-top: 40px;
                .tasks-manage-template-configuration-detail-logic-title {
                    margin-bottom: 24px;
                }
                .tasks-manage-template-configuration-detail-logic-score {
                    margin-bottom: 24px;
                }
                .tasks-manage-template-configuration-detail-logic-na {
                    display: flex;
                }
                .tasks-manage-template-configuration-detail-logic-rule {
                    margin-bottom: 24px;
                }
            }
            .tasks-manage-template-configuration-detail-body-footer {
                margin-top: 40px;
                padding-top: 8px;
                padding-left: 34px;
                border-top: rgba(0, 0, 0, 0.1) 1px solid;
            }
            .tasks-manage-template-configuration-detail-machine-learning {
                margin-left: 76px;
                .tasks-manage-template-configuration-detail-machine-learning-score {
                    margin-left: 20px;
                    margin-top: 24px;
                }
                .tasks-manage-template-configuration-detail-machine-learning-strategy {
                    margin-left: 20px;
                    margin-top: 29px;
                    margin-bottom: 24px;
                }
            }
            .dndflow {
                position: relative;

                .reactflow-wrapper {
                    height: 800px;
                    background-color: rgba(240, 248, 255, 0.5);
                }
            }
        }

        // 旧页面
        .deduction-input {
            display: inline-block;
            margin-right: 5px;
        }

        .rule-input {
            display: inline-block;
            margin-right: 5px;
            min-width: 140px;
            width: 100%;
        }

        .form-control-score-type {
            height: 34px !important;
            border: 0px;
        }

        .high-light-check-box {
            vertical-align: sub;
            margin-left: 8px;
        }

        .udesk-check-box {
            display: inline-block;
            // width: 49%;
        }

        .check-point-na-previous-formula-title {
            display: inline-block;
            height: 34px;
            line-height: 34px;
            margin-right: 10px;
        }

        .check-point-na-previous-formula {
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }

        .interactive-category-container {
            .category-title {
                position: relative;
                border: 1px solid #ddd;
                height: 34px;
                line-height: 34px;
                padding-left: 12px;

                .category-toggle {
                    position: absolute;
                    right: 5px;
                    top: 10px;
                }
            }

            .category-content {
                position: absolute;
                background: #fff;
                z-index: 1;
                border: 1px solid #ddd;
                width: 100%;
                height: 200px;
                overflow-y: auto;
                left: 0;
            }
        }

        // .tasks-manage-template-configuration-detail-body {
        //     background:#fff;
        //     padding: 16px;
        //     height: 100%;

        // }

        .tasks-manage-template-configuration-detail-logic-heightLight {
            margin-bottom: 24px;
        }
    }
    .data-preprocess-custom {
        position: relative;
        .index-manmagement-custom-drag-tarsk {
            position: absolute;
            text-align: center;
            top: -41px;
            z-index: 1;
            padding: 10px;
            background: rgba(0, 0, 0, 0.5);
            left: 50%;
            width: 200px;
            margin-left: -100px;
        }
        .data-preprocess-custom-drap-title {
            padding: 8px 0;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
        }
        .data-preprocess-cutom-drap-info {
            display: flex;
            width: calc(100% - 42px);
            .deleted-item {
                background-color: #ffa39e !important;
            }
        }
        display: flex;
    }
}
