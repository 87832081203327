.react-echarts {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: inherit;
    box-sizing: border-box;
    .map-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .react-echarts-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: inherit;
    }
    .echart-tooltip-header-name {
        margin-left: 5px;
        text-align: center;
    }
    .echart-tooltip-content {
        max-height: 400px;
        padding-right: 10px;
        .echart-tooltip-content-item {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 10px;
            flex-wrap: nowrap;
            justify-content: space-around;
            .echart-tooltip-title {
                overflow: hidden;
                flex: 1;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .echart-tooltip-value {
                min-width: 26px;
                height: 20px;
                margin-left: 20px;
                padding: 0 5px;
                line-height: 20px;
                color: #ffffff;
                border-radius: 3px;
                text-align: center;
            }
        }
        .echart-tooltip-content-extra-data {
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            max-height: 180px;
            word-wrap: break-word;
            word-break: break-all;
            overflow-wrap: break-word;
            .echart-tooltip-content-extra-data-item {
                margin-top: 8px;
            }
        }
    }
}