.ce-header {
  color: #000 !important;
}
.titleheader::-webkit-input-placeholder {
  color: #aaa;
  font-weight: bold;
  /* WebKit browsers */
  font-size: 30px;
}
.titleheader::-moz-placeholder {
  color: #aaa;
  font-weight: bold;
  /* Mozilla Firefox 19+ */
  font-size: 30px;
}
.titleheader:-ms-input-placeholder {
  color: #aaa;
  font-weight: bold;
  /* Internet Explorer 10+ */
  font-size: 30px;
}
.cdx-list {
  padding-left: 20px !important;
}
