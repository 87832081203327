.udesk-qa-react-safe-html {
  display: block;
  width: 100%;
  min-height: 120px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border-width: 1px;
  border: 0 solid #c5c5c5;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  img {
    width: 100%;
  }
}
