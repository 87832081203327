.customer-service-portrait-index-page {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    // height: 100%;
    background-color: rgba(240, 242, 245, 1);
    .customer-service-portrait-index-page-body {
        padding: 16px;
        .customer-service-portrait-index-page-body-contol-bar {
            width: 100%;
            background-color: rgba(255, 255, 255, 1);
            display: flex;
            flex-wrap: wrap;
            .customer-service-portrait-index-page-body-contol-bar-date {
                width: 316px;
                display: flex;
                .customer-service-portrait-index-page-body-contol-bar-date-des {
                    line-height: 32px;
                }
                .udesk-qa-ui-calendar-picker {
                    width: 246px;
                }
            }
            .customer-service-portrait-index-page-body-contol-bar-customer {
                // width: 414px;
                flex: 2;
                margin-left: 16px;
                display: flex;
                .customer-service-portrait-index-page-body-contol-bar-customer-des {
                    line-height: 32px;
                }
            }
            .customer-service-portrait-index-page-body-contol-bar-btn {
                flex: 1;
                margin-left: 19px;
                display: flex;
                justify-content: space-between;
                .udesk-qa-ui-btn-primary {
                    color: #fff;
                    background-color: #1890ff;
                    border-color: #1890ff;
                    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                }
                .btn-small {
                    margin-right: 8px;
                }
                .customer-service-portrait-index-page-body-contol-bar-btn-left {
                    display: flex;
                    margin-right: 8px;
                    .udesk-qa-ui-btn {
                        flex: 1;
                    }
                }
                .customer-service-portrait-index-page-body-contol-bar-btn-right {
                    display: flex;
                    .udesk-qa-ui-btn {
                        flex: 1;
                    }
                }
                // .customer-service-portrait-index-page-body-contol-bar-btn-right-2 {
                //     display: none;
                // }
                // @media screen and (max-width: 1434px){
                //     .customer-service-portrait-index-page-body-contol-bar-btn-right {
                //         display: none;
                //     }
                //     .customer-service-portrait-index-page-body-contol-bar-btn-right-2 {
                //         display: block;
                //     }
                // }
            }
        }
        .customer-service-portrait-index-page-body-dashboard {
            width: 100%;
            height: 100%;
            margin-top: 16px;
            display: flex;
            .customer-service-portrait-index-page-body-dashboard-left {
                flex: 63;
                background: #fff;
            }
            .customer-service-portrait-index-page-body-dashboard-right {
                flex: 37;
                padding-left: 16px;
                .customer-service-portrait-index-page-body-dashboard-right-top {
                    height: 458px;
                    background: #fff;
                    display: flex;
                    padding: 16px;
                    flex-direction: column;
                    .customer-service-portrait-index-page-body-dashboard-right-top-des {
                        display: flex;
                        justify-content: space-between;
                        .customer-service-portrait-index-page-body-dashboard-right-top-des-left {
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 22px;
                        }
                        .customer-service-portrait-index-page-body-dashboard-right-top-des-right {
                            color: rgba(27, 109, 255, 1);
                            cursor: pointer;
                        }
                    }
                    .right-top-cavas {
                        flex: 1;
                        // left: 50%;
                        // top: 0px;
                        // transform: translate(-50%, 0px);
                        // width: 80%;
                        // height: 80%;
                    }
                }
                .customer-service-portrait-index-page-body-dashboard-right-bottom {
                    background: #fff;
                    margin-top: 16px;
                    padding: 16px;
                    .customer-service-portrait-index-page-body-dashboard-right-bottom-title {
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 22px;
                        padding-bottom: 16px;
                    }
                    .customer-service-portrait-index-page-body-dashboard-right-bottom-content {
                        .customer-service-portrait-index-page-body-dashboard-right-bottom-content-label {
                            display: flex;
                            .customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-title {
                                color: rgba(0, 0, 0, 0.85);
                                line-height: 22px;
                                padding-right: 13px;
                                // text-align: right;
                                width: 90px;
                                word-break: break-all;
                            }
                            .customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-tiglist {
                                margin-bottom: 8px;
                                display: flex;
                                flex-wrap: wrap;
                                flex: 1;
                                .customer-service-portrait-index-page-body-dashboard-right-bottom-content-label-tig {
                                    margin-right: 8px;
                                    margin-bottom: 8px;
                                    min-width: 88px;
                                    padding: 1px 8px 1px;
                                    background: rgba(0, 0, 0, 0.05);
                                    border-radius: 3px;
                                    border: 1px solid rgba(0, 0, 0, 0.15);
                                    font-size: 12px;
                                    color: rgba(0, 0, 0, 0.65);
                                    line-height: 24px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.customer-service-portrait-index-page-body-madal-1 {
    .udesk-qa-ui-modal-content {
        width: 584px;
        .udesk-qa-ui-modal-header {
            padding: 16px 24px !important;
            background: #fff !important;
            border-bottom: 1px solid #e8e8e8;
            border-radius: 4px 4px 0 0;
            .udesk-qa-ui-modal-title {
                color: rgba(0, 0, 0, 0.85) !important;
            }
        }
        .udesk-qa-ui-modal-body {
            padding: 24px 24px 36px;
            font-size: 14px;
            line-height: 1.5;
            word-wrap: break-word;
            .customer-service-portrait-index-page-body-madal-1-content {
                width: 544px;
                height: 263px;
                border-radius: 3px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 8px 0;
                .customer-service-portrait-index-page-body-madal-1-content-search {
                    padding: 0 16px;
                    padding-bottom: 8px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid rgba(216, 216, 216, 1);
                }
            }
        }
        .udesk-qa-ui-modal-footer {
            padding: 10px 16px;
            text-align: right;
            background: #fff !important;
            border-top: 1px solid #e8e8e8;
            border-radius: 0 0 4px 4px;
        }
    }
}
