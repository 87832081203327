$react-alerts-header-height: 58px !default;
$react-alerts-header-fontSize: 18px !default;
$react-alerts-header-fontColor: #fff !default;
$react-alerts-header-backgroundColor: #00b38b !default;
$react-alerts-header-paddingLeft: 20px !default;
$react-alerts-header-paddingRight: 20px !default;
$react-alerts-header-icon-bi-close-hoverColor: #5b6875 !default;
$react-alerts-content-minHeight: 80px !default;
$react-alerts-content-padding: 20px !default;
$react-alerts-footer-height: 64px !default;
$react-alerts-footer-backgroundColor: #f4f5f9 !default;
$react-alerts-footer-btn-width: 106px !default;
$react-alerts-footer-btn-margin: 0 15px !default;
$react-alerts-footer-btn-cancel-color: #666666 !default;
$react-alerts-footer-btn-cancel-borderColor: #e5e5e5 !default;
$react-alerts-footer-btn-cancel-backgroundColor: #fff !default;
$react-alerts-footer-btn-cancel-hoverBorderColor: #c2c2c2 !default;
$react-alerts-footer-btn-cancel-hoverBackgroundColor: #e6e6e6 !default;
$react-alerts-footer-btn-ok-color: #fff !default;
$react-alerts-footer-btn-ok-backgroundColor: #00b38b !default;
$react-alerts-footer-btn-ok-borderColor: #00b38b !default;
$react-alerts-footer-btn-ok-hoverBorderColor: #00765b !default;
$react-alerts-footer-btn-ok-hoverBackgroundColor: #008063 !default;

@mixin display-flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.udesk-bi {
    &.react-alerts {
        .udesk-qa-ui-modal-content {
            .udesk-qa-ui-modal-body {
                padding: 0;

                .udesk-qa-ui-modal-confirm-body-wrapper {
                    .udesk-qa-ui-modal-confirm-body {
                        .anticon-question-circle {
                            display: none;
                        }

                        .udesk-qa-ui-modal-confirm-title {
                            .react-alerts-header {
                                overflow: hidden;
                                height: $react-alerts-header-height;
                                padding-left: $react-alerts-header-paddingLeft;
                                padding-right: $react-alerts-header-paddingRight;
                                font-size: $react-alerts-header-fontSize;
                                color: $react-alerts-header-fontColor;
                                line-height: $react-alerts-header-height;
                                background-color: $react-alerts-header-backgroundColor;

                                .icon-bi-close {
                                    float: right;
                                    font-weight: 700;
                                    transition: color 0.1s ease-in;
                                    cursor: pointer;

                                    &:hover {
                                        color: $react-alerts-header-icon-bi-close-hoverColor;
                                    }
                                }
                            }
                        }

                        .udesk-qa-ui-modal-confirm-content {
                            margin: 0;
                            padding: 0;

                            .react-alerts-content {
                                @include display-flex-center;
                                min-height: $react-alerts-content-minHeight;
                                padding: $react-alerts-content-padding;
                                text-align: center;
                            }

                            .react-alerts-footer {
                                @include display-flex-center;
                                height: $react-alerts-footer-height;
                                background-color: $react-alerts-footer-backgroundColor;

                                .react-alerts-footer-btn {
                                    margin: $react-alerts-footer-btn-margin; // font-size: 13px;
                                    width: $react-alerts-footer-btn-width;
                                    line-height: 1;

                                    &.react-alerts-footer-btn-cancel {
                                        color: $react-alerts-footer-btn-cancel-color;
                                        background-color: $react-alerts-footer-btn-cancel-backgroundColor;
                                        border: 1px solid $react-alerts-footer-btn-cancel-borderColor;
                                        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);

                                        &:hover {
                                            background-color: $react-alerts-footer-btn-cancel-hoverBackgroundColor;
                                            border-color: $react-alerts-footer-btn-cancel-hoverBorderColor;
                                        }
                                    }

                                    &.react-alerts-footer-btn-ok {
                                        color: $react-alerts-footer-btn-ok-color;
                                        background-color: $react-alerts-footer-btn-ok-backgroundColor;
                                        border-color: $react-alerts-footer-btn-ok-borderColor;
                                        text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);

                                        &:hover {
                                            background-color: $react-alerts-footer-btn-ok-hoverBackgroundColor;
                                            border-color: $react-alerts-footer-btn-ok-hoverBorderColor;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .udesk-qa-ui-modal-confirm-btns {
                        display: none;
                    }
                }
            }
        }
    }
}
