.task-operator-list-gather-component {
    .operator-list-item-box {
        width: 100%;
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: 5px;

        .operator-list-item-index {
            display: inline-block;
            width: 3%;
        }

        .operator-list-item-type {
            border: 0;
            display: inline-block;
            width: 20%;
            margin-right: 1%;
        }

        .operator-list-item-operator {
            border: 0;
            display: inline-block;
            width: 30%;
            margin-right: 1%;
        }

        .operator-list-item-keyword {
            display: inline-block;
            width: 40%;
            height: 32px;
            vertical-align: top;
            position: relative;
            left: -3px;
        }

        .operator-list-item-regular,
        .operator-list-item-context,
        .operator-list-item-word-number-input {
            display: inline-block;
            width: 30%;
            height: 32px;
            vertical-align: top;
            position: relative;
            left: -3px;
        }

        .operator-list-item-regular-test {
            color: #1ABB9C;
            cursor: pointer;
        }

        .operator-list-item-semantics {
            display: inline-block;
            width: 30%;
            vertical-align: top;
            position: relative;
            left: -3px;
        }

        .operator-list-item-apply-role-box {
            display: inline-block;
            width: 40%;

            .operator-list-item-apply-role-tip {
                display: inline-block;
                width: 70px;
            }

            .operator-list-item-apply-role {
                display: inline-block;
                width: calc(100% - 75px);
            }
        }

        .operator-list-item-similarity-score-box {
            display: inline-block;
            width: 40%;

            .operator-list-item-similarity-score-tip {
                display: inline-block;
                width: 45px;
            }

            .operator-list-item-similarity-score {
                display: inline-block;
                width: calc(100% - 70px);
            }
        }

        .operator-list-item-word-number-box {
            display: inline-block;
            width: 40%;

            .operator-list-item-word-number-tip {
                display: inline-block;
                width: 55px;
            }

            .operator-list-item-differ-word-number {
                display: inline-block;
                width: calc(100% - 60px);
            }
        }

        .operator-list-item-apply-scopes {
            display: inline-block;
            width: 30%;
        }

        .operator-list-item-interval-second-box {
            display: inline-block;
            width: 40%;

            .operator-list-item-interval-second-tip {
                display: inline-block;
                width: 80px;
            }

            .operator-list-item-interval-second {
                display: inline-block;
                width: calc(100% - 100px);
            }
        }

        .operator-list-item-word-per-minute-box {
            display: inline-block;
            width: 70%;

            .operator-list-item-word-per-minute {
                display: inline-block;
                // width: calc(100% - 75px);
                width: 25%;
            }
        }

        .operator-list-item-volume-detection-way {
            border: 0;
            display: inline-block;
            width: 25%;
            margin-right: 1%;
        }

        .operator-list-item-volume-detection-operator {
            border: 0;
            display: inline-block;
            width: 15%;
            margin-right: 1%;
        }

        .operator-list-item-volume-detection-value-box {
            display: inline-block;
            width: 30%;

            .operator-list-item-volume-detection-value {
                display: inline-block;
                width: calc(100% - 30px);
            }
        }

        .operator-list-item-button {
            height: 32px;
        }

        .operator-list-item-test-container-box {
            display: inline-block;
            width: 30%;
            text-align: center;
            position: relative;

            .operator-list-item-test-container {
                display: none;
                border: 1px solid #ddd;
                width: 100%;
                min-width: 200px;
                position: absolute;
                z-index: 9;
                background: #fff;
                padding: 5px 5px 0 5px;
                line-height: 25px;

                .operator-list-item-test-container-action {
                    color: #2878FF;
                    cursor: pointer;
                }
            }

            &:hover {
                .operator-list-item-test-container {
                    display: block;
                }
            }
        }

        .operator-list-item-icon-jianshao {
            height: 32px;
            vertical-align: top;
            font-size: 19px;
            position: absolute;
            right: 0;
        }

        .operator-list-item-semantic-tags-container {
            display: inline-block;
            width: 60%;
            vertical-align: top;

            .operator-list-item-semantic-tags-search-container {
                .operator-list-item-semantic-tags-sentence {
                    display: inline-block;
                    width: 60%;
                    height: 32px;
                    vertical-align: top;
                    position: relative;
                    left: -3px;
                }

                .operator-list-item-semantic-tags-button {}
            }

            .operator-list-item-semantic-tags-result-container {
                .operator-list-item-semantic-tags-result-tag {
                    display: inline-block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .operator-list-item-semantic-tags-result-sentence {
                    display: inline-block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .operator-list-item-grab-detection-box {
            display: inline-block;
            width: 70%;

            .operator-list-item-grab-input {
                display: inline-block;
                // width: calc(100% - 75px);
                width: 20%;
            }
        }

        .operator-list-item-entity-info-container {
            display: inline-block;
            width: 70%;
            vertical-align: top;

            .operator-list-item-entity-field-container {
                display: inline-block;
                width: 40%;
                vertical-align: top;
            }

            .operator-list-item-entity-operator-container {
                display: inline-block;
                width: 25%;
                vertical-align: top;

                .operator-list-item-entity-operator {
                    border: 0;
                }
            }

            .operator-list-item-entity-value-container {
                display: inline-block;
                width: 35%;
                vertical-align: top;
            }
        }

        .operator-list-item-basic-info-container {
            display: inline-block;
            width: 60%;
            vertical-align: top;

            .operator-list-item-basic-field-container {
                display: inline-block;
                width: 30%;
                vertical-align: top;
            }

            .operator-list-item-basic-operator-container {
                display: inline-block;
                width: 30%;
                vertical-align: top;
                height: 34px;
            }

            .operator-list-item-basic-value-container {
                display: inline-block;
                width: 30%;
                vertical-align: top;
            }
        }
    }

    .operator-list-item-icon {
        display: inline-block;
        font-size: 19px;
    }
}
