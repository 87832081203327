.layout-menu-pages {
    height: calc(100vh - 64px);
    .layout-second-level-header {
        background: #fff;
        padding: 15px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        .layout-second-level-header-title {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            display: inline-block;
        }
    }
    .layout-menu-pages-sider {
        position: relative;
        background: #fff;
        padding-bottom: 0px !important;
        .udesk-qa-ui-badge {
            width: 100%;
        }
        .layout-menu-pages-sider-actions-arrow {
            position: absolute;
            width: 33px;
            height: 61px;
            top: -1px;
            left: -18px;
            .icon-udesk-qa-react-web-zhankaitixing {
                font-size: 50px;
                cursor: pointer;
                color: #fff;
            }
            .icon-udesk-qa-react-web-ic-arrow-left-s-line {
                color: #000;
                font-size: 16px;
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
            }
            .icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou {
                color: #000;
                font-size: 16px;
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
            }
        }
        .udesk-qa-ui-layout-sider-trigger {
            position: absolute;
            top: 50%;
            width: 14px !important;
            background: none;
            margin-top: -48px;
            right: -14px;
            overflow: hidden;
        }
        .udesk-qa-ui-layout-sider-children {
            overflow: hidden;
            .udesk-qa-ui-menu-sub {
                &.udesk-qa-ui-menu-inline {
                    background-color: #fff;
                    border-radius: 2px;
                }
            }
        }
    }
}
