.pages-gong-home-page-container {
    padding: 0 !important;
    .pages-home-page-content-style {
        // height: calc(100vh - 260px);
        // overflow-y: auto;
        overflow: hidden;
        .level-title {
            font-weight: 700;
            font-size: 18px;
        }
        .pages-home-page-content-style-tabs {
            .udesk-qa-ui-tabs-nav-wrap {
                padding: 0 24px;
            }
        }
    }
    .use-message {
        padding: 16px;
        border-bottom: 8px solid #eee;
    }
}