.record-device-modal {
    .record-device-modal-content {
        display: flex;
        justify-content: space-between;
        overflow: auto;

        > div {
            flex: 1;

            &:nth-child(1) {
                overflow: auto;
                padding-right: 24px;
            }
            &:nth-child(2) {
                overflow: auto;
                padding-left: 24px;
            }
        }

        .task-info {
            padding: 16px 16px 16px 30px;
            border-radius: 4px;
            background-color: #F5F6F8;

            &.blue {
                --color: #1A6EFF;
            }

            &.green {
                --color: #0FBA26;
            }

            > div {
                position: relative;
                display: flex;
                justify-content: space-between;
                
                &::before {
                    position: absolute;
                    top: 8px;
                    left: -14px;
                    width: 5px;
                    height: 5px;
                    border-radius: 5px;
                    background-color: var(--color);
                    content: '';
                }

                + div {
                    margin-top: 12px;
                }

                > div:first-child {
                    color: rgba(0, 0, 0, 0.45);
                }

                > div:last-child {
                    max-width: 160px;
                    margin-left: 16px;
                    color: rgba(0, 0, 0, 0.85);
                }

                > div.blue {
                    color: #1A6EFF;
                    background-color: transparent;
                }
            }

            + .task-info {
                margin-top: 12px;
            }
        } 

        .task-info-description {
            > div {
                font-weight: 500;
                padding: 14px 0;
                color: rgba(0, 0, 0, 0.85);
            }
        }

        .device-label 
        .device-info {
            color: rgba(0, 0, 0, 0.85);
        }
        
        .device-info {
            padding-top: 16px;
           
            li.udesk-qa-ui-timeline-item:last-child {
                padding-bottom: 0;

                .udesk-qa-ui-timeline-item-tail {
                    display: none;
                }
            }
        }

        .device-info .udesk-qa-ui-timeline-item-content{
            > span {
                font-size: 13px;
                color: rgba(0, 0, 0, 0.45);
            }

            > div.wave {
                height: 40px;
            }

            > div.video-panel {
                margin-top: 12px;
                background: black;
                border-radius: 4px;
                min-height: 200px;
                display: flex;
                flex-flow: column;
                justify-content: center;

                > div {
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 14px;
                    opacity: 0.8;

                    >span:first-child {
                        margin-bottom: 8px;
                    }
                }

                > video {
                    border-radius: 4px;
                    transform: rotateY(180deg);
                }
            }
        }
    }

    &.double .udesk-qa-ui-modal-body {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top:0;
            bottom: 0;
            width: 1px;
            left: 50%;
            background-color: #e8e8e8;
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;