.tasks-approve-index {
    height: 100%;

    > div,
    > div> div,
    > div> div> div,
    > div> div> div> div,
    > div> div> div> div> div,
    > div> div> div> div> div> div {
        height: 100%
    }

    table {
        colgroup col:first-child {
            width: 0!important;
        }
        tr:not(.udesk-qa-ui-table-placeholder) td:first-child,
        tr:not(.udesk-qa-ui-table-placeholder) th:first-child {
            padding: 0;
           > * {
            display: none!important;
           }
        }
        tr td:last-child {
            button {
                padding: 4px 0;
            }
        }
    }
}