.role-manage-entry {
    padding: 0 16px;
    .create-role {
        padding: 24px;
        background: #fff;
        border-radius: 2px;

        .create-role-form {
            max-width: 800px;
            .udesk-qa-ui-form-item-control {
                width: 600px;
                flex: none;
            }   
        }
    }
}
