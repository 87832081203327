.udesk-loading-animation {
    position: absolute;

    &.animation-invisible {
        display: none;
    }

    &.left {
        left: 0;
    }

    &.center {
        left: 50%;
        transform: translateX(-50%);
    }

    &.right {
        right: 0;
    }

    &.middle {
        top: 50%;
        transform: translateY(-50%);
    }

    &.bottom {
        bottom: 0;
    }

    &.center.middle {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.animation-indicator {
        @import "animations/indicator.scss";
    }

    &.animation-moving-balls {
        width: 100%;
        min-width: 500px;

        @import "animations/moving-balls.scss";
    }

    &.animation-pulse {
        width: 100%;

        @import "animations/pulse.scss";
    }

    &.animation-line-scale-pulse-out {
        @import "animations/loader.css/line-scale-pulse-out.scss";
    }

    &.animation-line-scale-pulse-out-rapid {
        @import "animations/loader.css/line-scale-pulse-out-rapid.scss";
    }

    &.animation-ball-scale-multiple {
        @import "animations/loader.css/ball-scale-multiple.scss";
    }

    &.animation-ball-scale-ripple-multiple {
        @import "animations/loader.css/ball-scale-ripple-multiple.scss";
    }

    &.animation-line-spin-fade-loader {
        @import "animations/loader.css/line-spin-fade-loader.scss";
    }
}
