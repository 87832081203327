.semantic-tags-index-page {

    .semantic-tags-index-page-body {
        padding: 16px;
        .semantic-tags-index-page-body-search-container {}

        .semantic-tags-index-page-body-content-container {
            .udesk-qa-auto-size-table-container {
                margin-bottom: 16px;
            }
            .semantic-tags-item {
                display: block;
            }
            .qa-react-page-body-content-table-suggestion {
                .udesk-qa-ui-btn {
                    padding: 0px;
                }
            }
        }
    }
}