.udesk-qa-strategy-knowledge-list {
    .udesk-qa-list-item {
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
        > div {
            flex: 1;
            padding-left: 8px;
        }
    }
}
