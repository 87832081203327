.system-log-index-page {
    padding: 16px;
    .system-log-index-page-header {
        display: flex;
        align-items: center;
    }
    .system-log-index-page-body {
        margin-top: 16px;
    }
    .udesk-qa-ui-drawer-body {
        padding: 16px;
    }
}
.system-log-index-page-wrapper {
    position: relative;
    overflow-x: hidden;
}
.system-log-index-page-drawer {
    .system-log-index-page-drawer-list {
        display: flex;
        flex-direction: column;
        // margin: -8px;
        height: calc(100% + 16px);
        overflow-y: auto;
        .system-log-index-page-drawer-item {
            margin-bottom: 8px;
            display: flex;
            .system-log-index-page-drawer-item-label {
                min-width: 56px;
            }
            .system-log-index-page-drawer-item-content {
                flex: 1;
                word-break: break-all;;
            }
        }
    }
    .udesk-qa-ui-drawer-body {
        padding: 16px;
    }

}