$component-react-modal-ant-modal-header-backgroundColor:#00b38b !default;
$component-react-modal-ant-modal-header-title-fontColor:#fff !default;
$component-react-modal-ant-modal-header-close-modal-hoverColor:#ccc !default;
$component-react-modal-ant-modal-footer-backgroundColor:#f4f5f9 !default;
.ant-modal-wrap {
    .ant-modal {
        top: 70px;
        width: 60%;
        min-width: 550px;
        .ant-modal-content {
            .ant-modal-header {
                position: relative;
                background-color: $component-react-modal-ant-modal-header-backgroundColor;
                .ant-modal-title {
                    color: $component-react-modal-ant-modal-header-title-fontColor;
                    .close-modal {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        cursor: pointer;
                        &:hover {
                            color: $component-react-modal-ant-modal-header-close-modal-hoverColor;
                        }
                    }
                }
            }
            .ant-modal-body {
                padding: 20px;
            }
            .ant-modal-footer {
                padding: 15px;
                background-color: $component-react-modal-ant-modal-footer-backgroundColor;
            }
        }
    }
}