.udesk-component-new-version {
  position: relative;
  padding: 15px 5px;
  background-color: #ffe6b3;
  color: #071524;
  text-align: center;
  z-index: 9999;
}
.udesk-component-new-version .new-version-close {
  position: absolute;
  top: 10px;
  right: 5px;
}
.udesk-component-new-version .new-version-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #5cb85c;
  color: #fff;
  outline: none;
  font-size: 12px;
  border: none;
}
.udesk-component-new-version .new-version-button:hover {
  background-color: #449d44;
}

