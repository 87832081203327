.user-view {
	.detail-list{
		margin-top: 20px;
	}
	.role-list,
	.user-group-list,
	.authorization-list{
		padding-left: 0;
		list-style: none;
		.role-item,
		.user-group-item,
		.authorization-item{
			float: left;
			margin-right: 1rem;
		}
	}
	.avatar-row{
		min-height: 60px;
		overflow: hidden;
	}
}
