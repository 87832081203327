.score-detail-card {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    &.right {
        align-items: flex-end;

        .score-detail-card-tags {
            justify-content: flex-end;

            .score-detail-card-open-btn {
                justify-content: flex-start;
                align-items: center;
            }
        }
    }

    .score-detail-card-tags {
        display: inline-flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        width: 100%;
        margin-top: 8px;

        > span {
            height: 26px;
            line-height: 24px;
            margin: 4px;
        }

        .score-detail-card-open-btn {
            flex: 1;
            display: inline-flex;
            justify-content: flex-end;

            button.open {
                span[role='img'] {
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    .review-card-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 2px 12px;
        border-radius: 4px;
        background-color: #fff;

        > span {
            color: rgba(0, 0, 0, 0.45);
            &.changed {
                color: #1b6dff;
            }
        }

        > div {
            display: inline-flex;
            flex: 1;
            color: #f09a00;

            > span {
                padding-left: 4px;
                font-size: 16px;
            }

            > p {
                padding: 0 12px;
                white-space: wrap;

                span + span {
                    &::before {
                        content: '，';
                    }
                }
            }
        }
    }

    .score-detail-card-content {
        text-align: start;
        width: 100%;
        padding: 16px;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid #eee;
        background-color: #fff;
    }

    .score-detail-item {
        width: 100%;
        margin-bottom: 8px;

        button {
            padding: 0;
            width: 100%;
            text-align: left;

            span[role='img'] {
                position: relative;
                top: -2px;
            }

            .score-detail-item-title {
                width: calc(100% - 24px);
                display: inline-flex;
                justify-content: space-between;

                color: #555;
                margin-left: 8px;

                > span:first-child {
                    font-weight: 600;
                }
            }
        }

        .standard-words {
            width: 100%;
            padding: 16px;
            background: #f5f6f8;
            align-items: flex-start;

            .standard-words-title {
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.45);

                &::after {
                    content: '：';
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
    }

    .font-blue {
        color: #1a6eff;
    }

    .font-gold {
        color: #d48806;
    }

    .font-red {
        color: #fb5127;
    }

    .font-green {
        color: #0fba26;
    }
}

.udesk-qa-ui-score-tag {
    > span {
        max-width: 200px;
        overflow: hidden;
        vertical-align: top;
        display: inline-block;
        text-overflow: ellipsis;
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;