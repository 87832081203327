.wechat-qa-chart-record-detail {
    display: flex;
    padding: 16px;
    height: 100%;
    .wechat-qa-chart-record-detail-left-filter {
        height: 100%;
        overflow-y: hidden;
        .udesk-qa-ui-menu-item {
            width: 100%;
        }
        .udesk-qa-ui-input-search {
            .udesk-qa-ui-input-search-button {
                justify-content: center;
                display: flex;
                align-items: center;
            }
        }
        .udesk-qa-ui-spin-nested-loading {
            height: 100%;
            .udesk-qa-ui-spin-container {
                height: 100%;
                display: flex;
                flex-direction: row;
                ::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
                    height: 5px;
                }
                ::-webkit-scrollbar-thumb {
                    /*滚动条里面小方块*/
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
                    background: #e0e5eb;
                }
                ::-webkit-scrollbar-track {
                    /*滚动条里面轨道*/
                    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                    border-radius: 0;
                    // background: rgba(0,0,0,0.1);
                }
                .wechat-qa-chart-record-detail-left-filter-list {
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 100%;
                    margin-right: 16px;
                    // min-width: 250px;
                    transition: width 300ms ease;
                    width: 250px;
                    display: flex;
                    flex-direction: column;
                    &.closed {
                        min-width: 0;
                        width: 0;
                        margin-right: 0px;
                    }
                    .wechat-qa-chart-record-detail-left-filter-list-content {
                        flex: 1;
                        overflow-y: auto;
                        overflow-x: hidden;
                        width: 250px;
                        .udesk-qa-ui-spin-container {
                            width: 100%;
                            .udesk-qa-ui-empty {
                                width: 100%;
                            }
                        }
                        .udesk-qa-ui-tree {
                            width: 100%;
                        }
                        .udesk-qa-ui-tree-indent-unit {
                            width: 8px;
                        }
                        .udesk-qa-ui-tree-node-content-wrapper {
                            overflow-x: hidden;
                            display: flex;
                            .udesk-qa-ui-tree-title {
                                overflow-x: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .udesk-qa-ui-menu-item {
                            width: 100%;
                        }
                        .udesk-qa-ui-menu-item-selected {
                            color: #fff;
                            background: linear-gradient(90deg, #2C7CF6 0%, rgba(44, 149, 246, 0.8) 100%);
                            .wechat-qa-chart-record-detail-left-filter-list-item {
                                
                                .wechat-qa-chart-record-detail-left-filter-list-item-left {
                                    color: #fff;
                                }
                                .wechat-qa-chart-record-detail-left-filter-list-item-right {
                                    color: #fff;
                                }
                            }
                            
                        }
                        .wechat-qa-chart-record-detail-left-filter-list-item {
                            // height: 74px;
                            width: calc(100% - 32px);
                            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                            margin: 0 16px;
                            padding: 16px 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .wechat-qa-chart-record-detail-left-filter-list-item-right {
                                color: rgba(0, 0, 0, 0.25);
                                font-size: 12px;
                            }
                            .wechat-qa-chart-record-detail-left-filter-list-item-left {
                                color: rgba(0, 0, 0, 0.85);
                                flex: 1;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                margin-right: 8px;
                            }
                        }
                        .udesk-qa-ui-menu-item {
                            height: unset;
                            line-height: 22px;
                            margin: 0;
                            padding: 0 !important;
                        }
                    }
                    .employee-icon {
                        background: rgba(0, 0, 0, 0.25);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin: 8px auto;
                    }
                }
            }
        }
    }
    .wechat-qa-chart-record-detail-main {
        height: 100%;
        flex: 1;
        display: flex;
        .wechat-qa-chart-record-detail-left {
            // display: flex;
            // flex-direction: column;
            height: 100%;
            padding: 16px 8px;
            width: 60%;
            background-color: rgba(0, 0, 0, 0.03);
            .udesk-qa-ui-radio-button-wrapper {
                overflow: hidden;
            }
            .wechat-qa-chart-record-detail-left-chat-record {
                height: calc(100% - 48px);
            }    
        }
        .wechat-qa-chart-record-detail-right {
            flex: 1;
            margin-left: 16px;
            width: 40%;
            .udesk-qa-ui-tabs {
                height: 100%;
                .udesk-qa-ui-tabs-content-holder {
                    height: 100%;
                    overflow-y: auto;
                    .call-text-list {
                        padding: 0;
                    }
                    // .call-text-list-item {
                    //     margin-bottom: 0;
                    //     height: 30px;
                    //     // padding-bottom: 8px;
                    //     .call-text-list-item-title {
                    //         padding-right: 8px;
                    //         padding-bottom: 8px;
                    //         border-right: 1px solid #f0f0f0;
                    //     }
                    //     .call-text-list-item-content {
                    //         padding-left: 8px;
                    //     }
                    // }
                    .apply-templates-component {
                        border: 1px solid #f0f0f0;
                    }
                    .apply-templates-title {
                        background: #fff;
                        height: 38px;
                        line-height: 38px;
                        padding-left: 12px;
                        border-bottom: 1px solid #f0f0f0;
                    }
                }
            }
            .udesk-qa-ui-card-body {
                padding: 16px;
                .udesk-qa-ui-tree-indent-unit {
                    position: relative;
                    height: 100%;
                }
                .udesk-qa-ui-tree-indent-unit:before {
                    position: absolute;
                    top: 0;
                    right: 12px;
                    bottom: -4px;
                    border-right: 1px solid #d9d9d9;
                    content: "";
                }
                .udesk-qa-ui-tree-node-content-wrapper {
                    width: 80%;
                }
                .timely-scene-list {
                    display: flex;
                    justify-content: space-between;
                    .timely-scene-list-title {
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 80%;
                        .timely-scene-list-score {
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.85);
                        }    
                    }
                }
                .timely-scene-list-item {
                    .timely-scene-list-title {
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.65);
                        .timely-scene-list-score {
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.65);
                        }    
                    }
                }
            }
            .smart-tags-component {
                border: none;
                margin: 0px;
                .smart-tags-title {
                    display: none;
                }
                .smart-tags-content {
                    min-height: unset;
                    margin: 0px;
                }
            }
        }
    }

}