.record-upload-base-info-component {
    width: 100%;
    label {
        &.udesk-qa-ui-radio-button-wrapper {
            padding: 0 15px;
        }
    }
    .udesk-qa-ui-form-item-has-error {
        .udesk-qa-ui-upload {
            .udesk-qa-ui-btn {
                border-color: #ff4d4f !important;
            }
        }
    }
    .udesk-qa-ui-row {
        &.udesk-qa-ui-form-item {
            &:last-child {
                margin-bottom: 16px;
            }
        }
    }
    .upload-excel-tips-item {
        max-width: 350px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 20px;
    }
    .uploaded-file-btn {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        padding: 5px 8px;
        position: relative;
        color: rgba(0, 0, 0, 0.65);
        .uploaded-file-btn-inner {
            max-width: 200px;
            overflow: hidden;    
            text-overflow:ellipsis;    
            white-space: nowrap;
        }
        .uploaded-file-btn-close {
            display: none;
        }
        &:hover {
            .uploaded-file-btn-close {
                color: rgba(0, 0, 0, 0.45);
                position: absolute;
                top: -11px;
                right: -6px;
                font-size: 16px;
                display: block;
                cursor: pointer;
            }    
        }
    }
}