.customer-service-grounp-field {
    .customer-service-grounp-field-flowshow {
        padding-left: 15px;
    }
    .udesk-qa-ui-collapse-item {
        margin-bottom: 0px !important;
    }
    .udesk-qa-ui-collapse > .udesk-qa-ui-collapse-item > .udesk-qa-ui-collapse-header {
        padding: 5px 16px;
        padding-left: 40px;
    }
    .udesk-qa-ui-collapse-borderless
        > .udesk-qa-ui-collapse-item
        > .udesk-qa-ui-collapse-content
        > .udesk-qa-ui-collapse-content-box {
        padding-top: 0px;
        padding-bottom: 10px;
    }
}
.customer-service-edit-list {
    background: #fff;
    .udesk-qa-ui-tooltip-inner {
        background: #fff;
    }
    p {
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
    }
}
.customer-service-node {
    display: flex;
    justify-content: space-between;
}
