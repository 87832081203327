.quality-check-score-container {
    margin: 10px 0;
    border: 1px solid #ddd;
    height: calc(100% - 20px - 2px);
    position: relative;

    .quality-check-score-header {
        position: absolute;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #ddd;
        padding: 0px 10px;
        width: 100%;

        .quality-check-score-header-content {
            display: flex;
            align-items: center;

            .quality-check-actual-score {
                display: inline-block;
                width: 100px;
            }

            .quality-check-actual-grade {
                display: inline-block;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .quality-check-score-header-button {
            margin: 7px 5px;
        }
    }

    .quality-check-score-body {
        position: relative;
        top: 40px;
        height: calc(100% - 40px);
        overflow: auto;

        .quality-check-score-category {
            padding: 0;
            min-height: 100%;
            border-right: 1px solid #ddd;

            .quality-check-score-first-category {
                height: 40px;
                line-height: 40px;
                background: #cbf3ee;
                margin: 0;
                padding: 0 10px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .quality-check-score-second-category {
                height: 40px;
                line-height: 40px;
                margin: 0;
                padding: 0 10px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    background: #eee;
                }

                &.active {
                    background: #ddd;
                }
            }

            .quality-check-score-remark {
                padding: 0 10px;
                margin-top: 20px;

                .quality-check-score-remark-title {
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                .quality-check-score-remark-content {
                    .quality-check-score-remark-textarea {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .quality-check-score-points {
            padding: 0px;
            height: 100%;

            .quality-check-score-points-title {
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #ddd;
                padding: 0px 10px;
            }

            .quality-check-score-point {
                // height: 60px;
                border-bottom: 1px dashed #ddd;
                // display: flex;
                // justify-content: space-around;

                .quality-check-score-point-icon {
                    display: inline-block;
                    width: 60px;
                    height: 100%;
                    // border-right: 1px dashed #ddd;
                    text-align: center;

                    .udesk-qa-react-web-iconfont {
                        font-size: 20px;
                        display: inline-block;
                        margin-top: 25%;
                    }
                }

                .quality-check-score-point-content {
                    display: inline-block;
                    width: calc(100% - 60px);
                    height: 100%;
                    vertical-align: bottom;
                    padding: 10px;
                    border-left: 1px dashed #ddd;

                    .quality-check-score-point-score {
                        // height: 30px;
                        // line-height: 30px;
                        overflow: hidden;

                        .quality-check-score-point-col {
                            // width: 20%;
                            display: inline-block;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-right: 5px;
                        }

                        .quality-check-score-point-hit {
                            // width: 10%;
                            display: inline-block;
                            overflow-x: hidden;

                            .hit-btn {
                                cursor: pointer;
                            }
                        }

                        .quality-check-score-point-na {
                            display: inline-block;
                            // width: 10%;
                        }

                        .quality-check-score-point-type {
                            display: inline-block;
                            // width: 40%;
                            overflow-x: hidden;
                            text-align: right;

                            .point-type-radios {
                                .point-type-radio-item {
                                    width: 40px;
                                    margin-left: 5px;
                                }
                            }
                        }

                        .quality-check-score-point-check {
                            display: inline-block;
                            // width: 10%;
                            vertical-align: top;
                            text-align: center;
                            margin-left: 5px;

                            .quality-check-score-point-check-action {
                                vertical-align: sub;
                            }
                        }
                    }

                    .quality-check-score-point-remark {
                        height: 25px;
                        line-height: 25px;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 300px;
                    }
                }
            }
        }
    }
}