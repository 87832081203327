$udesk-pager-page-size-border: 1px solid #e1e1e1 !default;
$udesk-pager-goto-border: 1px solid transparent !default;
$udesk-pager-goto-focus-border: 1px solid #e1e1e1 !default;

.appeal-checking-task-sdk {
    display: flex;
    font-size: 14px;
    .btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 12px;
        line-height: 1.53847;
        border-radius: 3px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .btn-primary {
        color: #fff;
        background-color: #00B38B;
        border-color: #00B38B;
    }
    .appeal-checking-task-list-container {
        min-width: 400px;
    }
    .appeal-checking-task-detail-container {
        flex: 1;
        .appeal-checking-task-detail-box {
            .appeal-checking-task-detail-info {
                display: flex;
                .appeal-checking-task-detail-info-left {
                    flex: 1;
                    width: 50%;
                }
                .appeal-checking-task-detail-info-right {
                    flex: 1;
                    width: 50%;
                    .quality-check-score-header {
                        display: flex;
                        justify-content: space-between;
                    }
                    .quality-check-score-body {
                        display: flex;
                        .quality-check-score-category {
                            width: 25%;
                        }
                        .quality-check-score-points {
                            width: 75%;
                        }
                    }
                }
            }
        }
    }
}

#udeskQaSdkInstance {
    .udesk-qa-web-route-entry {
        height: 100%;
        .quality-checking-task {
            height: 100%;
        }
    }
}