.billing-center-index-page {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    height: 100%;
    .billing-center-index-page-body {
        background-color: rgba(240, 242, 245, 1);
        .billing-center-index-page-dashboard {
            overflow: hidden;
            display: flex;
            .billing-center-index-page-dashboard-quotaes {
                flex: 1;
                position: relative;
                height: 126px;
                background: rgba(255, 255, 255, 1);
                border-radius: 3px;
                .billing-center-index-page-dashboard-warning {
                    // position: absolute;
                    left: 100px;
                    top: 16px;
                    color: rgba(251, 81, 39, 1);
                    font-weight: 500;
                    display: inline;
                }
                .billing-center-index-page-dashboard-title {
                    // position: absolute;
                    // left: 16px;
                    // top: 16px;
                    padding: 16px 16px 0;
                    font-weight: 500;
                }
                .billing-center-index-page-dashboard-item-cover {
                    display: flex;
                    position: absolute;
                    bottom: 24px;
                    width: 100%;
                    padding-left: 28px;
                    .billing-center-index-page-dashboard-item-left {
                        height: 32px;
                        flex: 1;
                        overflow: hidden;
                        .billing-center-index-page-dashboard-item-icon {
                        }
                        .billing-center-index-page-dashboard-item-description-title {
                            width: 70px;
                            margin-left: 12px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .billing-center-index-page-dashboard-item-description-btn {
                            margin-left: 4px;
                            float: left;
                            line-height: 40px;
                            cursor: pointer;
                            color: #1b6dff;
                        }
                    }
                    .billing-center-index-page-dashboard-item-right {
                        height: 32px;
                        flex: 1;
                        overflow: hidden;
                        .billing-center-index-page-dashboard-item-description-title {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .billing-center-index-page-table {
            background: rgba(255, 255, 255, 1);
            margin-top: 16px;
            padding: 16px;
            .billing-center-index-page-table-time-filter {
                overflow: hidden;
                .billing-center-index-page-table-time-filter-description {
                    float: left;
                    width: 70px;
                    line-height: 32px;
                }
                .billing-center-index-page-table-time-filter-checkbox {
                    float: left;
                    width: 246px;
                    height: 32px;
                    margin-right: 16px;
                    .udesk-qa-ui-calendar-picker {
                        width: 246px !important;
                        .udesk-qa-ui-calendar-range-picker-input {
                            width: 90px;
                        }
                    }
                }
                .search-btn {
                    float: left;
                    .udesk-qa-ui-btn-primary {
                        background: rgba(27, 109, 255, 1);
                        border-color: rgba(27, 109, 255, 1);
                        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
                        margin-right: 8px;
                    }
                }
            }
            .billing-center-index-page-table-content {
                padding-top: 16px;
                td {
                    padding: 10px 16px;
                }
            }
            .billing-center-index-page-table-size-changer {
                margin-top: 16px;
                text-align: end;
            }
            // .billing-center-index-page-table-size-changer {
            //     margin-top: 16px;
            //     position: relative;
            //     width: 100%;
            //     .billing-center-index-page-table-size-changer-text {
            //         position: absolute;
            //         line-height: 32px;
            //         color: rgba(0, 0, 0, 0.45);
            //     }
            //     .udesk-qa-ui-pagination {
            //         width: 100%;
            //         text-align: right;
            //         .udesk-qa-ui-pagination-total-text {
            //             position: absolute;
            //             left: 188px;
            //             color: rgba(0, 0, 0, 0.45);
            //         }
            //     }
            //     .udesk-qa-ui-pagination-options {
            //         text-align: center;
            //         position: absolute;
            //         left: 64px;
            //         color: rgba(0, 0, 0, 0.45);
            //     }
            // }
        }
    }
}

.billing-center-index-page-dashboard-item-description-quota,
.billing-center-index-page-dashboard-item-description-suffix {
    font-size: 24px;
    font-weight: 500;
    float: left;
}

.billing-center-index-page-dashboard-item-description-title {
    float: left;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
}

.billing-center-index-page-left {
    float: left;
}
.billing-center-index-page-right {
    float: right;
    margin-left: 16px;
}
.udesk-qa-react-web-iconfont {
    font-family: 'udesk-qa-react-web-iconfont' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.billing-center-index-page-text {
    color: #7161cc;
    line-height: 32px;
    font-size: 26px !important;
}
.billing-center-index-page-call {
    color: #1b6dff;
    line-height: 32px;
    font-size: 26px !important;
}
