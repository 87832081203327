.semantic-intelligence {
    margin: 0;

    .semantic-intelligence-nav {
        background-color: #FFF;
        height: calc(100vh - 64px);
        overflow-y: auto;
        border: 1px solid #E6E9ED;
        padding: 0;
    }

    .semantic-intelligence-content {
        height: calc(100vh - 64px);
        overflow-y: auto;
    }
}