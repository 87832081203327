.qa-react-record-detail-page {
    height: 100%;
    overflow: auto;
    .qa-react-record-detail-page-info {
        .smart-tags-component {
            margin-top: 0;
        }

        .qa-react-record-detail-media-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            .qa-react-record-detail-media-video {
                flex: 280px 0 0;
            }

            .qa-react-record-detail-media-audio {
                flex: 1;
            }
        }

        .qa-react-record-detail-emotion-analysis-container {
            position: relative;

            .qa-react-record-detail-emotion-button {
                cursor: pointer;
                position: absolute;
                right: 0px;
                z-index: 1;
            }

            .qa-react-record-detail-emotion-analysis {
                height: 131px;
            }
        }

        .qa-react-record-detail-content {
            // 智能标签92px
            height: calc(100vh - 92px - 64px - 64px - 58px);
            min-height: 534px;
            margin: 0;

            &.with-audio {
                // 智能标签92px；声道11px；播放器168px
                height: calc(100vh - 92px - 64px - 64px - 182px - 58px);
                min-height: 534px;
            }

            &.with-emotion {
                // 智能标签92px；情绪分析180px
                height: calc(100% - 92px - 180px);
            }

            .qa-react-record-detail-content-left-part {
                height: 100%;
                padding-left: 0;

                .udesk-qa-ui-tabs-content {
                    height: calc(100% - 45px);
                }

                .audio-text-view {
                    height: 100%;
                }
            }

            .qa-react-record-detail-content-right-part {
                height: 100%;
                padding-right: 0;
                overflow-y: auto;
                .qa-react-record-detail-content-templates {
                    .apply-templates-component {
                        height: 100%;
                        min-height: 44px;
                    }
                }

                .qa-react-record-detail-content-word-cloud {
                    border: 1px solid #e6e6e6;
                    height: 347px;
                    background-color: #fff;

                    .udesk-qa-ui-tabs {
                        height: 100%;
                    }
                    .key-words-cloud {
                        height: 300px;
                    }
                    .udesk-qa-ui-tabs-bar {
                        margin-bottom: 0px;
                        background: rgba(0, 0, 0, 0.03);
                    }
                    .udesk-qa-ui-tabs-nav-list {
                        margin-left: 16px;
                    }
                    .udesk-qa-ui-tabs-nav {
                        margin: 0px;
                        background-color: rgba(0, 0, 0, 0.03);
                    }
                    .report-echarts-main {
                        border: none;
                    }
                }
            }
        }

        .qa-react-record-detail-page-no-value {
            text-align: center;
        }
    }
}
