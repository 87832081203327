.home-page-to-do-list-container {
    height: calc(100vh - 310px);
    overflow-y: auto;
    // padding-bottom: 16px;
    .components-common-to-do-list-container {
        width: 100%;
        .user-name-message {
            cursor: pointer;
             h3 {
                color: #1A6EFF;
                font-size: 14px;
            }
        }
        .components-common-to-do-list-actions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .udesk-qa-ui-btn-link {
                padding: 4px 0;
            }
        }
        .replay-content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            padding: 6px 16px;
            background: #F5F6F8;
        }
    }
}
.pages-home-page-call-list-content {
    height: calc(100vh - 376px);
    overflow-y: auto;
}
