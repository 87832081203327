.sale-business-user-info {
    padding: 16px 15px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.03);

    .udesk-qa-ui-descriptions-view {
        th {
            width: 120px;
            
            &:last-child {
                width: auto;
            }

            .udesk-qa-ui-descriptions-item-label{
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}