.customer-insight-tab-content {
    height: 100%;

    div.customer-insight-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px 24px;
        margin: -16px -16px 16px -16px;
    }

    .customer-insight-card {
        margin: 0px -16px 16px;
        height: calc(100% - 60px);
        overflow: auto;
        padding: 0 16px;

        > div:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 8px;
        }
    }
}
