.page-header-monit-dropdown{
    .icon-udesk-qa-react-web-zhankai1 {
        margin-top: -9px;
    }
}

.page-header-monit-dropdown-overlay {
    background: #fff;
    padding: 5px!important;
    box-shadow: -2px 1px 10px -5px #a5a5a5;

    .tit-overs {
        width: 210px;
    }

    .drop-list-ul {
        max-height: 210px;
        margin: 0 -5px;
        padding-top: 10px;
        overflow-y: auto;

        li {
            position: relative;
            padding: 0 10px;
            height: 38px;
            line-height: 38px;
            cursor: pointer;
            overflow: hidden;

            &:last-child {
                border: none;
            }

            &:hover {
                background-color: #f7f7f7;

                .tit-overs{
                    width: 185px;
                }

                div.tools {
                    > button.udesk-qa-ui-btn-text {
                        display: inline-block;
                    }
                }
            }

            div.name {
                width: 100%;
                height: 100%;
                color: #555;

                > input {
                    padding-right: 116px;
                }

            }

            div.tools {
                position: absolute;
                top: 0;
                right: 4px;
                background-color: transparent;

                > button {
                    top: 2px;
                    color: #b9b9b9;

                    &:hover {
                        color: #adadad;
                    }

                    > span {
                        margin: auto;
                    }
                }

                > button.udesk-qa-ui-btn-text {
                    display: none;
                }
            }
        }
    }
}

.tit-overs {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
