.customer-information-tags-index-page {
    padding: 16px;
    display: flex;
    flex-direction: column;
    .customer-information-tags-index-page-body {
        flex: 1;
        display: flex;
        .customer-information-tags-index-page-body-right {
            flex: 1;
        }
    }
    .customer-information-index-page-body-content-container {
        display: flex;
        height: calc(100% - 44px);
        .customer-information-index-page-body-content-container-left {
            width: 240px;
            padding: 5px;
            margin-right: 5px;
            border: 1px solid #e8e8e8;
            height: 100%;
        }
        .customer-information-index-page-body-content-container-right {
            overflow-x: auto;
            width: 100%;
        }
    }
}

.udesk-qa-customer-information-modal-header {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
}
.customer-information-tags-index-page-body-search-input {
    display: inline-block;
    width: 170px;
}
.customer-information-chekcbox {
    margin-left: 10px !important;
    margin-right: 3px !important;
}
.customer-information-modal {
    .udesk-qa-ui-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #fff;
    }
    .udesk-qa-ui-modal-header {
        height: 40px;
        padding: 0 10px;
        line-height: 40px;
        .udesk-qa-ui-modal-title {
            height: 40px;
            line-height: 40px;
        }
    }
    .udesk-qa-ui-modal-footer {
        .udesk-qa-ui-btn {
            background: #fff;
            color: rgba(8, 9, 10, 0.75);
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 3px 15px;
        }
        .udesk-qa-ui-btn-primary {
            background: #00b38b;
            color: #fff;
            border: 1px solid #00b38b;
            border-radius: 3px;
            padding: 3px 15px;
        }
    }
    .customer-information-modal-grounp-field {
        display: flex;
        flex-wrap: wrap;
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            text-align: center;
            line-height: 16px;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .udesk-qa-ui-legacy-form-item label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
