.learning-center-exercise-body.call {
  height: 100%;

  > div,
  > div> div,
  > div> div> div,
  > div> div> div> div {
    height: 100%;
  }

  > div> div> div> div> div> div {
    height: 100%;
    background-color: #fff;
  }

  

  .other-panel {
    width: 100%;
    background-color: transparent;

    > div {
      flex: 1;
      overflow: auto;
      background-color: #fff;
    }

    > div:nth-child(1) {
      flex: 0;
      overflow: visible;
    }
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;