.words-mining-favorite-page-left.selling-point{
    .table-row-item {
        display: flex;
        justify-content: space-between;

        > span:first-child {
            padding-left: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
        } 

        > .toolkit-button {
            .udesk-qa-ui-scroll-number {
                box-shadow: none;
            }
            .udesk-qa-ui-scroll-number-only-unit {
                color: #8f8f8f;
            }
        }
    }
    .column-header {
        text-align: left;
        padding: 8px;
        height: 43px;
        font-size: 16px;
    }
}

.words-mining-favorite-page-right {
    .column-header {
        display: flex;
    }
    .message {
        display: flex;

        > span {
            flex: 0 0 30px;
        }

        > p {
            flex: 1;
            padding: 4px 15px;
        }
    }
    .user-type {
        display: inline-block;
        height: 30px;
        width: 30px;
        margin: 0 auto;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        color: #0f9715 ;
        background-color: #d3f7e1;

        &.customer {
            color: #1552f3 ;
            background-color: #e1f4fa;

            + p{
                color: #aaa;
            }
        }
    }

}

.words-mining-favorite-edit-dialog {
    .ok {
        background-color: #fff;
        border-color: #d9d9d9!important;
    }
    .hidden-delete-button .udesk-qa-list-item {
        > button {
            display: none;
        }
    }
    .udesk-qa-list-item .udesk-qa-ui-input-textarea {
        flex: 1;
    }
}