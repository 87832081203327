$udesk-react-table-table-background: #fff !default;
$udesk-react-table-table-border: 1px solid rgba(221, 221, 221, 0.78) !default;
$udesk-react-table-table-bottom-margin: 20px !default;
$udesk-react-table-head-background-color: #fff !default;
$udesk-react-table-head-background: $udesk-react-table-head-background-color !default;
$udesk-react-table-head-font-color: #555 !default;
$udesk-react-table-head-padding: 8px !default;
$udesk-react-table-head-sort-icon-color: #333333 !default;
$udesk-react-table-head-sort-icon-hover-color: #333333 !default;
$udesk-react-table-head-sort-icon-has-sorting-color: $udesk-react-table-head-sort-icon-hover-color !default;
$udesk-react-table-head-sort-icon-no-sorting-color: #b4c3d3 !default;
$udesk-react-table-body-sorting-column-background: #fef8e3 !default;
$udesk-react-table-body-row-hover-background: rgba(38, 185, 154, 0.07) !default;
$udesk-react-table-body-row-active-border: 3px solid #0cc2aa;
$udesk-react-table-body-row-hover-border: 1px solid rgba(38, 185, 154, 0.11) !default;
$udesk-react-table-body-selected-row-background: rgba(38, 185, 154, 0.16) !default;
$udesk-react-table-body-selected-row-border: 1px solid rgba(38, 185, 154, 0.4) !default;
$udesk-react-table-column-action-size: 13px !default;
$udesk-react-table-columns-zindex: 4 !default;
$udesk-react-table-columns-trigger-font-size: 22px !default;
$udesk-react-table-columns-trigger-top: 8px !default;
$udesk-react-table-columns-trigger-right: 0 !default;
$udesk-react-table-columns-trigger-color: $udesk-react-table-head-font-color !default;
$udesk-react-table-columns-trigger-hover-color: #a0c2ff !default;
$udesk-react-table-columns-list-position-right: 0 !default;
$udesk-react-table-columns-list-position-top: 10px !default;
$udesk-react-table-columns-list-width: 180px !default;
$udesk-react-table-columns-list-max-height: 350px !default;
$udesk-react-table-columns-list-font-color: #333 !default;
$udesk-react-table-columns-list-background: #f9f9f9 !default;
$udesk-react-table-columns-item-background: none !default;
$udesk-react-table-columns-item-hover-background: #eaf2f5 !default;
$udesk-react-table-columns-item-hover-font-color: $udesk-react-table-columns-list-font-color !default;