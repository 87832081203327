.pages-gong-sale-client-center-list-container {
    .user-proportion, .carousel-detail-container {
        .bg-custom,
        .bg-user {
            position: relative;
            
            > div {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                display: inline-flex;
                flex-flow: column;
                justify-content: center;
                color: #fff;
                border-radius: 50%;
            }
            &:hover {
                z-index: 2000;
            }
        }
    }
}