$hr-border: #ccc;

// @media screen and (min-width: 1650px) {
//     .task-card-box {
//         width: calc(100% / 5);
//     }
// }

// @media screen and (min-width: 1359px) and (max-width: 1649px) {
//     .task-card-box {
//         width: calc(100% / 4);
//     }
// }

// @media screen and (min-width: 1069px) and (max-width: 1358px) {
//     .task-card-box {
//         width: calc(100% / 3);
//     }
// }

// @media screen and (min-width: 779px) and (max-width: 1068px) {
//     .task-card-box {
//         width: calc(100% / 2);
//     }
// }

// @media screen and (max-width: 778px) {
//     .task-card-box {
//         width: 100%;
//     }
// }

.task-card-box {
    cursor: default;
    user-select: none;
    width: 100%;
    overflow: hidden;

    .task-card {
        // height: 334px;
        min-width: 274px;
        margin: 8px 8px;
        border-radius: 2px;
        border: none;
        box-shadow: none;
        background: rgba(255, 255, 255, 1);
        padding: 24px;
        position: relative;
        .udesk-qa-ui-space-item {
            overflow: hidden;
        }
        &:hover {
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        }
        .task-card-type {
            width: 48px;
            height: 48px;
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 4px;
            padding-right: 3px;
            text-align: right;
            font-size: 12px;
            direction: rtl;
            white-space: nowrap;
        }
        .task-card-type-call {
            background: linear-gradient(
                45deg,
                transparent,
                transparent 50%,
                #e2fae1 50%,
                #e2fae1 100%
            );
            color: #0fba26;
        }
        .task-card-type-im {
            background: linear-gradient(
                45deg,
                transparent,
                transparent 50%,
                #e6f3ff 50%,
                #e6f3ff 100%
            );
            color: #1a6eff;
        }
        .task-card-info {
            user-select: auto;
            .task-card-info-label {
                height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 20px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 28px;
            }

            .task-card-info-remark {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 22px;
                min-width: 22px;
                height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 16px;
            }
        }

        .task-card-chart-loading {
            position: absolute;
            width: 100%;
            text-align: center;
            left: 0;
            height: 62%;
            top: 23%;
            background: rgba(0, 0, 0, 0.3);
            padding: 39% 0;

            &:after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom;
                animation: ellipsis 2s infinite;
                content: '\2026'; /* ascii code for the ellipsis character */
            }
        }

        .task-card-chart {
            .task-card-chart-item {
                background: rgba(0, 0, 0, 0.03);
                height: 74px;
                display: flex;
                justify-content: space-between;
                padding: 12px 16px;
                border-radius: 2px;

                .task-card-chart-item-left {
                    .task-card-chart-count {
                        height: 24px;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                        display: flex;
                        .task-card-chart-growth-count {
                            margin-left: 5px;
                            line-height: 24px;
                            font-weight: 400;
                            height: 24px;
                            font-size: 12px;
                        }
                        .task-card-chart-main-count {
                            &.empty-task-card {
                                width: 56px;
                                height: 20px;
                                background: rgba(0, 0, 0, 0.05);
                                border-radius: 2px;
                                margin-top: 3px;
                            }
                        }
                    }
                    .task-card-chart-item-title {
                        height: 22px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.45);
                        line-height: 22px;
                        // text-transform: capitalize;

                        &.empty-task-card {
                            width: 110px;
                            height: 12px;
                            background: rgba(0, 0, 0, 0.05);
                            border-radius: 2px;
                            margin-top: 8px;
                        }
                    }
                }
                .task-card-chart-item-right {
                    margin: 1px 0;
                    display: flex;
                    .task-card-chart-item-column {
                        margin-left: 2px;
                        background-color: rgba(27, 109, 255, 1);
                        width: 8px;
                        align-self: flex-end;
                    }
                    &.empty-task-card {
                        width: 44px;
                        height: 44px;
                        border-radius: 50%;
                        background: rgba(0, 0, 0, 0.05);
                    }
                }
                &.middle-chart-item {
                    margin: 8px 0;
                }
            }

            .task-card-chart-progress {
                .ant-progress-circle-trail {
                    stroke: rgba(0, 0, 0, 0.1) !important;
                }
            }
        }

        .task-card-btn {
            margin-top: 16px;
            color: rgba(0, 0, 0, 0.65);
            display: flex;
            justify-content: space-between;
            .ant-btn {
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 2px;
                cursor: pointer;
            }
            .task-card-btn-right {
                height: 32px;
                display: flex;
                .ant-switch {
                    width: 32px;
                    height: 16px;
                    margin: 8px 0;
                    min-width: 32px;
                    &::after {
                        width: 12px;
                        height: 12px;
                        border-radius: 12px;
                    }
                }
                .ant-switch-checked {
                    background: rgba(27, 109, 255, 1);
                }
                .task-card-btn-right-des {
                    line-height: 32px;
                    font-size: 14px;
                    height: 32px;
                }
            }
        }
    }
    @keyframes ellipsis {
        from {
            width: 2px;
        }
        to {
            width: 15px;
        }
    }
}
