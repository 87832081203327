.qa-react-inspection-detail-page {
    min-width: 860px;

    .qa-react-inspection-detail-media-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .qa-react-inspection-detail-media-video {
            flex: 200px 0 0;
        }

        .qa-react-inspection-detail-media-audio {
            flex: 1;
        }
    }

    .component-audio-stickers {
    }

    .wave-audio-component {
        .wave-audio-container {
        }

        .wave-audio-operation-panel {
            .wave-audio-button-box,
            .wave-audio-time-box {
                height: 24px !important;
                line-height: 24px !important;
            }
        }
    }
    .ql-mention-list-container {
        max-height: 300px;
        overflow-y: auto;
        .ql-mention-list-item.selected {
            background-color: #e8f4ff;
        }
        .ql-mention-list-item {
            line-height: 34px;
            font-size: 14px;
        }
    }
    .qa-react-inspection-detail-content {
        display: flex;
        // margin-top: 16px;
        height: calc(100vh - 64px - 58px - 32px - 32px - 40px);
        border-radius: 2px;
        &.width-audio {
            height: calc(100vh - 64px - 58px - 32px - 32px - 40px - 70px - 11px - 24px);
        }
        &.width-smart {
            height: calc(100vh - 64px - 58px - 32px - 32px - 40px - 13px);
        }
        &.width-all {
            height: calc(100vh - 64px - 58px - 32px - 32px - 40px - 70px - 11px - 24px);
        }
        .qa-react-inspection-detail-content-left-part {
            .audio-call-info-component-phone {
                margin: 10px 0;
                height: calc(100vh - 60px - 60px - 90px - 50px - 15px);
                min-height: calc(600px - 60px - 60px - 90px - 50px - 15px);

                .audio-text-view {
                    height: calc(100vh - 90px - 50px - 180px - 1px - 10px);
                    min-height: calc(600px - 90px - 50px - 180px - 1px - 10px);

                    .audio-text-list-container {
                        .audio-text-list {
                            height: calc(100vh - 90px - 50px - 180px - 2px - 10px - 47px);
                            min-height: calc(600px - 90px - 50px - 180px - 2px - 10px - 47px);
                            overflow-x: hidden;
                            overflow-y: auto;
                        }
                    }
                }
            }
            .audio-call-info-component-text {
                margin: 10px 0;
                height: calc(100vh - 60px - 60px - 20px);
                min-height: calc(600px - 60px - 60px - 20px);

                .audio-text-view {
                    height: calc(100vh - 90px - 50px - 35px - 1px - 20px);
                    min-height: calc(600px - 90px - 50px - 35px - 1px - 20px);

                    .audio-text-list-container {
                        .audio-text-list {
                            height: calc(100vh - 90px - 50px - 35px - 2px - 20px - 47px);
                            min-height: calc(600px - 90px - 50px - 35px - 2px - 20px - 47px);
                            overflow-x: hidden;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }

        .qa-react-inspection-detail-content-right-part {
            .quality-check-score-container-phone {
                margin: 10px 0;
                height: calc(100vh - 60px - 60px - 90px - 50px - 15px);
                min-height: calc(600px - 50px - 180px - 2px - 20px);
            }
            .quality-check-score-container-text {
                margin: 10px 0;
                height: calc(100vh - 60px - 60px - 20px);
                min-height: calc(600px - 50px - 35px - 2px - 20px);
            }
        }
        .qa-react-inspection-detail-basic-info {
            // width: 340px;
            height: 100%;
            display: inline-block;
            vertical-align: top;
            transition: width 0.3s;
            -moz-transition: width 0.3s; /* Firefox 4 */
            -webkit-transition: width 0.3s; /* Safari and Chrome */
            -o-transition: width 0.3s;
        }
        .qa-react-inspection-detail-content-info {
            // width: calc(100% - 652px);
            overflow-x: hidden;
            flex: 1;
            height: 100%;
            transition: width 0.3s;
            -moz-transition: width 0.3s; /* Firefox 4 */
            -webkit-transition: width 0.3s; /* Safari and Chrome */
            -o-transition: width 0.3s;
            vertical-align: top;
            display: inline-block;
        }
        .qa-react-inspection-detail-content-point {
            max-width: 444px;
            width: 0;
            flex: 1;
            height: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: inline-block;
            transition: max-width 0.3s;
            -moz-transition: max-width 0.3s; /* Firefox 4 */
            -webkit-transition: max-width 0.3s; /* Safari and Chrome */
            -o-transition: max-width 0.3s;
        }
        .qa-react-inspection-detail-content-extraction-point {
            max-width: 624px;
        }
    }

    .qa-react-inspection-detail-footer {
        margin-top: 16px;
        .component-change-list {
            .change-list-title {
                padding-left: 0;
            }
        }
        .quality-detail-remark-content {
            margin: 8px 0;
        }
    }
}
