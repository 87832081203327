.clustering-analysis-detail {
    position: relative;
    height: 100%;

    .detail-title {
        height: 50px;
        line-height: 53px;
        font-size: 16px;
        font-weight: 600;
    }

    > div:first-child {
        width: calc(100% - 761px);
        height: calc(100% - 55px);

        > div.detail-title {
            padding-left: 16px;
        }

        > div.udesk-qa-ui-standardize-table {
            width: 100%;
            height: 100%;
    
            > div:first-child {
                display: none;
            }
            > div:last-child {
                padding-top: 5px;
            }
        
            .udesk-qa-ui-table-column-sorters {
                padding: 10px 16px;
            }

            table {
                colgroup col:first-child {
                    width: 0!important;
                }
                button {
                    padding: 0;
                }
                tr:not(.udesk-qa-ui-table-placeholder) td:first-child,
                tr:not(.udesk-qa-ui-table-placeholder) th:first-child {
                    padding: 0;
                   > * {
                    display: none!important;
                   }
                }
                tr td:last-child {
                    button {
                        padding: 4px 0;
                    }
                }
            }
        }
    }

    > div.clustering-analysis-ranking {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 761px;
        overflow: auto;
        padding-right: 8px;
    }
}

.clustering-analysis-ranking {
    height: 100%;
    line-height: 0%;

    .udesk-qa-ui-space-align-center {
        width: 100%;
        height: calc(100% - 66px);
        align-items: flex-start;

        > div,
        > div> div,
        > div> div> div,
        > div> div> div> div,
        > div> div> div> div> div,
        > div> div> div> div> div> div {
            height: 100%;
        }
        
        > div {
            overflow: auto;
            > div {
                width: 325px;
            }

            &:last-child > div {
                width: 420px;
            }
        }

        .udesk-qa-ui-table-container {
            height: 100%;

            .udesk-qa-ui-table-body {
                height: calc(100% - 50px);
            }

            .udesk-qa-ui-btn .anticon {
                height: auto;
                width: auto;
                margin-left: 0;
            }

            button {
                min-width: 16px;
                width: 16px;
                height: 16px;
                color: #ccc;

                >span,
                >span svg {
                    width: 12px;
                    height: 12px;
                }

                &.udesk-qa-ui-btn-primary {
                    color: #fff;
                }
            }

            .user-type {
                display: block;
                height: 20px;
                width: 20px;
                margin: 0 auto;
                border-radius: 50%;
                line-height: 20px;
                text-align: center;
                color: #0f9715 ;
                background-color: #d3f7e1;

                &.customer {
                    color: #1552f3 ;
                    background-color: #e1f4fa;
                }
            }
        }
    }
}
