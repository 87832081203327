.supervision-list-page {
    height: 100%;
    // margin: -16px;
}
.supervision-detail-modal-wrap {
    .udesk-qa-ui-form-item-control-input-content {
        .udesk-qa-ui-form-item {
            margin-bottom: 0;
        }
    }
    .site-tag-plus {
        background: #fff;
        border-style: dashed;
    }
    .edit-tag {
        user-select: none;
    }
    .tag-input {
        width: 78px;
        margin-right: 8px;
        vertical-align: top;
    }
}

.intelligent-recommend-select .next-tree-select-not-found {
    height: auto;
}