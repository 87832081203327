.words-mining-favorite-view-page {
    .udesk-qa-web-page-body-root {
        height: 100%;
        width: 100%;
    }

    > div > div > div {
        display: flex;
        width: 100%;
        height: 100%;

        .words-mining-favorite-view-page-left {
            .udesk-qa-ui-table-wrapper,
            .udesk-qa-ui-spin-nested-loading,
            .udesk-qa-ui-table-container {
                height: 100%;
            }
            .udesk-qa-ui-table-fixed-header {
                flex: 1;
                height: calc(100% - 56px);
            }

            .udesk-qa-ui-table-body {
                overflow-y: auto!important;
    
                .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected {
                    td::after {
                        content: '';
                        display: inline-block;
                        height: 43px;
                        width: 2px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: #1A6EFF;
                    }
                }
                .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
                    border-bottom-color: transparent;
                }
            }
        }

        .words-mining-favorite-view-page-right {
            width: calc(100% - 250px);

            > div {
                height: 100%;
            } 
        }

        .words-mining-favorite-view-page-left {
            width: 250px;
            border-right: 1px solid #eee;
            
            .udesk-qa-ui-spin-nested-loading {
                > div {
                    height: auto;
                }
                > div.udesk-qa-ui-spin-container{
                    height: 100%;
                }
            }
    
            .udesk-qa-ui-table-body {
                height: calc(100% - 86px);
            }
            
            .udesk-qa-ui-table-thead > tr > th {
                background-color: #fff;
                border-color: #fff;
    
                &.udesk-qa-ui-table-cell-scrollbar {
                    box-shadow : none;
                }
            }
    
            .udesk-qa-ui-table-tbody > tr {
                > td {
                    border-color: transparent;
                }
            }
        }
    }
}