.udesk-qa-list-container {
    margin-top: -8px;
    .udesk-qa-list-item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        border-radius: 2px;
        position: relative;
    }
    .udesk-qa-list-btn-icon {
        font-size: 14px;
        margin-right: 5px;
    }
    .udesk-qa-list-control-row {
        margin-top: 8px;
    }
}