.tasks-manage-template-manual-add-index-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tasks-manage-template-manual-add-index-content-history {
        flex: 1;
    }
    .tasks-manage-template-basic-info-index-footer {
        padding: 16px;
    }
    .tasks-manage-template-basic-info-index-conduct {
        height: 48px;
        padding: 0 16px 0 20px;
        line-height: 48px;
        background: #fff;
        border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
        display: flex;
        justify-content: space-between;
        .tasks-manage-template-basic-info-index-conduct-left {
            flex: 1;
        }
        .tasks-manage-template-basic-info-index-conduct-right {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.45);
            line-height: 48px;
            margin-left: 10px;
        }
    }
    .tasks-manage-template-role-config-index-content-body {
        flex: 1;
    }

    .manual-add-content-container {
        margin-bottom: 16px;

        .container-header {
            margin: 0px 0px 15px;
            font-weight: bold;
        }

        .container-content {
            .container-screening-condition {
                .manual-add-records-item-row-filter {
                    background: rgba(0, 0, 0, 0.03);
                    border-radius: 3px;
                    width: 100%;
                    padding: 16px;
                    display: flex;
                    flex-direction: column;
                    line-height: 22px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    .manual-add-records-item-row-filter-title {
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 16px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .manual-add-records-item-row-filter-rules {
                        display: flex;
                        flex-direction: column;

                        .manual-add-records-item-row-filter-title {
                            color: rgba(0, 0, 0, 0.85);
                            margin-bottom: 16px;
                        }
                        .manual-add-records-item-row-content {
                            width: calc(100% - 80px);
                            color: rgba(0, 0, 0, 0.65);
                        }
                    }
                    .index-management-custom-filter-Info {
                        display: flex;
                        margin-bottom: 16px;
                        .manual-add-records-item-row-filter-title {
                            color: rgba(0, 0, 0, 0.85);
                        }
                        .manual-add-records-item-row-filter-rules {
                            color: rgba(0, 0, 0, 0.65);
                            .manual-add-records-item-row-filter-title {
                                color: rgba(0, 0, 0, 0.85);
                            }
                            .manual-add-records-item-row-content {
                                width: calc(100% - 80px);
                                color: rgba(0, 0, 0, 0.65);
                            }
                        }
                    }
                }
            }

            .container-input {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    .manual-add-buttons-box {
        margin-top: 20px;
    }

    .manual-add-records-box {
        margin-top: 16px;

        .manual-add-records-item {
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 16px;
            padding: 16px;
            line-height: 22px;

            .manual-add-records-item-row {
                margin-bottom: 16px;
                width: 100%;
                display: flex;

                .manual-add-records-item-col {
                    display: flex;
                    width: 256px;
                }

                .manual-add-records-item-row-title {
                    // width: 70px;
                    white-space: nowrap;
                    color: rgba(0, 0, 0, 0.85);
                }

                .manual-add-records-item-row-content {
                    width: calc(100% - 80px);
                    color: rgba(0, 0, 0, 0.65);

                    .manual-add-records-item-row-condition {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 5px;

                        .manual-add-records-item-condition-col {
                        }
                    }
                }
            }

            .manual-add-records-item-row-filter {
                background: rgba(0, 0, 0, 0.03);
                border-radius: 3px;
                width: 100%;
                padding: 16px;
                display: flex;
                flex-direction: column;
                line-height: 22px;
                .manual-add-records-item-row-filter-title {
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 16px;
                }
                .manual-add-records-item-row-filter-rules {
                    display: flex;
                    .manual-add-records-item-row-filter-title {
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 16px;
                    }
                    .manual-add-records-item-row-content {
                        width: calc(100% - 80px);
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
            }
        }
    }

    .x_content {
        padding: 0px 15px;
    }
}
