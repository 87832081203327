.udesk-qa-massage-sdk-new {
    position: absolute;
    overflow: hidden;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #e8f4ff;
    right: 16px;
    bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    // clip-path: polygon(50% 0,213% 27%,95% 73%,50% 100%,0 71%,0 9%);
    .udesk-qa-massage-sdk-logo {
        font-size: 21px;
        position: relative;
        z-index: 16;
    }
    .reflective {
        width: 22px;
        height: 22px;
        z-index: 2;
        transform: rotate(-70deg);
        background: rgba(255, 255, 255, 0.656);
        position: absolute;
        animation: move 3s infinite;
    }
    .circle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #52E5E7, #130CB7 100%);
        animation: rotate 1s linear infinite;
    }
    .circle::before{
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-image: linear-gradient(0deg, 
        #ABDCFF, #0396FF 100%);
        filter: blur(35px);
    }
    .circle::after{
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: #e8f4ff;
    }
    .circle-error{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #fafafa;
    }
    .circle-error::before{
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        filter: blur(35px);
    }
    .circle-error::after{
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: #d9d9d9;
    }
    span{
        position: absolute;
        color: #fff;
    }
    @keyframes  rotate{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
}
.udesk-qa-massage-sdk-tooltip {
    position: absolute;
    width: 1px;
    height: 1px;
    right: 60px;
    background-color: unset;
}
.udesk-qa-massage-sdk-notification-content {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.udesk-qa-massage-sdk-tooltip-content {
    width: 300px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}