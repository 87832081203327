.clustering-annotation-index-page-wrap {
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    .udesk-qa-ui-form-inline {
        padding: 8px;
        margin-bottom: 8px;
        background-color: #fff;
    }
    .udesk-qa-web-page-content {
        display: flex;
        flex-direction: column;
    }
}

.clustering-annotation-index-page {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    height: 100%;
    background-color: #fff;
    flex: 1;
    overflow: hidden;
    .clustering-annotation-index-page-left {
        width: 340px;
        min-width: 340px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .udesk-qa-ui-pagination-item-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .udesk-qa-ui-menu-item {
            margin: 0 !important;
        }
        .udesk-qa-ui-pagination {
            text-align: right;
            padding-right: 16px;
            margin-bottom: 16px;
        }
    }
    .clustering-annotation-index-page-part-content {
        // height: calc(100% - 47px - 34px - 24px);
        flex: 1;
        overflow-y: auto;
        margin-bottom: 12px;
        .udesk-qa-ui-checkbox-group {
            width: 100%;
        }
        .udesk-qa-ui-spin-nested-loading {
            height: 100%;
        }
        .clustering-annotation-index-page-checkbox-container {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            align-content: flex-start;
            padding: 8px 16px;
            .clustering-annotation-index-page-checkbox-item {
                // max-width: 350px;
                // min-width: 290px;
                white-space: nowrap;
                overflow: hidden;
                // width: 300px;
                // flex-grow: 1;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                .udesk-qa-ui-checkbox-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    // justify-content: space-between;
                    .udesk-qa-ui-checkbox {
                        display: flex;
                        align-items: center;
                        height: 16px;
                        & + span {
                            width: calc(100% - 16px);
                        }
                    }
                }
            }
        }
    }

    .clustering-annotation-index-page-right {
        flex: 1;
        min-width: 322px;
        display: flex;
        flex-direction: column;
        .udesk-qa-ui-pagination {
            margin-bottom: 16px;
            margin-left: 16px;
        }
    }
    .clustering-annotation-index-page-part-title {
        padding: 14px 16px 12px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 600;
    }
    .clustering-annotation-index-page-sentence {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 350px;
        min-width: 290px;
        width: 100%;
        .clustering-annotation-index-page-sentence-content {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .clustering-annotation-index-page-sentence-count {
            height: 20px;
            padding: 0px 8px;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
            border-radius: 42px;
            background: rgba(0, 0, 0, 0.05);
        }
    }
}
