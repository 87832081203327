.task-assess-rules-gather-component {
    .assess-rules-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 24px;

        .assess-rules-gather-component-sampling-frequency {
            display: inline-block;
            width: 100px;
        }

        .assess-rules-gather-component-sampling-time-container {
            display: inline-block;

            .assess-rules-gather-component-sampling-weekday {
                display: inline-block;
                width: 75px;
                margin-right: 5px;
            }
        }

        .assess-rules-gather-component-filter-container {
            display: inline-block;
            margin: 0px 5px;
        }

        .assess-rules-gather-component-action-type {
            display: inline-block;
            margin-right: 5px;
        }

    }
    .assess-rules-gather-component-delete-icon-container {
        height: 30px;
        display: inline-block;
        line-height: 30px;
        cursor: pointer;
        margin-left: 8px;
        color:rgba(27,109,255,1);
    }

    .assess-rules-gather-component-add-icon-container {
        height: 30px;
        line-height: 30px;
        color:rgba(27,109,255,1);
        cursor: pointer;

        .assess-rules-gather-component-add-icon {
            display: inline-block;
            cursor: pointer;
            vertical-align: sub;
        }
    }
    .rule-item-rule-filter-btn {
        width: max-content;
        height: 30px;
        line-height: 30px;
        margin-top: 24px;
        display: block;
        cursor: pointer;
        margin-right: 5px;
        color: rgba(27,109,255,1);
    }

}
