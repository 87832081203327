.react-echarts {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
  box-sizing: border-box;
}
.react-echarts .map-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-echarts .react-echarts-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
}
.react-echarts .echart-tooltip-header-name {
  margin-left: 5px;
  text-align: center;
}
.react-echarts .echart-tooltip-content {
  max-height: 400px;
  padding-right: 10px;
}
.react-echarts .echart-tooltip-content .echart-tooltip-content-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.react-echarts .echart-tooltip-content .echart-tooltip-content-item .echart-tooltip-title {
  overflow: hidden;
  flex: 1;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.react-echarts .echart-tooltip-content .echart-tooltip-content-item .echart-tooltip-value {
  min-width: 26px;
  height: 20px;
  margin-left: 20px;
  padding: 0 5px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 3px;
  text-align: center;
}
.react-echarts .echart-tooltip-content .echart-tooltip-content-extra-data {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 180px;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}
.react-echarts .echart-tooltip-content .echart-tooltip-content-extra-data .echart-tooltip-content-extra-data-item {
  margin-top: 8px;
}

