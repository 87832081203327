.check-range-component {
    .check-range-condition-title {
        margin-bottom: 5px;
    }

    .check-range-apply-option,
    .check-range-apply-condition-id,
    .check-range-apply-condition-hit-type,
    .check-range-apply-condition-scope,
    .check-range-apply-role {
        border: 0px;
        width: 20%;
        display: inline-block;
        margin-right: 1%;
    }

    .check-range-apply-condition-id {
        vertical-align: top;
    }

    .check-range-apply-condition-limit {
        width: 12%;
        display: inline-block;
        height: 32px;
        vertical-align: top;
        margin-right: 1%;
    }

    .check-range-apply-scope-input {
        width: 10%;
        display: inline-block;
        height: 32px;
        margin: 0 1%;
    }
}
