.classified-training-pages {
    .classified-training-pager-header {
        height: 58px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 0 16px;
        .classified-training-pager-header-title {
            font-size: 18px;
            font-weight: 500;
            line-height: 58px;
            float: left;
        }
    }
    .classified-training-pager-body {
        .classified-taining-pager-body-sumbit {
            width: 100%;
            background: #fff;
            padding: 16px;
            .classified-taining-pager-body-sumbit-time {
                margin-left: 10px;
            }
        }
        .classified-training-pager-body-chart {
            margin-top: 15px;
            width: 100%;
            .classified-training-pager-body-chart-card {
                width: 100%;
                background: #fff;
                padding: 16px;
                .classified-training-pager-body-chart-card-title {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                }
                .classified-training-pager-body-chart-card-info {
                    display: flex;
                    justify-content: space-between;
                    .classified-training-pager-body-chart-card-info-left {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 28px;
                    }
                    .classified-training-pager-body-chart-card-info-right {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(17, 168, 72, 1);
                        line-height: 28px;
                    }
                }
            }
        }
        .classified-tarining-pager-body-tree {
            margin-top: 15px;
            width: 100%;
            background: #fff;
            .classified-tarining-pager-body-tree-header {
                padding: 9px 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                justify-content: space-between;
                .classified-tarining-pager-body-tree-header-title {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 22px;
                    font-size: 14px;
                }
                .classified-tarining-pager-body-tree-header-tabs {
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 3px;
                    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
                    span {
                        padding: 2px 10px;
                        cursor: pointer;
                        display: inline-block;
                    }

                    .classified-training-tab-active {
                        background: rgba(255, 255, 255, 1);
                        border-radius: 2px;
                        color: rgba(27, 109, 255, 1);
                        padding: 2px 8px;
                    }
                }
            }
            .classified-tarining-pager-body-tree-body {
                padding: 16px 16px;
            }
        }
        .classified-tarining-pager-body-action {
            margin-top: 15px;
            width: 100%;
            .classified-tarining-pager-body-action-card {
                background: #fff;
                border-radius: 2px;
                .classified-tarining-pager-body-action-card-header {
                    padding: 9px 16px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    justify-content: space-between;
                    .classified-tarining-pager-body-action-card-header-title {
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 22px;
                        font-size: 14px;
                        span {
                            font-weight: 400;
                            color: rgba(0, 0, 0, 0.45);
                            line-height: 22px;
                        }
                    }
                    .classified-tarining-pager-body-action-card-header-type {
                        font-weight: 400;
                        color: rgba(251, 81, 39, 1);
                        line-height: 22px;
                        font-size: 14px;
                    }
                }
                .classified-tarining-pager-body-action-card-info {
                    padding: 16px;
                    .classified-tarining-pager-body-action-card-info-list {
                        display: flex;
                        .classified-tarining-pager-body-action-card-info-list-field {
                            min-width: 200px;
                            .classified-tarining-pager-body-action-card-info-list-num {
                                font-weight: 500;
                                color: rgba(0, 0, 0, 0.85);
                                line-height: 28px;
                                font-size: 20px;
                            }
                        }
                    }
                    .classified-tarining-pager-body-action-card-button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
.label-tabs-list-page {
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
    }
    ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        background: #e0e5eb;
    }
    ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        // background: rgba(0,0,0,0.1);
    }
    .label-tabs-list-page-field {
        display: inline-block;
        width: 190px;
        padding-right: 16px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.03);
        height: 278px;
        vertical-align: top;
        overflow-y: auto;
        position: relative;

        .label-field-target-drag {
            position: absolute;
            bottom: 5px;
            right: 2px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.25);
            cursor: col-resize;
        }
        .label-tabs-list-page-field-drag {
            min-height: 278px;
            height: auto;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 8px 0;
            .label-field-info {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 24px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 8px;
                .label-field-info-field {
                    display: inline-block;
                    width: calc(100% - 8px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .label-field-info-active {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 24px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 8px;
                background: rgba(27, 109, 255, 1);
                color: #fff;
            }
            .label-field-info-add {
                font-size: 12px;
                font-weight: 400;
                color: rgba(27, 109, 255, 1);
                line-height: 24px;
                padding: 0 8px;
                cursor: pointer;
            }
        }
    }
}
