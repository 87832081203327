.wechat-message-notification-page {
    background-color: #f5f6f8 !important;

    .qa-react-inspection-detail-footer {
        display: none;
    }

    .qa-react-inspection-detail-page .qa-react-inspection-detail-content {
        height: calc(100vh - 64px - 58px - 48px);
    }

    .audio-call-basic-info-page-v2 .udesk-qa-ui-tabs-content {
        height: calc(100% - 10px);
    }

    .udesk-qa-web-page-body-root-spin .udesk-qa-ui-spin-container .udesk-qa-web-page-content {
        padding: 6px 16px;
    }
}
