.concept-word-index-page {
    .concept-word-index-page-content {
        padding: 16px;
        background-color: #fff;
        .concept-word-index-page-content-contol {
            // height: 64px;
            padding-bottom: 16px;
            display: flex;
            justify-content: space-between;
            .udesk-qa-ui-btn {
                margin-left: 8px;
            }
            .udesk-qa-ui-input-search-button {
                margin-left: 0px;
            }

            .udesk-qa-ui-btn-primary {
                background: rgba(27, 109, 255, 1);
                border-color: rgba(27, 109, 255, 1);
            }
        }
        .concept-word-index-page-table-size-changer {
            margin-top: 16px;
            position: relative;
            width: 100%;
            text-align: right;
            // .concept-word-index-page-table-size-changer-text {
            //     position: absolute;
            //     line-height: 32px;
            //     color: rgba(0, 0, 0, 0.45);
            // }
            // .udesk-qa-ui-pagination {
            //     width: 100%;
            //     text-align: right;
            //     .udesk-qa-ui-pagination-total-text {
            //         position: absolute;
            //         left: 188px;
            //         color: rgba(0, 0, 0, 0.45);
            //     }
            // }
            // .udesk-qa-ui-pagination-options {
            //     text-align: center;
            //     position: absolute;
            //     left: 64px;
            //     color: rgba(0, 0, 0, 0.45);
            // }
        }
    }
}
