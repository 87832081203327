.custom-kanban-page {
    background-color: #fff !important;
    padding-bottom: 0 !important;

    .udesk-qa-web-page-body {
        padding: 0 !important;
    }

    .udesk-qa-ui-tabs-tab + .udesk-qa-ui-tabs-tab {
        margin-left: 16px;
    }
}

.custom-kanban-page-body {
    height: 100%;
    display: flex;
    flex-direction: column;

    .udesk-qa-ui-card {
        margin-bottom: 16px;

        .udesk-qa-ui-card-body {
            padding: 12px 20px;
        }

        .udesk-qa-ui-tag {
            margin: 4px;
            height: 24px;
            line-height: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;