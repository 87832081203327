.page-qa-call-anslysis {
    padding-bottom: 0 !important;
    .udesk-qa-web-page-body {
        padding: 0 !important;
    }
    .page-qa-call-anslysis-body {
        padding: 0 !important;
        height: 100%;
        .udesk-qa-web-page-content {
            height: 100%;
        }
    }
}
.page-qa-analysis-call-list-container {
    height: 100%;
    .udesk-qa-ui-layout-has-sider {
        height: 100%;
        .udesk-qa-ui-layout-sider {
            max-width: 30% !important;
            // min-width: 30% !important;
        }
    }
    .layout-menu-pages-sider {
        position: relative;
        background: #fff;
        padding-bottom: 0px !important;
        .layout-menu-pages-sider-actions-arrow {
            position: absolute;
            width: 33px;
            height: 61px;
            top: -1px;
            left: -18px;
            .icon-udesk-qa-react-web-zhankaitixing {
                font-size: 50px;
                cursor: pointer;
                color: #fff;
            }
            .icon-udesk-qa-react-web-ic-arrow-left-s-line {
                color: #000;
                font-size: 16px;
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
            }
            .icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou {
                color: #000;
                font-size: 16px;
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
            }
        }
        .udesk-qa-ui-layout-sider-trigger {
            position: absolute;
            top: 50%;
            width: 14px !important;
            background: none;
            margin-top: -48px;
            right: -14px;
            overflow: hidden;
        }
        .udesk-qa-ui-layout-sider-children {
            overflow: hidden;
            .udesk-qa-ui-menu-sub {
                &.udesk-qa-ui-menu-inline {
                    background-color: #fff;
                    border-radius: 2px;
                }
            }
        }
    }
    .common-call-list-filter-screen-container {
        // width: 368px;
        padding: 16px;
        padding-right: 0;
        height: 100%;
        .udesk-qa-ui-form-vertical {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .udesk-qa-ui-form-item {
            margin-bottom: 16px;
        }
        .common-call-list-filter-select {
            padding-bottom: 20px;
            margin: 0 -16px;
            margin-right: 0;
            margin-top: -16px;
            padding: 16px;
            background: #F5F6F8;
            box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1);
            .select-filter-text {
                margin-bottom: 8px;
                line-height: 22px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 700;
            }
        }
        .filter-condition-content {
            // width: 100%;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 11px 0;
            display: flex;
            align-items: center;
            margin-right: 16px;
            & > p {
                flex: 1;
                margin-left: 10px;
                font-weight: 700;
            }
        }
        .filter-scroll {
            // height: calc(100vh - 370px);
            overflow-y: auto;
            padding-right: 16px;
            .slider-filter-screen {
                margin: 10px 12px 10px;
                .udesk-qa-ui-slider-rail {
                    height: 8px;
                    background-color: rgba(0, 0, 0, 0.05);
                }
                .udesk-qa-ui-slider-track {
                    height: 8px;
                    background-color: #2C7CF6;
                }
                .udesk-qa-ui-slider-dot {
                    display: none;
                }
                .udesk-qa-ui-slider-handle {
                    top: 6px;
                }
            }
        }
    }
    .page-qa-analysis-call-list-content {
        height: calc(100vh - 64px);
        overflow-y: auto;
        .common-call-list-statistics-container {
            display: flex;
            align-items: center;
            padding: 16px;
            & > h3 {
                flex: 1;
                margin-right: 16px;
                font-size: 20px;
                font-weight: 700;
                line-height: 28px;
            }
            .call-tabs-style-left {
                border-radius: 44% 0 0 44%;
            }
            .call-tabs-style-right {
                border-radius: 0 44% 44% 0;
            }
            .udesk-qa-ui-radio-button-wrapper-checked:not(.udesk-qa-ui-radio-button-wrapper-disabled) {
                background: #1A6EFF;
                color: #fff;
            }
        }
        .common-call-list-item-content {
            flex-direction: column;
            cursor: pointer;
            .all-cal-item-image {
                width: 120px;
                height: 64px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F5F6F8;
                border: 1px solid #F0F2F5;
                border-radius: 2px;
                & > .time-durtion {
                    background: #fff;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.65);
                    padding: 0 4px;
                }
            }
            .call-title {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                white-space: nowrap;
            }
            .call-icon {
                border: 1px solid #1A6EFF;
                background: #E6F3FF;
                color: #E5F3FF;
                border-radius: 2px;
                color: #1A6EFF;
                font-size: 12px;
                line-height: 20px;
                cursor: default;
            }
            .call-commont {
                display: flex;
                align-items: center;
            }
            .call-time, .call-commont > span {
                color: rgba(0, 0, 0, 0.65);
                font-size: 14px;
                // line-height: 22px;
            }
            .call-commont > span {
                margin-left: 6px;
            }
            .call-space {
                width: 100%;
                display: flex;
                align-items: flex-start;
            }
            .all-cal-filter-time {
                width: 120px;
                display: flex;
                justify-content: flex-end;
                .time-btn {
                    cursor: default;
                }
                .udesk-qa-ui-btn-link {
                    padding: 0;
                }
            }
            .filter-content {
                color: rgba(0, 0, 0, 0.45);
                font-size: 14px;
                line-height: 22px;
                .search-key-word-hight-light {
                    & > em {
                        color: rgb(236, 128, 141);
                    }
                }
            }
        }
        .page-call-analysis-all-call-list {
            border: 16px solid #F5F6F8;
            padding: 16px;
            padding-top: 0;
            min-height: calc(100vh - 126px);
            // .udesk-qa-ui-list-items {
            //     height: calc(100vh - 372px);
            //     overflow-y: auto;
            // }
            .common-call-list-item-header {
                .normal-btn-link {
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;
                }
                .common-list-sort-tab-btn {
                    position: relative;
                    color: #1A6EFF;
                }
                .common-list-sort-tab-btn::after {
                    content: '';
                    display: inline-block;
                    width: 60px;
                    height: 4px;
                    background: #1A6EFF;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: -12px;
                }
            }
        }
    }
    .udesk-qa-ui-btn-round.udesk-qa-ui-btn-sm {
        line-height: 24px;
    }
    .common-call-list-item-header {
        display: flex;
    }
}

// #udesk_qa_web[operator='true'] {
//     #__reInspect {
//         display: flex !important;
//     }
// }