@mixin for-cascade-dropdown() {
    .cascade-drop-down-wrapper {
        .cascade-drop-down-options {
            display: inline-block;
            min-width: 80px;
            height: 30px;
            margin: 2px;
            padding: 2px;
            vertical-align: middle;
            text-align: center;
        }
    }
}
