.pages-coach-task-container {
    .ignore {
        color: #FB5127;
    }
    .already {
        color: #0FBA26;
    }
    .udesk-qa-ui-table-body {
        max-height: 556px !important;
    }
    .udesk-qa-ui-btn-link {
        padding: 4px 0;
    }
}