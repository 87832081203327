.tag-input {
    width: 78px;
    margin-top: 5px;
    margin-right: 8px;
    vertical-align: top;
}

.edit-tag {
    margin-top: 5px;
    line-height: 22px;

    > span {
        color: #1A6EFF!important;
    }
}

.sale-business-customer-tag {
    padding: 5px 15px 10px 15px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.03);
}