.task-rule-gather-component {
    .rule-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 24px;

        .rule-item-title {
            display: inline-block;
        }
        .rule-item-rule-control {
            background-color: rgba(0, 0, 0, 0.03);
            padding: 24px;
            min-width: 556px;
            flex: 1;
            .rule-item-inspector-title {
                display: inline-block;
                margin: 0px 5px;
            }

            .rule-item-inspector-type {
                display: inline-block;
                width: 120px;
            }

            .rule-item-inspector-select {
                display: inline-block;
                // width: 368px;
                margin-left: 10px;
                width: 250px;
                // height: 32px;
                // line-height: 32px;
                // .udesk-auto-complete {
                //     line-height: 100%;
                //     height: 32px;
                // }
            }
            .rule-item-rule-filter-btn {
                width: max-content;
                height: 30px;
                line-height: 30px;
                margin-top: 24px;
                display: block;
                cursor: pointer;
                margin-right: 5px;
                color: rgba(27, 109, 255, 1);
            }
        }

        .rule-item-delete {
            color: rgba(27, 109, 255, 1);
            display: inline-block;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            margin-left: 41px;
        }

        .rule-item-action {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            margin: 0 5px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.65);
        }

        .rule-item-action-move-up {
            &.disabled {
                color: #ddd;
                cursor: default;
            }
        }

        .rule-item-action-move-down {
            &.disabled {
                color: #ddd;
                cursor: default;
            }
        }

        .rule-item-action-move-top {
            &.disabled {
                color: #ddd;
                cursor: default;
            }
        }
    }

    .rult-gather-add-icon-container {
        height: 30px;
        line-height: 30px;

        .rule-gather-add-icon {
            height: 30px;
            line-height: 30px;
            margin-top: 24px;
            cursor: pointer;
            margin-right: 5px;
            color: rgba(27, 109, 255, 1);
        }
    }
}
