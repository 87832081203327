$page-layout-primary-color: #2878ff;

.select-call-time-container {
    .select-call-time-container-row {
        margin-bottom: 15px;

        .remind-icon {
            font-size: 15px;
            line-height: 15px;
            color: $page-layout-primary-color;
            vertical-align: middle;
        }
    }
    
}