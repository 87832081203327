.information-entities-detail-page {
    padding: 16px;
    .information-entities-detail-page-body {
        .form-group {
            &.row {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .information-entities-detail-input-list {
            display: block;

            .input-list-item {}
        }

        .information-entities-detail-page-buttons {
            .btn {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}