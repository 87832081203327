.audio-call-content-info {
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    display: flex;
    flex-direction: column;
    .demo-inspection-iframe {
        display: none;
    }
    .audio-call-content-search {
        padding: 6px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: space-between;
        .audio-call-content-search-input {
            // width: calc(100% - 130px - 64px - 36px);
            margin-right: 8px;
        }
    }
    .audio-call-content-view-info-content {
        padding: 8px 0;
        width: 100%;
        // height: calc(100% - 45px);
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #f0f2f5;
        position: relative;

        .anticon-play-circle {
            cursor: pointer;
        }
        .audio-call-content-drag {
            display: flex;
            margin-left: 10px;
            .udesk-qa-ui-checkbox-wrapper {
                line-height: 23px;
                margin-right: 10px;
            }
            .icon-udesk-qa-react-web-tuozhuai1 {
                cursor: pointer;
            }
        }
        .audio-call-content-view-custom {
            padding: 8px 16px;
            display: flex;
            // justify-content: space-between;
            min-height: 30px;
            position: relative;
            
            .udesk-qa-ui-space-horizontal {
                align-items: end;
                .udesk-qa-ui-space-item {
                    &:first-child {
                        align-self: baseline;
                    }
                }
                
            }

            .audio-call-content-view-custom-avatar {
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 1);
                border-radius: 20px;
                text-align: center;
                line-height: 24px;
                overflow: hidden;
                margin-top: 4px;
            }
            .audio-call-content-view-custom-text-info-status {
                align-self: flex-end;
                margin-left: 4px;
                font-size: 12px;
                color: rgba(0,0,0,.25);
            }
            .audio-call-content-view-custom-text {
                max-width: 315px;
                
                .audio-call-content-view-custom-text-info {
                    padding: 8px;
                    border-radius: 3px;
                    max-width: 315px;
                    min-width: 30px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: rgba(0, 0, 0, 0.85);
                    cursor: pointer;
                    .audio-call-content-view-custom-text-info-view {
                        width: 100%;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }
                }
                .audio-call-content-view-costom-text-time {
                    margin-top: 4px;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
        .audio-call-content-view-costom {
            padding: 8px 16px;
            display: flex;
            justify-content: flex-end;
            min-height: 30px;
            position: relative;
            .udesk-qa-ui-space-horizontal {
                align-items: end;
                .udesk-qa-ui-space-item {
                    &:last-child {
                        align-self: baseline;
                    }
                }
            }
            .audio-call-content-view-costom-avatar {
                width: 24px;
                height: 24px;
                background: rgba(191, 223, 255, 1);
                border-radius: 20px;
                text-align: center;
                line-height: 24px;
            }
            .audio-call-content-view-custom-text-info-status {
                align-self: flex-end;
                margin-right: 4px;
                font-size: 12px;
                color: rgba(0,0,0,.25);
            }
            .audio-call-content-view-costom-text {
                max-width: 315px;

                .audio-call-content-view-costom-text-info {
                    padding: 8px;
                    border-radius: 3px;
                    max-width: 315px;
                    min-width: 30px;
                    background: rgba(191, 223, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: rgba(0, 0, 0, 0.85);
                    cursor: pointer;
                    .audio-call-content-view-custom-text-info-view {
                        width: 100%;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }
                }
                .audio-call-content-view-costom-text-time {
                    margin-top: 4px;
                    text-align: right;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
        .audio-call-content-view-custom-checked {
            background: rgba(232, 244, 255, 1);
        }
    }
    .audio-text-highlight-navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30px;
        height: 100px;
        position: absolute;
        left: 15px;
        top: 30%;
        z-index: 3;

        .audio-text-highlight-navigation-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            cursor: pointer;
            margin-bottom: 5px;
            text-align: center;
            font-size: 10px;
            background-color: #ccc;
        }

        .button-up {
            border-top-right-radius: 13px;
            border-top-left-radius: 13px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .button-down {
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            border-bottom-right-radius: 13px;
            border-bottom-left-radius: 13px;
        }

        .audio-text-highlight-navigation-text {
            text-align: center;
        }

        .audio-text-highlight-navigation-close {
            height: 20px;
            cursor: pointer;
        }
    }
}
