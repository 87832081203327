.component-udesk-react-radio-btn {
    .radio-btn-item {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        margin-bottom: inherit;
        line-height: 20px;
        cursor: pointer;
        .radio-btn-input {
            position: absolute;
            z-index: 1;
            margin-left: -25px;
            opacity: 0;
            cursor: pointer;
            &.input-disabled {
                cursor: not-allowed;
            }
        }
        .radio-btn-decorate {
            float: left;
            position: relative;
            height: 18px;
            margin-top: 1px;
            margin-right: 24px;
            color: rgba(255, 255, 255, 0.87);
            background-color: #4caf50;
            &:before {
                content: "";
                position: absolute;
                width: 18px;
                height: 100%;
                border: 1px solid #9e9e9e;
                border-radius: 50%;
            }
        }
        input:checked+i:before {
            border-width: 0;
            background-color: inherit;
        }
        input[type="radio"]:checked+i:after {
            transform: rotate(45deg);
            position: absolute;
            left: 6px;
            top: 1px;
            display: table;
            width: 6px;
            height: 12px;
            border: 2px solid;
            border-top: 0;
            border-left: 0;
            content: ' ';
        }
    }
}