@text-color: #555;

.learning-center-exercise-body.ppt {
    .record-toolbar {
        position: relative;
        flex: 0 72px;
        padding: 8px 16px;
        background-color: #e6f3ff;
        border-top: 8px solid #f0f2f5;

        .text {
            color: @text-color;
            height: 50px;
            overflow: auto;
        }

        > div:first-child {
            display: flex;

            > div:first-child {
                width: 100%;
                flex: 1;
                overflow: hidden;

                > div {
                    position: relative;
                }

                .wave {
                    z-index: 1000;
                    height: 50px;
                    width: calc(100% - 24px);
                }

                .text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1001;
                    background-color: #e6f3ff;
                }
            }

            > div:last-child {
                flex: 0;
            }
        }

        .start-button {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1001;
            padding: 13px;
            background-color: #fff;
            text-align: center;
        }
    }

    .record-left-toolbar {
        position: relative;
        height: 224px;
        padding: 16px;

        .text {
            overflow: auto;
        }

        > div {
            display: flex;
            width: 100%;

            > div:nth-child(1) {
                flex: 1;

                > div {
                    position: relative;
                    > div {
                        width: 100%;
                        height: 145px;
                    }
                }

                .wave {
                    z-index: 1000;
                }

                .text {
                    position: absolute;
                    top: 0;
                    z-index: 1001;
                    color: @text-color;
                    background-color: #fff;
                }
            }

            > div:nth-child(2) {
                button {
                    width: 100%;
                }
            }
        }

        .start-button {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1001;
            padding: 13px;
            background-color: #fff;
            text-align: center;

            button {
                width: 100%;
            }
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;