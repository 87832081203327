.chat-record-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .udesk-qa-chat-record-list {
        height: auto;
        flex: 1;
    }

    .chat-panel {
        height: 100%;
        padding: 16px;
        overflow: auto;
    }

    .record-toolbar {
        position: relative;
        flex: 0 72px;
        padding: 8px 16px;
        background-color: #e6f3ff;
        border-top: 8px solid #f0f2f5;

        > div:first-child {
            display: flex;

            > div:first-child {
                flex: 1;
                overflow: hidden;

                .wave {
                    height: 50px;
                    width: calc(100% - 24px);
                }
            }

            > div:last-child {
                flex: 0;
            }
        }

        .start-button {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            padding: 13px;
            background-color: #fff;
            text-align: center;
        }

        form {
            > div:first-child {
                flex: 1;

                input {
                    line-height: 2.2;
                }
            }
            > div:last-child {
                flex: 0;
                margin-right: 0;
            }
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;