.intelligent-tags-index-page-body {
    // padding: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .intelligent-tags-index-page-body-bottom {
        display: flex;
        height: 100%;
        .intelligent-tags-index-page-body-bottom-left {
            width: 217px;
            display: flex;
            flex-direction: column;
            .udesk-qa-ui-menu {
                flex: 1;
                overflow-y: auto;
                overflow-x: hidden;
            }
            .intelligent-tags-index-page-body-bottom-left-classification-item {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .intelligent-tags-index-page-body-bottom-left-classification-item-actions {
                    display: none;
                }
                &:hover {
                    .intelligent-tags-index-page-body-bottom-left-classification-item-actions {
                        display: block;
                    }
                }
                .intelligent-tags-index-page-body-bottom-left-classification-item-title {
                    // width: 200px;
                    flex: 1;
                    overflow: hidden;    
                    text-overflow:ellipsis;    
                    white-space: nowrap;
                }
            }
            .preset-categories,
            .normal-categories {
                margin-top: 16px;

                .category-title {
                    color: #999;
                    font-size: 12px;
                    padding: 8px 0;
                    border-bottom: 1px solid #e8e8e8;
                    margin-bottom: 8px;
                }
            }

            .normal-categories {
                margin-top: 24px;
            }
        }
        .intelligent-tags-index-page-body-bottom-right {
            // flex: 1;
            width: calc(100% - 217px);
            padding-left: 16px;
            .intelligent-tags-index-page-body-search-container {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
            }
        }
    }
}
.udesk-qa-ui-form {
    label {
        margin-bottom: 0px;
    }
}
.intelligent-tags-page-index {
    height: 100%;
}