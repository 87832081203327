.dashboard-page-index {
    .dashboard-page-card {
        background-color: #fff;
        padding: 16px;
        display: flex;
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px;
        &.space-between {
            justify-content: space-between;
        }
        &.header {
            padding: 21px 16px;
        }
        .billing-module {
            .udesk-qa-ui-divider {
                height: 40px;
                margin: 0 16px;
            }
            .billing-module-warning {
                color: rgb(251, 81, 39);
            }
            .balance-card {
                .balance-card-title {
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 14px;
                    line-height: 22px;
                }
                .balance-card-value {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 28px;
                    margin-top: 4px;
                }
            }
        }
        .edition {
            background: rgba(17, 168, 72, 0.1);
            border-radius: 42px;
            padding: 2px 8px;
            color: #11a848;
            font-size: 12px;
            line-height: 20px;
        }
        .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }
        .company {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            line-height: 20px;
        }
        .logo {
            width: 64px;
            height: 64px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .udesk-wechat-qa-dashboard {
            margin: -8px;

            display: grid;

            @media screen and (min-width: 1650px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media screen and (min-width: 1359px) and (max-width: 1649px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media screen and (min-width: 1069px) and (max-width: 1358px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media screen and (min-width: 779px) and (max-width: 1068px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 778px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .task-card-box-fake {
            height: 170px;
            .task-card-fake {
                height: 154px !important;
                .udesk-qa-ui-skeleton-title {
                    margin-top: 6px;
                }
            }
        }
        .task-card {
            background: #f8faff;
        }
    }
}
