.udesk-qa-web-page-body-root.with-padding.page-role-manage-center {
    padding: 0;
    height: 100%;
    .udesk-qa-web-page-content {
        height: 100%;
    }
    .page-role-manage-container {
        display: flex;
        height: 100%;
        .role-frame {
            width: 200px;
            border-right: 6px solid #f0f2f5;
            display: flex;
            flex-direction: column;
            overflow: auto;

            & > h3 {
                padding: 16px;
                font-weight: 700;
                font-size: 18px;
                border-bottom: 1px solid #f0f2f5;
            }
            & > div {
                overflow: auto;
                > p {
                    font-size: 14px;
                    padding: 10px 16px;
                    cursor: pointer;
                }
            }
            .role-tabs.active {
                color: #1b6dff;
            }
        }
        .role-frame-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .page-role-form-name-field {
                label {
                    font-weight: 700;
                    font-size: 18px;
                    margin-bottom: 12px;

                    &::before{
                        display: none;
                    }

                    &::after {
                        display: inline-block;
                        margin-right: 4px;
                        color: #ff4d4f;
                        font-size: 14px;
                        font-family: SimSun, sans-serif;
                        line-height: 1;
                        content: '*';
                    }
                }
            }
            .role-frame-content-actions {
                display: flex;
                align-items: center;
                padding: 10px 16px;
                border-bottom: 1px solid #f0f2f5;
                .actions-title {
                    flex: 1;
                    h3 {
                        font-weight: 700;
                        font-size: 18px;
                    }
                    p {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            .page-role-frame-scroll {
                height: calc(100vh - 208px);
                overflow-y: auto;
            }
            .role-frame-detail {
                display: flex;
                flex-direction: column;

                & > h3 {
                    padding: 16px;
                    font-weight: 700;
                    font-size: 18px;
                }
                .level-child {
                    padding: 10px;
                    .pression-frame {
                        margin-right: 16px;
                        border-right: none;
                        background: rgb(250, 250, 250);
                    }
                }
                .role-edit-level {
                    // height: calc(100vh - 356px);
                    flex: 1;
                    overflow-y: auto;
                    margin-bottom: 16px;
                }
            }
            .role-frame-edit-detail {
                // height: calc(100% - 150px);
                // overflow-y: auto;
                flex: 1;
            }
        }
    }
}