.learning-center-learn-body {
  height: 100%;

  .udesk-qa-web-page-content {
    display: flex;
    flex-direction: column;
    > div {
      flex: 1 1 auto;

      &:first-child {
        flex: 0 0 auto;
      }
    }
  }
  
  .course-info {
    width: 100%;
    padding: 16px;
    background: #fff;

    .course-name,
    .course-introduce {
        font-size: 14px;
        color: #555;

        > span:first-child {
          font-weight: 600;

          &::after {
            content: '：';
          }
        }
    }
  }

  .course-content,
  .course-download {
    > div:first-child {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    margin-top: 16px;
    padding: 16px;
    background-color: #fff;
  }

  .course-content {
    height: calc(100% - 16px);

    .safe-html-swap {
      box-shadow: none!important;
      max-height: initial!important;
    }
  }

  .course-download {
    .file-link-panel {
      width: 100%;
    }
    .file-link {
      padding: 0;
      white-space: initial;
      text-align: left;
    }
  }

  .start-task-btn {
    margin-top: 16px;
    width: 100%;
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;