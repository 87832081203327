.learning-center-node-test-body {
    height: 100%;

    .udesk-qa-ui-col {
        display: flex;
        flex-direction: column;
        // background-color: #fff;
        height: 100%;
        .customer-info {
            height: 100%;
            background-color: #fff;
        }
    }
    .node-test-page-chat {
        padding: 16px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .other-panel {
        width: 100%;
        background-color: transparent;

        > div {
            flex: 0;
            background-color: #fff;
        }

        > div:nth-child(2) {
            flex: 1;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;