.pages-team-analysis-container {
    .conversation-common {
        cursor: pointer;
        margin-bottom: 10px;
        &.active::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: #1b6dff;
        }
        .udesk-qa-ui-card-body {
            & > h3 {
                font-weight: 700;
                font-size: 30px;
                margin-top: 16px;
                text-align: center;
            }
            & > p {
                color: rgb(174, 174, 174);
                text-align: center;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .conversation-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        h3 {
            font-weight: 700;
            font-size: 20px;
            text-align: center;
        }
        span {
            font-size: 16px;
        }
        p {
            width: 100px;
            height: 10px;
            background: #F2F2F2;
        }
    }
    .self-data-show-container {
        display: flex;
        margin-top: 30px;
        .self-data-show-chart {
            flex: 2;
        }
        .self-data-show-chart, .self-data-show-list {
            & > h3 {
                font-size: 20px;
                font-weight: 700;
            }
        }
        .self-data-show-list {
            flex: 1;
            .common-item {
                & > h3 {
                    font-size: 20px;
                }
                p {
                    font-size: 16px;
                }
                span {
                    font-size: 14px;
                    color: #AEAEAE;
                }
            }
        }
    }
}