@charset "UTF-8";
.component-udesk-react-input-list .input-list-item {
  margin-bottom: 10px;
  position: relative;
}
.component-udesk-react-input-list .input-list-item .input-box {
  display: inline-block;
  width: 230px;
  outline: none;
  margin-right: 5px;
}
.component-udesk-react-input-list .input-list-item .action-icon {
  color: #00B38B;
  cursor: pointer;
  font-size: 20px;
}
.component-udesk-react-input-list .input-list-item .search-list-container {
  position: absolute;
  width: 230px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 1;
}
.component-udesk-react-input-list .input-list-item .search-list-container .search-list-item {
  padding: 3px 5px;
  cursor: pointer；;
}

