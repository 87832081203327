// @keyframes play {
//     to {
//         width: 100%;
//     }
// }

.audio-stickers-talk-v2 {
    position: relative;
    // top: 8px;
    width: 100%;
    overflow-x: hidden;
    z-index: 9;
    position: absolute;
    // top: 8px;
    width: 100%;
    overflow-x: hidden;

    .audio-sticker-view-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .audio-sticker-view-label {
        flex: 40px 0 0;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
    }

    .audio-sticker-view {
        flex: 1;
        width: 100%;
        height: 8px;
        position: relative;
        background-color: rgba(0, 0, 0, 0.05);
        margin: 5px 0px;

        .audio-sticker-item {
            height: 100%;
            position: absolute;
        }
    }
    .progress {
        background-color: rgba(0, 0, 0, 0.05);
        animation-timing-function: linear;
        height: 4px;
        .progress-inner {
            height: 100%;
            background-color: #1a6eff;
        }
    }
    // .progress.play {     /* 使animation动画启动 */
    //     animation-play-state: running;
    // }

    // .progress.pause {    /* 使animation动画暂停 */
    //     animation-play-state: paused;
    // }
}
