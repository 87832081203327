.audio-player-view {
    background: #f5f6f8;
    border-radius: 2px;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .audio-player-view-block {
        width: 33%;
        font-size: 14px;
        line-height: 22px;
        &.center {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
}
