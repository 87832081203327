.udesk-qa-chat-record-item,
.udesk-qa-ui-list-item {
    &.current-high-light-item {
        .udesk-qa-chat-record-item-time {
            .high-light-prev {
                background-color: #ff8552;
            }
        }
    }
    .audio-text-list-item-text-hit-status {
        background: #ffe59f;
        color: rgba(0, 0, 0, 0.85);
        .current-highlight-item {
            background-color: #ff8552;
        }
    }
    .high-light-prev {
        background-color: #ffe59f;
    }
    .current-highlight-element {
        background-color: #ff8552 !important;
        .high-light-prev {
            background-color: #ff8552;
        }
    }
    &.current-playing-item {
        background-color: #e8f4ff;
    }
    .udesk-qa-chat-record-item-chat-bubble-content {
        padding: 8px;
        display: inline-block;
        width: 100%;
        word-break: break-all;
        max-width: 300px;
        position: relative;

        &.chat-record {
            width: 240px;
            // height: 98px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            cursor: pointer;

            .chat-record-component-chat-card-chatrecord-title {
                overflow: hidden;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .chat-record-component-chat-card-chatrecord-content {
                overflow: hidden;
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .chat-record-component-chat-card-chatrecord-bottom {
                color: rgba(0, 0, 0, 0.25);
                font-size: 12px;
            }
        }
        .audio-text-list-item-file-size {
            margin-left: 8px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
        }

        .audio-text-list-item-text-hit-status {
            background: #ffe59f;
            color: rgba(0, 0, 0, 0.85);
            .current-highlight-item {
                background-color: #ff8552;
            }
        }
        video {
            &::-internal-media-controls-overflow-menu-list-item {
                display: none;
            }
            &::-webkit-media-controls-fullscreen-button {
                display: none;
            }
        }
        .audio-text-list-item-text-video-play {
            font-size: 40px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            color: #000;
            background-color: transparent;
            border-radius: 50%;
        }

        &.isTicket {
            max-width: fit-content;
            word-break: break-word;
        }
    }
    .udesk-qa-chat-record-item-chat-bubble-content-rich {
        // max-width: 300px;
        img {
            // max-width: 300px;
            height: 100%;
            width: 100%;
        }
        .udesk-image-viewer {
            display: block;
        }
    }
    .udesk-qa-chat-record-item-chat-bubble-content-image {
        padding: 8px;
        .udesk-image-viewer {
            display: block;
        }
    }
    .udesk-qa-ui-divider {
        margin: 0;
        display: flex;
        justify-content: center;
    }
    .udesk-qa-ui-divider-with-text {
        &:before {
            top: 0%;
            width: 25%;
        }
        &:after {
            top: 0%;
            width: 25%;
        }
        .udesk-qa-ui-divider-inner-text {
            color: #1890ff;
            font-size: 12px;
        }
    }
    .udesk-qa-chat-record-item-chat-bubble-content-text {
        display: flex;
        align-items: center;
        img {
            // max-width: 300px;
            height: 100%;
            width: 100%;
        }
    }
    .chat-record-component-chat-card-card {
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        width: 240px;
        // margin: -8px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .chat-record-component-chat-card-card-top {
            display: flex;
            justify-content: space-between;
            margin: 10px;
        }

        .chat-record-component-chat-card-card-top-box {
            display: flex;
            flex-flow: column;
        }

        .chat-record-component-chat-card-card-corp-name {
            margin-right: 8px;
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            line-height: 20px;
        }

        .chat-record-component-chat-card-card-name {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: normal;
            font-size: 14px;
            font-family: PingFang SC;
            font-style: normal;
            line-height: 22px;
        }

        .chat-record-component-chat-card-card-bottom {
            display: flex;
            margin-left: 10px;
            color: rgba(0, 0, 0, 0.25);
            font-size: 12px;
            line-height: 30px;
        }
    }
    .chat-record-component-chat-card-weapp {
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        width: 244px;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .weapp-icon {
            line-height: 27px;
        }

        .weapp-icon-big {
            font-size: 64px !important;
        }
        .chat-record-component-chat-card-weapp-title {
            display: flex;
            margin-top: 15px;
            margin-bottom: 5px;
            margin-left: 15px;
        }

        .chat-record-component-chat-card-weapp-content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
            margin: 10px 15px;
            background: #f2f5fa;
        }

        .chat-record-component-chat-card-weapp-bottom {
            display: flex;
            margin-left: 15px;
            color: rgba(0, 0, 0, 0.25);
            font-size: 12px;
            line-height: 30px;
        }
    }
    .chat-record-component-chat-card-redpacket {
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        width: 240px;
        height: 98px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .chat-record-component-chat-card-redpacket-top {
            display: flex;
            height: 69px;
            color: white;
            background-color: #fe6355;
        }

        .chat-record-component-chat-card-redpacket-content {
            display: -webkit-box;
            height: 44px;
            margin-top: 10px;
            overflow: hidden;
            line-height: 22px;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .chat-record-component-chat-card-redpacket-bottom {
            display: flex;
            height: 29px;
            color: rgba(0, 0, 0, 0.25);
            font-size: 12px;
            line-height: 20px;
            background-color: white;
        }

        .redpacket-icon {
            margin: 12px;
            font-size: 36px;
        }
    }
    .chat-record-component-sphfeed {
        display: flex;
        flex-flow: column;
        width: 180px;
        padding: 11px 11px 8px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.03);

        .chat-record-component-sphfeed-imgbox {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 172px;
            background: #f7f9fc;
            border-radius: 2px;

            .sphfeed-icon {
                font-size: 100px;
            }
        }

        .chat-record-component-sphfeed-name {
            margin-top: 8px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
        }

        .chat-record-component-sphfeed-desc {
            overflow: hidden;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .chat-record-component-sphfeed-msgtype {
            display: flex;
            align-items: center;

            .chat-record-component-sphfeed-title {
                color: rgba(0, 0, 0, 0.25);
                font-size: 12px;
            }
        }
    }

    .chat-record-component-address {
        display: flex;
        flex-flow: column;
        width: 240px;
        padding: 11px 11px 8px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.03);

        .chat-record-component-address-name {
            overflow: hidden;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .chat-record-component-address-desc {
            color: rgba(0, 0, 0, 0.25);
            font-size: 12px;
        }
    }

    .udesk-qa-chat-mixed-item-chat-bubble-content {
        width: 300px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
}

.chat-record-component-chat-card-chatrecord-modal {
    .chat-record-modal-body {
        margin: -8px -4px;
        height: 500px;
        overflow: auto;
    }

    .chat-record-modal-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .chat-record-modal-item-title-time {
        color: rgba(0, 0, 0, 0.25);
        font-size: 12px;
    }

    .chat-record-modal-item-description {
        padding: 4px 12px;
    }

    .chat-record {
        width: 240px;
        // height: 98px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        cursor: pointer;
        margin: -8px;
        padding: 8px;

        .chat-record-component-chat-card-chatrecord-title {
            overflow: hidden;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .chat-record-component-chat-card-chatrecord-content {
            overflow: hidden;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .chat-record-component-chat-card-chatrecord-bottom {
            color: rgba(0, 0, 0, 0.25);
            font-size: 12px;
        }
    }
}
