.comment-display-item {
    display: flex;
    .avatar {
        margin-right: 12px;
        width: unset;
        max-height: 84px;
        display: flex;
        flex-direction: column;
        .udesk-qa-ui-avatar {
            img {
                margin-top: 0;
                // border-radius: 50%;
                // height: 40px;
                // width: 40px;
                object-fit: cover;
                background-color: #f8f8f8;
            }
        }
        .icon-udesk-qa-react-web-a-bangzhuzhongxin1 {
            margin-top: 14px;
            color: #0d51d9;
            font-size: 16px;
        }
    }
    .comment-display-item-body {
        flex: 1;
        overflow-x: hidden;
        font-size: 14px;
        line-height: 22px;
        .comment-display-item-body-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }
        .create-time {
            color: rgba(0, 0, 0, 0.25);
            // overflow-x: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
        .comment-display-item-body-reply {
            min-height: 190px;
            display: flex;
            flex-direction: column;
            .comment-reply-component {
                flex: 1;
            }
        }
    }
    .content {
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
    .reply-to {
        white-space: nowrap;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .user {
        // margin-right: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 56px;
    }
    .reply {
        color: rgba(0, 0, 0, 0.25);
        white-space: nowrap;
    }
    .quote {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.45);
        padding-left: 8px;
        border-left: 3px solid #c4c4c4;
        margin-bottom: 8px;
    }
    .child-comments {
        // margin-left: 80px;
        > .udesk-qa-ui-btn-link {
            padding: 0;
        }
    }
    .udesk-qa-ui-btn-link {
        padding: 0;
    }
}
