.udesk-qa-react-score-record-item {
    display: flex;

    > span {
        padding: 0 11px 0 0;
    }

    > div {
        display: flex;
        flex: 1;
        flex-flow: column;

        > span {
            padding-top: 5px;
            color: rgba(0,0,0,0.45);
        }

        > div {
            display: inline-flex;
            justify-content: space-between;

            > span {
                flex: 2;
                white-space: pre-wrap;

                &:nth-child(3) {
                    flex: 3;
                    text-align: right;
                    padding-right: 5px;
                }

                &:last-child {
                    white-space: nowrap;
                    color: #1b6dff;
                    flex: 0;
                }
            }
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;