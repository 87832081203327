.massage-push-configuration-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    .massage-push-configuration-row-label {
        min-width: 70px;
        height: 24px;
        line-height: 24px;
    }
    .massage-push-configuration-row-content {
        flex: 1;
        .massage-push-configuration-child-row {
            .massage-push-configuration-child-row-label {
                min-width: 70px;
            }
            .massage-push-configuration-child-row-content {
                flex: 1;
            }
        }
    }
    .text-area-content {
        display: flex;
        .operator-list-item-associate-model {
            height: 306px;
            overflow: auto;
            .operator-list-item-associate-model-item {
                padding-left: 16px;
            }
        }
    }
}
