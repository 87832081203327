//Vertical line separation style
@mixin has-tooltip-hover() {
    &.has-tooltip {
        .nav-bar-item-tooltip {
            visibility: visible;
        }
        &:hover {
            .nav-bar-item-tooltip {
                visibility: visible;
            }
        }
    }
}

.component-report-nav-bar {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    line-height: 1;
    .report-nav-bar-item {
        &:nth-child(1) {
            border: none;
        }
        &.has-tooltip {
            .nav-bar-item-tooltip {
                margin-left: 3px;
                visibility: hidden;
            }
            @import '../react-field-tool/index.scss';
        }
    }
}

.component-report-nav-bar {
    &.nav-bar-theme-default {
        justify-content: space-around;
        .report-nav-bar-item {
            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid #ccc;
            &:nth-child(1) {
                border: none;
            }
        }
    }
} //theme tab
.component-report-nav-bar {
    &.nav-bar-theme-tab {
        align-items: center;
        margin-top: 10px;
        padding-left: 20px;
        border-bottom: 1px solid #ccc;
        .report-nav-bar-item {
            padding: 10px 15px;
            &.report-nav-bar-item-active {
                transform: translateY(1px);
                background-color: #fff;
                border: 1px solid #ccc;
                border-bottom: none;
                @include has-tooltip-hover();
            }
        }
    }
} //theme button
.component-report-nav-bar {
    &.nav-bar-theme-button {
        align-items: center;
        margin-top: 10px;
        border-bottom: 1px solid #ccc;
        .report-nav-bar-item {
            padding: 10px 15px;
            color: #666;
            &.report-nav-bar-item-active {
                border: 1px solid #ccc;
                border-bottom: none;
                color: #fff;
                background-color: #0cc2aa;
                @include has-tooltip-hover();
            }
        }
    }
}