.template-components-list-page-detail {
    height: 100%;
    background-color: transparent !important;
    .template-components-list-detail-descrt-title {
        padding: 13px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        span {
            font-weight: bold;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .template-components-list-page-detail-header {
        background: #fff;

        .udesk-qa-ui-descriptions-view {
            padding: 0 16px 8px;
        }
        .udesk-qa-ui-descriptions-item-container {
            align-items: center;
            height: 32px;
        }

        .template-components-list-detail-descrt-item {
            .udesk-qa-ui-descriptions-item-container {
                align-items: center;
            }
            .udesk-qa-ui-descriptions-item-label {
                vertical-align: top;
            }
            .udesk-qa-ui-descriptions-item-content {
                width: calc(100% - 70px);
            }
        }
        .template-components-list-detail-input-item {
            .udesk-qa-ui-descriptions-item-content {
                height: 32px;
            }
        }
    }
    .template-components-list-page-detail-body {
        margin-top: 16px;
        background: #fff;
        .template-components-list-page-detail-body-content {
            padding: 16px;
            position: relative;
            .template-components-list-page-detail-body-list {
                border: 1px solid rgba(0, 0, 0, 0.1);
                height: 520px;
                overflow-y: auto;
                .text-slice-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .udesk-qa-ui-anchor-link {
                    padding: 0;
                }
                .udesk-qa-ui-affix {
                    position: absolute !important;
                    top: 1px !important;
                }
                .template-components-list-page-detail-body-list-header {
                    height: 40px;
                    line-height: 40px;
                    background: rgba(0, 0, 0, 0.03);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                    padding-left: 16px;
                }
                .template-components-list-page-detail-body-list-content {
                    padding: 0px 8px 0px 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .template-components-list-page-detail-body-content-color {
                        font-size: 14px;
                        cursor: pointer;
                        .icon-udesk-qa-react-web-ic-log-line-beizhu {
                            color: rgba(72, 108, 250, 1);
                        }
                        .template-components-list-page-detail-body-content-color {
                            color: rgba(102, 166, 80, 1);
                        }
                    }
                }
            }
            .template-components-list-page-detail-updateList {
                .template-components-list-page-detail-updateList-item {
                    .template-components-list-page-detail-updateList-item-field {
                        display: inline-block;
                        width: 70px;
                    }
                }
                .template-components-list-page-detail-updateList-item-info {
                    display: inline-block;
                    .template-components-list-page-detail-list {
                        .template-components-list-page-detail-list-field {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .template-components-list-page-detail-body-list-content-checked {
            background: #e8f4ff;
        }
    }
}
