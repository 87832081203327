.ppt-content-panel {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 1;
    background-color: #F5F6F8;
    
    .ppt-content {
        display: inline-flex;
        justify-content: center;
        
        >.ppt-content-title {
            position: absolute;
            top:0;
            left:0;
            right: 0;
            height: 50px;
            line-height: 50px;
            padding: 0 16px;

            display: flex;
            justify-content: space-between;

            >span {
                font-weight: 500;
                font-size: 20px;
                color: rgba(0, 0, 0, 0.85);
            }

            >div {
                font-size: 14px;

                >span {
                    color: rgba(0, 0, 0, 0.45);
                }

                >span:nth-child(2) {
                    font-size: 16px;
                    color: #1b6dff;
                }
            }
        }
        >div.udesk-qa-ui-space {
            position: absolute;
            right: 16px;
            bottom: 16px;
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;