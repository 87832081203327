.smart-tools-sider-bar-index-page {
    .smart-tools-sider-bar-index-page-drawer {
        .smart-tools-sider-bar-index-page-drawer-body {
            padding: 8px 16px;
            .smart-tools-sider-bar-index-page-drawer-body-search-bar {
                position: relative;
                border-radius:3px;
                border:1px solid rgba(0,0,0,0.15);
                .smart-tools-sider-bar-index-page-drawer-body-input {
                    width: 88%;
                    height: 32px;
                    border: none;
                    margin-left: 5px;
                }
                .smart-tools-sider-bar-index-page-drawer-body-input-search {
                    position: absolute;
                    right: 7px;
                    font-size: 23px;
                    color: rgba(0,0,0,0.25);
                    cursor: pointer;
                }
            }
            .smart-tools-sider-bar-index-page-drawer-body-similar-discover-content {
                // padding: 4px 0px 8px;
                margin: 16px 0px 0px;
                max-height: calc(100vh - 135px);
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;
                .smart-tools-sider-bar-index-page-drawer-body-similar-discover-content-item {
                    margin-bottom: 9px;
                    margin-right: 12px;
                    height:24px;
                    line-height:24px;
                    padding: 0 4px;
                    color: rgba(0,0,0,.65);
                    background-color: rgba(232,244,255,1);
                    border-radius:2px;
                    min-width: 56px;
                    text-align: center;
                    cursor: pointer;
                    .smart-tools-sider-bar-index-page-drawer-body-similar-discover-content-item-icon {
                        margin-left: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
