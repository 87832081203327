    .quality-inspection-list-index-page-body {
        position: relative;
        padding: 16px;
        .quality-inspection-list-cloumns-contal {
            top: -48px !important;
        }
        .qa-react-list-page-funtion-search-input {
            display: inline-block;
            width: 200px;
            margin-right: 10px;
            height: 35px;
        }
        .quality-inspection-list-index-page-body-content-container {
            width: 100%;

            .component-react-table {
                .table-wrapper {
                    .columns {
                        z-index: 4;
                    }
                }
            }
        }
        em {
            color: red;
        }
        .udesk-pager {
            margin-top: 16px;
        }
        .quality-inspection-list-index-page-pagination {
            text-align: end;
            padding-right: 7px;
        }
    }



.re-inspection-modal {
    overflow: hidden;
    padding: 0 30px 20px 30px;

    .re-inspection-modal-progress-header {
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
    }

    .re-inspection-modal-config-content {
        .udesk-qa-ui-radio-wrapper {
            display: block;
        }

        .re-inspection-modal-config-content-points {
            width: 100%;
            height: 200px;
            overflow-y: auto;

            .re-inspection-modal-config-content-points-category {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .re-inspection-modal-config-content-points-point {
                width: 20%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .re-inspection-modal-progress-content {
        .re-inspection-modal-progress-bar-tip {
            font-size: 14px;
            overflow: hidden;
            height: 30px;
            line-height: 30px;
        }

        .re-inspection-modal-progress-bar {
            width: 100%;
            height: 30px;
            margin-top: 10px;
            background-color: #e8eeff;
            border-radius: 5px;

            .re-inspection-modal-progress-bar-completed-text {
                width: 100%;
                float: right;
                display: block;
                height: 30px;
                text-align: center;
                line-height: 30px;
                color: #fff;
                font-weight: bold;
            }

            .re-inspection-modal-progress-bar-completed {
                display: block;
                height: 30px;
                background-color: #5a6eff;
                border-radius: 5px;
            }
        }
    }

    .re-inspection-modal-progress-footer {
        overflow: hidden;
        margin-top: 20px;
        font-size: 14px;

        .re-inspection-modal-progress-footer-tip {
            height: 34px;
            line-height: 34px;
        }

        .end-reinspection-button {
            margin-right: 5px;
        }
    }
}
