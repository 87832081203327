.other-panel {
    .cue-panel {
        height: 100%;
        display: flex;
        flex-flow: column;

        .cue-title {
            padding: 16px;
            flex: 0;

            > div {
                position: relative;
                display: inline;

                > span {
                    position: relative;
                    font-size: 15px;
                    font-weight: 600;
                    position: relative;
                    z-index: 1000;
                }

                &::after {
                    position: absolute;
                    content: '';
                    height: 6px;
                    left: 0;
                    right: 0;
                    bottom: 3px;
                    z-index: 1;
                    border-radius: 6px;
                    background-color: #bddeff;
                }
            }

            > button {
                float: right;
            }
        }

        .cue-list {
            white-space: pre-wrap;
            overflow: auto;
            padding: 2px 16px 16px 16px;
            flex: 1;
        }

        .cue-tip {
            padding: 2px 16px 16px 16px;
            overflow: auto;

            > div {
                span:first-child {
                    &::after {
                        content: '：';
                    }
                }
            }
        }

        .udesk-qa-ui-empty {
            margin: auto 0;
        }

        .cue-list-item {
            word-break: break-all;
        }

        .cue-list-operation {
            text-align: center;
            margin: 10px 0;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;