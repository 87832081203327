.component-udesk-react-check-box {
    .check-box-item {
        display: inline-block;
        max-width: 100%;
        margin-bottom: inherit;
        padding: 3px 10px;
        line-height: 20px;
        cursor: pointer;
        &::before {
            display: table;
            content: " ";
        }
        &::after {
            clear: both;
        }
        .check-box-input {
            float: left;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;
            &.input-disabled {
                cursor: not-allowed;
            }
            &.input-disabled+i {
                cursor: not-allowed;
                &:before {
                    background: #EEE;
                }
            }
            &:checked {
                &+.check-box-decorate:before {
                    border-width: 0;
                    background-color: inherit;
                }
                &+.check-box-decorate:after {
                    transform: rotate(45deg);
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    display: table;
                    width: 5px;
                    height: 10px;
                    border: 2px solid;
                    border-top: 0;
                    border-left: 0;
                    content: ' ';
                }
            }
        }
        .check-box-decorate {
            float: left;
            height: 16px;
            margin-top: 1px;
            margin-right: 22px;
            position: relative;
            background-color: #00C49B;
            color: rgba(255, 255, 255, 0.87);
            &:before {
                content: "";
                position: absolute;
                width: 16px;
                height: 100%;
                border: 1px solid #CCC;
                border-radius: 1px;
            }
        }
        .check-box-label {
            float: left;
            margin-left: 5px;
        }
    }
}