.customize-model-error-template-page {
    width: 100%;
    text-align: center;
    // vertical-align: middle;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .customize-model-error-template-page-error-msg {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
        color: rgba(0,0,0,.6);
    }
}