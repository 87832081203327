.udesk-qa-web-coach-evaluation-overview {
    .evaluation-overview-container {
        padding: 16px;
        min-width: 1200px;

        .content-layout {
            // 数据部分
            .data-section {
                display: flex;
                gap: 16px;
                margin-bottom: 16px;

                .statistics-card, .score-card {
                    flex: 1;
                }
            }

            // 趋势部分
            .trend-section {
                display: flex;
                gap: 16px;
                border-radius: 4px;

                .trend-chart-section {
                    flex: 3;
                    padding: 16px;
                    background: #fff;

                    .section-title {
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 16px;
                    }
                }

                .score-table-section {
                    flex: 2;
                    flex-shrink: 0;
                    border-left: 1px solid #f0f0f0;
                    background: #fff;
                    max-width: 600px;

                    .table-header {
                        padding: 16px;
                        font-weight: 500;
                        border-bottom: 1px solid #f0f0f0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .sort-icon {
                            color: #1890ff;
                        }
                    }
                }
            }
        }
    }
} 
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;