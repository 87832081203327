.smart-tools-sider-bar-index-page {
    .smart-tools-sider-bar-index-page-drawer {
        .smart-tools-sider-bar-index-page-drawer-body {
            padding: 8px 16px;
            .smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar-container {
                height: 34px;
                overflow: hidden;
                display: flex;
                align-items: center;
                .smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar-btns {
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: nowrap;
                }
                .smart-tools-sider-bar-index-page-drawer-body-sentence-search-bar {
                    position: relative;
                    border-radius:3px;
                    border:1px solid rgba(0,0,0,0.15);
                    display: inline-block;
                    transition: width 1s;
                    width: 100%;
                    .smart-tools-sider-bar-index-page-drawer-body-input {
                        width: 88%;
                        height: 32px;
                        border: none;
                        margin-left: 5px;
                    }
                    .smart-tools-sider-bar-index-page-drawer-body-input-search {
                        position: absolute;
                        right: 7px;
                        font-size: 23px;
                        color: rgba(0,0,0,0.25);
                        cursor: pointer;
                    }
                }
                .smart-tools-sider-bar-index-page-drawer-body-search-bar-searching {
                    width: 180px;
                }
            }
            .smart-tools-sider-bar-index-page-drawer-body-sentence-content {
                // padding: 4px 0px 8px;
                margin: 16px 0px 0px;
                height: calc(100vh - 135px);
                overflow-y: auto;
                .smart-tools-sider-bar-index-page-drawer-body-sentence-content-item {
                    margin-bottom: 12px;
                    color:rgba(0,0,0,.65);
                }
            }
        }
        .smart-tools-sider-bar-index-page-drawer-footer {
            border-top: rgba(0,0,0,0.1) 1px solid;
            display: flex;
            justify-content: space-between;
            height: 38px;
            padding: 8px 16px 0px;
            .smart-tools-sider-bar-index-page-drawer-footer-left {
                color:rgba(0,0,0,0.65);
            }
            .smart-tools-sider-bar-index-page-drawer-footer-right {
                display: flex;
                .smart-tools-sider-bar-index-page-drawer-footer-right-page {
                    margin-left: 8px;
                }
            }
        }
        em {
            color: rgba(232,100,82,1);
            font-style: normal;
        }
    }
}
