.gong-check-point-test-page-index {
    height: 100%;
    .udesk-qa-web-page-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .check-point-test-page-index-foot {
            display: flex;
            width: 100%;
            &-left {
                flex: 1;
                display: flex;
                .udesk-qa-ui-btn {
                    margin-left: 8px;
                }
            }
            &-right {
                flex: 1;
                max-width: 444px;
                display: flex;
                flex-direction: column;
                .udesk-qa-ui-btn {
                    margin-right: 8px;
                    margin-left: 16px;
                }
            }
        }
    }
    .qa-react-inspection-detail-page {
        .qa-react-inspection-detail-content {
            .qa-react-inspection-detail-content-point {
                display: none;
            }
        }
    }
    .qa-react-inspection-detail-page {
        flex: 1;
        min-width: unset;
        .audio-call-content-info-v2 {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    .gong-check-point-test-page-right {
        flex: 1;
        padding-left: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-width: 444px;
        .title {
            height: 42px;
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}
