// 文件夹组件
.component-common-folder-item-container {
    min-width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 16px;
    cursor: pointer;
    .folder-content-detail {
        display: flex;
        align-items: flex-start;
        .folder-actions {
            display: flex;
            align-items: center;
        }
        .folder-icon {
            position: relative;
            &::before {
                content: '';
                display: inline-block;
                width: 48px;
                height: 2px;
                background: #1a6eff;
                position: absolute;
                left: -16px;
                top: -16px;
            }
        }
        .folder-content-call-num {
            flex: 1;
            margin-left: 10px;
            margin-right: 10px;
            & > h3 {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                line-height: 22px;
                font-weight: 700;
                max-width: 170px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            & > span {
                display: inline-block;
                color: rgba(0, 0, 0, 0.85);
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 20px;
                margin-top: 4px;
            }
            & > p {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 20px;
                margin-top: 4px;
            }
        }
    }
    &.folder-item-active {
        border: 1px solid #1a6eff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
    }
}

// 文件组件
.component-common-file-item-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    .file-icon {
        position: relative;
        margin-right: 10px;
        &::before {
            content: '';
            display: inline-block;
            width: 48px;
            height: 2px;
            background: #1a6eff;
            position: absolute;
            left: -16px;
            top: -16px;
        }
    }
    .file-content-detail {
        flex: 1;
        .file-message {
            .file-detail-duration {
                .udesk-qa-ui-space-item {
                    h3 {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.85);
                        max-width: 140px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    p {
                        font-size: 12px;
                        line-height: 20px;
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
                .file-duration {
                    padding: 2px 4px;
                    background: #e6f3ff;
                    color: #1a6eff;
                    border-radius: 2px;
                    font-size: 12px;
                    line-height: 20px;
                }
                .file-paragraph {
                    padding: 2px 4px;
                    background: #fff5f0;
                    border: 1px solid #ff7a52;
                    color: #ff7a52;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
            .file-content-date {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.85);
                margin-top: 12px;
            }
            .file-recommend-reason {
                color: rgba(0, 0, 0, 0.85);
                font-size: 12px;
                line-height: 20px;
            }
            .file-create-user {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 20px;
                margin-top: 20px;
            }
            .file-left-text-common-width {
                width: 202px;
            }
        }
    }
    &.file-item-active {
        border: 1px solid #1a6eff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 4px 14px rgba(0, 0, 0, 0.05);
    }
}

// 树组件
.common-component-files-tree-container {
    width: 240px;
    .udesk-qa-ui-tree-node-content-wrapper {
        overflow: hidden;
        flex: 1;
    }
    .files-tree-title {
        display: flex;
        align-items: center;
        & > p {
            flex: 1;
            margin-right: 10px;
            margin-bottom: 0;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .files-tree-actions {
            display: none;
        }
        &:hover {
            .files-tree-actions {
                display: block;
            }
        }
    }
}

.common-component-files-tree-container .udesk-qa-ui-tree .udesk-qa-ui-tree-node-content-wrapper.udesk-qa-ui-tree-node-selected {
    background: #E6F3FF;
    color: #1A6EFF;
}
