@import './variables.scss';

// @import './loader-animations.scss';
// @import 'value-components/power-select.scss';
@import 'value-components/cascade-dropdown.scss';
@import 'value-components/date-range-picker.scss';
@import 'value-components/bootstrap-duration-picker.scss';
@import 'value-components/select.scss';

.udesk-custom-filters-react {
    .filter-wrapper {
        .filter-conditions {
            width: $component-udesk-custom-filters-table-width;
            border-collapse: separate; // 在td之间增加间距
            border-spacing: $component-udesk-custom-filters-rows-gap;
            margin: -1 * $component-udesk-custom-filters-rows-gap; // 防止表格四周出现缩进
            // 我们本意是希望自定义过滤器最外层的table显示间距，但也会有副作用，
            // 在某些值组件内部会有table、或者有display: table的元素存在，也会导致子元素被继承了间距。
            // 所以我们需要在tbody上再恢复默认值，防止影响内部的布局
            & > tbody {
                border-collapse: collapse;
                border-spacing: 0;
            }

            colgroup {
                col {
                    &.condition-prefix-column {
                        @if $component-udesk-custom-filters-column-width-condition-prefix == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-prefix +
                                $component-udesk-custom-filters-cells-gap;
                        }
                    }

                    &.condition-field-column {
                        @if $component-udesk-custom-filters-column-width-condition-field == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-field +
                                $component-udesk-custom-filters-cells-gap;
                        }
                    }

                    &.condition-operator-column {
                        @if $component-udesk-custom-filters-column-width-condition-operator == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-operator +
                                $component-udesk-custom-filters-cells-gap;
                        }
                    }

                    &.condition-value-column {
                        @if $component-udesk-custom-filters-column-width-condition-value == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-value +
                                $component-udesk-custom-filters-cells-gap;
                        }
                    }

                    &.condition-postfix-column {
                        @if $component-udesk-custom-filters-column-width-condition-postfix == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-postfix +
                                $component-udesk-custom-filters-cells-gap;
                        }
                    }

                    &.condition-removal-column {
                        @if $component-udesk-custom-filters-column-width-condition-removal == auto {
                            width: auto;
                        } @else {
                            width: $component-udesk-custom-filters-column-width-condition-removal;
                        }
                    }
                }
            }

            .filter-head {
                .filter-head-row {
                    .filter-head-item {
                        font-weight: $component-udesk-custom-filters-header-font-weight;
                    }
                }
            }

            .filter-body {
                .condition-row {
                    .condition-item {
                        &.condition-prefix {
                            .condition-number {
                            }
                        }

                        &.condition-value {
                            .range-from-value {
                                display: inline-block;
                            }

                            .range-to-value {
                                display: inline-block;
                            }

                            .value-loading-error {
                                color: $component-udesk-custom-filters-error-color;
                            }

                            // @include value-loader-spinner();
                        }

                        &.condition-removal {
                            .filter-icon-removal {
                                .filter-icon {
                                    color: $component-udesk-custom-filters-removal-icon-color;

                                    &:hover {
                                        color: darken($component-udesk-custom-filters-removal-icon-color, 10%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .filter-actions {
            margin-top: $component-udesk-custom-filters-actions-row-top-gap;

            a.filter-icon-wrapper {
                color: inherit;
            }

            .add-condition {
                display: inline-block;

                .filter-icon-add {
                    &.filter-icon-add-with-condition-number {
                        margin-left: $component-udesk-custom-filters-column-width-condition-prefix;
                    }

                    .filter-icon {
                        color: $component-udesk-custom-filters-add-icon-color;

                        &:hover {
                            color: darken($component-udesk-custom-filters-add-icon-color, 10%);
                        }
                    }
                }
            }
        }

        .filter-validation-errors {
            margin-top: $component-udesk-custom-filters-validation-summary-top-gap;

            .validation-summary {
                margin-bottom: 10px;
                font-weight: bold;
                color: $component-udesk-custom-filters-error-color;
            }

            .field-error {
                margin-bottom: 5px;

                .field-name {
                    font-weight: bold;

                    &:after {
                        content: ' :';
                    }
                }

                .error-message {
                    padding-left: 20px;
                    color: $component-udesk-custom-filters-error-color;
                }
            }
        }

        .filter-icon-wrapper {
            .filter-icon {
                font-size: $component-udesk-custom-filters-action-icon-font-size;
            }

            .action-icon-text {
                font-size: $component-udesk-custom-filters-action-icon-text-font-size;
            }
        }

        .invisible-column {
            display: none !important;
        }
    }
}

.udesk-custom-filters-react.bootstrap {
    .filter-conditions {
        .filter-body {
            .condition-row {
                transition: border-color 0.5s;

                .condition-item {
                    &.condition-prefix {
                    }

                    &.condition-field {
                        .field-selector {
                            width: 100%;
                            padding: 5px 5px;
                            font-size: $component-udesk-custom-filters-condition-value-font-size;
                            height: 30px;
                        }

                        &.field-error {
                            .field-selector {
                                border: 1px solid $component-udesk-custom-filters-error-color;
                            }
                        }
                    }

                    &.condition-operator {
                        .operator-selector {
                            width: 100%;
                            padding: 5px 0 5px 5px;
                            font-size: $component-udesk-custom-filters-condition-value-font-size;
                            height: 30px;
                        }

                        &.operator-error {
                            .operator-selector {
                                border: 1px solid $component-udesk-custom-filters-error-color;
                            }
                        }
                    }

                    &.condition-value {
                        .form-control {
                            font-size: $component-udesk-custom-filters-condition-value-font-size;
                        }

                        .form-group {
                            margin-bottom: 0px;
                        }

                        .input-sm-wide {
                            min-width: 70px;
                            width: 70px;
                        }

                        .range-from-text {
                            margin-right: 5px;
                        }

                        .range-to-text {
                            margin-left: 5px;
                            margin-right: 5px;
                        }

                        // @include for-power-select();
                        @include for-cascade-dropdown;

                        @include for-date-range-picker;

                        @include for-bootstrap-duration-picker;

                        @include for-select;

                        &.value-error {
                            // input {
                            //     border: 1px solid $component-udesk-custom-filters-error-color;
                            // }
                        }
                    }
                }
            }
        }
    }
}

.udesk-custom-filters-react.default {
    .filter-conditions {
        .filter-body {
            .condition-row {
                transition: border-color 0.5s;

                .condition-item {
                    &.condition-field {
                        &.text-mode {
                            background-color: $component-udesk-custom-filters-disable-background-color;
                        }
                        .field-selector {
                            width: 100%;
                        }
                        .field-display-as-text {
                            padding-left: $component-udesk-custom-filters-rows-gap;
                        }

                        &.field-error {
                            .field-selector {
                                border: 1px solid $component-udesk-custom-filters-error-color;
                            }
                        }
                    }

                    &.condition-operator {
                        .operator-selector {
                            width: 100%;
                        }

                        &.operator-error {
                            .operator-selector {
                                border: 1px solid $component-udesk-custom-filters-error-color;
                            }
                        }
                    }

                    &.condition-value {
                        .range-from-text {
                            margin-right: 5px;
                        }

                        .range-to-text {
                            margin-left: 5px;
                            margin-right: 5px;
                        }

                        &.value-error {
                            // input {
                            //     border: 1px solid $component-udesk-custom-filters-error-color;
                            // }
                        }
                    }
                }
            }
        }
    }
}

.udesk-custom-filters-react.filter-animated {
    /* Set overflow to hidden to be friendly to animations.
    Some rollback animations need elements moved out of container, 
    but we should not see them out of container, so we can set this 
    style to hide them. */
    &.condition-adding,
    &.condition-removing {
        overflow: hidden;
    }

    .condition-animation {
        /* If animation is turned on, all actions are registered as callback of animations or transitions,
        we have to add certain animation effects to let animation callbacks work!
        To provide a default transition that seems NOT a transition at all, we can fade elements in&out 
        with very tiny changes that is unnoticeable for human people.  */
        transition: opacity 0.001s;
        opacity: 0.999;

        &.animation-in {
            opacity: 1;
        }
    }
}

.udesk-custom-filters-react.animations-default {
    .filter-wrapper {
        .filter-conditions {
            .condition-row {
                &.condition-animation {
                    transition: transform $component-udesk-custom-filters-condition-animation-duration;
                    transform: translateX(-100%);

                    &.animation-in {
                        transform: translateX(0);
                    }
                }

                .condition-item {
                    &.condition-value {
                        &.condition-animation {
                            opacity: 0;
                            transition: opacity $component-udesk-custom-filters-condition-value-animation-duration;

                            &.animation-in {
                                opacity: 1;
                            }

                            &.animation-out {
                                opacity: 0;
                            }
                        }
                    }
                }

                .condition-removal {
                    &.condition-animation {
                        opacity: 0;

                        &.animation-in {
                            transition: opacity
                                $component-udesk-custom-filters-removal-icon-on-adding-animation-duration-in;
                            opacity: 1;
                        }

                        &.animation-out {
                            transition: opacity
                                $component-udesk-custom-filters-removal-icon-on-one-row-left-animation-duration-out;
                            opacity: 0;
                        }
                    }
                }

                &.removing {
                    .condition-removal {
                        &.condition-animation {
                            &.animation-out {
                                transition: opacity
                                    $component-udesk-custom-filters-removal-icon-on-removing-animation-duration-out;
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        .filter-validation-errors {
            &.condition-animation {
                transition: opacity $component-udesk-custom-filters-validation-summary-animation-duration;
                opacity: 0;

                &.animation-in {
                    opacity: 1;
                }
            }
        }
    }
}
