.role-detail-basic-info-page {
    .role-detail-basic-info-page-tree {
        width: 100%;
        height: calc(100vh - 380px);
        overflow-y: auto;

        .react-tree.default {
            .tree-node {
                &.active-node {
                    .node-name {
                        background-color: #fff;
                        &:hover {
                            background-color: #fff;
                        }
                    }
                }
                &.checked-node {
                    .node-name {
                        background-color: #fff;
                    }
                }
                &.checkable-node:not(.checked-node) {
                    .node-name:hover {
                        background-color: #fff;
                    }
                }
                &.clickable-node:not(.active-node) {
                    .node-name:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}