.pages-gong-sale-client-center-detail-container {
    .sale-business-title {
        display: flex;
        justify-content: space-between;

        span.name {
            color: #555;
            font-size: 16px;
            font-weight: 500;
        }
        span.count {
            font-size: 14px;
            color: #1b6dff;
        }
    }
}