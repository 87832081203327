
@-webkit-keyframes action
{
    from{transform: rotate(-360deg) translate(16px) rotate(360deg);}

to{transform: rotate(0deg)  translate(16px) rotate(0deg);}
}
.record-upload-result-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .udesk-qa-ui-result {
        padding-top: 90px;
        width: 100%;
        .search-icon {
            -webkit-animation:action 2s linear infinite;
        }
        .udesk-qa-ui-result-extra {
            margin-top: 40px;
        }
        .udesk-qa-ui-progress {
            max-width: 560px;
        }
        .udesk-qa-ui-progress-inner {
            border-radius: 2px;
        }
        .udesk-qa-ui-progress-bg {
            border-radius: 2px;
            &:before {
                border-radius: 2px;
            }
        }
    }
}
