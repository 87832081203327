.udesk-coach-evaluation-record {
    height: 100%;
    background: transparent !important;
    .filter-form {
        padding: 24px;
        background: #fff;
        margin-bottom: 16px;
        border-radius: 4px;

        .form-row {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .udesk-qa-ui-form-inline {
        gap: 16px;
    }
} 