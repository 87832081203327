.data-preprocess-detail-page-body {
  padding: 16px;
  .data-preprocess-detail-page-body-select {
  }

  .data-preprocess-detail-page-body-edit-action {
    display: inline-block;
    color: #2878ff;
    cursor: pointer;
  }

  .data-preprocess-detail-page-radios {
    .data-preprocess-detail-page-radio-item {
      display: inline-block;
      margin-right: 10px;
      width: 100px;
    }
  }
  .data-preprocess-custom {
    position: relative;
    .index-manmagement-custom-drag-tarsk {
      position: absolute;
      text-align: center;
      top: -41px;
      z-index: 1;
      padding: 10px;
      background: rgba(0, 0, 0, 0.5);
      left: 50%;
      width: 200px;
      margin-left: -100px;
    }
    .data-preprocess-custom-drap-title {
      padding: 8px 0;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .data-preprocess-cutom-drap-info {
      display: flex;
      width: calc(100% - 42px);
    }
    display: flex;
  }
}

