.intelligent-extractors-page-index {
    .udesk-qa-ui-page-header {
        padding-bottom: 0!important;
    }
    .udesk-qa-ui-table tfoot > tr > td, 
    .udesk-qa-ui-table tfoot > tr > th, 
    .udesk-qa-ui-table-tbody > tr > td, 
    .udesk-qa-ui-table-thead > tr > th {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .udesk-qa-ui-btn .anticon {
        height: auto;
        width: auto;
        margin-left: 0;
    }
}