.smart-tools-sider-bar-index-page {
    .smart-tools-sider-bar-index-page-btn {
        min-width: 92px;
        height: 32px;
        position: fixed;
        z-index: 1031;
        border-radius: 16px 0px 0px 16px;
        background-color: rgba(27, 109, 255, 1);
        color: #fff;
        top: 45%;
        right: -64px;
        transition: right 0.5s;
        line-height: 30px;
        cursor: pointer;
        user-select: none;
        .smart-tools-sider-bar-index-page-btn-icon {
            margin-right: 6px;
            margin-left: 7px;
        }
    }
    .smart-tools-sider-bar-index-page-btn:hover {
        right: 0px;
    }
    .smart-tools-sider-bar-index-page-drawer {
        position: fixed;
        z-index: 1032;
        height: 100vh;
        width: 368px;
        top: 0px;
        right: -370px;
        transition-property: right;
        transition-duration: 0.6s;
        background-color: #fff;
        box-shadow: 6px 0px 10px 6px rgba(0, 0, 0, 0.15);
        .smart-tools-sider-bar-index-page-drawer-header {
            height: 40px;
            border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
            padding: 0px 16px;
            position: relative;
            transition: 0.6s;
            transform-style: preserve-3d;
            perspective: 1000;
            .smart-tools-sider-bar-index-page-drawer-header-cube {
                height: 100%;
                position: relative;
                transition: all 600ms;
                transform-style: preserve-3d;
                .smart-tools-sider-bar-index-page-drawer-header-cube-front {
                    height: 100%;
                    z-index: 2;
                }
                .smart-tools-sider-bar-index-page-drawer-header-cube-bottom {
                    height: 100%;
                    transform: rotateX(180deg);
                }
                .smart-tools-sider-bar-index-page-drawer-header-cube-front,
                .smart-tools-sider-bar-index-page-drawer-header-cube-bottom {
                    justify-content: space-between;
                    display: flex;
                    position: absolute;
                    align-items: center;
                    backface-visibility: hidden;
                    background-color: #fff;
                    top: 0;
                    left: 0;
                    .smart-tools-sider-bar-index-page-drawer-header-left {
                        display: flex;
                        .smart-tools-sider-bar-index-page-drawer-header-left-tags {
                            margin-right: 16px;
                            padding: 9px 0;
                            color: rgba(0, 0, 0, 0.65);
                            cursor: pointer;
                            user-select: none;
                        }
                        .active {
                            border-bottom: rgba(27, 109, 255, 1) 2px solid;
                            color: rgba(27, 109, 255, 1);
                        }
                    }
                    .smart-tools-sider-bar-index-page-drawer-header-right {
                        padding: 9px 0;
                        .smart-tools-sider-bar-index-page-drawer-header-right-cancel {
                            color: rgba(0, 0, 0, 0.65);
                            cursor: pointer;
                            position: absolute;
                            top: 0;
                            left: -57px;
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            background-color: #fff;
                            box-shadow: -8px 0px 15px -3px rgba(0, 0, 0, 0.15);
                        }
                    }
                }
            }
            .flipme {
                transform: rotateX(180deg);
            }
        }
    }
    .open {
        right: 0;
    }
    // 滚动背景
    ::-webkit-scrollbar {
        width: 0.3rem;
        height: 0.3rem;
        background-color: rgba(0, 0, 0, 0.1);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    // 滚动条
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(27, 109, 255, 0.5);
        transition: all 0.2s;
        border-radius: 0.5rem;
    }
}
