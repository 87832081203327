.classified-training-history-index-page {
    font-size: 14px;
    padding-bottom: 1px;
    color:rgba(0,0,0,0.85);
    .classified-training-history-index-page-body {
        background-color: #fff;
        padding: 16px;
        .classified-training-history-index-page-body-top {
            display: flex;
            white-space: nowrap;
            justify-content: space-between;
            padding-bottom: 4px;
            .classified-training-history-index-page-body-top-date {
                margin-bottom: 12px;
                margin-right: 16px;
            }
            .classified-training-history-index-page-body-top-tag {
                margin-right: 16px;
                margin-bottom: 12px;
            }
            .classified-training-history-index-page-body-top-user {
                margin-right: 16px;
                margin-bottom: 12px;
            }
            .classified-training-history-index-page-body-top-data {
                margin-right: 16px;
                margin-bottom: 12px;
            }
            .ant-btn-primary {
                color: #fff;
                background-color: #1890ff;
                border-color: #1890ff;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            }
        }
        .classified-training-history-index-page-body-table {
            .ant-pagination {
                width: 100%;
                text-align: right;
                margin-bottom: 0;
                .ant-pagination-total-text {
                    position: absolute;
                    left: 100px;
                    color:rgba(0,0,0,0.45);
                }
            }
            .ant-pagination-options {
                text-align: center;
                position: absolute;
                left: 0px;
                color:rgba(0,0,0,0.45);
            }
        }
    }
}