div {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #ccc;
    top: 45%;
    border-radius: 50%;
}

div:nth-child(1) {
    background-color: #FF5460;
    animation: udesk-loading-animation-moveing-balls 2s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

div:nth-child(2) {
    background-color: #FF9D84;
    animation: udesk-loading-animation-moveing-balls 2s 150ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

div:nth-child(3) {
    background-color: #efe67b;
    animation: udesk-loading-animation-moveing-balls 2s 300ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

div:nth-child(4) {
    background-color: #ff943d;
    animation: udesk-loading-animation-moveing-balls 2s 450ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

@keyframes udesk-loading-animation-moveing-balls {
    0% {
        left: 0%;
    }

    100% {
        left: 100%;
    }
}
