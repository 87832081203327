.index-management-tags-index-page {
  padding: 16px;
  .customer-information-tags-index-page-body {
    .index-management-tags-index-page-fitler {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}
