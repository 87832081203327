.audio-control-view {
    .audio-control-view-hit-point-list {
        position: relative;
        height: 28px;
        margin-bottom: -24px;
        .audio-control-view-hit-point {
            position: absolute;
            .audio-control-view-hit-point-header {
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: #fff;
                transform: translateX(-50%);
                cursor: pointer;
                z-index: 2;
                position: absolute;
                top: 2px;
            }
            .audio-control-view-hit-point-body {
                height: 16px;
                width: 9px;
                // border-radius: 50% 50% 50% 50% / 29% 29% 71% 71%;
                // background-color: red;
                color: red;
                // transform: translateX(-50%);
                cursor: pointer;
            }
        }
    }
    .udesk-qa-ui-slider {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 16px;
        &:hover {
            .udesk-qa-ui-slider-track {
                border-top-color: #6eadff;
            }
        }
        .udesk-qa-ui-slider-rail {
            height: 10px;
            padding-bottom: 100px;
            background-color: unset !important;
        }
        .udesk-qa-ui-slider-step {
            height: 10px;
            border-radius: 5px;
            background-color: #f5f5f5;
        }
        .udesk-qa-ui-slider-handle {
            margin-top: -1px;
            width: 14px;
            height: 12px;
            border: 1px solid #1a6eff;
            z-index: 12;
        }
        .udesk-qa-ui-slider-track {
            border-right: 1px solid #6F9FF5;
            border-top: 10px solid #96c7ff;
            background-color: transparent;
            transition: border-color 0.3s;
            height: 95px;
            z-index: 11;
        }
    }
    .udesk-qa-ui-slider-step {
        .udesk-qa-ui-slider-dot {
            // display: inline-block;
            height: 10px;
            background: #F09A00;
            top: 0;
            border-radius: 0;
            border: none;
            z-index: 11;
            display: none;
        }
    }
    .udesk-qa-ui-slider-mark {
        .udesk-qa-ui-slider-mark-text {
            display: none;
        }
    }
}
