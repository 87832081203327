.wrong-question-exercise-message-modal{
    .test-exit-desc {
        color: #aaa;

        span.num {
            color: #1b6dff;
        }
    }

    .not-prompted {
        padding-top: 20px;

        .udesk-qa-ui-checkbox-wrapper {
            color: #777;
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;