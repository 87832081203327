.group-test-page-body {
    height: 100%;

    .udesk-qa-web-page-content {
        display: flex;

        .data-report-page {
            flex: 1;
        }
    }
    .udesk-qa-ui-divider-vertical {
        bottom: 0;
        display: block;
        height: auto;
    }
}


.udesk-qa-group-test-list {
    flex: 0 0 240px;
    width: 240px;
    height: 100%;


    .udesk-qa-ui-table-wrapper,
    .udesk-qa-ui-spin-nested-loading,
    .udesk-qa-ui-table,
    .udesk-qa-ui-table.udesk-qa-ui-table-ping-right,
    .udesk-qa-ui-table-container,
    .udesk-qa-ui-table-content {
        height: 100%;
    }

    .udesk-qa-ui-table-body {
        height: calc(100% - 55px);
    }

    .udesk-qa-ui-table-cell.udesk-qa-ui-table-selection-column {
        border-color: transparent;
    }

    .group-test-item {
        display: flex;
        justify-content: space-between;

        > span:first-child {
            width: 222px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
        }

        > .toolkit-button {
            position: absolute;
            right: 7px;

            > button {
                display: none;
                width: 22px;
                height: 22px;
            }
        }

        &:hover {
            > span:first-child {
                width: 175px;
            }
            > span.udesk-qa-ui-tag {
                display: none;
            }
            > .toolkit-button > button {
                display: inline-flex;
            }
        } 
    }
    .udesk-qa-ui-table .udesk-qa-ui-table-thead > tr > th {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;

        > button {
            width: 100%;
        }
    }
}