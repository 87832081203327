.udesk-qa-app-entry {
    min-height: 100%;
    background-color: #f2f7ff;
    position: relative;
    &::before {
        content: ' ';
        background: linear-gradient(152deg, #1a6eff 0%, transparent 50%);
        opacity: 0.2;
        position: absolute;
        width: 1090.5px;
        height: 544px;
        top: 0;
        left: 0;
    }
    &::after {
        content: ' ';
        background: linear-gradient(-23deg, #1a6eff 0%, transparent 50%);
        opacity: 0.2;
        position: absolute;
        width: 1240px;
        height: 560px;
        bottom: 0;
        right: 0;
    }
    .udesk-qa-app-entry-sub-apps {
        padding: 84px 48px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, max-content));
        grid-gap: 16px;
        justify-content: center;
        position: relative;
        z-index: 10;
    }
}

.udesk-qa-app-entry-modal {
    .udesk-qa-ui-modal-title {
        text-align: center;
        font-size: 14px;
        font-weight: bold; 
    }
    .udesk-qa-ui-modal-body {
        padding: 0 50px;
    }
    .udesk-qa-ui-modal-footer {
        text-align: initial;
    }
}