.words-mining-dissent-page-right {
    .column-header {
        display: flex;
        justify-content: space-between;

        > div {
            overflow: hidden;
            margin-right: 16px;

            > div:nth-child(1) {
                width: 100%;
                overflow: hidden;
            }
        }

        .column-header-title {
            display: inline-flex;
            width: inherit;

            > span {
                white-space: nowrap;
            }
            > span:first-child {
                color: #1b6dff;
            }
            > span:last-child {
                color: #ccc;
                font-size: 12px;
                margin-left: 7px;
            }
        }
    }

    .page-right-table-item {
        text-align: left;
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;


        &:last-child {
            border-bottom: 1px solid transparent;
        }

        .message {
            display: flex;

            > span {
                flex: 0 0 30px;
            }

            > p {
                flex: 1;
                padding: 4px 15px;
            }
        }

        .user-type {
            display: inline-block;
            height: 30px;
            width: 30px;
            margin: 0 auto;
            border-radius: 50%;
            line-height: 30px;
            text-align: center;
            color: #0f9715 ;
            background-color: #d3f7e1;

            &.customer {
                color: #1552f3 ;
                background-color: #e1f4fa;

                + p{
                    color: #aaa;
                }
            }
        }

        .toolkit-button {
            float: right;
        }

        .page-right-form {
            flex: 1;
            padding-left: 15px;
        }
    }
}