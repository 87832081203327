.check-point-index-create-modal {
    .check-point-index-create-modal-body {
        margin: -24px;
        background: rgba(0, 0, 0, 0.03);
        padding: 16px 32px;
        height: 512px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        .check-point-index-create-modal-tabs {
            text-align: center;
        }
        .check-point-index-create-modal-ai {
            margin-top: 40px;
            text-align: center;
        }
        .check-point-index-create-modal-templates {
            margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
            .check-point-index-create-modal-templates-item {
                width: 160px;
                background-color: #fff;
                margin: 0 8px 16px;
                padding-top: 23px;
                padding-bottom: 8px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                user-select: none;
                &:hover {
                    transform: translate3d(0, -2px, 0);
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                    transition: all 0.2s linear;
                }
                .check-point-index-create-modal-templates-item-name {
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
    }
}
