.asr-status-content {
    padding: 16px;

    .asr-tag {
        display: inline-block;
        padding: 2px 8px;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        border-radius: 2px;
        color: #52c41a;
        font-size: 12px;
        margin-bottom: 16px;
    }

    .asr-status-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 12px;
            color: #333;
            font-size: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.asr-status-button {
    background-color: #4CAF50;
    color: white;
    margin-bottom: 20px;
}