.words-mining-dissent-page {
    .udesk-qa-web-page-body-root {
        height: 100%;
        width: 100%;
    }

    > div > div > div {
        display: flex;
        width: 100%;
        height: 100%;

        .udesk-qa-ui-table-wrapper,
        .udesk-qa-ui-spin-nested-loading,
        .udesk-qa-ui-table-container {
            height: 100%;
        }
        .udesk-qa-ui-table-fixed-header {
            flex: 1;
            height: calc(100% - 56px);
        }
        .udesk-qa-ui-table-body {
            overflow-y: auto!important;

            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected {
                td::after {
                    content: '';
                    display: inline-block;
                    height: 43px;
                    width: 2px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #1A6EFF;
                }
            }
            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
                border-bottom-color: transparent;
            }
        }

        .words-mining-dissent-page-left {
            width: 250px;
            border-right: 1px solid #eee;

            .udesk-qa-ui-table-thead > tr > th {
                background-color: #fff;
                border-color: #fff;
    
                &.udesk-qa-ui-table-cell-scrollbar {
                    box-shadow : none;
                }
            }
        }
    
        .words-mining-dissent-page-right {
            width: calc(100% - 258px);
            margin-left: 8px;
            padding: 16px 16px 16px 0;
        }
    }
}