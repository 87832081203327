$border-color: #ccc; 
$ul-padding-left: 20px;
$rule-node-width: 50px;

.clustering-rule{
    &.udesk-qa-ui-form-item-has-error{
        .udesk-qa-ui-select {
            &.error:not(.udesk-qa-ui-select-disabled):not(.udesk-qa-ui-select-customize-input) .udesk-qa-ui-select-selector {
                background-color: #fff;
                border-color: #ff4d4f!important;
            }
            &:not(.udesk-qa-ui-select-disabled):not(.udesk-qa-ui-select-customize-input) .udesk-qa-ui-select-selector {
                background-color: #fff;
                border-color: #d9d9d9!important;
            }
        }
        .udesk-qa-ui-input {
            &.error {
                background-color: #fff;
                border-color: #ff4d4f!important;
            }
            & {
                background-color: #fff;
                border-color: #d9d9d9!important;
            }
        }
    }

    > div.udesk-qa-ui-col-offset-3 {
        margin: -10px 0 0 calc(12.5% - 35px);
    }

    .udesk-qa-ui-form-item-control-input-content {
        ul {
            li {
                .udesk-qa-ui-btn .anticon {
                    height: auto;
                    width: auto;
                    margin-left: 0;
                }
                button {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
                    color: #ccc;

                    >span,
                    >span svg {
                        width: 12px;
                        height: 12px;
                    }

                    &.udesk-qa-ui-btn-primary {
                        color: #fff;
                    }
                }
            }
            
            ul {
                padding-left: $ul-padding-left;

                li {
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: -($ul-padding-left + $rule-node-width / 2);
                        width: 47px;
                        height: 1px;
                        background-color: $border-color;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -($ul-padding-left + $rule-node-width / 2);
                        width: 1px;
                        background-color: $border-color;
                    }

                    &:first-child {
                        &::after {
                            top: 50%;
                        }
                    }
                    &:last-child {
                        &::after {
                            bottom: 50%;
                        }
                    }
                }
            }

            li {
                padding: 10px;
                position: relative;
                display: flex;
                > ul,
                > div {
                    flex: 1;
                }

                > div.rule-node {
                    flex: 0 0 110px;
                    display: flex;
                    position: relative;
                    align-items: center;

                    > div {
                        background-color: #fff;
                        z-index: 1000;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 25px;
                            width: 10px;
                            height: 1px;
                            background-color: $border-color;
                        }
                    }
                }
            }
        }
    }
}