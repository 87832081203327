.fast-training-index-page {
    background-color: #fff;
    display: flex;
    width: 100%;
    height: 100%;
    .fast-training-index-page-body-left {
        width: 240px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-right: 1px solid rgba(240, 242, 245, 1);
        .fast-training-index-page-body-left-tltle {
            line-height: 22px;
            padding: 9px 16px;
            border-bottom: 1px solid rgba(240, 242, 245, 1);
            font-weight: 600;
        }
        .udesk-qa-ui-pagination {
            display: flex;
            margin: 0 auto 16px;
            // .udesk-qa-ui-pagination-prev {
            //     flex: 1;
            //     min-width: 22px;
            //     max-width: 24px;
            //     height: 24px;
            //     line-height: 24px;
            // }
            // .udesk-qa-ui-pagination-item {
            //     flex: 1;
            //     min-width: 22px;
            //     max-width: 24px;
            //     height: 24px;
            //     line-height: 24px;
            //     a {
            //         padding: 0;
            //     }
            // }
            // .udesk-qa-ui-pagination-jump-next {
            //     flex: 1;
            //     min-width: 20px;
            //     max-width: 24px;
            //     height: 24px;
            //     line-height: 24px;
            // }
            // .udesk-qa-ui-pagination-jump-prev {
            //     flex: 1;
            //     min-width: 20px;
            //     max-width: 24px;
            //     height: 24px;
            //     line-height: 24px;
            // }
            // .udesk-qa-ui-pagination-next {
            //     flex: 1;
            //     min-width: 22px;
            //     max-width: 24px;
            //     height: 24px;
            //     line-height: 24px;
            // }
        }
        .fast-training-index-page-body-left-content {
            .fast-training-index-page-body-left-content-input {
                padding: 16px 16px 7px;
            }
            .fast-training-index-page-body-left-content-list {
                padding: 16px 0;
                .fast-training-index-page-body-left-content-list-item {
                    padding: 0 16px;
                    height: 40px;
                    line-height: 40px;
                    border-right: 2px solid rgba(255, 255, 255, 1);
                    display: flex;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(240, 248, 255, 1);
                        border-right: 2px solid rgba(240, 248, 255, 1);
                    }
                    &.active {
                        background-color: rgba(240, 248, 255, 1);
                        border-right: 2px solid rgba(44, 124, 246, 1);
                        color: rgba(44, 124, 246, 1);
                    }
                    .fast-training-index-page-body-left-content-list-item-title {
                        max-width: 200px;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .fast-training-index-page-body-right {
        flex: 1;
        display: flex;
        width: calc(100% - 240px);
        .fast-training-index-page-body-right-similar-add-bar {
            padding: 16px;
            width: 60%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .udesk-qa-ui-btn-primary {
                background: rgba(27, 109, 255, 1);
                border-color: rgba(27, 109, 255, 1);
            }
            .udesk-qa-ui-pagination {
                display: flex;
                margin: 16px 16px 0;
                justify-content: flex-end;

                .udesk-qa-ui-pagination-prev {
                    flex: 1;
                    min-width: 22px;
                    max-width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
                .udesk-qa-ui-pagination-item {
                    flex: 1;
                    min-width: 22px;
                    max-width: 24px;
                    height: 24px;
                    line-height: 24px;
                    a {
                        padding: 0;
                    }
                }
                .udesk-qa-ui-pagination-jump-next {
                    flex: 1;
                    min-width: 20px;
                    max-width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
                .udesk-qa-ui-pagination-jump-prev {
                    flex: 1;
                    min-width: 20px;
                    max-width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
                .udesk-qa-ui-pagination-next {
                    flex: 1;
                    min-width: 22px;
                    max-width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
            }
            .fast-training-index-page-body-right-similar-add-bar-standard-sentence {
                padding: 12px 16px;
                background: rgba(0, 0, 0, 0.03);
                border-radius: 3px;
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                // max-width: 35vw;
                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: wrap;
                    // max-width: 35vw;
                }
                .udesk-qa-ui-input {
                    height: 22px;
                }
            }
            .fast-training-index-page-body-right-similar-add-bar-standard-sentence-input {
                display: flex;
                .udesk-qa-ui-input-wrapper {
                    font-size: 14px;
                    .udesk-qa-ui-input {
                        font-size: 14px;
                    }
                }
                .udesk-qa-ui-btn {
                    height: 37px;
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;
                }
                .udesk-qa-ui-btn-primary {
                    background: rgba(255, 255, 255, 1);
                    color: rgba(44, 124, 246, 1);
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    box-shadow: none;
                }
            }
            .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list {
                margin-top: 16px;
                height: calc(100% - 155px);
                .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title {
                    display: flex;
                    justify-content: space-between;
                    height: 38px;
                    box-shadow: 0px 1px 0px 0px rgba(232, 232, 232, 1);
                    .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-left {
                        font-weight: 600;
                        line-height: 38px;
                    }
                    .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-right {
                        color: rgba(0, 0, 0, 0.45);
                        line-height: 38px;
                    }
                }
                .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-title-left-content {
                    // height: calc(100vh - 395px);
                    overflow-y: auto;
                    height: 100%;
                    .fast-training-index-page-body-right-similar-add-bar-similar-sentence-list-item {
                        box-shadow: 0px 1px 0px 0px rgba(232, 232, 232, 1);
                        height: 42px;
                        line-height: 42px;
                        padding-left: 16px;
                        justify-content: space-between;
                        display: flex;
                        div {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .udesk-qa-ui-input {
                            height: 42px;
                            padding: 0;
                            // width: calc(100% + 16px);
                            margin-left: -16px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
        .fast-training-index-page-body-right-similar-recommend-bar {
            padding: 16px 16px 16px 0;
            width: 40%;
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 12px;

            .fast-training-index-page-body-right-similar-recommend-bar-inner {
                height: 100%;
                background: rgba(0, 0, 0, 0.03);
                border: 1px solid rgba(0, 0, 0, 0.1);
                justify-content: space-between;
                flex-direction: column;
                display: flex;
                position: relative;
                .fast-training-index-page-body-more-info {
                    width: 320px;
                    height: 496px;
                    background-color: #fff;
                    position: absolute;
                    right: 375px;
                    top: 40px;
                    z-index: 1;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
                    border-radius: 4px;
                    .fast-training-index-page-body-more-info-title {
                        height: 40px;
                        border-bottom: 1px solid rgba(216, 216, 216, 1);
                        padding: 9px 8px;
                        display: flex;
                        justify-content: space-between;
                        .fast-training-index-page-body-more-info-cancel {
                            cursor: pointer;
                            color: rgba(0, 0, 0, 0.45);
                        }
                    }
                    .fast-training-index-page-body-more-info-content {
                        padding: 8px;
                        display: flex;
                        flex-direction: column;
                        overflow-y: auto;
                        height: 456px;
                        .fast-training-index-page-body-more-info-content-item-customer {
                            margin-bottom: 16px;
                            display: flex;
                            justify-content: flex-end;
                            .fast-training-index-page-body-more-info-content-item-customer-left {
                                .fast-training-index-page-body-more-info-content-item-customer-left-content {
                                    width: 240px;
                                    background: rgba(191, 223, 255, 1);
                                    border-radius: 3px;
                                    padding: 8px;
                                }
                                .fast-training-index-page-body-more-info-content-item-customer-left-time {
                                    line-height: 20px;
                                    width: 240px;
                                    color: rgba(0, 0, 0, 0.45);
                                    font-size: 12px;
                                }
                            }
                            .fast-training-index-page-body-more-info-content-item-customer-rigth {
                                background: rgba(191, 223, 255, 1);
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                margin-left: 8px;
                                text-align: center;
                            }
                        }
                        .fast-training-index-page-body-more-info-content-item-user {
                            margin-bottom: 16px;
                            display: flex;
                            .fast-training-index-page-body-more-info-content-item-user-left {
                                background: rgba(0, 0, 0, 0.05);
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                text-align: center;
                            }
                            .fast-training-index-page-body-more-info-content-item-user-rigth {
                                margin-left: 8px;
                                .fast-training-index-page-body-more-info-content-item-user-rigth-content {
                                    width: 240px;
                                    background: rgba(0, 0, 0, 0.05);
                                    border-radius: 3px;
                                    padding: 8px;
                                }
                                .fast-training-index-page-body-more-info-content-item-user-rigth-time {
                                    line-height: 20px;
                                    width: 240px;
                                    color: rgba(0, 0, 0, 0.45);
                                    font-size: 12px;
                                    text-align: end;
                                }
                            }
                        }
                    }
                }
                .fast-training-index-page-body-right-similar-recommend-bar-title {
                    height: 40px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 16px 8px;
                    line-height: 22px;
                    user-select: none;
                    span {
                        cursor: pointer;
                        user-select: none;
                    }
                    .select-all {
                        .udesk-qa-ui-checkbox-wrapper {
                            height: 22px;
                            line-height: 22px;
                            color: rgba(27, 109, 255, 1);
                        }
                        .udesk-qa-ui-checkbox {
                            display: none;
                        }
                    }
                }
                .fast-training-index-page-body-right-similar-recommend-bar-checkbox {
                    padding: 16px;
                    overflow-y: auto;
                    // min-width: 360px;
                    // max-width: 30vw;

                    .udesk-qa-ui-checkbox-group {
                        .udesk-qa-ui-row {
                            .udesk-qa-ui-col-24 {
                                height: 22px;
                                line-height: 22px;
                                margin-bottom: 8px;
                                .udesk-qa-ui-checkbox-wrapper {
                                    height: 22px;
                                    line-height: 22px;
                                    max-width: 100%;
                                    flex: 1;
                                }
                            }
                        }
                    }
                    .fast-training-index-page-body-right-similar-add-bar-similar-sentence-recommend-list-item {
                        justify-content: space-between;
                        display: flex;
                    }
                }
                .fast-training-index-page-body-right-similar-recommend-bar-footer {
                    .udesk-qa-ui-pagination {
                        display: flex;
                        margin: 8px 16px;
                        justify-content: flex-end;
                        .udesk-qa-ui-pagination-options {
                            .udesk-qa-ui-select-selector {
                                height: 24px;
                                .udesk-qa-ui-select-selection-item {
                                    line-height: 24px;
                                }
                            }
                            .udesk-qa-ui-select-suffix {
                                vertical-align: top;
                            }
                        }
                        .udesk-qa-ui-pagination-total-text {
                            height: 24px;
                            line-height: 24px;
                        }
                        .udesk-qa-ui-pagination-prev {
                            flex: 1;
                            min-width: 22px;
                            max-width: 24px;
                            height: 24px;
                            line-height: 24px;
                        }
                        .udesk-qa-ui-pagination-item {
                            flex: 1;
                            min-width: 22px;
                            max-width: 24px;
                            height: 24px;
                            line-height: 24px;
                            a {
                                padding: 0;
                            }
                        }
                        .udesk-qa-ui-pagination-jump-next {
                            flex: 1;
                            min-width: 20px;
                            max-width: 24px;
                            height: 24px;
                            line-height: 24px;
                        }
                        .udesk-qa-ui-pagination-jump-prev {
                            flex: 1;
                            min-width: 20px;
                            max-width: 24px;
                            height: 24px;
                            line-height: 24px;
                        }
                        .udesk-qa-ui-pagination-next {
                            flex: 1;
                            min-width: 22px;
                            max-width: 24px;
                            height: 24px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .system-sentence-loading {
                height: 50%;

                .udesk-qa-ui-spin-container {
                    height: 100%;
                }
            }

            .fast-training-index-page-body-right-similar-recommend-bar-gpt {
                height: 50%;
            }
        }
    }
}

.fast-training-similar-sentences-modal {
    .udesk-qa-ui-modal-content {
        width: 480px;
        // height: 534px;
        .udesk-qa-ui-modal-header {
            padding: 16px 24px !important;
            background: #fff !important;
            border-bottom: 1px solid #e8e8e8;
            border-radius: 4px 4px 0 0;
            .udesk-qa-ui-modal-title {
                color: rgba(0, 0, 0, 0.85) !important;
            }
        }
        .udesk-qa-ui-modal-body {
            padding: 20px;
            // height: 420px;
            font-size: 14px;
            line-height: 1.5;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            word-wrap: break-word;
            position: relative;
            .udesk-qa-ui-input-search {
                .udesk-qa-ui-input {
                    border-radius: 3px;
                }
            }
            .fast-training-index-page-body-more-info {
                width: 320px;
                height: 496px;
                background-color: #fff;
                position: absolute;
                right: -328px;
                top: -55px;
                box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
                border-radius: 4px;
                .fast-training-index-page-body-more-info-title {
                    height: 40px;
                    border-bottom: 1px solid rgba(216, 216, 216, 1);
                    padding: 9px 8px;
                    display: flex;
                    justify-content: space-between;
                    .fast-training-index-page-body-more-info-cancel {
                        cursor: pointer;
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
                .fast-training-index-page-body-more-info-content {
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    height: 456px;
                    .fast-training-index-page-body-more-info-content-item-customer {
                        margin-bottom: 16px;
                        display: flex;
                        justify-content: flex-end;
                        .fast-training-index-page-body-more-info-content-item-customer-left {
                            .fast-training-index-page-body-more-info-content-item-customer-left-content {
                                width: 240px;
                                background: rgba(191, 223, 255, 1);
                                border-radius: 3px;
                                padding: 8px;
                            }
                            .fast-training-index-page-body-more-info-content-item-customer-left-time {
                                line-height: 20px;
                                width: 240px;
                                color: rgba(0, 0, 0, 0.45);
                                font-size: 12px;
                            }
                        }
                        .fast-training-index-page-body-more-info-content-item-customer-rigth {
                            background: rgba(191, 223, 255, 1);
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            margin-left: 8px;
                            text-align: center;
                        }
                    }
                    .fast-training-index-page-body-more-info-content-item-user {
                        margin-bottom: 16px;
                        display: flex;
                        .fast-training-index-page-body-more-info-content-item-user-left {
                            background: rgba(0, 0, 0, 0.05);
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            text-align: center;
                        }
                        .fast-training-index-page-body-more-info-content-item-user-rigth {
                            margin-left: 8px;
                            .fast-training-index-page-body-more-info-content-item-user-rigth-content {
                                width: 240px;
                                background: rgba(0, 0, 0, 0.05);
                                border-radius: 3px;
                                padding: 8px;
                            }
                            .fast-training-index-page-body-more-info-content-item-user-rigth-time {
                                line-height: 20px;
                                width: 240px;
                                color: rgba(0, 0, 0, 0.45);
                                font-size: 12px;
                                text-align: end;
                            }
                        }
                    }
                }
            }
            .udesk-qa-ui-pagination {
                display: flex;
                justify-content: flex-end;
                // .udesk-qa-ui-pagination-prev {
                //     flex: 1;
                // }
                // .udesk-qa-ui-pagination-item {
                //     flex: 1;
                //     a {
                //         padding: 0;
                //     }
                // }
                // .udesk-qa-ui-pagination-jump-next {
                //     flex: 1;
                // }
                // .udesk-qa-ui-pagination-jump-prev {
                //     flex: 1;
                // }
                // .udesk-qa-ui-pagination-next {
                //     flex: 1;
                // }
            }
        }
        .udesk-qa-ui-modal-footer {
            padding: 12px 15px;
            text-align: right;
            background: #fff;
            border-top: 1px solid #e8e8e8;
            border-radius: 0 0 4px 4px;
        }
    }
}
