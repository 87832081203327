.udesk-qa-communication-strategy-detail {
    .bf-content {
        min-height: 200px;
        max-height: 200px;
        height: unset;
    }
    .bf-link {
        text-decoration: underline;
    }
}
