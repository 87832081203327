.interactive-recognition-detail-page {
    padding: 16px;
    .interactive-recognition-detail-page-body {
        .udesk-qa-ui-legacy-form-item-label {
            max-width: 200px;
        }
        .invication-actions-node {
            width: 200px;
            text-align: right;
            span {
                color: rgba(27, 109, 255, 1);
                cursor: pointer;
            }
        }
        .udesk-qa-ui-col-sm-offset-3 {
            margin-left: 200px;
        }
        .interactive-custom-doute-answer {
            margin-bottom: 10px;
            .udesk-qa-ui-legacy-form-item {
                margin-bottom: 0px;
                .udesk-qa-ui-col-sm-offset-3 {
                    margin-left: 184px;
                    .interactive-custom-doute-answer-field {
                        min-width:530px;
                        position: relative;
                        display: inline-block;
                        padding: 5px 16px;
                        background: rgba(0, 0, 0, 0.03);
                        .interactive-custom-doute-answer-field-del {
                            position: absolute;
                            top: 38px;
                            right: -34px;
                            width: 24px;
                            height: 24px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 50%;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            line-height: 21px;
                            z-index: 100;
                            cursor: pointer;
                            i {
                                font-size: 12px;
                                color: #1b6dff;
                            }
                        }
                    }
                }
            }
        }
        .interactive-custom-action {
            position: fixed;
            bottom: 0px;
            height: 48px;
            background: #fff;
            width: 100%;
            margin-left: -33px;
            padding: 10px;
            padding-left: 150px;
            z-index: 10;
            border-top: 1px solid #d8d8d8;
        }
        .form-group {
            &.row {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .interactive-recognition-detail-input-list {
            display: block;

            .input-list-item {
            }
        }

        .interactive-recognition-detail-page-body-standard-sentences {
            position: relative;

            .next-form-item-control {
                position: relative;

                .information-entities-container {
                    position: absolute;
                    background: #fff;
                    border: 1px solid #ddd;
                    width: 100%;
                    z-index: 2;

                    .information-entity-item {
                        padding: 3px 5px;
                        cursor: pointer;

                        &:hover {
                            background: #eee;
                        }
                    }
                }
            }
        }

        .interactive-recognition-detail-page-body-standard-sentences-tip {
            margin: 0;
        }

        .interactive-recognition-types-radios {
            .interactive-recognition-type-radio-item {
                display: inline-block;
                width: auto;
                margin-right: 10px;
            }
        }

        .category-title {
            border: 1px solid #ddd;
            height: 34px;
            line-height: 34px;
            padding-left: 12px;
            position: relative;

            .category-content {
                position: absolute;
                left: 0;
                border: 1px solid #ddd;
                z-index: 1;
                background: #fff;
                max-height: 150px;
                overflow-y: auto;
                width: 100%;
            }
        }

        .interactive-recognition-detail-page-buttons {
            .btn {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
.interactive-recognition-detail-page-modal {
    a {
        color: #6887ff;
        margin-right: 5px;
    }
}
