.audio-call-info-component {
    height: 100%;
    border: 1px solid #d7d7d7;
    overflow: auto;

    .audio-call-info-navs {
        display: flex;
        justify-content: center;

        .nav-item {
            flex-grow: 1;
            height: 44px;
            cursor: pointer;
            line-height: 44px;
            display: inline-block;
            text-align: center;
            border-bottom: 1px solid #d7d7d7;
            border-right: 1px solid #d7d7d7;

            &:last-child {
                border-right: none;
            }

            &.active {
                color: #00b18d;
                border-bottom: 0px solid #d7d7d7;
            }
        }
    }

    .audio-text-view {
        height: 300px;
        overflow: auto;
    }
}
.upload-download-model {
    width: 380px;
    min-height: 310px;
    color: rgba(8, 9, 10, 0.75);
    position: absolute;
    top: -1px;
    right: -100px;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .upload-download-title {
        text-align: center;
        position: relative;
        border-bottom: 1px solid rgba(8, 9, 10, 0.1);
        padding-bottom: 5px;
        .title-clear {
            position: absolute;
            right: 0px;
        }
    }
    .model-bottom {
        height: 40px;
        border-top: 1px solid rgba(8, 9, 10, 0.1);
        background: #fff;
    }
    .model-content {
        position: relative;
        border-bottom: 1px solid rgba(8, 9, 10, 0.1);
        padding: 5px 0px;
        overflow: hidden;
    }
    .content-list {
        margin-left: 10px;
        float: left;
        font-size: 12px;
        max-width: 300px;
        cursor: pointer;
        .model-content-list-left {
            display: inline-block;
            width: 50px;
        }
        .model-content-list-right {
            display: inline-block;
            margin-left: 12px;
        }
    }
    .model-list-xiazai {
        position: absolute;
        right: 0px;
        line-height: 42px;
    }
    .clearModel {
        width: 100%;
        height: 220px;
        font-size: 14px;
        color: #999;
        text-align: center;
        line-height: 220px;
    }
    .uploadDownloadHover {
        color: #2878ff;
    }
    .uploadDownloadHover:hover {
        text-decoration: underline !important;
        color: blue;
    }
}
