.pages-gong-sale-client-center-detail-container {

    .sale-business {
        width: 100%;
        padding: 20px 0;

        .avatar {
            width: 120px;
            padding: 5px 30px;
        }
        > div:first-child {
            align-self: flex-start;
        }
        > div:last-child {
            width: 100%;
            height: 100%;
            
            >div {
                width: 100%;
            }
        }
    }

    .sale-business-bottom {
        margin: 0 -20px;
        height: 10px;
        background-color: #f0f2f5;
    }
}