.component-ud-select2 {
    position: relative;

    .font-blue {
        border: none;
        color: #00B38B;
    }

    .component-ud-select2-dropdown-menu {
        position: absolute;
        left: 0;
        padding: 0 5px;
        max-height: 200px;
        overflow: auto;
    }
}