.success-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-dialog {
    position: relative;
    width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .close-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        padding: 4px;

        &:hover {
            color: #666;
        }
    }

    .success-content {
        padding: 40px 20px;
        text-align: center;
    }

    .success-icon {
        font-size: 56px;
        color: #87D068;
        line-height: 1;
    }

    .success-title {
        margin: 20px 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #333;
    }

    .success-desc {
        margin: 8px 0 0;
        font-size: 14px;
        color: #999;
    }

    .success-btns {
        margin-top: 24px;

        button {
            height: 32px;
            padding: 0 16px;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s;
            border: none;
            margin: 0 8px;
        }

        .btn-later {
            background: #f7f7f7;
            color: #666;

            &:hover {
                background: #f0f0f0;
            }
        }

        .btn-check-task {
            background: #3370ff;
            color: #fff;

            &:hover {
                background: #2860df;
            }
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;