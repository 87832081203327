.qa-react-list-page-body-uploadDownload {
    height: auto !important;
    .qa-react-list-page-content {
        height: auto !important;
    }
    .uploadDownloadHover {
        color: #2878ff !important;
    }
    .uploadDownloadHover:hover {
        text-decoration: underline !important;
        color: blue !important;
    }
}
.qa-react-uploadDownload-list-page-footer {
    .udesk-qa-customize-pagination {
        padding-top: 0;
    }
}
