.iziToast.non-draggable {
  user-select: text;
  cursor: unset;
}
.iziToast .iziToast-body .iziToast-message {
  word-break: break-word;
}

.iziToast.iziToast-theme-default.iziToast-color-red {
  background: rgba(231, 76, 60, 0.88);
  border-color: rgba(231, 76, 60, 0.88);
}
.iziToast.iziToast-theme-default.iziToast-color-red .iziToast-body .iziToast-message,
.iziToast.iziToast-theme-default.iziToast-color-red .iziToast-body .iziToast-icon {
  color: #E9EDEF;
}
.iziToast.iziToast-theme-default.iziToast-color-yellow {
  background: rgba(243, 156, 18, 0.88);
  border-color: rgba(243, 156, 18, 0.88);
}
.iziToast.iziToast-theme-default.iziToast-color-yellow .iziToast-body .iziToast-message,
.iziToast.iziToast-theme-default.iziToast-color-yellow .iziToast-body .iziToast-icon {
  color: #E9EDEF;
}
.iziToast.iziToast-theme-default.iziToast-color-blue {
  background: rgba(52, 152, 219, 0.88);
  border-color: rgba(52, 152, 219, 0.88);
}
.iziToast.iziToast-theme-default.iziToast-color-blue .iziToast-body .iziToast-message,
.iziToast.iziToast-theme-default.iziToast-color-blue .iziToast-body .iziToast-icon {
  color: #E9EDEF;
}
.iziToast.iziToast-theme-default.iziToast-color-green {
  background: rgba(38, 185, 154, 0.88);
  border-color: rgba(38, 185, 154, 0.88);
}
.iziToast.iziToast-theme-default.iziToast-color-green .iziToast-body .iziToast-message,
.iziToast.iziToast-theme-default.iziToast-color-green .iziToast-body .iziToast-icon {
  color: #E9EDEF;
}

