.qa-react-list-page {
    .qa-react-list-page-header {
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 10px;
        height: 48px;
        line-height: 48px;

        .qa-react-list-page-header-title {
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    .qa-react-list-page-body {
        height: calc(100vh - 64px - 10px - 48px - 10px - 36px - 40px - 10px);
        min-height: 200px;
        display: flex;
        flex-direction: column;

        .qa-react-list-page-functions {
            overflow: hidden;

            .qa-react-list-page-functions-left-part {
                .qa-react-list-page-funtion-search-input {
                    display: inline-block;
                    width: 200px;
                    margin-right: 10px;
                    height: 35px;
                }
            }

            .qa-react-list-page-functions-right-part {
                .btn {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .qa-react-list-page-funtion-button {
                height: 35px;
                vertical-align: top;
            }
        }

        .qa-react-list-page-content {
            // position: absolute;
            flex: 1;
            // width: calc(100% - 20px);
            padding-top: 16px;
            // height: calc(100vh - 64px - 10px - 48px - 10px - 36px - 20px - 10px - 20px);
            overflow: auto;

            em {
                color: red;
            }

            .component-react-table {
                .table-wrapper {
                    .columns {
                        z-index: 4;
                        top: -35px;
                    }

                    .data-table-wrapper {
                        table {
                            thead {
                                tr {
                                    th {
                                        &:last-child {
                                            border-right: 1px solid #ddd;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .qa-react-list-page-content-no-value {
                text-align: center;
                margin-top: 10px;
            }
        }
    }

    .qa-react-list-page-footer {
    }
}
.custom-filter-info-type {
    max-height: 400px;
    overflow-y: auto;
}
