.pages-gong-sale-client-center-detail-container {
    .sale-business-stage {
        width: 100%;
        display: inline-flex;

        > div:first-child {
            flex: 1;
        }

        .empty {
            background: #f7f7f7;
            padding: 10px 20px;
            color: #555;
        }

        ul {
            height: 32px;
            display: flex;
            overflow: hidden;
            border-radius: 3px;

            &.lose li, 
            &.lose li.active,
            &.lose li:last-child.active {
                color: #fff!important;
                background-color: #e4ebf5!important;

                &:hover {
                    background-color: #e4ebf5!important;
                }
            }

            li {
                flex: 1;
                position: relative;
                display: inline-block;
                width: auto;
                min-width: 160px;
                padding: 5px 5px 5px 16px;
                text-align: center;
                background-color: #0EAC4D;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: #0da048;
                }

                span {
                    display: inline-block;
                    width: calc(100% - 20px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &:last-child.active {
                    background-color: #0EAC4D;

                    &:hover {
                        background-color: #0da048;
                    } 
                }
            }   

            li.active {
                background-color: #1A6EFF;

                &:hover {
                    background-color: #1a69f3;
                }
            }

            li.active ~ li {
                color: #888;
                background-color: #efefef;

                &:hover {
                    background-color: #e7e6e6;
                }
            }

            li:nth-last-child(n + 2) {
                &::before,
                &::after {
                    position: absolute;
                    top: 3px;
                    content: '';
                    width: 26px;
                    height: 26px;
                    transform: rotate(45deg);
                    z-index: 1000;
                }
                &::before {
                    right: -12px;
                    background-color: #fff;
                }
                &::after {
                    right: -6px;
                    background-color: inherit;
                }
            }
        }

        .custom-tooltip .custom-tooltip-content {
            padding-bottom: 5px;
        }
    }
}

.business-stage-tooltip {
    padding: 7px;
    color: #eee;

    > div:first-child {
        padding-bottom: 7px;
    }
    > div:last-child {
        font-size: 12px;
        padding-left: 7px;
    }
}