.evaluate-drawer {
    .evaluate-drawer-title {
        .title {
            font-weight: bold;
            margin-bottom: 8px;
        }
        .course-name {
            color: rgba(0, 0, 0, 0.45);
        }
    }
    
    .evaluate-drawer-content {
        .slider-container {
            display: flex;
            align-items: center;
            width: 100%;
            
            .slider-min,
            .slider-max {
                margin: 0 8px;
                color: rgba(0, 0, 0, 0.45);
            }
            
            .udesk-qa-ui-slider {
                flex: 1;
            }
            
            .udesk-qa-ui-input-number {
                width: 65px;
                margin-left: 16px;
            }
            
            .slider-desc {
                margin-left: 8px;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
    
    .drawer-footer {
        text-align: center;
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;