.interactive-recognition-index-page {
    padding: 16px;
    .interactive-recognition-index-page-body {
        .udesk-qa-customize-pagination {
            padding-top: 0;
        }
        .interactive-recognition-index-page-body-search-container {
            .interactive-recognition-index-page-body-search-input-container {
                padding: 0;
            }

            .qa-react-page-body-search-container-middle-part {
                margin-left: 18px;
                height: 34px;
                line-height: 34px;
            }
        }

        .interactive-recognition-index-page-body-content-container {
            &.row {
                margin-left: 0;
            }

            .interactive-recognition-index-page-classification-container {
                border: 1px solid #ddd;

                .interactive-recognition-category-container {
                    .react-tree-main {
                        .tree-node {
                            padding: 0;

                            .node-toggle {
                                margin-left: 0;
                                width: 10px;
                            }

                            .node-body {
                                width: calc(100% - 10px);

                                .node-name {}

                                .node-actions {}
                            }
                        }
                    }
                }
            }
        }
    }
    .answer,
    .question {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}