.wave-audio-component {
    .wave-audio-container {
        position: relative;

        .wave-audio-container-cursor {
            position: absolute;
            height: 100%;
            background: #000;
            margin: 0;
        }

        .wave-audio-container-no-wave-message {
            position: absolute;
            top: 50%;
            font-size: 12px;
            color: #888;
            margin-top: -9px;
        }
    }
}