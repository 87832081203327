.tasks-manage-template-automatic-add-page {
    // .udesk-qa-ui-tabs-nav {
    //     display: none;
    // }
    .automatic-content-item-body-item-content {
        flex: 1;
        align-self: center;
        width: 100%;
    }
    .automatic-content-item-body-item-content-custom-filters {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        width: 100%;
        height: 32px;
        padding: 0 11px;
    }
    .automatic-content-item-body-item-content-custom-input {
        input {
            height: 32px;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-variant: tabular-nums;
            list-style: none;
            font-feature-settings: 'tnum';
            position: relative;
            display: inline-block;
            width: 100%;
            min-width: 0;
            padding: 4px 11px;
            color: rgba(0,0,0,.65);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            transition: all .3s;
            &:focus {
                border-right-width: 1px!important;
                outline: 0;
            }
        }
    }
    .tasks-automatic-add-card {
        width: 100%;
        background-color: #fff;
        border-radius: 3px;
        margin-bottom: 16px;
        .tasks-automatic-add-card-header {
            min-height: 48px;
            padding: 13px 16px;
            .udesk-qa-ui-space-item {
                display: flex;
            }
            .tasks-automatic-add-card-header-title {
                font-weight:500;
                color:rgba(0,0,0,0.85);
                font-size: 14px;
                // margin-right: 16px;
            }
        }
        .tasks-automatic-add-card-body {
            padding: 40px;
            border-bottom: rgba(0,0,0,0.1) 1px solid;
            border-top: rgba(0,0,0,0.1) 1px solid;
            .automatic-content-item-body-item {
                display: flex;
                .automatic-content-item-body-item {
                    margin-bottom: 8px;
                }
                .automatic-content-item-body-item-title {
                    line-height: 30px;
                }
            }
            .tasks-automatic-add-card-body-push-list {
                display: flex;
                flex-direction: column;
                .tasks-automatic-add-push-item {
                    // display: flex;
                    padding: 0 40px;
                    margin-bottom: 16px;
                    .tasks-automatic-add-push-item-label {
                        min-width: 70px;
                    }
                    .tasks-automatic-add-push-item-content {
                        flex: 1;
                        overflow-x: hidden;
                    }
                }
                .tasks-automatic-add-push-item-hr {
                    padding-bottom: 16px;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                }
            }
        }
        .tasks-automatic-add-card-footer {
            height: 48px;
            padding: 8px 110px;
        }
    }
    .tasks-automatic-add-sider-bar {
        z-index: 500;
        position: fixed;
        right: 16px;
        width: 120px;
        height: 170px;
        top: 164px;
        padding: 16px;
        border-left: rgba(0,0,0,0.1) 1px solid;
        .automatic-quick-jump-list {
            .automatic-quick-jump-item {
                cursor: pointer;
                color:rgba(0,0,0,0.65);
                line-height:22px;
                a {
                    &:hover{
                        color: rgba(27,109,255,1);
                    }    
                }
                .automatic-quick-jump-item-icon {
                    width: 4px;
                    height: 4px;
                    border: 1px solid rgba(0,0,0,0.25);
                    display: inline-block;
                    vertical-align: middle;
                    background: rgba(0,0,0,0.25);
                    border-radius: 50%;
                    &:hover{
                        background: rgba(27,109,255,1);
                        border: 1px solid rgba(27,109,255,1);
                    }  
                }
            }
        }
    }
    .index-management-custom-filter {
        margin-left: 200px;
        margin-bottom: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.03);
        max-width: 800px;
        width: auto;
        max-height: 400px;
        overflow-y: auto;
        .index-management-custom-filter-edit {
            text-align: right;
            span {
                cursor: pointer;
                color: rgba(27, 109, 255, 1);
            }
        }
        .index-management-custom-filter-Info {
            display: flex;
        }
        .component-udesk-react-radio-btn .radio-btn-item {
            width: auto;
        }
    }
    .index-management-custom-filter-width-other {
        margin-left: 0;
    }
    #caseLibrary {
        .udesk-qa-ui-form-item {
            .udesk-qa-ui-form-item {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

