.component-share-modal-rang-time-container {
    position: relative;
    margin-top: 16px;
    .select-rang-time {
        height: 50px;
        display: flex;
        align-items: center;
        // border: 1px solid rgb(217, 217, 217);
        background: #F7F9FC;
        padding: 40px 20px;
        .slider-rang {
            flex: 1;
            margin-left: 16px;
        }
    }
    .slider-rang-time {
        position: absolute;
        bottom: 2px;
        right: 16px;
        font-size: 12px;
    }
    .udesk-qa-ui-slider-rail {
        background-color: #e1e1e1;
    }
    .udesk-qa-ui-slider-track {
        background-color: #FF7A52;
    }
    .udesk-qa-ui-slider:hover .udesk-qa-ui-slider-track {
        background-color: #FF7A52;
    }
    .udesk-qa-ui-slider-handle {
        border: 2px solid #FF7A52;
    }
    .udesk-qa-ui-slider:hover .udesk-qa-ui-slider-handle {
        border: 2px solid #FF7A52;
    }
    .udesk-qa-ui-slider-handle:focus {
        box-shadow: 0 0 0 5px rgba(255, 122, 82, 0.12);
    }
}