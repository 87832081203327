.information-entities-index-page {

    .information-entities-index-page-body {
        padding: 16px;
        .information-entities-index-page-body-search-container {
            margin-bottom: 10px;
            overflow: hidden;
        }

        .information-entities-index-page-body-content-container {
            .information-entities-item {
                display: block;
            }
        }
        .udesk-qa-auto-size-table-container {
            margin-bottom: 16px;
        }
    }
}