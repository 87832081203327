.record-upload-component {
    display: flex;
    width: 100%;
    justify-content: center;
    &-upload-wrapper {
        max-width: 400px;
        margin-right: 40px;
        width: 40%;
        &-inner {
            height: 186px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
        }
    }
    &-uploaded-display-list {
        flex: 1;
        max-width: 624px;
        display: flex;
        flex-direction: column;
        &-progress {
            .upload-icon {
                display: flex;
                align-items: center;
                margin-right: 4px;    
            }  
        }
        &-checkbox-list {
            flex: 1;
            overflow-y: auto;
            padding-top: 9px;
            &-item {
                display: flex;
                justify-content: space-between;
                border: 1px solid #fff;
                box-sizing: border-box;
                border-radius: 2px;
                padding: 4px 8px;
                cursor: pointer;
                border: 1px solid transparent;
                button {
                    display: none;
                }
                &:hover {
                    background: #E6F3FF;
                    border: 1px solid #1A6EFF;
                    box-sizing: border-box;
                    button {
                        display: inline-block;
                        border: none;
                        height: 22px;
                        position: absolute;
                        right: 1px;
                        background-color: #E6F3FF;
                    }
                }
            }
        }
    }
    .biue-color {
        color: #1A6EFF;
    }
}
.record-upload-component-uploaded-failed-list {
    max-height: 50vh;
    overflow-y: auto;
    .uploaded-failed-file-name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .uploaded-failed-file-reason {
        color: rgb(251, 81, 39);
        margin-left: 16px;
        margin-right: 16px;
    }
}