.selling-point-dissent-page {
    > div > div {
        height: 100%;
        display: flex;
        flex-flow: column;

        > div {
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex: 1;
            
            > div {
                width: 250px;
                flex: 0 0 250px;

                &:last-child {
                    flex: 1;
                    width: calc(100% - 500px);
                }
            }
        }

        .udesk-qa-ui-table-wrapper,
        .udesk-qa-ui-spin-nested-loading,
        .udesk-qa-ui-table-container {
            height: 100%;
        }
        .udesk-qa-ui-table-fixed-header {
            flex: 1;
            height: calc(100% - 56px);
        }
    }
}

.selling-point-dissent-page-left {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    > div:nth-child(1) {
        > div {
            padding: 14px;
        }
        > div:nth-child(1) {
            font-weight: 500;
            font-size: 14px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    > div:nth-child(2) {
        flex: 1;
        overflow: auto;

        .table-row-item {
            position: relative;
            padding: 5px 12px 5px 26px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            &::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                top: 13px;
                left: 12px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.15);
            }

            &.active,
            &:hover {
                color: #1A6EFF;
                border: 1px solid #1A6EFF;
                &::before {
                    background-color: #4791FF;
                }
            }
        }

        .udesk-qa-ui-table-thead .udesk-qa-ui-table-selection-column {
            padding: 16px;
            background-color: #fff;
            border-bottom-color: transparent;
        }
        .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
            padding: 4px 16px;
            border-bottom-color: transparent;
        }
        .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row:hover td {
            background-color: transparent;
        }
    }
}

.selling-point-dissent-page-center {
    display: flex;
    flex-direction: column;

    > div:nth-child(1) {
        font-weight: 500;
        font-size: 14px;

        > div {
            padding: 14px;
        }
        > div:nth-child(1) {   
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    > div:nth-child(2) {
        flex: 1;
        overflow: auto;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        .table-row-item {
            cursor: pointer;
            display: flex;
            padding: 0 0 0 6px;
            justify-content: space-between;
            
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .udesk-qa-ui-table-thead .udesk-qa-ui-table-selection-column {
            height: 46px;
            padding-left: 14px;
            text-align: left;
            background-color: #fff;
        }
        .udesk-qa-ui-table-body {
            overflow-y: auto!important;

            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected {
                td::after {
                    content: '';
                    display: inline-block;
                    height: 42px;
                    width: 2px;
                    position: absolute;
                    right: 0px;
                    top: -6px;
                    background-color: #1A6EFF;
                }
            }
            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
                padding: 4px 6px 4px 16px;
                border-top: 6px solid transparent;
                border-bottom: 0;
            }
        }
    }
}

.selling-point-dissent-page-right {
    display: flex;
    flex-direction: column;

    > div:nth-child(1) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 51px;
        padding-right: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    > div:nth-child(2) {
        flex: 1;
        overflow: auto;
        border-right: 1px solid rgba(0, 0, 0, 0.1);


        .udesk-qa-ui-table-thead .udesk-qa-ui-table-selection-column{
            border-bottom: 0;
            padding: 0;
            background-color: #fff;
        }

        .udesk-qa-ui-tabs-nav {
            margin-bottom: 0;
            padding: 0 12px 0 16px;
        }

        .udesk-qa-ui-pagination {
            padding: 0 20px;
        }
    }
}