.audio-call-content-view-info-content {
    em {
        color: red;
    }
    .message-tools-bar {
        .message-tools-bar-remark {
            margin-top: 4px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            min-width: 250px;
            padding: 2px 16px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.45);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }
    }
    .audio-text-list-item-text-hit {
        background: #ff8552;
        color: rgba(0, 0, 0, 0.85);
        .audio-text-list-item-text-hit-status {
            background: #ff8552;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .audio-text-list-item-text-hit-status {
        background: #ffe59f;
        color: rgba(0, 0, 0, 0.85);
    }
    .aduio-text-case-base-agent,
    .aduio-text-case-base-robot_agent {
        text-align: left;
        color: #185abd;
        i {
            margin-right: 3px;
        }
        .case-audio-add {
            cursor: pointer;
            margin-right: 10px;
        }
        .audio-detail-okText {
            cursor: pointer;
            margin-right: 5px;
        }
    }
    .aduio-text-case-base-customer {
        text-align: right;
        color: #185abd;
        i {
            margin-right: 3px;
        }
        .case-audio-add {
            cursor: pointer;
            margin-right: 10px;
        }
        .audio-detail-okText {
            cursor: pointer;
            margin-right: 5px;
        }
    }
    .icon-udesk-qa-react-web-yitianjiaanli {
        color: #36864a;
        margin: 0px 10px;
    }
    .marker-list-page {
        .marker-list-page-person {
            padding: 6px 10px;
            background: #fff;
            margin: 0px 10px;
            border-radius: 3px;
            cursor: pointer;
            .marker-list-page-person-name {
                margin-left: 10px;
                color: #1b6dff;
            }
        }
    }
    .udesk-image-viewer {
        display: block;
    }
    .audio-text-list-item-file-size {
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
    }
    .audio-text-list-item-sys-msg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        word-break: keep-all;
        color: rgba(0, 0, 0, 0.35);
        width: 100%;
        text-align: center;
    }
    .struct-type-card-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .struct-type-card-item-bottom {
        padding-bottom: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 4px;
    }
    .display-block {
        display: inline-block;
        word-break: break-all;
    }
    .audio-text-list-item-text-video {
        max-width: 299px;
        max-height: 274px;
    }
    .message-tools-bar-remark-content {
        width: 199px;
    }
}
