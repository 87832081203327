.wechat-intelligent-tags-page-index {
    height: 100%;
    display: flex;
    flex-direction: column;
    .udesk-qa-ui-card {
        .udesk-qa-ui-card-body {
            padding: 12px 20px;
        }
        margin-bottom: 16px;
        .udesk-qa-ui-tag {
            margin: 4px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.intelligent-tags-page-index {
    height: 100%;
}