$bg-custom: rgba(251, 81, 39, 0.5);
$bg-user: rgba(26, 110, 255, 0.7);

.pages-gong-sale-client-center-risk-tips-text {
    & > p > i {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ff7a52;
        margin-right: 4px;
    }
}
.pages-modal-sale-client-center-risk-setting {
    .semantics-tags-item {
        display: flex;
        align-items: center;
        .semantics-tags-item-content {
            flex: 1;
            & > h3 {
                margin: 0;
                font-size: 12px;
                padding: 9px 16px;
                line-height: 20px;
                background: #f8f8f8;
                box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
            & > p {
                margin: 0;
                padding: 9px 16px;
                font-size: 12px;
                line-height: 20px;
                box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
        }
        .semantics-tags-item-icon {
            margin-left: 16px;
            font-size: 24px;
            color: #1b6dff;
            cursor: pointer;
        }
    }
    .udesk-qa-ui-modal-body {
        height: calc(100vh - 500px);
        overflow-y: auto;
    }
}
.pages-gong-sale-client-center-list-container {
    .udesk-qa-ui-btn-link.udesk-qa-ui-btn {
        padding: 4px 0;
    }
    .risk-tips {
        background: #ffeba3;
        padding: 6px 12px;
        border-radius: 2px;
    }
    .risk-allocation {
        padding: 9px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 700;
        }
    }
    .user-proportion,
    .carousel-detail-container {
        i {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
        .bg-custom {
            background: $bg-custom;
        }
        .bg-user {
            background: $bg-user;
            transform: translateX(-40%);
        }
    }
    .carousel-detail {
        width: 468px;
        position: relative;
        .prev-btn {
            width: auto;
            padding: 0;
            position: absolute;
            left: -6px;
            z-index: 10;
            top: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
        }
        .next-btn {
            width: auto;
            display: inline;
            padding: 0;
            position: absolute;
            right: -22px;
            z-index: 10;
            top: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
        }
        .custom-carousel-container-class-name {
            .slick-slide.slick-active {
                overflow-y: hidden;
                overflow-x: auto;
                & > div {
                    width: max-content;
                }
            }
        }
        .carousel-detail-container {
            display: flex !important;
            overflow-x: auto;
            overflow-y: hidden;
            .carousel-detail-day {
                min-width: 90px;
                position: relative;
                display: flex;
                flex-direction: column;
                & > h3 {
                    white-space: nowrap;
                    text-align: center;
                    font-size: 12px;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    right: 0;
                }
                & > div {
                    height: 53px;
                    line-height: 73px;
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }
    .sale-client-center-list-echarts-container {
        padding: 0 16px;
    }
}
