.intelligent-analysis-page-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    .udesk-qa-ui-card {
        .udesk-qa-ui-card-body {
            padding: 12px 20px;
        }
        margin-bottom: 16px;
        .udesk-qa-ui-tag {
            margin: 4px;
            height: 24px;
            line-height: 24px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;