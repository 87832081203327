@import './util';
@import './vendor/_index';
@import './sibling-udesk-bi/_index';

.udesk-qa-ui-select-tree-indent-unit {
    width: 8px;
}

.udesk-qa-ui-radio-inner {
    &::after {
        top: 50%;
        left: 50%;
        transform: scale(0.5) translate(-100%, -100%);
        height: 16px;
        width: 16px;
    }
}

.udesk-qa-ui-radio-checked {
    .udesk-qa-ui-radio-inner {
        &::after {
            top: 50%;
            left: 50%;
            transform: scale(0.5) translate(-100%, -100%);
            height: 16px;
            width: 16px;
        }
    }
}

.udesk-qa-ui-form-builder-container {
    textarea.udesk-qa-ui-input {
        margin-bottom: 0px;
    }
    label {
        &.udesk-qa-ui-radio-button-wrapper {
            padding: 0 15px;
        }
    }
}
.udesk-qa-ui-input-search-button {
    justify-content: center;
    display: flex;
    align-items: center;
}
.udesk-qa-web-route-entry {
    @import './_variables';
    @import './_reset';
    @import './_layout';
    @import './components/_index';
    // padding: 20px 28px;
    .udesk-qa-ui-layout {
        .udesk-qa-ui-layout-content {
            height: 100%;
        }
    }
    .udesk-qa-web-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        .udesk-qa-ui-btn-icon-only {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        .udesk-qa-ui-anchor-wrapper {
            background-color: #fff;
        }
        .udesk-qa-ui-radio-button-wrapper {
            padding: 0 15px;
        }
        .udesk-qa-web-page-header {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
            padding: 9px 24px;
            .udesk-qa-ui-page-header-footer {
                margin-top: 0;
            }
        }
        .udesk-qa-web-page-body {
            padding: 16px 16px 0;
            flex: 1;
            overflow-y: auto;
            .udesk-qa-web-page-body-root {
                @extend .beautiful-scrollbar;

                min-height: 100%;
                background-color: #fff;

                th {
                    &.udesk-qa-ui-table-cell-fix {
                        &-left,
                        &-right {
                            background-color: #f8f8f8;
                        }
                    }
                }

                .udesk-qa-ui-form-builder-container {
                    & label {
                        display: inline-flex;
                    }
                }
                .udesk-qa-web-page-footer {
                    background-color: #fff;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }
                label {
                    &.udesk-qa-ui-checkbox-wrapper {
                        display: inline-flex;
                        height: unset;
                        line-height: unset;
                    }
                    &.udesk-qa-ui-radio-wrapper {
                        display: inline-flex;
                        height: unset;
                        line-height: unset;
                    }
                }
            }
            .udesk-qa-ui-input-group-wrapper {
                &.udesk-qa-ui-input-search {
                    vertical-align: middle;
                }
            }
        }
    }
    .full-amount-work-pages {
        height: 100%;
    }
}
.daterangepicker {
    max-height: 424px;
    overflow-y: auto;
}
.udesk-qa-modal-default-header {
    overflow: hidden;
    height: 58px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    color: #fff;
    line-height: 58px;
    background-color: #00b38b;
}

.udesk-qa-modal-body-content {
    //导入功能
    .upload-file-button-container {
        width: 46px;
        height: 30px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;

        .upload-file-inpiut {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 30px;
        }
    }

    .upload-file-button {
    }

    .upload-file-name {
        display: inline-block;
    }

    .upload-file-template {
        color: #2878ff;
        cursor: pointer;
    }

    //智能标签分类项
    .smart-tag-category-item {
        margin-bottom: 10px;

        .smart-tag-category-item-text {
            display: inline-block;
            width: 78%;
        }

        .smart-tag-category-item-color {
            display: inline-block;
            width: 10%;
            margin: 0 1%;
        }

        .smart-tag-category-item-delete {
            display: inline-block;
            text-align: center;
            width: 10%;
            color: #2878ff;
            cursor: pointer;
        }
    }

    .smart-tag-category-item-add-action {
        color: #2878ff;
        cursor: pointer;
    }

    .table-actions {
        display: inline-block;
        margin-right: 5px;
        color: #2878ff;

        &:last-child {
            margin-right: 0;
        }
    }

    //通话对话管理
    .customer-filters-judgeStrategy-radios {
        .customer-filters-judgeStrategy-radio-item {
            display: inline-block;
            width: auto;
            margin-right: 10px;
        }
    }

    .customer-filters-save-options {
        .customer-filters-save-option-item {
            &:first-child {
                padding-left: 0;
            }
        }
    }
}

.udesk-qa-modal-default-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 64px;
    background-color: #f4f5f9;
}

.udesk-qa-smart-words-library-modal-header {
    background-color: #fff;
    height: 38.5px;
    padding: 10px 15px;
    font-size: 14px;
    color: rgba(8, 9, 10, 0.75);
}

.udesk-qa-smart-words-library-modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .btn-success {
        background-color: #2878ff;
    }
}

// 智能词库
.ant-modal {
    .ant-modal-content {
        .ant-modal-body {
            .smart-words-library-nav-item-form {
                padding-top: 14px;
            }

            .ant-form-item {
                margin-bottom: 12px;

                .ant-form-item-label {
                    label {
                        font-size: 13px;
                        color: rgba(8, 9, 10, 0.75);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

// 智能词库-有效性选择器
.udesk-qa-smart-words-library-react-select {
    padding-left: 5px;
    width: 57px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
    min-width: 47px;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    height: 30px;
    font-size: 12px;
    line-height: 1.42857143;
    color: rgba(8, 9, 10, 0.75);
}

// 智能词库-同义词
.udesk-qa-smart-words-library-modal-body-synonym-words-list-item-groups {
    margin-top: 8px;
    line-height: 22px;

    .synonym-words-item-group {
        float: left;
        margin-right: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        max-width: 367px;

        .synonym-words-item-label {
            margin-bottom: 0;
            font-size: 12px;
            color: rgba(8, 9, 10, 0.75);
        }

        .synonym-words-item-button {
            padding: 1px 6px;
            background: #fff;
            border: 1px solid #fff;
        }
    }
}

.synonym-words-required-form-item {
    position: relative;

    .synonym-words-required-star {
        color: red;
        position: absolute;
        left: -62px;
    }
}

.udesk-qa-smart-words-library-modal-body-synonym-words-list-input-box {
    padding: 0;
    display: flex;
    align-items: center;

    .udesk-qa-smart-words-library-modal-body-synonym-words-list-input {
        padding: 6px 12px;
        line-height: 1.42857143;
        color: rgba(8, 9, 10, 0.75);
        background-color: #fff;
        background-image: none;
    }

    .no-field-warning {
        border-color: #f5222d;
    }

    .synonym-words-item-button {
        background: #fff;
        border: 1px solid #fff;
        padding: 1px 6px;
        padding-right: 0px;

        .synonym-words-icon {
            color: #9e9494;
        }

        .no-synonym-words-warning {
            color: #f5222d;
        }
    }
}

// 智能词库-权重
.ant-modal-content {
    .smart-words-library-success-modal {
        width: 200px;
    }

    .ant-modal-body {
        .ant-form {
            .edit-weight-input {
                width: 24%;
            }

            // 感叹号
            .standard-weight-information {
                padding-left: 9px;
                font-size: 12px;
                line-height: 32px;

                .exclamation {
                    display: inline-block;
                    height: 15px;
                    color: #4285ff;
                    font-size: 12px;
                    margin-right: 2px;
                }
            }
        }
    }
}

.ant-modal .ant-modal-content {
    .ant-modal-body {
        // 上传模板样式
        .udesk-qa-upload-modal-content {
            .udesk-qa-upload-modal-item {
                margin-bottom: 10px;
            }

            .upload-item-btn {
                color: #0cc2aa;
                background-color: #fff;
                border: 1px solid #e5e5e5;
                text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
                margin: 0 15px;
                line-height: 1;
            }

            .upload-modal-top-json-btn,
            .upload-modal-top-excel-btn,
            .upload-modal-top-txt-btn {
                color: #337ab7;
            }

            .upload-modal-content-bottom {
                display: flex;
                justify-content: center;

                .upload-item-btn {
                    width: 100px;
                    height: 30px;
                }

                .upload-modal-content-bottom-upload-btn {
                    margin-right: 20px;
                    background-color: #00b38b;
                    color: #fff;
                    line-height: 30px;
                    text-align: center;
                }

                .upload-file-input {
                    display: none;
                }
            }
        }

        // 智能词库-语料数据
        form {
            .corpus-text-area-box {
                display: flex;
                align-self: center;
            }

            .have-corpus-content-textarea-form-group {
                display: flex;
                align-items: center;

                .have-corpus-content-textarea-form-group-right {
                    position: relative;

                    .show-corpus-content-required-info {
                        color: #f5222d;
                        font-size: 14px;
                        position: absolute;
                        left: -34px;
                        top: 0;
                    }
                }
            }

            // 语料数据底部select与警告盒子
            .corpus-data-select-or-exclamation-box {
                padding: 0;

                span,
                a {
                    font-size: 12px;
                }
            }

            .no-field-warning {
                border-color: #f5222d;
            }

            .corpus-content-textarea {
                resize: vertical;
                border-radius: 4px;
            }

            .corpus-data-modal-footer-form-item {
                margin: 0;

                .ant-form-item-control-wrapper {
                    .corpus-data-modal {
                        padding-bottom: 0;
                        padding-top: 20px;
                        background-color: #fff;

                        button[disabled] {
                            background-color: #ccc;
                            border-color: #ccc;
                        }
                    }
                }
            }

            .exclamation {
                display: inline-block;
                height: 15px;
                color: #4285ff;
                font-size: 12px;
                margin-right: 2px;
            }

            .upload-btn {
                color: #4285ff;
                cursor: pointer;
            }

            .udesk-qa-smart-words-library-modal-body-warning-span {
                color: #f5222d;
                // font-size:12px;
            }
        }
    }
}

.ant-modal-header {
    padding: 0;
}

.ant-modal-content {
    .ant-modal-header {
        padding: 0;
    }
}

.ant-modal-body {
    padding: 0;

    .udesk-qc-modal-body-content {
        padding: 10px 20px 0 20px;
    }

    .udesk-qa-modal-body-content {
        padding: 10px 20px 0 20px;
    }
}

.ant-modal-footer {
    padding: 0;

    button.btn-default {
        color: #666666;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
        margin: 0 15px;
        width: 106px;
        line-height: 1;
    }

    button.btn-success {
        color: #fff;
        background-color: #00b38b;
        border-color: #00b38b;
        text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
        margin: 0 15px;
        width: 106px;
        line-height: 1;
    }
}

.component-react-table {
    .columns {
        .columns-wrapper {
            .columns-list {
                .column-item {
                    .checkbox {
                        .column-label {
                            padding-left: 20px;

                            input[type='checkbox'] {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.udesk-qa-modal-sementic-tags-content {
    .sementic-tags-search-box {
        margin-bottom: 5px;

        .sementic-tags-input {
            width: 350px;
            display: inline-block;
        }
    }

    .semantic-tags-create-box {
        .semantic-tags-create-action {
            color: #2878ff;
            cursor: pointer;
        }
    }
}

.udesk-qa-component-element[lang='en'] {
    // text-transform: lowercase;

    .udesk-qa-ui-layout-sider-children,
    .udesk-qa-ui-tabs-nav-list,
    .udesk-qa-ui-page-header-heading-title,
    .udesk-qa-ui-btn,
    .check-point-nav-link {
        text-transform: capitalize;
    }
    .udesk-qa-ui-table-thead {
        .udesk-qa-ui-table-cell {
            text-transform: capitalize;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .header-cell-body-item {
        text-transform: capitalize;
    }
}

.udesk-qa-ui-standardize-table .udesk-qa-ui-table-wrapper .udesk-qa-ui-pagination {
    margin-bottom: 2px;
}

.udesk-qa-ui-select-arrow {
    margin-top: -8px;
}
