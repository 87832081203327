.component-report-nav-bar {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  line-height: 1;
}
.component-report-nav-bar .report-nav-bar-item:nth-child(1) {
  border: none;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .nav-bar-item-tooltip {
  margin-left: 3px;
  visibility: hidden;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool {
  position: relative;
  display: inline-block;
  font-size: 0;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-main {
  min-width: 13px;
  min-height: 13px;
  color: #00B38B;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-main:hover {
  color: #006750;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px;
  z-index: 1050;
  width: 600px;
  max-width: 800px;
  max-height: 350px;
  line-height: 1.42857143;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  line-break: auto;
  background: #fff;
  border: 1px solid #CCC;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container.component-react-tool-container-show {
  display: block;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container .component-react-tool-content {
  padding: 0;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container .component-react-tool-content .component-react-tool-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 25px;
  text-align: left;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container .component-react-tool-content .component-react-tool-content-item .component-react-tool-content-item-title {
  overflow: hidden;
  width: 100px;
  padding-left: 10px;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container .component-react-tool-content .component-react-tool-content-item .component-react-tool-content-item-content {
  flex: 1;
  color: #333333;
}
.component-report-nav-bar .report-nav-bar-item.has-tooltip .component-react-tool .component-react-tool-container .component-react-tool-content .component-react-tool-content-item:hover {
  background-color: #f0f4fa;
}

.component-report-nav-bar.nav-bar-theme-default {
  justify-content: space-around;
}
.component-report-nav-bar.nav-bar-theme-default .report-nav-bar-item {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 1px solid #ccc;
}
.component-report-nav-bar.nav-bar-theme-default .report-nav-bar-item:nth-child(1) {
  border: none;
}

.component-report-nav-bar.nav-bar-theme-tab {
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}
.component-report-nav-bar.nav-bar-theme-tab .report-nav-bar-item {
  padding: 10px 15px;
}
.component-report-nav-bar.nav-bar-theme-tab .report-nav-bar-item.report-nav-bar-item-active {
  transform: translateY(1px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom: none;
}
.component-report-nav-bar.nav-bar-theme-tab .report-nav-bar-item.report-nav-bar-item-active.has-tooltip .nav-bar-item-tooltip {
  visibility: visible;
}
.component-report-nav-bar.nav-bar-theme-tab .report-nav-bar-item.report-nav-bar-item-active.has-tooltip:hover .nav-bar-item-tooltip {
  visibility: visible;
}

.component-report-nav-bar.nav-bar-theme-button {
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
}
.component-report-nav-bar.nav-bar-theme-button .report-nav-bar-item {
  padding: 10px 15px;
  color: #666;
}
.component-report-nav-bar.nav-bar-theme-button .report-nav-bar-item.report-nav-bar-item-active {
  border: 1px solid #ccc;
  border-bottom: none;
  color: #fff;
  background-color: #0cc2aa;
}
.component-report-nav-bar.nav-bar-theme-button .report-nav-bar-item.report-nav-bar-item-active.has-tooltip .nav-bar-item-tooltip {
  visibility: visible;
}
.component-report-nav-bar.nav-bar-theme-button .report-nav-bar-item.report-nav-bar-item-active.has-tooltip:hover .nav-bar-item-tooltip {
  visibility: visible;
}

