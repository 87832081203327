.audio-text-list-container {
    .audio-text-list {
        .audio-text-list-item {
            padding: 5px 10px;

            &.customer {
                text-align: left;
            }

            &.agent {
                text-align: right;
            }

            .audio-text-list-item-icon {
                display: inline-block;
                margin: 0 5px;
                width: 40px;
                height: 40px;
                font-size: 15px;
                font-weight: 900;
                line-height: 40px;
                color: #fff;
                text-align: center;
                border-radius: 360%;
                vertical-align: top;

                &.customer {
                    background: #fac138;
                }

                &.agent {
                    background: #e25252;
                }
            }

            .audio-text-list-item-content {
                display: inline-block;
                max-width: 70%;
                width: 70%;

                .audio-text-list-item-time {
                    margin-bottom: 5px;
                    height: 22px;
                    line-height: 22px;

                    .audio-text-list-item-player {
                        color: #00b38b;
                        cursor: pointer;
                        display: inline-block;
                        margin: 0px 5px;
                        vertical-align: bottom;
                    }
                }

                .audio-text-list-item-text {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .audio-text-list-item-text-action {
                        display: none;
                        margin: 0 5px;
                        height: 36px;
                        line-height: 36px;

                        .audio-text-list-item-mark-semantic {
                            color: #fac138;
                        }
                    }

                    &:hover {
                        .audio-text-list-item-text-action {
                            display: block;
                        }
                    }

                    .audio-text-list-item-text-container {
                        flex-grow: 1;
                        margin: 0;
                        padding: 10px;
                        background-color: #f2f2f2;
                        text-align: left;

                        .audio-text-list-item-text {
                            display: inline-block;
                            margin: 0;
                        }

                        .audio-text-list-item-img {
                            width: 100%;
                            cursor: pointer;
                        }
                    }
                }
            }

            .audio-text-list-item-appeal-container {
                display: inline-block;
                position: relative;
                margin: 5px;

                .audio-text-list-item-appeal-types {
                    border: 1px solid #ddd;
                    position: absolute;
                    background: #fff;
                    text-align: center;
                    display: none;

                    &.agent-without-reason {
                        width: 150px;
                        right: -150px;
                        top: -15px;
                        padding: 15px 0;

                        .udesk-qa-ui-radio-wrapper {
                            display: block;
                        }
                    }

                    &.customer-without-reason {
                        width: 150px;
                        right: 17px;
                        top: -15px;
                        padding: 15px 0;

                        .udesk-qa-ui-radio-wrapper {
                            display: block;
                        }
                    }

                    &.agent-with-reason {
                        width: 280px;
                        right: -280px;
                        top: -15px;

                        .udesk-qa-ui-input {
                            width: 260px;
                            margin-bottom: 10px;
                        }
                    }

                    &.customer-with-reason {
                        width: 280px;
                        right: 17px;
                        top: -15px;

                        .udesk-qa-ui-input {
                            width: 260px;
                            margin-bottom: 10px;
                        }
                    }
                }

                &:hover {
                    .audio-text-list-item-appeal-types {
                        display: block;
                    }
                }
            }
        }
    }
}
