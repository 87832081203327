.react-qa-custom-filters {

    ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
    }
    ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        background: #e0e5eb;
    }
    ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        // background: rgba(0,0,0,0.1);
    }
    .udesk-qa-ui-modal {
        width: 1080px !important;
        .udesk-qa-ui-modal-content {
            .udesk-qa-ui-modal-header {
                height: 55px;
                padding: 0 16px;
                background-color: #fff !important;
                border-bottom: 1px solid #d8d8d8;
                box-shadow: 0px 0px -1px 0px rgba(0, 0, 0, 0.1);
                .custom-filter-modal-title-node {
                    height: 55px;
                    line-height: 52px;
                    padding-right: 32px;
                    display: flex;
                    font-size: 14px;
                    justify-content: space-between;
                    color: rgba(0, 0, 0, 0.65);
                    .custom-filter-modal-title-node-left-info {
                        display: flex;
                        justify-content: space-between;
                        width: 210px;
                        padding-right: 16px;
                        border-right: 1px solid #e8e8e8;
                        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
                        .udesk-qa-ui-tabs-nav {
                            margin: 8px 0 16px 0;
                            &::before {
                                border-bottom: none;
                            }
                        }
                        .custom-filter-modal-titile-node-left-info-title {
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.85);
                            font-size: 16px;
                        }
                        .custom-filter-modal-titile-node-left-info-action {
                            font-weight: 400;
                            color: rgba(27, 109, 255, 1);
                            cursor: pointer;
                        }
                    }
                }
            }
            .udesk-qa-ui-modal-body {
                padding: 0 0 0 16px !important;
                height: 550px;
                .react-qa-custom-filters-body {
                    height: 100%;
                    display: flex;
                    .component-react-select {
                        height: 32px;
                    }
                    .form-control {
                        font-size: 12px;
                    }
                    .custom-fiter-custom-type-show {
                        width: 210px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        border-right: 1px solid #e8e8e8;
                    }
                    .react-qa-custom-filter-body-left-info {
                        padding: 8px 0;
                        width: 210px;
                        height: 100%;
                        overflow-y: auto;
                        padding-right: 16px;
                        border-right: 1px solid #e8e8e8;
                        .udesk-qa-ui-collapse-borderless {
                            background: #fff;
                        }
                        .panel-header-title {
                            width: 160px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .react-qa-custom-filter-body-left-info-field {
                            padding: 3px 8px 1px 8px;
                            border: 1px solid #e8e8e8;
                            line-height: 22px;
                            border-radius: 4px;
                            margin-bottom: 5px;
                            .anticon-unordered-list {
                                vertical-align: -3px;
                                margin-right: 5px;
                                cursor: pointer;
                            }
                            .react-qa-custom-filter-body-left-info-text {
                                display: inline-block;
                                width: 141px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                vertical-align: middle;
                            }
                        }
                        .udesk-qa-ui-collapse-borderless > .udesk-qa-ui-collapse-item {
                            border: 0;
                        }
                        .udesk-qa-ui-collapse-icon-position-right
                            > .udesk-qa-ui-collapse-item
                            > .udesk-qa-ui-collapse-header {
                            padding: 12px 0;
                        }
                        .udesk-qa-ui-collapse-icon-position-right
                            > .udesk-qa-ui-collapse-item
                            > .udesk-qa-ui-collapse-header
                            .udesk-qa-ui-collapse-arrow {
                            right: 0;
                        }
                        .udesk-qa-ui-collapse-content > .udesk-qa-ui-collapse-content-box {
                            padding: 4px 0 16px 0;
                        }
                    }
                    .react-qa-custom-filter-body-right-info {
                        height: 100%;
                        width: 854px;
                        .react-qa-custom-filter-body-right-info-title {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .react-qa-custom-filter-body-right-info-title-name {
                                font-weight: 400;
                                color: rgba(0, 0, 0, 0.85);
                            }
                        }
                        .custom-filter-field-list-page {
                            margin-top: 3px;
                            display: flex;
                            .custom-filter-field-info-count {
                                height: 32px;
                                width: 20px;
                                line-height: 32px;
                                margin-right: 8px;
                            }
                            .custom-filter-field-info {
                                width: 700px;
                                > div {
                                    width: 100%;
                                    display: flex;
                                }
                                .custom-fitler-field-info-type {
                                    width: 170px;
                                    border: 1px solid rgba(0, 0, 0, 0.15);
                                    height: 32px;
                                    line-height: 32px;
                                    color: rgba(0, 0, 0, 0.65);
                                    border-radius: 3px;
                                    padding-left: 12px;
                                    margin-right: 8px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                .custom-fitler-field-info-operator {
                                    width: 170px;
                                    border: 1px solid rgba(0, 0, 0, 0.15);
                                    height: 32px;
                                    line-height: 32px;
                                    color: rgba(0, 0, 0, 0.65);
                                    border-radius: 3px;
                                    padding-left: 12px;
                                    margin-right: 8px;
                                }
                                .custom-fitler-field-info-value {
                                    width: 200px;
                                    border: 1px solid rgba(0, 0, 0, 0.15);
                                    height: 32px;
                                    line-height: 32px;
                                    color: rgba(0, 0, 0, 0.65);
                                    border-radius: 3px;
                                    padding-left: 12px;
                                    margin-right: 8px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .custom-fitler-field-info-postfix {
                                    padding: 5px 0;
                                }
                            }
                        }
                        .react-qa-custom-filter-body-right-info-field {
                            height: 300px;
                            padding-top: 10px;
                            padding-left: 24px;
                            overflow-y: auto;
                            border-bottom: 1px solid #e8e8e8;
                            .udesk-custom-filters-react {
                                .field-selector {
                                    height: 32px;
                                    width: 170px;
                                }
                                .operator-selector {
                                    height: 32px;
                                    width: 140px;
                                }
                                .filter-wrapper {
                                    .filter-conditions {
                                        margin-top: 0;

                                        tr.is-tow-line,
                                        tr.is-tow-line + tr {
                                            position: relative;

                                            &::after {
                                                content: '';
                                                position: absolute;
                                                top: -5px;
                                                bottom: -5px;
                                                left: 0;
                                                right: 41px;
                                                background-color: #f2f2f2;
                                                z-index: 0;
                                            }
                                            td {
                                                position: relative;
                                                z-index: 1000;

                                                &:first-child {
                                                    > * {
                                                        position: relative;
                                                        z-index: 1;
                                                    }
                                                    &::after {
                                                        content: '';
                                                        position: absolute;
                                                        top: -5px;
                                                        bottom: -5px;
                                                        left: 0;
                                                        right: 0;
                                                        background-color: #fff;
                                                        z-index: 0;
                                                    }
                                                }
                                            }
                                        }

                                        tr.is-tow-line + tr {
                                            &::after {
                                                bottom: 0;
                                            }

                                            .udesk-qa-ui-select,
                                            .udesk-qa-ui-select-arrow {
                                                color: #075ef2;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .react-qa-custom-filter-body-right-info-footer {
                            padding: 16px 24px;
                            max-height: 250px;
                            overflow-y: auto;
                            .udesk-qa-ui-tabs-bar {
                                border-bottom: 0;
                                .udesk-qa-ui-tabs-nav .udesk-qa-ui-tabs-tab {
                                    padding: 12px 16px 12px 0;
                                }
                            }
                            .index-management-custom-drag {
                                position: relative;
                                .index-manmagement-custom-drag-tarsk {
                                    position: absolute;
                                    text-align: center;
                                    top: -41px;
                                    z-index: 1;
                                    padding: 10px;
                                    background: rgba(0, 0, 0, 0.5);
                                    left: 50%;
                                    width: 200px;
                                    margin-left: -100px;
                                }
                                .react-qa-custom-filter-body-right-info-drap-title {
                                    padding: 8px 0;
                                    font-weight: 400;
                                    color: rgba(0, 0, 0, 0.85);
                                }
                                display: flex;
                            }
                        }
                    }
                }
            }

            .udesk-qa-ui-modal-footer {
                padding: 8px 20px;
                background-color: #fff;
            }
        }
    }
}
