.audio-sticker-view-v3 {
    // overflow: hidden;
    .audio-sticker-view-v3-sticker {
        width: 100%;
        height: 10px;
        // margin-bottom: 12px;
        position: relative;
        background: #f7f7f7;
        border-radius: 5px;
        .audio-sticker-item {
            height: 100%;
            position: absolute;
            border-radius: 18px;
        }
    }
}
