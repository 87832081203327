.components-task-condition-dictionaries {

    position: absolute;
    top: 70px;
    right: 0px;
    z-index: 3;

    .condition-dictionaries-entry {
        display: inline-block;
        margin-top: 44px;
        padding: 5px 0;
        width: 30px;
        height: 70px;
        text-align: center;
        border: 1px solid #cccccc;
        background-color: white;
        border-right: 0px;
        vertical-align: top;

        .condition-dictionaries-entry-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-bottom: 12px;
        }
    }

    .condition-dictionaries-content {
        display: inline-block;
        width: 350px;
        height: 500px;
        padding: 0 10px;
        border: 1px solid #cccccc;
        background-color: white;

        .condition-dictionaries-content-title {
            margin: 20px 0 10px 0;
            height: 20px;
            font-size: 16px;
        }

        .condition-dictionaries-content-search {
            width: 100%;
            position: relative;
            margin-bottom: 10px;

            .condition-dictionaries-content-search-icon {
                padding-top: 4px;
                width: 30px;
                height: 30px;
                position: absolute;
                right: 5px;
            }

            .condition-dictionaries-content-search-input {
                width: 100%;
                height: 30px;
            }
        }

        .condition-dictionaries-content-list {
            height: 400px;
            overflow: auto;

            .condition-dictionaries-content-list-li {
                padding: 8px 5px;
                border-bottom: 1px solid #cccccc;

                .condition-dictionaries-content-list-li-title {
                    display: inline-block;
                    font-weight: 900;
                    width: 40%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .condition-dictionaries-content-list-li-switch {
                    display: inline-block;
                    font-weight: 900;
                    width: 10%;
                    cursor: pointer;
                    color: #00c1ab;
                }

                .condition-dictionaries-content-list-li-details {
                    margin-top: 10px;

                    .condition-dictionaries-content-list-li-details-li {
                        padding: 2px 0;

                        .condition-dictionaries-content-list-li-details-li-title {
                            display: inline-block;
                            padding-right: 8px;
                            vertical-align: top;
                        }

                        .condition-dictionaries-content-list-li-details-li-text {
                            display: inline-block;
                        }

                        .condition-dictionaries-content-list-li-details-li-operator {
                            display: inline-block;

                            .condition-dictionaries-content-list-li-details-li-operator-text {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

    }
}
