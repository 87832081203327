@import './_variables';
.component-react-table {
    position: relative;
    &.invisible {
        display: none;
        position: fixed;
        z-index: 1;
    }
    &::before {
        display: table;
        content: " ";
    }
    &::after {
        display: table;
        content: " ";
        clear: both;
    }
    .table-wrapper {
        float: left;
        min-width: 100%;
        margin-bottom: $udesk-react-table-table-bottom-margin;
        background: $udesk-react-table-table-background;
        .data-table-wrapper,
        .frozen-table-wrapper,
        .frozen-table-column-wrapper,
        .frozen-table-header-column-wrapper {
            float: left;
            min-width: 100%;
            >table {
                border: $udesk-react-table-table-border;
                margin-bottom: 0;
                >thead {
                    >tr.stacked-header-row {
                        >th {
                            text-align: center;
                        }
                    }
                    >tr.header-row {
                        background: $udesk-react-table-head-background;
                        color: $udesk-react-table-head-font-color;
                        >th {
                            &.checkbox-col {
                                width: 50px;
                            }
                            .header-cell-wrapper {
                                display: table; // Turn this rule on to let table header actions right aligned!
                                // width: 100%;
                                .header-cell-body {
                                    display: table-row;
                                    .header-cell-body-item {
                                        display: table-cell;
                                        &.table-actions {
                                            text-align: right;
                                            padding-left: 5px;
                                            .action-item {
                                                font-size: $udesk-react-table-column-action-size;
                                            }
                                        }
                                    }
                                    &.hide-no-sorting-icon-,
                                    &.hide-no-sorting-icon-hover,
                                    &.hide-no-sorting-icon-always {
                                        .table-actions {
                                            .action-item {
                                                .sort-icon {
                                                    &.sorting-none {
                                                        visibility: hidden;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.hide-no-sorting-icon-hover:hover {
                                        .table-actions {
                                            .action-item {
                                                .sort-icon {
                                                    &.sorting-none {
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.sortable {
                    >thead {
                        >tr {
                            >th.sortable {
                                cursor: pointer;
                                .sort-icon {
                                    color: $udesk-react-table-head-sort-icon-color;
                                    &:not(.sorting-none) {
                                        color: $udesk-react-table-head-sort-icon-has-sorting-color;
                                    }
                                }
                                &:hover {
                                    .sort-icon {
                                        color: $udesk-react-table-head-sort-icon-hover-color;
                                        &.sorting-none {
                                            color: $udesk-react-table-head-sort-icon-no-sorting-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                tbody {
                    >tr {
                        >td {
                            &.sorting {
                                background-color: $udesk-react-table-body-sorting-column-background;
                            }
                        }
                        &:hover,
                        &.row-item-hover {
                            >td {
                                background: $udesk-react-table-body-row-hover-background;
                                border-top: $udesk-react-table-body-row-hover-border;
                                border-bottom: $udesk-react-table-body-row-hover-border;
                                &.sorting {
                                    background-color: $udesk-react-table-body-sorting-column-background;
                                }
                            }
                        }
                        &.selected {
                            background: $udesk-react-table-body-selected-row-background;
                            >td {
                                border-top: $udesk-react-table-body-selected-row-border;
                                border-bottom: $udesk-react-table-body-selected-row-border;
                            }
                        }
                        &.row-clickable {
                            cursor: pointer;
                        }
                        &.active-item {
                            >td:first-child {
                                position: relative;
                                &:before {
                                    content: '';
                                    top: 0;
                                    left: 0;
                                    height: 100%;
                                    position: absolute;
                                    border-left: $udesk-react-table-body-row-active-border;
                                }
                            }
                        }
                    }
                }
            }
            >table>thead>tr>th,
            >table>thead>tr>td,
            >table>tbody>tr>th,
            >table>tbody>tr>td,
            >table>tfoot>tr>th,
            >table>tfoot>tr>td {
                vertical-align: middle;
            }
            >table>thead>tr>th,
            >table>thead>tr>td {
                border-bottom-width: 1px;
            }
        }
        &.vertical-scrollbar {
            height: 100%;
            overflow-y: auto;
        }
        &.horizontal-scrollbar {
            width: 100%;
            overflow-x: auto;
            .data-table-wrapper {
                >table {
                    width: auto;
                    min-width: 100%;
                }
            }
            .data-table-wrapper,
            .frozen-table-wrapper,
            .frozen-table-column-wrapper,
            .frozen-table-header-column-wrapper {
                >table {
                    table-layout: fixed;
                    >colgroup>col,
                    >col {
                        &.data-col {
                            max-width: 290px;
                        }
                    }
                    >thead {
                        >tr {
                            >th {
                                white-space: nowrap;
                                &:last-child {
                                    border-right: 1px solid $udesk-react-table-head-background-color;
                                }
                            }
                        }
                    }
                    >tbody {
                        >tr {
                            >td {
                                overflow-x: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                &.data-col {
                                    max-width: 290px;
                                    .radio-image {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.choose-columns {
            .data-table-wrapper,
            .frozen-table-wrapper,
            .frozen-table-column-wrapper,
            .frozen-table-header-column-wrapper {
                >table {
                    >thead {
                        >tr {
                            >th {
                                &:last-child {
                                    padding-right: $udesk-react-table-columns-trigger-font-size + $udesk-react-table-head-padding * 3;
                                }
                            }
                        }
                    }
                    >tbody {
                        >tr {
                            >td {
                                &:last-child {
                                    padding-right: $udesk-react-table-columns-trigger-font-size + $udesk-react-table-head-padding * 3;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.freeze-header,
        &.freeze-columns,
        &.frozen-header-column-table {
            &.vertical-scrollbar {
                overflow-y: hidden;
                height: 100%;
            }
            &.horizontal-scrollbar {
                overflow-x: hidden;
                width: 100%;
            }
            .frozen-table-wrapper,
            .frozen-table-header-column-wrapper {
                position: relative;
                >table {
                    position: absolute;
                    z-index: 1;
                    background: $udesk-react-table-table-background;
                }
            }
            .data-table-wrapper {
                height: 100%;
            }
            &.vertical-scrollbar {
                .data-table-wrapper {
                    overflow-y: auto;
                }
            }
            &.horizontal-scrollbar {
                .data-table-wrapper {
                    overflow-x: auto;
                    width: 100%;
                }
            }
        }
        &.freeze-header {
            .data-table-wrapper {
                >table {
                    >thead {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        &.freeze-columns,
        &.freeze-header-columns {
            .frozen-table-column-wrapper,
            .frozen-table-header-column-wrapper {
                position: relative;
                >table {
                    position: absolute;
                    z-index: 1;
                    width: auto;
                    background: $udesk-react-table-table-background;
                    border-right: none;
                }
            }
            .frozen-table-column-wrapper {
                overflow: hidden;
                position: absolute;
                max-width: initial;
                min-width: initial;
            }
        }
        .columns {
            position: absolute;
            right: $udesk-react-table-columns-trigger-right;
            top: $udesk-react-table-columns-trigger-top;
            z-index: 2;
            .columns-trigger {
                color: $udesk-react-table-columns-trigger-color;
                background: $udesk-react-table-head-background;
                &:hover {
                    color: $udesk-react-table-columns-trigger-hover-color;
                }
                .trigger-icon {
                    font-size: $udesk-react-table-columns-trigger-font-size;
                    padding-left: $udesk-react-table-head-padding * 2;
                    padding-right: $udesk-react-table-head-padding;
                }
            }
            .columns-wrapper {
                position: relative;
                .columns-list {
                    overflow: hidden;
                    overflow-y: auto;
                    position: absolute;
                    z-index: $udesk-react-table-columns-zindex;
                    top: $udesk-react-table-columns-list-position-top;
                    right: $udesk-react-table-columns-list-position-right;
                    width: $udesk-react-table-columns-list-width;
                    max-height: $udesk-react-table-columns-list-max-height;
                    padding: $udesk-react-table-head-padding;
                    margin-bottom: 0;
                    color: $udesk-react-table-columns-list-font-color;
                    background-color: $udesk-react-table-columns-list-background;
                    border-left: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    box-shadow: -5px 5px 6px #bbb;
                    cursor: default;
                    .column-item {
                        background: $udesk-react-table-columns-item-background;
                        .checkbox {
                            margin-top: 0;
                            margin-bottom: 2px;
                            padding-top: 2px;
                            padding-bottom: 2px;
                            .column-label {
                                width: 100%;
                                cursor: pointer;
                                .ember-checkbox {
                                    margin-top: 2px;
                                }
                            }
                        }
                        &:hover {
                            color: $udesk-react-table-columns-item-hover-font-color;
                            background: $udesk-react-table-columns-item-hover-background;
                        }
                    }
                }
            }
        }
    }
}