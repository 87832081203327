.call-manage-detail-page {
    .call-manage-detail-page-left-part {
        border-right: 1px solid #ddd;
    }

    .call-manage-detail-page-right-part {
        .qa-react-call-manage-detail-title {
            border-bottom: 1px solid #e4e4e4;
            margin-bottom: 10px;
            height: 48px;
            line-height: 48px;

            .qa-react-call-manage-detail-span {
                display: inline-block;
                cursor: pointer;
            }
        }
    }
}
