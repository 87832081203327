.udesk-loading-animation {
  position: absolute;
}
.udesk-loading-animation.animation-invisible {
  display: none;
}
.udesk-loading-animation.left {
  left: 0;
}
.udesk-loading-animation.center {
  left: 50%;
  transform: translateX(-50%);
}
.udesk-loading-animation.right {
  right: 0;
}
.udesk-loading-animation.middle {
  top: 50%;
  transform: translateY(-50%);
}
.udesk-loading-animation.bottom {
  bottom: 0;
}
.udesk-loading-animation.center.middle {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.udesk-loading-animation.animation-indicator .indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.udesk-loading-animation.animation-indicator .indicator svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.udesk-loading-animation.animation-indicator .indicator svg polyline#back {
  stroke: rgba(59, 211, 171, 0.3);
}
.udesk-loading-animation.animation-indicator .indicator svg polyline#front {
  stroke: #3bd3ab;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 48;
  animation: udesk-loading-animation-dash 1s linear infinite;
}
.udesk-loading-animation.animation-indicator .cta {
  position: fixed;
  bottom: 20px;
  right: 30px;
  color: #222;
  font-weight: bold;
}
@keyframes udesk-loading-animation-dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.udesk-loading-animation.animation-moving-balls {
  width: 100%;
  min-width: 500px;
}
.udesk-loading-animation.animation-moving-balls div {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #ccc;
  top: 45%;
  border-radius: 50%;
}
.udesk-loading-animation.animation-moving-balls div:nth-child(1) {
  background-color: #FF5460;
  animation: udesk-loading-animation-moveing-balls 2s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}
.udesk-loading-animation.animation-moving-balls div:nth-child(2) {
  background-color: #FF9D84;
  animation: udesk-loading-animation-moveing-balls 2s 150ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}
.udesk-loading-animation.animation-moving-balls div:nth-child(3) {
  background-color: #efe67b;
  animation: udesk-loading-animation-moveing-balls 2s 300ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}
.udesk-loading-animation.animation-moving-balls div:nth-child(4) {
  background-color: #ff943d;
  animation: udesk-loading-animation-moveing-balls 2s 450ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}
@keyframes udesk-loading-animation-moveing-balls {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
.udesk-loading-animation.animation-pulse {
  width: 100%;
}
.udesk-loading-animation.animation-pulse .pulse {
  height: 100px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.udesk-loading-animation.animation-pulse .pulse:after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200px 100px" enable-background="new 0 0 200px 100px" xml:space="preserve"><polyline fill="none" stroke-width="3px" stroke="%2384ef7b" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>') 0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 2s pulse linear infinite;
  -moz-animation: 2s pulse linear infinite;
  -o-animation: 2s pulse linear infinite;
  animation: 2s pulse linear infinite;
  clip: rect(0, 0, 100px, 0);
}
.udesk-loading-animation.animation-pulse .pulse:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;
}
@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }
  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
@keyframes line-scale-pulse-out {
  0% {
    transform: scaley(1);
  }
  50% {
    transform: scaley(0.4);
  }
  100% {
    transform: scaley(1);
  }
}
.udesk-loading-animation.animation-line-scale-pulse-out .line-scale-pulse-out > div {
  background-color: #bbb;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}
.udesk-loading-animation.animation-line-scale-pulse-out .line-scale-pulse-out > div:nth-child(2), .udesk-loading-animation.animation-line-scale-pulse-out .line-scale-pulse-out > div:nth-child(4) {
  animation-delay: -0.4s;
}
.udesk-loading-animation.animation-line-scale-pulse-out .line-scale-pulse-out > div:nth-child(1), .udesk-loading-animation.animation-line-scale-pulse-out .line-scale-pulse-out > div:nth-child(5) {
  animation-delay: -0.2s;
}
@keyframes line-scale-pulse-out-rapid {
  0% {
    transform: scaley(1);
  }
  80% {
    transform: scaley(0.3);
  }
  90% {
    transform: scaley(1);
  }
}
.udesk-loading-animation.animation-line-scale-pulse-out-rapid .line-scale-pulse-out-rapid > div {
  background-color: #bbb;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  vertical-align: middle;
  animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
}
.udesk-loading-animation.animation-line-scale-pulse-out-rapid .line-scale-pulse-out-rapid > div:nth-child(2), .udesk-loading-animation.animation-line-scale-pulse-out-rapid .line-scale-pulse-out-rapid > div:nth-child(4) {
  animation-delay: -0.25s;
}
.udesk-loading-animation.animation-line-scale-pulse-out-rapid .line-scale-pulse-out-rapid > div:nth-child(1), .udesk-loading-animation.animation-line-scale-pulse-out-rapid .line-scale-pulse-out-rapid > div:nth-child(5) {
  animation-delay: 0s;
}
@keyframes ball-scale-multiple {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.udesk-loading-animation.animation-ball-scale-multiple .ball-scale-multiple {
  position: relative;
  transform: translateY(-30px);
}
.udesk-loading-animation.animation-ball-scale-multiple .ball-scale-multiple > div:nth-child(2) {
  animation-delay: -0.4s;
}
.udesk-loading-animation.animation-ball-scale-multiple .ball-scale-multiple > div:nth-child(3) {
  animation-delay: -0.2s;
}
.udesk-loading-animation.animation-ball-scale-multiple .ball-scale-multiple > div {
  background-color: #bbb;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  left: -30px;
  top: 0px;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  animation: ball-scale-multiple 1s 0s linear infinite;
}
@keyframes ball-scale-ripple-multiple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple {
  position: relative;
  transform: translateY(-25px);
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple > div:nth-child(0) {
  animation-delay: -0.8s;
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple > div:nth-child(1) {
  animation-delay: -0.6s;
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple > div:nth-child(2) {
  animation-delay: -0.4s;
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple > div:nth-child(3) {
  animation-delay: -0.2s;
}
.udesk-loading-animation.animation-ball-scale-ripple-multiple .ball-scale-ripple-multiple > div {
  animation-fill-mode: both;
  position: absolute;
  top: -2px;
  left: -26px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #bbb;
  animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}
@keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -4px;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(1) {
  top: 20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(2) {
  top: 13.6363636364px;
  left: 13.6363636364px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(4) {
  top: -13.6363636364px;
  left: 13.6363636364px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(5) {
  top: -20px;
  left: 0;
  animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(6) {
  top: -13.6363636364px;
  left: -13.6363636364px;
  transform: rotate(-45deg);
  animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -20px;
  transform: rotate(90deg);
  animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div:nth-child(8) {
  top: 13.6363636364px;
  left: -13.6363636364px;
  transform: rotate(45deg);
  animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
}
.udesk-loading-animation.animation-line-spin-fade-loader .line-spin-fade-loader > div {
  background-color: #bbb;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  width: 5px;
  height: 15px;
}

