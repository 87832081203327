.react-tree.text-ellipsis .tree-node {
  width: 250px;
}
.react-tree.text-ellipsis .tree-node .node-toggle {
  width: 15px;
}
.react-tree.text-ellipsis .tree-node .node-body {
  position: relative;
  width: 230px;
  line-height: 20px;
}
.react-tree.text-ellipsis .tree-node .node-body .node-name {
  display: inline-block;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.react-tree.text-ellipsis .tree-node .node-body .node-actions {
  vertical-align: middle;
  width: 90px;
}
.react-tree .node-heading .tree-name {
  display: inline-block;
}
.react-tree .node-heading .tree-actions {
  margin-left: 10px;
  display: inline-block;
}
.react-tree .node-heading .tree-actions .tree-action-item {
  padding-right: 10px;
}
.react-tree .node-heading .tree-actions .tree-action-item .action-icon {
  font-size: 18px;
}
.react-tree .tree-node {
  padding: 5px 0 5px 100px;
}
.react-tree .tree-node .invisible {
  visibility: hidden;
}
.react-tree .tree-node .node-toggle {
  margin-left: -100px;
  display: inline-block;
}
.react-tree .tree-node .node-toggle .toggle-link {
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
}
.react-tree .tree-node .node-toggle .toggle-link i.toggle-icon {
  font-size: 20px;
}
.react-tree .tree-node .node-check {
  display: inline-block;
  vertical-align: middle;
}
.react-tree .tree-node .node-body {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  border: 5px solid transparent;
}
.react-tree .tree-node .node-body .node-name {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  transition: background-color 0.1s linear, color 0.1s linear;
}
.react-tree .tree-node .node-body .node-actions {
  display: inline-block;
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.react-tree .tree-node .node-body .node-actions .node-action-item {
  padding-right: 10px;
}
.react-tree .tree-node .node-body .node-actions .node-action-item .action-icon {
  font-size: 18px;
}
.react-tree .tree-node .node-body .sort-button {
  position: absolute;
  cursor: pointer;
  right: 0;
}
.react-tree .tree-node:hover .node-actions {
  opacity: 1;
}
.react-tree .tree-node.dragOvering-node.drop-top {
  border-top-color: #baf5e9;
  border-top: 2px solid #baf5e9;
}
.react-tree .tree-node.dragOvering-node.drop-bottom {
  border-top-color: #baf5e9;
  border-bottom: 2px solid #baf5e9;
}
.react-tree .tree-node.dragOvering-node.drop-inner {
  background: #baf5e9;
}
.react-tree .tree-node.dragOvering-node.drop-inner .node-body .node-name .node-name-text {
  color: #fff;
}
.react-tree .child-tree {
  margin-left: 16px;
}

.react-tree.default .node-heading .tree-actions .tree-action-item.add-node {
  color: #5cb85c;
}
.react-tree.default .node-heading .tree-actions .tree-action-item.add-node:hover {
  color: #80c780;
}
.react-tree.default .tree-node .node-toggle .toggle-link {
  color: #777777;
}
.react-tree.default .tree-node .node-toggle .toggle-link:hover {
  color: #5e5e5e;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.add-node {
  color: #5cb85c;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.add-node:hover {
  color: #80c780;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.edit-node {
  color: #df8a13;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.edit-node:hover {
  color: #eea237;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.delete-node {
  color: #d9534f;
}
.react-tree.default .tree-node .node-body .node-actions .node-action-item.delete-node:hover {
  color: #e27c79;
}
.react-tree.default .tree-node.active-node .node-name {
  font-weight: bold;
  color: #006b42;
  background-color: #e9f8f5;
}
.react-tree.default .tree-node.active-node .node-name:hover {
  color: #006b42;
  background-color: #e9f8f5;
}
.react-tree.default .tree-node.checked-node .node-name {
  color: #00404c;
  background-color: #e9f8f5;
}
.react-tree.default .tree-node.checkable-node:not(.checked-node) .node-name:hover {
  color: #00404c;
  background-color: #e9f8f5;
}
.react-tree.default .tree-node.clickable-node:not(.active-node) .node-name:hover {
  color: inherit;
  background-color: #d3f1eb;
}
.react-tree.default .react-tree-dragging.tree-node {
  opacity: 0.3;
}
.react-tree.default .react-tree-dragging.tree-node .node-toggle .toggle-link {
  display: none;
}
.react-tree.default .react-tree-dragging.tree-node .node-body {
  position: inherit;
}

