.udesk-qa-web-page-body-root {
    &.with-padding {
        padding: 16px;
    }
    .udesk-qa-web-page-body-root-spin {
        height: 100%;
        .udesk-qa-ui-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            .udesk-qa-web-page-content-with-footer {
                flex: 1;
            }
            .udesk-qa-web-page-content {
                min-height: 100%;
                flex: 1;
                .udesk-qa-web-page {
                    padding-bottom: 0;
                    .udesk-qa-web-page-body {
                        padding: 0;
                    }
                }
            }
            .udesk-qa-web-page-finxed-footer {
                background-color: #fff;
                padding: 12px 16px;
                margin-top: 16px;
            }
        }
    }

    .udesk-qa-web-page-header {
        display: none;
    }

}