.quality-detail-remark-content-rich-text {
    margin-bottom: 16px;
    .ql-editor {
        padding: 0;
        p {
            line-height: 1.75;
        }
    }
    .mention {
        background-color: #E8F4FF;
        color: #1A6EFF;
        padding: 2px 4px;
        border-radius: 2px;
    }
}