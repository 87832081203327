$app-layout-color: #6887ff;
$app-layout-active-color: #00b38b;
$app-layout-nav-item-border-color: #ccc;

.page-udesk-qa-react-web-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 18px;
    margin-bottom: 10px;

    .page-udesk-qa-react-web-header-title {
        font-size: 18px;
        line-height: 1;
        color: #333;
    }
}

.action-btn {
    display: inline;
    border: none;
    padding: 0;
    color: $app-layout-color;
    background: 0 0;

    &:hover {
        text-decoration: underline;
    }
}

.form-control-label {
    text-align: right;
}

.form-control {
    border-radius: 0;
    // height: 30px;
}

label {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-weight: normal;
}

.table > thead > tr > th {
    border-bottom: 1px solid #ddd;
}

.udesk-qc-react-select {
    background: #fff;
    border: 1px solid #d9d9d9;
    outline: none;
    min-width: 120px;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    height: 34px;
    padding: 0 5px;
}

.udesk-qc-proportion-sampling-box {
    display: inline-block;
    text-align: center;

    .proportion-sampling-input {
        width: 92px;
        border: 1px solid #d9d9d9;
        height: 34px;
        vertical-align: middle;
        outline: none;
        padding: 0 10px;
    }

    .proportion-sampling-icon {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border: 1px solid #d9d9d9;
        vertical-align: middle;
        background: #d9d9d9;
    }
}

.btn-success {
    background-color: #00c49b;
    border-color: #00c49b;
}

.component-udesk-react-radio-btn {
    .radio-btn-item {
        .radio-btn-decorate {
            background-color: #00c49b;
        }
    }
}

.udesk-pager {
    .pager-left {
        .total-block,
        .total-pages-block {
            letter-spacing: 0;
            margin: 0 5px;
        }
    }

    .pager-right {
        .pagination {
            span {
                padding: 0;
                margin-right: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.component-udesk-react-input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input-list-item {
        margin: 0 10px 10px 0;

        .action-icon {
            border: 1px solid #ccc;
            border-radius: 10px;
            vertical-align: middle;
        }
    }
}

.qa-react-page {
    margin: 0;

    .qa-react-page-left-part {
        padding: 0;
        height: 100%;
    }

    .qa-react-page-right-part {
        padding: 10px;
        height: 100%;
        overflow: auto;
    }
}

.qa-react-page-header {
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
    height: 48px;
    line-height: 48px;

    .qa-react-page-header-title {
        font-size: 16px;
        font-weight: 500;

        .qa-react-page-header-title-left-part {
            cursor: pointer;
            float: left;
        }

        .qa-react-page-header-title-right-part {
            float: right;
        }

        .qa-react-page-header-title-back-action {
            cursor: pointer;
        }
    }
}

.qa-react-page-body {
    .qa-react-page-body-search-container {
        margin-bottom: 10px;
        overflow: hidden;

        .qa-react-page-body-search-container-left-part {
            .semantic-tags-index-page-body-search-input {
                width: 200px;
            }
        }

        .qa-react-page-body-search-container-right-part {
            .btn {
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .qa-react-page-body-content-container {
        .qa-react-page-body-content-table-actions {
            .action-btn {
                margin-right: 5px;
                cursor: pointer;
            }

            .delete-btn {
                color: #f00;
            }
        }
    }
}

.semantic-intelligence-nav-container {
    display: block;

    .semantic-intelligence-nav-item {
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        display: block;
        border: none;

        &.semantic-intelligence-nav-item-active {
            color: #2878ff;
        }

        &:hover {
            background: #e6e9ed;
            color: #2878ff;
        }
    }

    .form-group {
        margin: 0 0 15px 0;

        .form-control-label,
        .control-label {
            position: relative;

            .required {
                position: absolute;
                color: #f00;

                &:after {
                    content: '*';
                }
            }
        }

        .btn {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.udesk-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
