.quality-check-score-talk {
    width: 100%;
    max-width: 624px;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .udesk-qa-ui-anchor {
        padding: 0;
    }
    .udesk-qa-ui-anchor-ink {
        display: none;
    }
    .semantic-mark-component-info {
        .semantic-mark-component-body {
            border: none;
        }
    }
    .smart-tags-component {
        border: none;
        .smart-tags-title {
            display: none;
        }
    }
    .udesk-qa-ui-tabs {
        &.udesk-qa-ui-tabs-left {
            flex: 1;
            .udesk-qa-ui-tabs-nav {
                width: 64px;
                .udesk-qa-ui-tabs-tab {
                    padding: 8px;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.65);
                    &-active {
                        background-color: #e8f4ff;
                    }
                    .udesk-qa-ui-tabs-tab-btn {
                        width: 100%;
                    }
                }
            }
        }
    }
    .udesk-qa-ui-tabs-tabpane {
        padding-left: 0px !important;
    }
    .udesk-qa-ui-drawer {
        position: absolute;
    }
    .udesk-qa-ui-drawer-header {
        padding: 8px 16px;
    }
    .udesk-qa-ui-drawer-body {
        padding: 8px 0;
    }
    .udesk-qa-ui-drawer-content-wrapper {
        height: calc(100% - 86px) !important;
        top: 84px;
    }
    .quality-check-score-talk-header {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        .quality-check-score-talk-header-evaluation {
            display: flex;
            align-items: center;
            .qutality-check-score-talk-header-evaluation-choose {
                position: relative;
                margin-right: 8px;
                .qutality-check-score-talk-header-evaluation-choose-arrows {
                    position: absolute;
                    font-size: 16px;
                    top: -11px;
                    color: rgba(27, 109, 255, 1);
                    left: 70px;
                    width: 50px;
                    .qutality-check-score-talk-header-evaluation-choose-scroe {
                        width: 30px;
                        display: inline-block;
                        font-size: 12px;
                    }
                }
                .qutality-check-score-talk-header-evaluation-choose-list {
                    margin-top: 8px;
                    width: 100px;
                    height: 10px;
                    background: linear-gradient(
                        90deg,
                        rgba(251, 81, 39, 1) 0%,
                        rgba(255, 212, 44, 1) 52%,
                        rgba(17, 168, 72, 1) 100%
                    );
                    border-radius: 5px;
                }
                .qutality-check-score-talk-header-evaluation-choose-title {
                    display: flex;
                    justify-content: space-between;
                    font-size: 10px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
    }
    .udesk-qa-ui-tabs-content {
        position: static;
    }
    .quality-check-score-talk-body-content {
        height: calc(100% - 52px - 68px);
        overflow-y: auto;
        .udesk-qa-ui-anchor-link {
            padding: 0px;
            background-color: #fff;
            // .udesk-qa-ui-anchor-link-title {
            //     background-color: rgba(0, 0, 0, 0.03);
            // }
        }
        .udesk-qa-ui-affix {
            position: absolute !important;
            top: 52px !important;
        }
        &.with-filter {
            .udesk-qa-ui-affix {
                position: absolute !important;
                top: 84px !important;
            }
        }
        .udesk-qa-ui-anchor-wrapper {
            margin-left: 0;
            margin-top: -3px;
            padding-left: 0;
        }
    }
    // 滚动背景
    ::-webkit-scrollbar {
        width: 0.3rem;
        height: 0.3rem;
        background-color: rgba(0, 0, 0, 0.1);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    // 滚动条
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(27, 109, 255, 0.5);
        transition: all 0.2s;
        border-radius: 0.5rem;
    }
    .quality-check-score-talk-class {
        padding: 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.03);
        display: flex;
        align-items: center;
        .check-point-name-copy-btn {
            display: none;
            margin: -8px 0;
        }
        &:hover {
            .check-point-name-copy-btn {
                display: block;
            }
        }
        .quality-check-score-talk-class-draw {
            display: inline-block;
        }
        .anticon-menu-unfold {
            font-size: 14px;
            cursor: pointer;
        }
        .quality-check-score-talk-class-title {
            margin-left: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;
            .quality-check-score-talk-class-score {
                margin-left: 6px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
    .quality-check-score-talk-field {
        position: relative;
        overflow: hidden;

        .udesk-qa-ui-menu-submenu-title {
            padding-left: 16px !important;
        }
        .udesk-qa-ui-menu-item {
            padding-left: 16px !important;
        }
        .udesk-qa-ui-card {
            height: 100%;
            overflow-y: auto;
            .udesk-qa-ui-card-body {
                display: flex;
                flex-flow: wrap;
                padding: 0;
                .quality-check-talk-point-with-appeal-drawer {
                    .quality-check-talk-point-appeal-drawer {
                        // width: 200px;
                        padding: 8px;
                        background-color: rgba(0, 0, 0, 0.25);
                        height: 100%;
                        transform: translateX(100%);
                        right: 0;
                        position: absolute;
                        top: 0;
                        transition: transform 0.1s ease 0.1s;
                        display: flex;
                        align-items: center;
                    }
                    &:hover {
                        .quality-check-talk-point-appeal-drawer {
                            transform: translateX(0);
                        }
                    }
                    .quality-check-talk-point-wrap {
                        transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        background-color: #fff;
                        &.highlight {
                            background-color: #ffa39e;
                        }
                    }
                }
            }
        }
        .udesk-qa-ui-card-grid {
            padding: 0px;
            position: relative;
            cursor: pointer;

            .grad-crid-drop {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 100;
            }
        }
        .grad-crid-drop-active {
            background: rgba(27, 109, 255, 0.05);
        }
        // .icon-udesk-qa-react-web-daohang {
        //     margin-right: 10px;
        //     font-size: 14px;
        //     vertical-align: middle;
        // }
        .quality-check-score-talk-field-proint {
            // line-height: 30px;
            display: flex;
            flex: 1;
            // width: 100%;
            // max-width: 50%;
            color: rgba(0, 0, 0, 0.65);
            .udesk-qa-ui-btn-link:hover,
            .udesk-qa-ui-btn-link:focus {
                color: #458fff;
            }
            .check-point-name {
                padding: 0px;
                // flex: 1;
                text-align: inherit;
                overflow: hidden;
                flex: 1;
                display: flex;
                align-items: center;
                margin-right: 12px;
                span {
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
            .check-point-name-copy-btn {
                display: none;
            }
            &:hover {
                .check-point-name-copy-btn {
                    display: block;
                }
            }
        }
        .quality-check-score-talk-field-choose {
            // line-height: 30px;
            .udesk-qa-ui-radio-wrapper {
                // font-size: 0.8725rem;
                height: 21px;
                line-height: 20px;
                // margin-top: 3px;
            }
            .test-set-list-status-reluat {
                display: inline-block;
                .test-set-list-radio {
                    display: inline-block;
                    margin-right: 10px;
                    .icon-udesk-qa-react-web-xiazaishibai {
                        display: inline-block;
                        margin-right: 5px;
                        vertical-align: -1px;
                        color: #e03c32;
                    }
                    .icon-udesk-qa-react-web-chenggong {
                        display: inline-block;
                        margin-right: 5px;
                        vertical-align: -1px;
                        color: #36864a;
                    }
                    .test-set-list-circle {
                        display: inline-block;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        margin-right: 5px;
                        border: 1px solid #999;
                        vertical-align: middle;
                    }
                }
            }
            .test-set-list-checkbox {
                display: inline-block;
                margin-right: 20px;
                .icon-udesk-qa-react-web-xiazaishibai {
                    display: inline-block;
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                    border-radius: 0%;
                    vertical-align: -1px;
                    color: #e03c32;
                }
                .icon-udesk-qa-react-web-chenggong {
                    display: inline-block;
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                    border-radius: 0%;
                    vertical-align: -1px;
                    color: #36864a;
                }
                .test-set-list-checkbox-just {
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    border-radius: 0%;
                    margin-right: 5px;
                    border: 1px solid #999;
                    vertical-align: middle;
                }
            }
            .test-set-list-select-cancel {
                display: inline-block;
                width: 50%;
                .point-type-react-select {
                    width: 100% !important;
                    color: #e03c32;
                }
            }
            .test-set-list-select-ok {
                display: inline-block;
                width: 50%;
                .point-type-react-select {
                    width: 100% !important;
                    color: #36864a;
                }
                .test-set-list-select-cancel-option {
                    margin-top: 5px;
                    text-align: left;
                    color: #e03c32;
                }
            }
            .test-set-list-input-cancel {
                display: inline-block;
                width: 50%;
                .point-type-input {
                    width: 100% !important;
                    color: #e03c32;
                }
            }
            .test-set-list-input-ok {
                display: inline-block;
                width: 50%;
                .point-type-input {
                    width: 100% !important;
                    color: #36864a;
                }
                .test-set-list-input-cancel-option {
                    margin-top: 5px;
                    text-align: left;
                    color: #e03c32;
                }
            }
            .udesk-qa-ui-select-selector {
                height: 24px;
                .udesk-qa-ui-select-selection-search-input {
                    height: 22px;
                }
                .udesk-qa-ui-select-selection-item {
                    line-height: 22px;
                }
            }
            .udesk-qa-ui-select-selection-placeholder {
                line-height: 24px;
            }
            .udesk-qa-ui-input-number-input {
                height: 24px;
            }
        }
        .quality-check-score-talk-field-remark {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            line-height: 20px;
        }
    }
    .quality-check-score-talk-remark {
        width: 100%;
        height: 68px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        .quality-check-score-remark-title {
            width: 30px;
        }
        .quality-check-score-remark-content {
            flex: 1;
        }
    }
    .udesk-qa-ui-tabs-content {
        height: 100%;
        .udesk-qa-ui-tabs-tabpane {
            display: flex;
            flex-direction: column;
            .quality-check-score-talk-body-content {
                flex: 1;
            }

            .problem-extraction-markdown {
                min-height: 300px;
                overflow: auto;

                table th {
                    white-space: nowrap;
                    padding: 0 5px 0 0;
                }
                // table tr td:nth-child(3),
                table tr td:nth-child(5) {
                    text-align: center !important;
                }
            }
        }
    }
    .appeal-history-component-detail-item-content {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        .appeal-history-component-detail-item-content-value {
            color: rgba(0, 0, 0, 0.65);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .appeal-history-component-detail-item-content-label {
            white-space: nowrap;
        }
    }
}
.quality-check-score-talk-header-eveluation-person {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    .quality-check-score-talk-header-eveluation-person-item {
        text-align: center;
    }
    .quality-check-score-talk-header-icon {
        font-size: 20px;
    }
    .quality-check-score-talk-header-xiao {
        color: rgba(17, 168, 72, 1);
    }
    .quality-check-score-talk-header-ku {
        color: rgba(251, 81, 39, 1);
    }
}

.interactive-point-result-container-call-info {
    border: 1px solid #ddd;
    max-height: 150px;
    overflow-y: auto;
    margin: 0;
    width: 100%;
    background: #eee;
    padding: 0 5px;

    .interactive-point-result-item {
        margin: 0;
        padding: 5px 0;
        max-width: 400px;
        .interactive-point-result-icon-chenggong {
            margin-right: 5px;
            color: green;
        }

        .interactive-point-result-icon-shibai {
            margin-right: 5px;
            color: red;
        }

        .interactive-point-result-question {
            margin: 0;
            display: flex;

            .interactive-point-result-question-title {
                // display: inline-block;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // width: calc(100% - 100px);
                white-space: pre-wrap;
                flex: 1;
            }

            .interactive-point-result-question-type {
                // width: 100px;
                // display: inline-block;
                text-align: right;
                margin-left: 8px;
            }
        }

        .interactive-point-result-answer {
            margin: 0;
            display: flex;

            .interactive-point-result-answer-title {
                // display: inline-block;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // width: calc(100% - 100px);
                white-space: pre-wrap;
                flex: 1;
            }

            .interactive-point-result-answer-type {
                // width: 100px;
                // display: inline-block;
                text-align: right;
                margin-left: 8px;
            }
        }
    }
}
div {
    &#driver-popover-item {
        .driver-popover-footer {
            button {
                font-weight: 400;
                white-space: nowrap;
                text-align: center;
                background-image: none;
                border: 1px solid transparent;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                cursor: pointer;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                touch-action: manipulation;
                // height: 32px;
                padding: 4px 15px;
                font-size: 14px;
                border-radius: 2px;
                color: rgba(0, 0, 0, 0.85);
                background: #fff;
                border-color: #d9d9d9;
            }
        }
    }
}
