.wave-audio-component {
    .wave-audio-operation-panel {
        width: 100%; // height: 40px;
        // line-height: 40px;
        border: 1px solid #ddd;
        margin-top: 10px;
        background-color: #00b38b;
        color: #fff;

        .wave-audio-button-box {
            display: inline-block;
            cursor: pointer;
            height: 40px;
            line-height: 40px;
            vertical-align: sub;
            margin: 0 30px;
        }

        .wave-audio-emotion-button {
            margin: 0 10px;
        }

        .wave-audio-play-button {}

        .wave-audio-pause-button {}

        .wave-audio-volumn-button {
            margin: 0 15px;
            position: relative;

            .wave-audio-volumn-input-box {
                display: inline-block;
                position: absolute;
                transform: rotate(-90deg);
                right: -28px;
                top: -56px;
                z-index: 3;

                input[type=range]::-webkit-slider-runnable-track {
                    height: 8px;
                    border-radius: 10px;
                    background-color: #00b38b;
                }

                input[type=range]:focus {
                    outline: none;
                }

                input[type=range]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    height: 12px;
                    width: 12px;
                    margin-top: -2px;
                    background: #ffffff;
                    border-radius: 50%;
                    border: 1px solid #000;
                }
            }
        }

        .wave-audio-rate-button {
            width: 35px;
            margin: 0 15px 0 0;
            position: relative;

            .wave-audio-rate-options {
                position: absolute;
                background-color: #00b38b;
                bottom: 42px;
                border-radius: 5px;
                z-index: 3;

                .wave-audio-rate-option {
                    height: 20px;
                    line-height: 20px;
                    padding: 0 5px;

                    &:hover {
                        background-color: #dcf8f0;
                    }
                }
            }
        }

        .wave-audio-time-box {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            margin-right: 15px;
        }

        .udesk-qc-iconfont {
            font-size: 24px;
        }
    }
}