.udesk-qa-web-product-matrix-component {
    display: inline-flex;
}

.udesk-qa-web-product-matrix-component-tooltip {
    padding: 24px 4px 0px;
    // 滚动背景
    ::-webkit-scrollbar {
        width: 0.3rem;
        height: 0.3rem;
        background-color: rgba(0, 0, 0, 0.1);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    // 滚动条
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(27, 109, 255, 0.5);
        transition: all 0.2s;
        border-radius: 0.5rem;
    }
    .udesk-qa-web-product-matrix-component-tooltip-header {
        background: url('../../../../static/img/gaussMindLoag.png') 0 0 no-repeat;
        height: 32px;
        margin-bottom: 16px;
        margin-left: 24px;
    }
    .udesk-qa-web-product-matrix-component-tooltip-body {
        max-height: 80vh;
        overflow-y: auto;
        padding-bottom: 16px;
        .udesk-qa-web-product-matrix-component-card {
            display: flex;
            // align-items: center;
            padding: 16px;
            // min-width: 236px;
            border-radius: 2px;
            transition: all 0.3s;
            user-select: none;
            cursor: pointer;
            // border: 1px solid rgba(0, 0, 0, 0.5);
            margin: 8px 4px;
            position: relative;
            &:hover {
                background: #e6f3ff;
                // background: linear-gradient(90deg, #2C7CF6 0%, rgba(44, 149, 246, 0.8) 100%);;
                position: absolute;
                z-index: 2;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
                width: calc(100% - 8px);
                min-height: 100%;
                // .udesk-qa-web-product-matrix-component-card-content {
                //     .udesk-qa-web-product-matrix-component-card-title {
                //         .udesk-qa-web-product-matrix-component-card-name {
                //             color: #fff;
                //         }
                //     }
                // }
                .udesk-qa-web-product-matrix-component-card-introduction {
                    height: auto;
                    -webkit-line-clamp: unset;
                    // color: #fff;
                }
            }

            &-logo {
                margin-top: 4px;
                width: 32px;
                height: 32px;
                // background-color: rgba(0, 0, 0, 0.5);
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &-content {
                flex: 1;
                margin-left: 16px;

                .udesk-qa-web-product-matrix-component-card-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .udesk-qa-web-product-matrix-component-card-name {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.85);
                        flex: 1;
                        text-transform: capitalize;
                    }
                    .udesk-qa-web-product-matrix-component-card-status {
                        width: 32px;
                        height: 16px;
                        background: #0fba26;
                        color: #fff;
                        border-radius: 2px;
                        line-height: 12px;
                        padding: 2px 0px;
                        text-align: center;
                        font-weight: 600;
                        font-size: 10px;
                    }
                }
            }
            &-introduction {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}
