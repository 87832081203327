.udesk-qa-web-course-management-detail {
    height: 100%;
    overflow: hidden;
    position: relative;
    .react-flow__panel.react-flow__attribution.bottom.right {
        display: none;
    }
    .udesk-qa-ui-form-builder-container {
        .udesk-qa-ui-space-item {
            > .udesk-qa-ui-form-item {
                margin-bottom: 0;
            }
        }
    }

    .control-button-bar {
        height: 36px;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        .react-flow__controls-button {
            min-width: 16px;
            width: auto;
            border-bottom: none;
            white-space: nowrap;
        }
        .udesk-qa-ui-divider-vertical {
            border-left-color: rgba(0, 0, 0, 0.1);
        }
    }
    .dndflow {
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        height: 100%;
        position: relative;
        .global-setting-btn {
            background: #ffffff;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 32px;
            height: 32px;
            cursor: pointer;
        }
        .react-flow__controls {
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            display: flex;

            > button {
                min-width: 36px;
                height: 36px;
                background: #ffffff;
                // border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 4px;
                &:hover {
                    background: #f5f5f5;
                }
            }
        }

        // .target-handle-node,
        // .source-handle-node {
        //     /* 相对定位 */
        //     position: relative;
        //     /* 将a元素转为块级元素，不然无法设置宽和高 */
        //     display: block;
        //     width: 100%;
        //     height: 8px;
        //     min-height: 4px;
        //     text-align: center;
        //     text-decoration: none;
        //     /* 动画过渡 */
        //     transition: all 0.3s ease-in-out;
        //     /* 改变各个元素的颜色【划重点】 */
        //     /* hue-rotate是颜色滤镜，可以加不同的度数来改变颜色，这里我们用了calc自动计算函数，以及var函数来调用我们给每一个a设置的不同自定义属性1~5，然后分别乘以60度，就能够分别得到不同的度数 */
        //     filter: hue-rotate(calc(var(--i) * 60deg));
        //     border-radius: unset;
        //     background-color: transparent;
        //     border: none;
        // }
        // .target-handle-node {
        //     top: 8px;
        //     &::before {
        //         content: ' ';
        //         width: 100%;
        //         height: 1px;
        //         position: absolute;
        //         left: 0;
        //         top: -1px;
        //     }
        // }
        // .source-handle-node {
        //     bottom: 8px;
        //     &::after {
        //         content: ' ';
        //         width: 100%;
        //         height: 1px;
        //         position: absolute;
        //         left: 0;
        //         top: 8px;
        //     }
        // }
        // .selectable {
        //     .source-handle-node:hover {
        //         &::after {
        //             background: linear-gradient(
        //                 to right,
        //                 rgba(0, 0, 0, 0) 0%,
        //                 #4071ff 50%,
        //                 rgba(0, 0, 0, 0) 100%
        //             );
        //         }
        //     }
        //     .target-handle-node:hover {
        //         &::before {
        //             background: linear-gradient(
        //                 to right,
        //                 rgba(0, 0, 0, 0) 0%,
        //                 #4071ff 50%,
        //                 rgba(0, 0, 0, 0) 100%
        //             );
        //         }
        //     }
        // }
    }

    .dndflow .reactflow-wrapper {
        flex-grow: 1;
        height: 100%;
    }

    .dndflow .selectall {
        margin-top: 10px;
    }

    @media screen and (min-width: 768px) {
        .dndflow {
            flex-direction: row;
        }

        .dndflow aside {
            width: 20%;
            max-width: 164px;
        }
    }
}
