$component--report-echarts-main-borderColor: #ccc !default;
$component--report-echarts-header-fontSize: 14px !default;
$component--report-echarts-header-fontColor: #666666 !default;
$component--report-echarts-header-lineHeight: 1.5 !default;
$component--report-echarts-main-minHeight:320px !default;

.report-echarts {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: inherit;

    .report-echarts-header {
        font-size: $component--report-echarts-header-fontSize;
        line-height: $component--report-echarts-header-lineHeight;
        color: $component--report-echarts-header-fontColor;
        text-align: left;
    }

    .report-echarts-main {
        position: relative;
        width: 100%;
        height: -moz-calc(100% - #{$component--report-echarts-header-fontSize} * #{$component--report-echarts-header-lineHeight});
        height: -webkit-calc(100% - #{$component--report-echarts-header-fontSize} * #{$component--report-echarts-header-lineHeight});
        height: calc(100% - #{$component--report-echarts-header-fontSize} * #{$component--report-echarts-header-lineHeight});
        min-height: inherit;
        padding: 15px 8px;
        border: 1px solid $component--report-echarts-main-borderColor;

        &.report-echarts-container-no-header {
            height: -moz-calc(100%);
            height: -webkit-calc(100%);
            height: calc(100%);
            min-height: inherit;
        }

        &.has-field-group {
            min-height: 300px;
        }

        .prompt {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 208px;
            max-width: 240px;
            min-height: 40px;
            padding: 10px 16px;
            line-height: 20px;
            color: #24c1aa;
            border: 1px dashed #d7dbe0;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        // toggle-btn
        .toggle-button {
            position: absolute;
            right: 10px;
            bottom: 10px;
            display: none;
            cursor: pointer;
            line-height: 16px;

            .toggle-btn-info {
                position: absolute;
                right: 100%;
                top: 50%;
                transform: translate(0, -50%);
                display: none;
                margin-right: 10px;
                white-space: nowrap;
                background-color: #ddd;
                height: 25px;
                line-height: 25px;
                font-size: 12px;
                color: #666;
                padding: 0 5px;
                border-radius: 3px;
            }

            &:hover .toggle-btn-info {
                display: block;
            }


        }

        &:hover .toggle-button {
            display: block;
        }

        .data-view-table {
            height: initial;
            overflow: hidden;
        }

        .data-view-table .table-wrapper {
            overflow: hidden;
        }
    }

    .report-echart-tips {
        position: absolute;
        top: 38px;
        right: 2%;
        font-size: 16px;
        .report-echart-tips-num {
            color: #2878ff;
        }
    }

    .trend-group-box {
        overflow: hidden;
        overflow-y: auto;
        max-height: 200px;
        border: 1px solid #ccc;
        border-top: none;
        width: 100%;

        .trend-group-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;

            .group-item {
                flex: 1;
                padding: 20px 15px;
                background-color: #fff;

                &.group-item-active {
                    color: #fff;
                    background-color: #00cbac;
                    border: none;
                }

                &:not(:nth-of-type(1)) {
                    border: none;
                    border-left: 1px solid #ccc;
                }

                .group-item-content-item {
                    width: 100%;
                }
            }
        }
    }
}