.task-center-list-body {
    height: 100%;

    // > div,
    // > div > div,
    // > div > div > div,
    // > div > div > div > div,
    // > div > div > div > div > div {
    //     height: 100%;
    // }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;