$component-udesk-custom-filters-column-width-condition-prefix: 20px !default;
$component-udesk-custom-filters-column-width-condition-field: 130px !default;
$component-udesk-custom-filters-column-width-condition-operator: 105px !default;
$component-udesk-custom-filters-column-width-condition-value: 170px !default;
$component-udesk-custom-filters-column-width-condition-postfix: auto !default;
$component-udesk-custom-filters-column-width-condition-removal: auto !default;
$component-udesk-custom-filters-table-width: auto !default;
$component-udesk-custom-filters-font-size: 13px !default;
$component-udesk-custom-filters-condition-value-font-size: 12px !default;
$component-udesk-custom-filters-rows-gap: 10px !default;
$component-udesk-custom-filters-header-gap: $component-udesk-custom-filters-rows-gap / 2 !default;
$component-udesk-custom-filters-header-font-weight: normal !default;
$component-udesk-custom-filters-cells-gap: 10px !default;
$component-udesk-custom-filters-actions-row-top-gap: 5px !default;
$component-udesk-custom-filters-validation-summary-top-gap: 10px !default;
$component-udesk-custom-filters-action-icon-font-size: $component-udesk-custom-filters-font-size * 1.5 !default;
$component-udesk-custom-filters-action-icon-text-font-size: $component-udesk-custom-filters-font-size !default;
$component-udesk-custom-filters-disable-background-color: #ddd !default;
$component-udesk-custom-filters-removal-icon-color: #d9534f !default;
$component-udesk-custom-filters-add-icon-color: #449d44 !default;
$component-udesk-custom-filters-error-color: #a94442 !default;
$component-udesk-custom-filters-condition-animation-duration: 0.5s !default;
$component-udesk-custom-filters-condition-value-animation-duration: 0.1s !default;
$component-udesk-custom-filters-removal-icon-on-adding-animation-duration-in: 0.3s 0.5s !default;
$component-udesk-custom-filters-removal-icon-on-one-row-left-animation-duration-out: 0.5s !default;
$component-udesk-custom-filters-removal-icon-on-removing-animation-duration-out: 0.3s !default;
$component-udesk-custom-filters-validation-summary-animation-duration: 0.5s !default;
