.connected-by-conversation-index-page {
    position: relative;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .qa-react-list-connenct-by-converstaion-title-header {
        display: flex;
        font-size: 14px;
        margin-bottom: 20px;
        h2 {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 25px;
            margin-right: 20px;
        }
        .qa-react-list-connenct-by-converstaion-title-header-right-info {
            line-height: 25px;
            .connect-by-converstaion-switch {
                margin-right: 20px;
            }
            i {
                margin-right: 5px;
                vertical-align: middle;
                color: rgba(0, 0, 0, 0.65);
            }
            span {
                vertical-align: middle;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
    .qa-react-list-connenct-by-converstaion-custome-filter {
        margin-top: 20px;
        margin-left: 55px;
        padding: 72px 72px 112px;
        .custome-filter-driver-box {
            position: relative;
            .udesk-qa-ui-select {
                min-width: 130px;
            }
            .customer-filter-driver-circle-add {
                position: absolute;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 1);
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
                text-align: center;
                line-height: 21px;
                top: calc(50% + 55px);
                left: -84px;
                cursor: pointer;
                i {
                    font-size: 12px;
                    color: #1b6dff;
                }
            }
            .customer-filter-driver-company {
                margin-right: 5px;
                input {
                    width: 100px;
                    margin-right: 5px;
                }
                span {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .custome-filter-driver-box-or {
                position: absolute;
                width: 48px;
                height: 32px;
                background: rgba(255, 255, 255, 1);
                border-radius: 16px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                text-align: center;
                line-height: 28px;
                color: #1b6dff;
                font-size: 16px;
                top: 50%;
                transform: translateY(-25%);
                left: -97px;
                z-index: 1;
            }
            .custome-filter-driver-one-box {
                position: relative;
                padding-bottom: 20px;
                &:before {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    top: 15px;
                    left: -73px;
                    // transform: translateY(-50%);
                    content: '';
                }
                .customer-filter-driver-circle-add {
                    top: 40px;
                }
                .custome-filter-driver-one {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 32px;
                    &:before {
                        position: absolute;
                        width: 72px;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.1);
                        top: 50%;
                        left: -72px;
                        transform: translateY(-50%);
                        content: '';
                    }
                    .customer-filter-driver-circle-del {
                        width: 24px;
                        height: 24px;
                        background: rgba(255, 255, 255, 1);
                        border-radius: 50%;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        text-align: center;
                        line-height: 21px;
                        cursor: pointer;
                        i {
                            font-size: 12px;
                            color: #1b6dff;
                        }
                    }
                }

                .custome-filter-driver-two-box {
                    position: relative;
                    margin-left: 72px;
                    padding: 20px 0;
                    &:before {
                        position: absolute;
                        width: 1px;
                        height: calc(100% - 20px);
                        background: rgba(0, 0, 0, 0.1);
                        top: 0px;
                        left: -63px;
                        // transform: translateY(-50%);
                        content: '';
                    }
                    .customer-filter-driver-circle-add {
                        top: calc(100% - 20px);
                        left: -73px;
                    }
                    .customer-filter-driver-three-box {
                        position: relative;
                        margin-left: 50px;
                        &:before {
                            position: absolute;
                            width: 50px;
                            height: 1px;
                            background: rgba(0, 0, 0, 0.1);
                            top: 50%;
                            left: -112px;
                            transform: translateY(-50%);
                            content: '';
                        }
                        &:after {
                            position: absolute;
                            width: 1px;
                            height: calc(100% - 20px);
                            background: rgba(0, 0, 0, 0.1);
                            top: 20px;
                            left: -63px;
                            // transform: translateY(-50%);
                            content: '';
                        }
                        .customer-filter-driver-circle-add {
                            top: 100%;
                        }
                        .custome-filter-driver-box-and {
                            position: absolute;
                            width: 48px;
                            height: 32px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 16px;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            line-height: 28px;
                            color: #1b6dff;
                            font-size: 16px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -85px;
                            z-index: 1;
                        }
                    }
                    .custome-filter-driver-two {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 5px 0px;
                        height: 42px;
                        &:before {
                            position: absolute;
                            width: 62px;
                            height: 1px;
                            background: rgba(0, 0, 0, 0.1);
                            top: 50%;
                            left: -62px;
                            transform: translateY(-50%);
                            content: '';
                        }
                        .customer-filter-driver-circle-del {
                            width: 24px;
                            height: 24px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 50%;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            line-height: 21px;
                            cursor: pointer;
                            i {
                                font-size: 12px;
                                color: #1b6dff;
                            }
                        }
                    }
                }
                .custome-filter-driver-two-box-driver-length {
                    &:before {
                        height: 50%;
                    }
                }
            }
            .custome-filter-driver-one-box-length {
                height: 40px;
                &:before {
                    height: 40px;
                }
                .custome-filter-driver-two-box {
                    margin-bottom: 100px;
                }
            }
        }
    }
    .qa-react-list-connect-by-converstaion-save-or-cancel {
        // position: fixed;
        // bottom: 0px;
        // padding: 10px;
        width: 100%;
        padding-top: 16px;
        border-top: 1px solid #ccc;
        background: rgba(255, 255, 255, 1);
        // box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        // transform: translateX(-15px);
        .connect-by-converstaion-save {
            width: 60px;
            height: 32px;
            background: rgba(27, 109, 255, 1);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
            font-size: 14px;
            border-radius: 3px;
            line-height: 32px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            margin-left: 50px;
            margin-right: 15px;
        }
        .connect-by-converstaion-cancel {
            width: 60px;
            height: 32px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
            border: 1px solid rgba(0, 0, 0, 0.15);
            font-size: 14px;
            border-radius: 3px;
            line-height: 32px;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            cursor: pointer;
        }
    }
}

