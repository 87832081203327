@import "variables";
@import "mixins";

@keyframes line-scale-pulse-out-rapid {
    0% {
        transform: scaley(1);
    }

    80% {
        transform: scaley(0.3);
    }

    90% {
        transform: scaley(1);
    }
}

.line-scale-pulse-out-rapid {
    > div {
        @include lines;

        @include global-animation;

        display: inline-block;
        vertical-align: middle;
        animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);

        &:nth-child(2), &:nth-child(4) {
            animation-delay: -0.25s;
        }

        &:nth-child(1), &:nth-child(5) {
            animation-delay: 0s;
        }
    }
}
