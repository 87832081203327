.udesk-qa-survey-manage-detail-page {
    .qa-react-record-detail-page {
        padding: 0;
    }
    .qa-react-record-detail-content-left-part {
        padding-right: 0 !important;
    }
    .related-records-list-page {
        background-color: #fff;
        margin-top: 8px;
    }
}
