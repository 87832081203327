.third-party-quality-inspection-list-detail-page-body {
    padding: 16px;
    .btn {
        margin-right: 5px;
    }
    .udesk-qa-ui-tabs-content {
        height: calc(100%);

    }
    .qa-react-inspection-detail-page .qa-react-inspection-detail-content {
         height: calc(100vh - 64px - 58px - 32px - 32px - 40px) !important;
    }
}

// .quality-check-score-talk-header-eveluation-person {
//     cursor: pointer;
//     font-size: 20px;
//     color: rgba(0, 0, 0, 0.25);
//     .quality-check-score-talk-header-xiao {
//         color: rgba(17, 168, 72, 1);
//     }
//     .quality-check-score-talk-header-ku {
//         color: rgba(251, 81, 39, 1);
//     }
// }
.quality-inspection-list-detail-header-footer {
    padding-bottom: 0 !important;
    .udesk-qa-ui-page-header-footer {
        margin-top: 0px;
    }
}
.custom-modal-progress-style {
    .udesk-qa-ui-modal-body {
        display: flex;
        justify-content: center;
    }
}