.anomalous-data-pages {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #f0f2f5;
    .anomalous-data-pages-header {
        height: 58px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 0 16px;
        .anomalous-data-pages-header-title {
            font-size: 18px;
            font-weight: 600;
            line-height: 58px;
            float: left;
        }
    }
    .anomalous-data-pages-body {
        background: #fff;
        padding: 16px;
        min-height: calc(100% - 58px);
        .udesk-qa-ui-pagination {
            width: 100%;
            text-align: right;
            .udesk-qa-ui-pagination-total-text {
                position: absolute;
                left: 100px;
                color: rgba(0, 0, 0, 0.45);
            }
        }
        .udesk-qa-ui-pagination-options {
            text-align: center;
            position: absolute;
            left: 0px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
}
