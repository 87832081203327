.index-management-tags-detail-page {
    padding: 16px;
    .udesk-qa-ui-col-5 {
        max-width: 200px;
    }
    .customer-filter-driver-circle-del {
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.15);
        text-align: center;
        line-height: 21px;
        cursor: pointer;
        display: inline-block;
        vertical-align: 2px;
        margin-left: 20px;
        i {
            font-size: 12px;
            color: #1b6dff;
        }
    }
    .index-management-custom-filter {
        margin-left: 200px;
        margin-bottom: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.03);
        max-width: 800px;
        width: auto;
        max-height: 400px;
        overflow-y: auto;
        .index-management-custom-filter-edit {
            text-align: right;
            span {
                cursor: pointer;
                color: rgba(27, 109, 255, 1);
            }
        }
        .index-management-custom-filter-Info {
            display: flex;
        }
        .component-udesk-react-radio-btn .radio-btn-item {
            width: auto;
        }
    }
    .index-management-custom-filter-width-other {
        margin-left: 0;
    }
}
