.admin-user-detail {
    .admin-form .form-control-label.account,
    .admin-form .form-control-label.avator {
        text-align: left;
    }
    .account {
        height: 17px;
        padding: 6px;
        float: left;
    }
    .box {
        overflow: hidden;
        padding-bottom: 4rem;
    }
    .thumbnail {
        margin-right: 1rem;
    }
    .upload-file-input-user {
        display: none;
    }
}

.modal-btn-container {
    overflow: hidden;
    text-align: center;
}
