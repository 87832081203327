.report-echarts {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
}
.report-echarts .report-echarts-header {
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  text-align: left;
}
.report-echarts .report-echarts-main {
  position: relative;
  width: 100%;
  height: -moz-calc(100% - 14px * 1.5);
  height: -webkit-calc(100% - 14px * 1.5);
  height: calc(100% - 14px * 1.5);
  min-height: inherit;
  padding: 15px 8px;
  border: 1px solid #ccc;
}
.report-echarts .report-echarts-main.report-echarts-container-no-header {
  height: -moz-calc(100%);
  height: -webkit-calc(100%);
  height: calc(100%);
  min-height: inherit;
}
.report-echarts .report-echarts-main.has-field-group {
  min-height: 300px;
}
.report-echarts .report-echarts-main .prompt {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 208px;
  max-width: 240px;
  min-height: 40px;
  padding: 10px 16px;
  line-height: 20px;
  color: #24c1aa;
  border: 1px dashed #d7dbe0;
  transform: translate(-50%, -50%);
  text-align: center;
}
.report-echarts .report-echarts-main .toggle-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: none;
  cursor: pointer;
  line-height: 16px;
}
.report-echarts .report-echarts-main .toggle-button .toggle-btn-info {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  display: none;
  margin-right: 10px;
  white-space: nowrap;
  background-color: #ddd;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: #666;
  padding: 0 5px;
  border-radius: 3px;
}
.report-echarts .report-echarts-main .toggle-button:hover .toggle-btn-info {
  display: block;
}
.report-echarts .report-echarts-main:hover .toggle-button {
  display: block;
}
.report-echarts .report-echarts-main .data-view-table {
  height: initial;
  overflow: hidden;
}
.report-echarts .report-echarts-main .data-view-table .table-wrapper {
  overflow: hidden;
}
.report-echarts .report-echart-tips {
  position: absolute;
  top: 38px;
  right: 2%;
  font-size: 16px;
}
.report-echarts .report-echart-tips .report-echart-tips-num {
  color: #2878ff;
}
.report-echarts .trend-group-box {
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
}
.report-echarts .trend-group-box .trend-group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.report-echarts .trend-group-box .trend-group-container .group-item {
  flex: 1;
  padding: 20px 15px;
  background-color: #fff;
}
.report-echarts .trend-group-box .trend-group-container .group-item.group-item-active {
  color: #fff;
  background-color: #00cbac;
  border: none;
}
.report-echarts .trend-group-box .trend-group-container .group-item:not(:nth-of-type(1)) {
  border: none;
  border-left: 1px solid #ccc;
}
.report-echarts .trend-group-box .trend-group-container .group-item .group-item-content-item {
  width: 100%;
}

