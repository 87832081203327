.udesk-custom-filters-react .filter-wrapper .filter-conditions {
  width: auto;
  border-collapse: separate;
  border-spacing: 10px;
  margin: -10px;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions > tbody {
  border-collapse: collapse;
  border-spacing: 0;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-prefix-column {
  width: 30px;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-field-column {
  width: 140px;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-operator-column {
  width: 115px;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-value-column {
  width: 180px;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-postfix-column {
  width: auto;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions colgroup col.condition-removal-column {
  width: auto;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-head .filter-head-row .filter-head-item {
  font-weight: normal;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-from-value {
  display: inline-block;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-to-value {
  display: inline-block;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-body .condition-row .condition-item.condition-value .value-loading-error {
  color: #a94442;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-body .condition-row .condition-item.condition-removal .filter-icon-removal .filter-icon {
  color: #d9534f;
}
.udesk-custom-filters-react .filter-wrapper .filter-conditions .filter-body .condition-row .condition-item.condition-removal .filter-icon-removal .filter-icon:hover {
  color: #c9302c;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions {
  margin-top: 5px;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions a.filter-icon-wrapper {
  color: inherit;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions .add-condition {
  display: inline-block;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions .add-condition .filter-icon-add.filter-icon-add-with-condition-number {
  margin-left: 20px;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions .add-condition .filter-icon-add .filter-icon {
  color: #449d44;
}
.udesk-custom-filters-react .filter-wrapper .filter-actions .add-condition .filter-icon-add .filter-icon:hover {
  color: #357935;
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors {
  margin-top: 10px;
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors .validation-summary {
  margin-bottom: 10px;
  font-weight: bold;
  color: #a94442;
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors .field-error {
  margin-bottom: 5px;
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors .field-error .field-name {
  font-weight: bold;
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors .field-error .field-name:after {
  content: " :";
}
.udesk-custom-filters-react .filter-wrapper .filter-validation-errors .field-error .error-message {
  padding-left: 20px;
  color: #a94442;
}
.udesk-custom-filters-react .filter-wrapper .filter-icon-wrapper .filter-icon {
  font-size: 19.5px;
}
.udesk-custom-filters-react .filter-wrapper .filter-icon-wrapper .action-icon-text {
  font-size: 13px;
}
.udesk-custom-filters-react .filter-wrapper .invisible-column {
  display: none !important;
}

.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row {
  transition: border-color 0.5s;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-field .field-selector {
  width: 100%;
  padding: 5px 5px;
  font-size: 12px;
  height: 30px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-field.field-error .field-selector {
  border: 1px solid #a94442;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-operator .operator-selector {
  width: 100%;
  padding: 5px 0 5px 5px;
  font-size: 12px;
  height: 30px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-operator.operator-error .operator-selector {
  border: 1px solid #a94442;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .form-control {
  font-size: 12px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .form-group {
  margin-bottom: 0px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .input-sm-wide {
  min-width: 70px;
  width: 70px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-from-text {
  margin-right: 5px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-to-text {
  margin-left: 5px;
  margin-right: 5px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .cascade-drop-down-wrapper .cascade-drop-down-options {
  display: inline-block;
  min-width: 80px;
  height: 30px;
  margin: 2px;
  padding: 2px;
  vertical-align: middle;
  text-align: center;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .date-range-picker {
  padding-top: 0;
  padding-left: 0;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .date-range-picker label {
  display: inline;
  margin-bottom: 0;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .date-range-picker .form-control {
  height: 30px;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .udesk-bootstrap-duration-picker .form-control {
  min-width: auto;
}
.udesk-custom-filters-react.bootstrap .filter-conditions .filter-body .condition-row .condition-item.condition-value .component-react-select {
  width: 100%;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row {
  transition: border-color 0.5s;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-field.text-mode {
  background-color: #ddd;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-field .field-selector {
  width: 100%;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-field .field-display-as-text {
  padding-left: 10px;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-field.field-error .field-selector {
  border: 1px solid #a94442;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-operator .operator-selector {
  width: 100%;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-operator.operator-error .operator-selector {
  border: 1px solid #a94442;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-from-text {
  margin-right: 5px;
}
.udesk-custom-filters-react.default .filter-conditions .filter-body .condition-row .condition-item.condition-value .range-to-text {
  margin-left: 5px;
  margin-right: 5px;
}
.udesk-custom-filters-react.filter-animated {
  /* Set overflow to hidden to be friendly to animations.
  Some rollback animations need elements moved out of container, 
  but we should not see them out of container, so we can set this 
  style to hide them. */
}
.udesk-custom-filters-react.filter-animated.condition-adding, .udesk-custom-filters-react.filter-animated.condition-removing {
  overflow: hidden;
}
.udesk-custom-filters-react.filter-animated .condition-animation {
  /* If animation is turned on, all actions are registered as callback of animations or transitions,
  we have to add certain animation effects to let animation callbacks work!
  To provide a default transition that seems NOT a transition at all, we can fade elements in&out 
  with very tiny changes that is unnoticeable for human people.  */
  transition: opacity 0.001s;
  opacity: 0.999;
}
.udesk-custom-filters-react.filter-animated .condition-animation.animation-in {
  opacity: 1;
}

.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row.condition-animation {
  transition: transform 0.5s;
  transform: translateX(-100%);
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row.condition-animation.animation-in {
  transform: translateX(0);
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-item.condition-value.condition-animation {
  opacity: 0;
  transition: opacity 0.1s;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-item.condition-value.condition-animation.animation-in {
  opacity: 1;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-item.condition-value.condition-animation.animation-out {
  opacity: 0;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-removal.condition-animation {
  opacity: 0;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-removal.condition-animation.animation-in {
  transition: opacity 0.3s 0.5s;
  opacity: 1;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row .condition-removal.condition-animation.animation-out {
  transition: opacity 0.5s;
  opacity: 0;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-conditions .condition-row.removing .condition-removal.condition-animation.animation-out {
  transition: opacity 0.3s;
  opacity: 0;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-validation-errors.condition-animation {
  transition: opacity 0.5s;
  opacity: 0;
}
.udesk-custom-filters-react.animations-default .filter-wrapper .filter-validation-errors.condition-animation.animation-in {
  opacity: 1;
}

