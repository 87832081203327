.tasks-manage-test-template-basic-info-page {
    display: flex;
    flex-direction: column;
    .tasks-manage-template-basic-info-index-content {
        padding: 16px;
        background-color: rgba(240,242,245,1);
        min-height: 100%;
        .tasks-manage-template-basic-info-index-conduct {
            height:48px;
            padding: 0 16px 0 20px;
            line-height: 48px;
            background: #fff;
            border-bottom: rgba(0,0,0,0.1) 1px solid;
            display: flex;
            justify-content: space-between;
            .tasks-manage-template-basic-info-index-conduct-right {
                cursor: pointer;
                color:rgba(0,0,0,0.45);
            }
        }
    }
    .tasks-manage-template-basic-info-index-form {
        padding: 40px 25px;
        background: #fff;
        flex: 1;
    }
    .tasks-manage-template-basic-info-index-footer {
        height: 48px;
        padding-left: 118px;
        padding-top: 8px;
    }
}