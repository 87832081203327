
.collection-component-list {
    overflow-y: auto;
    .collection-component-col {
        padding: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: all 0.3s;
        position: relative;

        &:hover {
            position: relative;
            z-index: 1;
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
            border-bottom: 1px solid #fff;
        }

        .collection-component-col-item {
            display: flex;
        
    
            .collection-component-col-label {
                width: 46%;
                display: flex;
                
                &::after {
                    content: ':';
                }
            }
            .collection-component-col-label-content {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
            }
            .collection-component-col-value {
                flex: 1;
                margin-left: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .collection-component-col-item-remark {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0,0,0,.45);
            font-size: 12px;
            line-height: 20px;
        }
    }
}
.collection-component-title {
    padding: 8px 16px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    background: rgba(0,0,0,.03);
}
