@font-face {
  font-family: "udesk-qa-react-web-iconfont";
  src: url("./vendor/iconfont.eot?t=1557541670153");
  /* IE9*/
  src: url("./vendor/iconfont.eot?t=1557541670153#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./vendor/iconfont.woff?t=1557541670153") format("woff"),
    url("./vendor/iconfont.ttf?t=1555308207764") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("./vendor/iconfont.svg?t=1557541670153#udesk-qa-react-web-iconfont")
      format("svg");
  /* iOS 4.1- */
}

.udesk-qa-react-web-iconfont {
  font-family: "udesk-qa-react-web-iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-qa-react-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.icon-udesk-qa-react-web-ic-arrow-right-s-yousanjiao:before {
  content: "\e69e";
}

.icon-udesk-qa-react-web-ic-add-circle-jia:before {
  content: "\e6a4";
}

.icon-udesk-qa-react-web-ic-minus-circle-jian:before {
  content: "\e6a1";
}

.icon-udesk-qa-react-web-ic-arrow-left-zuojiantou:before {
  content: "\e6af";
}

.icon-udesk-qa-react-web-ic-close-circle-guanbi:before {
  content: "\e6a5";
}

.icon-udesk-qa-react-web-ic-right-circle-duigou:before {
  content: "\e6a6";
}

.icon-udesk-qa-react-web-ic-upload-shangchuan:before {
  content: "\e6df";
}

.icon-udesk-qa-react-web-ic-search-sousuo:before {
  content: "\e713";
}

.icon-udesk-qa-react-web-cloud:before {
  content: "\e6b9";
}

.icon-udesk-qa-react-web-ic-warn-jinggao:before {
  content: "\e6a9";
}

.icon-udesk-qa-react-web-ic-pause-zanting:before {
  content: "\e6f1";
}

.icon-udesk-qa-react-web-ic-play-line:before {
  content: "\e786";
}

.icon-udesk-qa-react-web-ic-ellipsis-shenglve:before {
  content: "\e72f";
}

.icon-udesk-qa-react-web-list-check:before {
  content: "\e72b";
}

.icon-udesk-qa-react-web-help-copy:before {
  content: "\e655";
}

.icon-udesk-qa-react-web-ic-notice-line:before {
  content: "\e783";
}

.icon-udesk-qa-react-web-gongzuotai:before {
  content: "\e67b";
}

.icon-udesk-qa-react-web-shangchuanxiazai:before {
  content: "\e67c";
}

.icon-udesk-qa-react-web-duihuaguanli:before {
  content: "\e67d";
}

.icon-udesk-qa-react-web-shezhi1:before {
  content: "\e67e";
}

.icon-udesk-qa-react-web-yuyizhineng:before {
  content: "\e67f";
}

.icon-udesk-qa-react-web-shujufenxi:before {
  content: "\e680";
}

.icon-udesk-qa-react-web-zhinengfenxi:before {
  content: "\e683";
}

.icon-udesk-qa-react-web-jifei:before {
  content: "\e684";
}

.icon-udesk-qa-react-web-kefuzhongxin:before {
  content: "\e688";
}

.icon-udesk-qa-react-web-tonghuaguanli:before {
  content: "\e689";
}

.icon-udesk-qa-react-web-Insight:before {
  content: "\e68b";
}

.icon-udesk-qa-react-web-xunlianzhongxin:before {
  content: "\e68c";
}

.icon-udesk-qa-react-web-ic-pause-circle-zanting1:before {
  content: "\e748";
}

.icon-udesk-qa-react-web-ic-play-circle-bofang1:before {
  content: "\e74b";
}

.icon-udesk-qa-react-web-ic-play-circle-bofang:before {
  content: "\e6a2";
}

.icon-udesk-qa-react-web-ic-pause-circle-zanting:before {
  content: "\e6a7";
}

.icon-udesk-qa-react-web-ic-arrow-down-s-xiajiantou:before {
  content: "\e6b0";
}

.icon-udesk-qa-react-web-ic-arrow-up-s-line:before {
  content: "\e6b4";
}

.icon-udesk-qa-react-web-ic-computer-diannao:before {
  content: "\e6bd";
}

.icon-udesk-qa-react-web-ic-plan-jihua:before {
  content: "\e6cd";
}

.icon-udesk-qa-react-web-ic-download-xiazai:before {
  content: "\e6e1";
}

.icon-udesk-qa-react-web-ic-volume-up-yinliang:before {
  content: "\e6e5";
}

.icon-udesk-qa-react-web-ic-volume-mute-jingyin:before {
  content: "\e6e6";
}

.icon-udesk-qa-react-web-ic-customer-service-kefu:before {
  content: "\e6f4";
}

.icon-udesk-qa-react-web-ic-send-planefasongfeiji:before {
  content: "\e6f8";
}

.icon-udesk-qa-react-web-ic-menuopen-zhankaicebianlan:before {
  content: "\e719";
}

.icon-udesk-qa-react-web-ic-menufold-shouqicebianlan1:before {
  content: "\e71b";
}

.icon-udesk-qa-react-web-ic-user-ren:before {
  content: "\e71e";
}

.icon-udesk-qa-react-web-ic-drag-tuozhuai:before {
  content: "\e732";
}

.icon-udesk-qa-react-web-ic-right-circle-duigou1:before {
  content: "\e74e";
}

.icon-udesk-qa-react-web-yichu1:before {
  content: "\e685";
}

.icon-udesk-qa-react-web-xiala:before {
  content: "\e686";
}

.icon-udesk-qa-react-web-tianjia1:before {
  content: "\e687";
}

.icon-udesk-qa-react-web-iconset0196:before {
  content: "\e660";
}

.icon-udesk-qa-react-web-add:before {
  content: "\e727";
}

.icon-udesk-qa-react-web-zidian:before {
  content: "\e679";
}

.icon-udesk-qa-react-web-delete:before {
  content: "\e60c";
}

.icon-udesk-qa-react-web-tishi:before {
  content: "\e611";
}

.icon-udesk-qa-react-web-edit:before {
  content: "\e76a";
}

.icon-udesk-qa-react-web-add1:before {
  content: "\e60a";
}

.icon-udesk-qa-react-web-baocun1:before {
  content: "\e60e";
}

.icon-udesk-qa-react-web-cuo:before {
  content: "\e6c9";
}

.icon-udesk-qa-react-web-delete_minus:before {
  content: "\e682";
}

.icon-udesk-qa-react-web-ic-arrow-down-s-xiasanjiao:before {
  content: "\e69b";
}

.icon-udesk-qa-react-web-ic-arrow-up-s-shangsanjiao:before {
  content: "\e69d";
}

.icon-udesk-qa-react-web-ic-course-kecheng:before {
  content: "\e702";
}

.icon-udesk-qa-react-web-ic-close-cha:before {
  content: "\e6ae";
}

.icon-udesk-qa-react-web-zhankaitixing:before {
  content: "\e681";
}

.icon-udesk-qa-react-web-ic-arrow-left-s-line:before {
  content: "\e6ad";
}

.icon-udesk-qa-react-web-ic-arrow-right-s-youjiantou:before {
  content: "\e6b1";
}

.icon-udesk-qa-react-web-ic-copy-fuzhi:before {
  content: "\e736";
}

.icon-udesk-qa-react-web-ic-course-kecheng1:before {
  content: "\e78e";
}

.icon-udesk-qa-react-web-ic-mic-maikefeng:before {
  content: "\e6e9";
}

.icon-udesk-qa-react-web-ic-dialogue-duihua:before {
  content: "\e6ed";
}

.icon-udesk-qa-react-web-tuozhuai1:before {
  content: "\e606";
}

.icon-udesk-qa-react-web-AI:before {
  content: "\e621";
}

.icon-udesk-qa-react-web-uparrow:before {
  content: "\e62b";
}

.icon-udesk-qa-react-web-uparrow-copy:before {
  content: "\e777";
}

.icon-udesk-qa-react-web-biangeng:before {
  content: "\e654";
}

.icon-udesk-qa-react-web-dingwei:before {
  content: "\e64a";
}

.icon-udesk-qa-react-web-tianjiaanli:before {
  content: "\e64b";
}

.icon-udesk-qa-react-web-yitianjiaanli:before {
  content: "\e64d";
}

.icon-udesk-qa-react-web-yuyibiaoqianlianxiang:before {
  content: "\e64f";
}

.icon-udesk-qa-react-web-yixuanzhong:before {
  content: "\e650";
}

.icon-udesk-qa-react-web-yinpin:before {
  content: "\e652";
}

.icon-udesk-qa-react-web-yichu:before {
  content: "\e653";
}

.icon-udesk-qa-react-web-shouqi:before {
  content: "\e647";
}

.icon-udesk-qa-react-web-zhankai:before {
  content: "\e648";
}

.icon-udesk-qa-react-web-shezhi:before {
  content: "\e646";
}

.icon-udesk-qa-react-web-baocun:before {
  content: "\e63f";
}

.icon-udesk-qa-react-web-bianji:before {
  content: "\e640";
}

.icon-udesk-qa-react-web-shanchu:before {
  content: "\e641";
}

.icon-udesk-qa-react-web-quxiao:before {
  content: "\e642";
}

.icon-udesk-qa-react-web-tianjia:before {
  content: "\e643";
}

.icon-udesk-qa-react-web-guanbi1:before {
  content: "\e644";
}

.icon-udesk-qa-react-web-tuozhuai:before {
  content: "\e645";
}

.icon-udesk-qa-react-web-kefu1:before {
  content: "\e63b";
}

.icon-udesk-qa-react-web-kehu:before {
  content: "\e63d";
}

.icon-udesk-qa-react-web-xiazaishibai:before {
  content: "\e6b3";
}

.icon-udesk-qa-react-web-jinhangzhong:before {
  content: "\e603";
}

.icon-udesk-qa-react-web-chenggong:before {
  content: "\e6fb";
}

.icon-udesk-qa-react-web-shangchuan:before {
  content: "\e6dc";
}

.icon-udesk-qa-react-web-xiazai1:before {
  content: "\e6dd";
}

.icon-udesk-qa-react-web-xindiantu:before {
  content: "\e68a";
}

.icon-udesk-qa-react-web-guanbi:before {
  content: "\e649";
}

.icon-udesk-qa-react-web-mingzhongweixieqingbaoyoujianshu:before {
  content: "\e61c";
}

.icon-udesk-qa-react-web-iconfont707:before {
  content: "\e610";
}

.icon-udesk-qa-react-web-jianhao:before {
  content: "\e627";
}

.icon-udesk-qa-react-web-dengpao:before {
  content: "\e6ba";
}

.icon-udesk-qa-react-web-dengpao1:before {
  content: "\e600";
}

.icon-udesk-qa-react-web-xiazai:before {
  content: "\e63a";
}

.icon-udesk-qa-react-web-yinliang:before {
  content: "\e602";
}

.icon-udesk-qa-react-web-icon--:before {
  content: "\e774";
}

.icon-udesk-qa-react-web-icon--1:before {
  content: "\e776";
}

.icon-udesk-qa-react-web-bofang:before {
  content: "\e6ce";
}

.icon-udesk-qa-react-web-arrow-right-copy-copy-copy:before {
  content: "\e604";
}

.icon-udesk-qa-react-web-daohang:before {
  content: "\e651";
}

.icon-udesk-qa-react-web-ren:before {
  content: "\e6e0";
}

.icon-udesk-qa-react-web-jiantou2:before {
  content: "\e605";
}

.icon-udesk-qa-react-web-play:before {
  content: "\e69a";
}

.icon-udesk-qa-react-web-icon-:before {
  content: "\e60b";
}

.icon-udesk-qa-react-web-jiqiren:before {
  content: "\e668";
}
