.spot-check-list-page {
    margin: 0;
    padding: 20px;

    .spot-check-list-header {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #ddd;

        .spot-check-list-header-title {}
    }

    .spot-check-list-body {
        position:relative;
        width:100%;
        .spot-check-list-body-search {
            margin: 20px 0;

            .spot-check-list-body-search-date {
                display: inline-block;
                width: 181px;
                margin-right: 10px;
                vertical-align: top;
            }

            .spot-check-list-body-search-checkbox-label {
                height: 34px;

                .spot-check-list-body-search-checkbox-label-input {
                    display: inline-block;
                    vertical-align: sub;
                }

                .spot-check-list-body-search-checkbox-label-span {
                    display: inline-block;
                    height: 34px;
                    line-height: 34px;
                }
            }
            .spot-check-list-body-auto-component{
                width: 200px;
                display: inline-block;
                vertical-align:top;
                position:absolute;
                z-index:1;
            }
            .spot-check-list-body-auto-component-blur{
                .css-1fcjq53{
                   max-height:26px;
                }
            }
        }

        .spot-check-list-body-list {
            position:absolute;
            width:100%;
            .component-react-table {
                .table-wrapper {
                    .columns {
                        z-index: 4;
                    }
                }
            }
        }
    }

    .spot-check-list-actions {
        color: #337ab7;
        cursor: pointer;
    }
}