h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
// li,
pre,
form,
fieldset,
legend,
label,
th,
td {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
}

address,
cite,
dfn,
em,
var {
    font-style: normal;
}

code,
kbd,
pre,
samp {
    font-family: couriernew, courier, monospace;
}

small {
    font-size: 12px;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
    background-color: transparent;
    &:hover {
        color: $app-link-hover-color;
    }
    &:active {
        outline: 0;
    }
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

legend {
    color: $app-legend-color;
}

fieldset,
img {
    border: 0;
}

div {
    box-sizing: border-box;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 100px;
    border-radius: 10px;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}
