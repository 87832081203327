.udesk-qa-ui-page-header-heading {
    .udesk-qa-ui-page-header-heading-left {
        flex: 1;

        .udesk-qa-ui-page-header-heading-title {
            flex: 1;
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;