.smart-tags-component {
    border: 1px solid #ddd;
    margin: 10px 0;
    padding: 8px 0px;
    // min-height: 50px;
    display: flex;
    align-items: center;

    .smart-tags-title {
        margin-left: 16px;
        height: 20px;
        line-height: 20px;
        display: flex;
        align-self: baseline;
        align-items: center;
        // margin-bottom: 8px;
    }
    .smart-tags-content-no-style {
        min-height: unset !important;
        margin: unset !important;

    }
    .smart-tags-content {
        // min-height: 50px;
        margin: 0 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: -5px;
        flex: 1;
        .udesk-qa-ui-empty-normal {
            margin: 0;
        }
        .smart-tags {
            max-width: 100px;
            overflow: hidden;    
            text-overflow:ellipsis;    
            white-space: nowrap;
        }
        .udesk-qa-ui-tag {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }
        .smart-tag-item {
            padding: 5px 10px;
            margin: 0 10px 5px 10px;
            cursor: pointer;
            border-radius: 4px;
        }

        .smart-tags-content-no-value {
            width: 100%;
            text-align: center;
        }
        .udesk-qa-ui-tag {
            .anticon-close {
                color: black;
            }
        }
        .udesk-qa-ui-tag-hidden {
            display: flex;
        }
    }
}

.smart-tags-component-no-style {
    border: unset;
    margin: unset;
    padding: unset;
}