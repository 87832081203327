.qa-wechat-emoji {
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    background: url('../../static/img/wechat-emoji.png') 0 0 no-repeat;
}

//华丽的分割线
.qa-wechat-emoji0 {
    background-position: 0px 0px;
}

.qa-wechat-emoji1 {
    background-position: -29px 0px;
}

.qa-wechat-emoji2 {
    background-position: -58px 0px;
}

.qa-wechat-emoji3 {
    background-position: -87px 0px;
}

.qa-wechat-emoji4 {
    background-position: -116px 0px;
}

.qa-wechat-emoji5 {
    background-position: -145px 0px;
}

.qa-wechat-emoji6 {
    background-position: -174px 0px;
}

.qa-wechat-emoji7 {
    background-position: -203px 0px;
}

.qa-wechat-emoji8 {
    background-position: -232px 0px;
}

.qa-wechat-emoji9 {
    background-position: -261px 0px;
}

.qa-wechat-emoji10 {
    background-position: -290px 0px;
}

.qa-wechat-emoji11 {
    background-position: -319px 0px;
}

.qa-wechat-emoji12 {
    background-position: -348px 0px;
}

.qa-wechat-emoji13 {
    background-position: -377px 0px;
}

.qa-wechat-emoji14 {
    background-position: -406px 0px;
}

.qa-wechat-emoji15 {
    background-position: 0px -29px;
}

.qa-wechat-emoji16 {
    background-position: -29px -29px;
}

.qa-wechat-emoji17 {
    background-position: -58px -29px;
}

.qa-wechat-emoji18 {
    background-position: -87px -29px;
}

.qa-wechat-emoji19 {
    background-position: -116px -29px;
}

.qa-wechat-emoji20 {
    background-position: -145px -29px;
}

.qa-wechat-emoji21 {
    background-position: -174px -29px;
}

.qa-wechat-emoji22 {
    background-position: -203px -29px;
}

.qa-wechat-emoji23 {
    background-position: -232px -29px;
}

.qa-wechat-emoji24 {
    background-position: -261px -29px;
}

.qa-wechat-emoji25 {
    background-position: -290px -29px;
}

.qa-wechat-emoji26 {
    background-position: -319px -29px;
}

.qa-wechat-emoji27 {
    background-position: -348px -29px;
}

.qa-wechat-emoji28 {
    background-position: -377px -29px;
}

.qa-wechat-emoji29 {
    background-position: -406px -29px;
}

.qa-wechat-emoji30 {
    background-position: 0px -58px;
}

.qa-wechat-emoji31 {
    background-position: -29px -58px;
}

.qa-wechat-emoji32 {
    background-position: -58px -58px;
}

.qa-wechat-emoji33 {
    background-position: -87px -58px;
}

.qa-wechat-emoji34 {
    background-position: -116px -58px;
}

.qa-wechat-emoji35 {
    background-position: -145px -58px;
}

.qa-wechat-emoji36 {
    background-position: -174px -58px;
}

.qa-wechat-emoji37 {
    background-position: -203px -58px;
}

.qa-wechat-emoji38 {
    background-position: -232px -58px;
}

.qa-wechat-emoji39 {
    background-position: -261px -58px;
}

.qa-wechat-emoji40 {
    background-position: -290px -58px;
}

.qa-wechat-emoji41 {
    background-position: -319px -58px;
}

.qa-wechat-emoji42 {
    background-position: -348px -58px;
}

.qa-wechat-emoji43 {
    background-position: -377px -58px;
}

.qa-wechat-emoji44 {
    background-position: -406px -58px;
}

.qa-wechat-emoji45 {
    background-position: 0px -87px;
}

.qa-wechat-emoji46 {
    background-position: -29px -87px;
}

.qa-wechat-emoji47 {
    background-position: -58px -87px;
}

.qa-wechat-emoji48 {
    background-position: -87px -87px;
}

.qa-wechat-emoji49 {
    background-position: -116px -87px;
}

.qa-wechat-emoji50 {
    background-position: -145px -87px;
}

.qa-wechat-emoji51 {
    background-position: -174px -87px;
}

.qa-wechat-emoji52 {
    background-position: -203px -87px;
}

.qa-wechat-emoji53 {
    background-position: -232px -87px;
}

.qa-wechat-emoji54 {
    background-position: -261px -87px;
}

.qa-wechat-emoji55 {
    background-position: -290px -87px;
}

.qa-wechat-emoji56 {
    background-position: -319px -87px;
}

.qa-wechat-emoji57 {
    background-position: -348px -87px;
}

.qa-wechat-emoji58 {
    background-position: -377px -87px;
}

.qa-wechat-emoji59 {
    background-position: -406px -87px;
}

.qa-wechat-emoji60 {
    background-position: 0px -116px;
}

.qa-wechat-emoji61 {
    background-position: -29px -116px;
}

.qa-wechat-emoji62 {
    background-position: -58px -116px;
}

.qa-wechat-emoji63 {
    background-position: -87px -116px;
}

.qa-wechat-emoji64 {
    background-position: -116px -116px;
}

.qa-wechat-emoji65 {
    background-position: -145px -116px;
}

.qa-wechat-emoji66 {
    background-position: -174px -116px;
}

.qa-wechat-emoji67 {
    background-position: -203px -116px;
}

.qa-wechat-emoji68 {
    background-position: -232px -116px;
}

.qa-wechat-emoji69 {
    background-position: -261px -116px;
}

.qa-wechat-emoji70 {
    background-position: -290px -116px;
}

.qa-wechat-emoji71 {
    background-position: -319px -116px;
}

.qa-wechat-emoji72 {
    background-position: -348px -116px;
}

.qa-wechat-emoji73 {
    background-position: -377px -116px;
}

.qa-wechat-emoji74 {
    background-position: -406px -116px;
}

.qa-wechat-emoji75 {
    background-position: 0px -145px;
}

.qa-wechat-emoji76 {
    background-position: -29px -145px;
}

.qa-wechat-emoji77 {
    background-position: -58px -145px;
}

.qa-wechat-emoji78 {
    background-position: -87px -145px;
}

.qa-wechat-emoji79 {
    background-position: -116px -145px;
}

.qa-wechat-emoji80 {
    background-position: -145px -145px;
}

.qa-wechat-emoji81 {
    background-position: -174px -145px;
}

.qa-wechat-emoji82 {
    background-position: -203px -145px;
}

.qa-wechat-emoji83 {
    background-position: -232px -145px;
}

.qa-wechat-emoji84 {
    background-position: -261px -145px;
}

.qa-wechat-emoji85 {
    background-position: -290px -145px;
}

.qa-wechat-emoji86 {
    background-position: -319px -145px;
}

.qa-wechat-emoji87 {
    background-position: -348px -145px;
}

.qa-wechat-emoji88 {
    background-position: -377px -145px;
}

.qa-wechat-emoji89 {
    background-position: -406px -145px;
}

.qa-wechat-emoji90 {
    background-position: 0px -174px;
}

.qa-wechat-emoji91 {
    background-position: -29px -174px;
}

.qa-wechat-emoji92 {
    background-position: -58px -174px;
}

.qa-wechat-emoji93 {
    background-position: -87px -174px;
}

.qa-wechat-emoji94 {
    background-position: -116px -174px;
}

.qa-wechat-emoji95 {
    background-position: -145px -174px;
}

.qa-wechat-emoji96 {
    background-position: -174px -174px;
}

.qa-wechat-emoji97 {
    background-position: -203px -174px;
}

.qa-wechat-emoji98 {
    background-position: -232px -174px;
}

.qa-wechat-emoji99 {
    background-position: -261px -174px;
}

.qa-wechat-emoji100 {
    background-position: -290px -174px;
}

.qa-wechat-emoji101 {
    background-position: -319px -174px;
}

.qa-wechat-emoji102 {
    background-position: -348px -174px;
}

.qa-wechat-emoji103 {
    background-position: -377px -174px;
}

.qa-wechat-emoji104 {
    background-position: -406px -174px;
}

.qa-qqface {
    zoom: calc(22 / 64);
    display: inline-block;
    vertical-align: middle;
    width: 64px;
    height: 64px;
    font-size: 0;
    text-indent: -999em;
    background: url('../../static/img/wechat-emoji-new.png') 0 0;

    &.e2_02 {
        background-position: -66px 0;
    }

    &.e2_04 {
        background-position: -462px -396px;
    }

    &.e2_05 {
        background-position: 0 -66px;
    }

    &.e2_06 {
        background-position: -66px -66px;
    }

    &.e2_09 {
        background-position: -132px 0;
    }

    &.e2_11 {
        background-position: -132px -66px;
    }

    &.e2_12 {
        background-position: 0 -132px;
    }

    &.e2_14 {
        background-position: -66px -132px;
    }

    &.smiley_0 {
        background-position: -132px -132px;
    }

    &.smiley_1 {
        width: 63px;
        // height: 64px;
        background-position: -660px -594px;
    }

    &.smiley_10 {
        background-position: -198px -66px;
    }

    &.smiley_11 {
        background-position: -198px -132px;
    }

    &.smiley_12 {
        background-position: 0 -198px;
    }

    &.smiley_13 {
        background-position: -66px -198px;
    }

    &.smiley_14 {
        background-position: -132px -198px;
    }

    &.smiley_15 {
        background-position: -198px -198px;
    }

    &.smiley_17 {
        background-position: -264px 0;
    }

    &.smiley_18 {
        background-position: -264px -66px;
    }

    &.smiley_19 {
        background-position: -264px -132px;
    }

    &.smiley_2 {
        background-position: -264px -198px;
    }

    &.smiley_20 {
        background-position: 0 -264px;
    }

    &.smiley_21 {
        background-position: -66px -264px;
    }

    &.smiley_22 {
        background-position: -132px -264px;
    }

    &.smiley_23 {
        background-position: -198px -264px;
    }

    &.smiley_25 {
        background-position: -264px -264px;
    }

    &.smiley_26 {
        background-position: -330px 0;
    }

    &.smiley_27 {
        background-position: -330px -66px;
    }

    &.smiley_28 {
        background-position: -330px -132px;
    }

    &.smiley_29 {
        background-position: -330px -198px;
    }

    &.smiley_3 {
        background-position: -330px -264px;
    }

    &.smiley_30 {
        background-position: 0 -330px;
    }

    &.smiley_31 {
        background-position: -66px -330px;
    }

    &.smiley_313 {
        background-position: -132px -330px;
    }

    &.smiley_314 {
        background-position: -198px -330px;
    }

    &.smiley_315 {
        background-position: -264px -330px;
    }

    &.smiley_316 {
        background-position: -330px -330px;
    }

    &.smiley_317 {
        background-position: -396px 0;
    }

    &.smiley_318 {
        background-position: -396px -66px;
    }

    &.smiley_319 {
        background-position: -396px -132px;
    }

    &.smiley_32 {
        background-position: -396px -198px;
    }

    &.smiley_320 {
        background-position: -396px -264px;
    }

    &.smiley_321 {
        background-position: -396px -330px;
    }

    &.smiley_322 {
        background-position: 0 -396px;
    }

    &.smiley_33 {
        background-position: -66px -396px;
    }

    &.smiley_34 {
        background-position: -132px -396px;
    }

    &.smiley_36 {
        background-position: -198px -396px;
    }

    &.smiley_37 {
        background-position: -264px -396px;
    }

    &.smiley_38 {
        background-position: -330px -396px;
    }

    &.smiley_39 {
        background-position: -396px -396px;
    }

    &.smiley_4 {
        background-position: -462px 0;
    }

    &.smiley_40 {
        background-position: -462px -66px;
    }

    &.smiley_41 {
        background-position: -462px -132px;
    }

    &.smiley_42 {
        background-position: -462px -198px;
    }

    &.smiley_44 {
        background-position: -462px -264px;
    }

    &.smiley_45 {
        background-position: -462px -330px;
    }

    &.smiley_46 {
        background-position: 0 0;
    }

    &.smiley_47 {
        background-position: 0 -462px;
    }

    &.smiley_48 {
        background-position: -66px -462px;
    }

    &.smiley_49 {
        background-position: -132px -462px;
    }

    &.smiley_5 {
        background-position: -198px -462px;
    }

    &.smiley_50 {
        background-position: -264px -462px;
    }

    &.smiley_51 {
        background-position: -330px -462px;
    }

    &.smiley_52 {
        background-position: -396px -462px;
    }

    &.smiley_54 {
        background-position: -462px -462px;
    }

    &.smiley_55 {
        background-position: -528px 0;
    }

    &.smiley_56 {
        background-position: -528px -66px;
    }

    &.smiley_57 {
        background-position: -528px -132px;
    }

    &.smiley_6 {
        background-position: -528px -198px;
    }

    &.smiley_60 {
        background-position: -528px -264px;
    }

    &.smiley_61 {
        background-position: -528px -330px;
    }

    &.smiley_62 {
        background-position: -528px -396px;
    }

    &.smiley_63 {
        background-position: -528px -462px;
    }

    &.smiley_64 {
        background-position: 0 -528px;
    }

    &.smiley_65 {
        background-position: -66px -528px;
    }

    &.smiley_66 {
        background-position: -132px -528px;
    }

    &.smiley_67 {
        background-position: -198px -528px;
    }

    &.smiley_68 {
        background-position: -264px -528px;
    }

    &.smiley_7 {
        background-position: -330px -528px;
    }

    &.smiley_70 {
        background-position: -396px -528px;
    }

    &.smiley_74 {
        background-position: -462px -528px;
    }

    &.smiley_75 {
        background-position: -528px -528px;
    }

    &.smiley_76 {
        background-position: -594px 0;
    }

    &.smiley_78 {
        background-position: -594px -66px;
    }

    &.smiley_79 {
        background-position: -594px -132px;
    }

    &.smiley_8 {
        background-position: -594px -198px;
    }

    &.smiley_80 {
        background-position: -594px -264px;
    }

    &.smiley_81 {
        background-position: -594px -330px;
    }

    &.smiley_82 {
        background-position: -594px -396px;
    }

    &.smiley_83 {
        background-position: -594px -462px;
    }

    &.smiley_84 {
        background-position: -594px -528px;
    }

    &.smiley_85 {
        background-position: 0 -594px;
    }

    &.smiley_89 {
        background-position: -66px -594px;
    }

    &.smiley_9 {
        background-position: -132px -594px;
    }

    &.smiley_92 {
        background-position: -198px -594px;
    }

    &.smiley_93 {
        background-position: -264px -594px;
    }

    &.smiley_94 {
        background-position: -330px -594px;
    }

    &.smiley_95 {
        background-position: -396px -594px;
    }

    &.u1F381 {
        background-position: -462px -594px;
    }

    &.u1F389 {
        background-position: -528px -594px;
    }

    &.u1F47B {
        background-position: -594px -594px;
    }

    &.u1F4AA {
        background-position: -660px 0;
    }

    &.u1F602 {
        background-position: -660px -66px;
    }

    &.u1F604 {
        background-position: -660px -132px;
    }

    &.u1F612 {
        background-position: -660px -198px;
    }

    &.u1F614 {
        background-position: -660px -264px;
    }

    &.u1F61D {
        background-position: -660px -330px;
    }

    &.u1F631 {
        background-position: -660px -396px;
    }

    &.u1F633 {
        background-position: -660px -462px;
    }

    &.u1F637 {
        background-position: -198px 0;
    }

    &.u1F64F {
        background-position: -660px -528px;
    }

    &.smiley_400 {
        background-position: -264px 0;
    }
}
