.pages-study-analysis-container {
    .search-condition {
        margin-bottom: 16px;
        & > h3 {
            margin-top: 16px;
        }
    }
    .last-month-data {
        position: relative;
        .last-line-location {
            width: 340px;
            border-top: 2px solid #E8E8E8;
        }
        .last-month-date {
            display: flex;
            justify-content: space-between;
            margin-top: 4px;
        }
        .call-detail-tips {
            position: absolute;
            top: 0;
        }
    }
    .statistical-quantity {
        font-size: 18px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 700;
    }
    .rise-color {
        color: #11A848;
        font-size: 12px;
        line-height: 20px;
    }
    .decline-color {
        font-size: 12px;
        line-height: 20px;
        color: #FB759D;
    }
    .udesk-qa-ui-table-pagination {
        margin: 16px 0 0;
    }
}