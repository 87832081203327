.component-model-loading-text,
.component-model-error {
    position: relative;
    min-width: 100%;
    min-height: 100px;

    .text-content {
        position: absolute;
    }

    &.left {
        .text-content {
            left: 0;
        }
    }

    &.center {
        .text-content {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.right {
        .text-content {
            right: 0;
        }
    }

    &.middle {
        .text-content {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.bottom {
        .text-content {
            bottom: 0;
        }
    }

    &.center.middle {
        .text-content {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.component-model-loading-animation {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 200px;
}
