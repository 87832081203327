.ppt-thumbnail-list {
    max-width: 327px;
    
    &,
    > div,
    > div> div,
    > div> div> div,
    > div> div> div> div,
    > div> div> div> div> div {
      height: 100%;
    }
  
    > div> div> div> div> div> div.udesk-qa-ui-table-body {
        height: calc(100% - 59px);
    }

    .udesk-qa-ui-table-header {
        th {
            background-color: #fff !important;
        }
    }

    .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected td {
        background-color: #fff;

        .udesk-qa-ui-image {
            outline: 2px solid #1A6EFF;
        }
    }

    .ppt-thumbnail-list-title {
        display: flex;
        justify-content: space-between;

        span {
            color: #1A6EFF;
        }
    }

    .ppt-thumbnail-item {
        position: relative;

        .ppt-index {
            position: absolute;
            top: 0;
            left: 1px;
        }

        .ipad-ppt-index {
            position: absolute;
            top: 0;
            left: -9px;
            z-index: 2;
            width: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;