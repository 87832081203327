.appeal-history-component {
    width: 100%;
    .appeal-history-component-item {
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .appeal-history-component-item-body {
            font-size: 12px;
            line-height: 24px;
            .appeal-history-component-item-body-row {
                
                display: flex;

                &.space-between {
                    justify-content: space-between;
                }
                .appeal-history-component-item-body-row-label {
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 500;
                }
                .appeal-history-component-item-body-row-value {
                    flex: 1;
                    margin-left: 8px;
                    color: rgba(0, 0, 0, 0.65);
                }
                .appeal-history-component-item-body-row-buttons {
                    margin-left: 8px;
                    .udesk-qa-ui-btn {
                        padding: 0;
                    }
                }
            }
        }
    }
}
.appeal-history-component-detail-modal {
    .udesk-qa-ui-modal-body {
        max-height: 406px;
        overflow-y: auto;
    }
    .appeal-history-component-detail-item {
        margin-bottom: 16px;
        .appeal-history-component-detail-item-title {
            background: rgba(0, 0, 0, 0.03);
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            padding: 5px 8px;
            margin-bottom: 8px;
        }
        .appeal-history-component-detail-item-content {
            margin-top: 4px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            .appeal-history-component-detail-item-content-value {
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}