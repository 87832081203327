.udesk-qa-web-coach-dashboard {
    background: #e8f0fc !important;

    .udesk-qa-web-page-body {
        .header-title {
            border-radius: 4px;
            .title {
                font-size: 14px;
                line-height: 22px;
            }
            .company {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.65);
                margin-top: 2px;
            }
        }
        .task-progress-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }
    }
}
