.role-detail-data-permissions-page {
    height: 100%;
    .role-detail-data-permissions-page-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
    }
}