.customer-portrait {
    height: 100%;
    .customer-portrait-index-page {
        background: #f1f1f1;
        display: flex;
        flex-direction: column;
        height: 100%;
        .customer-portrait-index-page-list-header {
            .customer-portrait-index-page-search {
                height: auto;
                padding: 10px;
            }
        }
        .customer-portrait-index-page-body-list {
            display: flex;
            flex: 1;
            height: calc(100% - 52px);
            .customer-portrait-index-page-list-wrapper {
                display: flex;
                flex-direction: column;
                width: 280px;
                background: rgba(0, 0, 0, 0.02);
                border: 1px solid rgba(0, 0, 0, 0.1);

                .customer-portrait-index-page-list {
                    position: relative;
                    height: 1%;
                    flex: 1;
                    overflow-x: hidden;
                    overflow-y: auto;
                    border-top: 1px solid #f1f1f1;
                    .async-model-error-msg-tip {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: #ccc;
                    }
                    .udesk-loading-animation {
                        position: absolute;
                    }
                    .customer-item {
                        user-select: none;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        border-bottom: 1px solid #f1f1f1;
                        transition: all 0.1s ease-out;
                        .customer-item-bottom {
                            margin-top: 5px;
                        }
                        .customer-item-user-id {
                            font-weight: 500;
                            font-size: 13px;
                        }
                    }
                    .customer-item:hover {
                        background: #eef4ff;
                        box-shadow: 0px 2px 4px 1px rgba(92, 139, 220, 0.3);
                    }
                    .customer-item.active {
                        background: #2878ff;
                        border-bottom: 1px solid #2878ff;
                        color: #fff;
                    }
                    .customer-not-found {
                        user-select: none;
                        color: #ccc;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .customer-portrait-index-pager {
                    position: relative;
                    box-shadow: 0px -2px 10px rgba(130, 130, 130, 0.1);
                    user-select: none;
                    height: auto;
                    padding: 15px 10px;
                    .customer-portrait-index-pager-first-and-final {
                        border-radius: 2px;
                        font-weight: bold;
                        opacity: 0;
                        cursor: pointer;
                        display: flex;
                        font-size: 12px;
                        color: #fff;
                        background: #0cc2aa;
                        position: absolute;
                        z-index: 3;
                        top: 50%;
                        left: 20px;
                        transform: translate(0, -50%);
                        width: 94px;
                        max-width: 95px;
                        height: 27px;
                        line-height: 27px;
                        // background: #fff;
                        transition: all 0.15s ease-in;
                        .jump-pager-btn {
                            width: 50%;
                            text-align: center;
                            cursor: pointer;
                        }
                        .first-pager-btn {
                            border-right: 1px solid #fff;
                        }
                    }
                    .customer-portrait-index-pager-first-and-final:hover {
                        opacity: 1;
                    }
                    .udesk-pager {
                        // position: relative;
                        width: calc(100% + 100% * 0.2);
                        margin-left: calc(100% * 0.2 * (-1));
                        transform: scale(0.8);
                        transform-origin: right;
                        .pager-left {
                            min-width: 115px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            padding: 6px;
                            border: 1px dashed #ccc;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            .customer-portrait-index-page-content {
                background: #fff;
                padding: 0 8px;
                width: calc(100% - 280px);
                .customer-portrait-index-page-body {
                    flex: 1;
                    height: 100%;
                    padding-bottom: 10px;
                    .page-body-top-wrapper {
                        display: flex;
                        height: 100%;
                        .customer-portrait-timeline-list {
                            height: 100%;
                            overflow-y: auto;
                            padding-top: 4px;
                        }
                        .customer-portrait-timeline-item {
                            padding-bottom: 10px;
                        }
                        .customer-portrait-timeline-item-header {
                            color: rgba(0, 0, 0, 0.45);
                            cursor: pointer;
                            transition: color .3s;
                            
                            &:hover {
                                color: #1890ff;
                            }
                        }
                        .customer-portrait-timeline-item-info {
                            padding: 8px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 2px;
                            width: 100%;
                            display: flex;
                            align-items: center;

                            .customer-portrait-timeline-item-info-banner {
                                padding-right: 8px;
                                .customer-portrait-timeline-item-info-banner-emoties {
                                    display: inline-flex;
                                    align-items: center;

                                }
                            }
                            .customer-portrait-timeline-item-info-content {
                                width: calc(100% - 43px);
                                height: 100%;
                                min-height: 55px;
                                border-left: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                        .customer-portrait-business-tag {
                            display: inline-block;
                            padding: 2px 5px;
                            border-radius: 3px;
                            margin: 0 4px 8px 4px;
                        }
                        .customer-portrait-index-page-body-list-info {
                            height: 100%;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            .customer-portrait-index-page-body-list-info-header {
                                height: 39px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                background: rgba(0, 0, 0, 0.02);
                                padding-left: 16px;
                                color: rgba(0, 0, 0, 0.85);
                                line-height: 40px;
                                font-size: 600;
                            }
                            .customer-portrait-index-page-body-list-info-content {
                                padding: 8px;
                                height: calc(100% - 40px);
                                overflow-y: auto;
                            }
                            .page-body-key-words-statistics {
                                // 关键词统计
                                .key-words-statistics-content {
                                    flex: 1;
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                    position: relative;
                                    padding: 10px;
                                    overflow-x: hidden;
                                    overflow-y: auto;
                                    li.key-word-item {
                                        user-select: none;
                                        padding: 0 10px 8px 0;
                                    }
                                }
                            }
                            .key-words-cloud-content {
                                position: relative;
                                height: 100%;
                                .key-words-cloud {
                                    .report-echarts-main {
                                        border: none;
                                    }
                                }
                                .not-word-cloud-init {
                                    color: #f9f9f9;
                                    font-size: 100px;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                        // .page-body-key-words-and-business {

                        // }
                        // .page-body-key-words-cloud {
                        //     display: flex;
                        //     flex-direction: column;
                        //     flex: 1;
                        //     margin-left: 10px;
                        //     background: #fff;
                        //     border: 1px solid #f1f1f1;

                        // }
                    }
                }
            }
        }
        .udesk-qa-customize-pagination {
            padding-bottom: 8px;
            padding-top: 0;
        }
        .key-words-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 50%;
            border: 1px solid #f1f1f1;
            background: #fff;
        }
        .key-words-wrapper-title {
            padding: 8px 10px;
            font-size: 12px;
            font-weight: bold;
        }

        .customer-item-row {
            display: flex;
            .customer-item-row-item {
                width: 1%;
                max-width: 135px;
                flex: 1;
                font-size: 12px;
                .customer-item-title {
                    margin-right: 2px;
                }
            }
        }

        .text-right {
            text-align: right;
        }

        .no-content-tip {
            user-select: none;
            border: 1px dashed #d7dbe0;
            padding: 10px 16px;
            color: #ccc;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
