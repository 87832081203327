.udesk-qa-course-real-time-analyzer {
    display: flex;
    flex-flow: row-reverse;

    > div {
        padding: 0 48px 12px 12px;
        width: 80%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .udesk-qa-course-real-time-analyzer-item {
        font-size: 14px;
        background-color: #eee;
        padding: 2px 0;
        margin-top: 12px;

        &:nth-child(n + 1) {
            margin-left: 12px;
        }

        > div:nth-child(1) {
            .udesk-qa-course-real-time-analyzer-item-title {
                background-color: #2075ff;
                padding: 0 12px;
                color: #fff;
                white-space: nowrap;

                &.red {
                    background-color: #ff5500;
                }

                &.cyan {
                    background-color: #2db7f5;
                }

                &.gold {
                    background-color: #ffe58f;
                    color: #d48806;
                }

                &.hotpink {
                    background-color: hotpink;
                }
            }
        }

        > div:nth-child(n + 1) {
            .udesk-qa-course-real-time-analyzer-hit-item {
                padding: 0 12px;
                color: #666;
                display: inline-flex;

                > span:nth-child(1) {
                    margin-right: 7px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 120px;
                    vertical-align: bottom;
                }

                > span:nth-child(2) {
                    font-size: 12px;

                    &.svg-green svg {
                        color: #389e0d;
                    }

                    &.svg-red svg {
                        color: #ff4d4f;
                    }
                }
            }
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;