.reactflow-wrapper {
    .react-flow__attribution {
        display: none;
    }
    .control-button-bar {
        height: 36px;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        .react-flow__controls-button {
            min-width: 16px;
            width: auto;
            border-bottom: none;
            white-space: nowrap;
        }
        .udesk-qa-ui-divider-vertical {
            border-left-color: rgba(0, 0, 0, 0.1);
        }
    }
}
.data-preprocess-custom {
    position: relative;
    .index-manmagement-custom-drag-tarsk {
        position: absolute;
        text-align: center;
        top: -41px;
        z-index: 1;
        padding: 10px;
        background: rgba(0, 0, 0, 0.5);
        left: 50%;
        width: 200px;
        margin-left: -100px;
    }
    .data-preprocess-cutom-drap-info {
        //   display: flex;
        width: calc(100% - 42px);
    }
    // display: flex;
}
