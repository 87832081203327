.wrong-question-exercise-other-panel {
  .cue-panel {
    height: 100%;
    display: flex;
    flex-flow: column;

    .cue-title {
      padding: 16px;
      flex: 0;

      > div {
        position: relative;
        display: inline;

        > span {
          position: relative;
          font-size: 15px;
          font-weight: 600;
          position: relative;
          z-index: 1000;
        }

        &::after {
          position: absolute;
          content: '';
          height: 6px;
          left: 0;
          right: 0;
          bottom: 3px;
          z-index: 1;
          border-radius: 6px;
          background-color: #bddeff;
        }
      }

      > button {
        float: right;
      }
    }

    .cue-list {
      display: flex;
      overflow: auto;
      padding: 2px 16px 16px 16px;
      flex: 1;

      > button {
        padding-left: 0px;
        padding-right: 15px;
        white-space: break-spaces;
        text-align: left;
      }
    }

    .cue-tip {
      padding-top: 16px;
      
      > div {
        span:first-child{
          &::after {
            content: '：';
          }
        }
      }
    }

    .udesk-qa-ui-empty {
      margin: auto 0;
    }
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;