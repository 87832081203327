.component-react-table {
  position: relative;
}
.component-react-table.invisible {
  display: none;
  position: fixed;
  z-index: 1;
}
.component-react-table::before {
  display: table;
  content: " ";
}
.component-react-table::after {
  display: table;
  content: " ";
  clear: both;
}
.component-react-table .table-wrapper {
  float: left;
  min-width: 100%;
  margin-bottom: 20px;
  background: #fff;
}
.component-react-table .table-wrapper .data-table-wrapper,
.component-react-table .table-wrapper .frozen-table-wrapper,
.component-react-table .table-wrapper .frozen-table-column-wrapper,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper {
  float: left;
  min-width: 100%;
}
.component-react-table .table-wrapper .data-table-wrapper > table,
.component-react-table .table-wrapper .frozen-table-wrapper > table,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table {
  border: 1px solid rgba(221, 221, 221, 0.78);
  margin-bottom: 0;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.stacked-header-row > th,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.stacked-header-row > th,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.stacked-header-row > th,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.stacked-header-row > th {
  text-align: center;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row {
  background: #fff;
  color: #555;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th.checkbox-col,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th.checkbox-col,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th.checkbox-col,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th.checkbox-col {
  width: 50px;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper {
  display: table;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body {
  display: table-row;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item {
  display: table-cell;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions {
  text-align: right;
  padding-left: 5px;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions .action-item,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions .action-item,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions .action-item,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body .header-cell-body-item.table-actions .action-item {
  font-size: 13px;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon- .table-actions .action-item .sort-icon.sorting-none, .component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover .table-actions .action-item .sort-icon.sorting-none, .component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-always .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon- .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-always .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon- .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-always .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon- .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-always .table-actions .action-item .sort-icon.sorting-none {
  visibility: hidden;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover:hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover:hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover:hover .table-actions .action-item .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr.header-row > th .header-cell-wrapper .header-cell-body.hide-no-sorting-icon-hover:hover .table-actions .action-item .sort-icon.sorting-none {
  visibility: visible;
}
.component-react-table .table-wrapper .data-table-wrapper > table.sortable > thead > tr > th.sortable,
.component-react-table .table-wrapper .frozen-table-wrapper > table.sortable > thead > tr > th.sortable,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table.sortable > thead > tr > th.sortable,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table.sortable > thead > tr > th.sortable {
  cursor: pointer;
}
.component-react-table .table-wrapper .data-table-wrapper > table.sortable > thead > tr > th.sortable .sort-icon,
.component-react-table .table-wrapper .frozen-table-wrapper > table.sortable > thead > tr > th.sortable .sort-icon,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table.sortable > thead > tr > th.sortable .sort-icon,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table.sortable > thead > tr > th.sortable .sort-icon {
  color: #333333;
}
.component-react-table .table-wrapper .data-table-wrapper > table.sortable > thead > tr > th.sortable .sort-icon:not(.sorting-none),
.component-react-table .table-wrapper .frozen-table-wrapper > table.sortable > thead > tr > th.sortable .sort-icon:not(.sorting-none),
.component-react-table .table-wrapper .frozen-table-column-wrapper > table.sortable > thead > tr > th.sortable .sort-icon:not(.sorting-none),
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table.sortable > thead > tr > th.sortable .sort-icon:not(.sorting-none) {
  color: #333333;
}
.component-react-table .table-wrapper .data-table-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon,
.component-react-table .table-wrapper .frozen-table-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon {
  color: #333333;
}
.component-react-table .table-wrapper .data-table-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon.sorting-none,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table.sortable > thead > tr > th.sortable:hover .sort-icon.sorting-none {
  color: #b4c3d3;
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr > td.sorting,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr > td.sorting,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr > td.sorting,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr > td.sorting {
  background-color: #fef8e3;
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr:hover > td, .component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.row-item-hover > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr:hover > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.row-item-hover > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr:hover > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.row-item-hover > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr:hover > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.row-item-hover > td {
  background: rgba(38, 185, 154, 0.07);
  border-top: 1px solid rgba(38, 185, 154, 0.11);
  border-bottom: 1px solid rgba(38, 185, 154, 0.11);
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr:hover > td.sorting, .component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.row-item-hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr:hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.row-item-hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr:hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.row-item-hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr:hover > td.sorting,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.row-item-hover > td.sorting {
  background-color: #fef8e3;
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.selected,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.selected,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.selected,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.selected {
  background: rgba(38, 185, 154, 0.16);
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.selected > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.selected > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.selected > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.selected > td {
  border-top: 1px solid rgba(38, 185, 154, 0.4);
  border-bottom: 1px solid rgba(38, 185, 154, 0.4);
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.row-clickable,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.row-clickable,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.row-clickable,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.row-clickable {
  cursor: pointer;
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.active-item > td:first-child,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.active-item > td:first-child,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.active-item > td:first-child,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.active-item > td:first-child {
  position: relative;
}
.component-react-table .table-wrapper .data-table-wrapper > table tbody > tr.active-item > td:first-child:before,
.component-react-table .table-wrapper .frozen-table-wrapper > table tbody > tr.active-item > td:first-child:before,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table tbody > tr.active-item > td:first-child:before,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table tbody > tr.active-item > td:first-child:before {
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  border-left: 3px solid #0cc2aa;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .data-table-wrapper > table > tbody > tr > th,
.component-react-table .table-wrapper .data-table-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper .data-table-wrapper > table > tfoot > tr > th,
.component-react-table .table-wrapper .data-table-wrapper > table > tfoot > tr > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table > tbody > tr > th,
.component-react-table .table-wrapper .frozen-table-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table > tfoot > tr > th,
.component-react-table .table-wrapper .frozen-table-wrapper > table > tfoot > tr > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > tbody > tr > th,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > tfoot > tr > th,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > tfoot > tr > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > tbody > tr > th,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > tfoot > tr > th,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > tfoot > tr > td {
  vertical-align: middle;
}
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .data-table-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-column-wrapper > table > thead > tr > td,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper .frozen-table-header-column-wrapper > table > thead > tr > td {
  border-bottom-width: 1px;
}
.component-react-table .table-wrapper.vertical-scrollbar {
  height: 100%;
  overflow-y: auto;
}
.component-react-table .table-wrapper.horizontal-scrollbar {
  width: 100%;
  overflow-x: auto;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table {
  width: auto;
  min-width: 100%;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table {
  table-layout: fixed;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > colgroup > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > colgroup > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > colgroup > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > colgroup > col.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > col.data-col {
  max-width: 290px;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > thead > tr > th,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > thead > tr > th:last-child {
  border-right: 1px solid #fff;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > tbody > tr > td,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > tbody > tr > td {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > tbody > tr > td.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > tbody > tr > td.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > tbody > tr > td.data-col,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > tbody > tr > td.data-col {
  max-width: 290px;
}
.component-react-table .table-wrapper.horizontal-scrollbar .data-table-wrapper > table > tbody > tr > td.data-col .radio-image,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-wrapper > table > tbody > tr > td.data-col .radio-image,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-column-wrapper > table > tbody > tr > td.data-col .radio-image,
.component-react-table .table-wrapper.horizontal-scrollbar .frozen-table-header-column-wrapper > table > tbody > tr > td.data-col .radio-image {
  width: 20px;
  height: 20px;
}
.component-react-table .table-wrapper.choose-columns .data-table-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-column-wrapper > table > thead > tr > th:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-header-column-wrapper > table > thead > tr > th:last-child {
  padding-right: 46px;
}
.component-react-table .table-wrapper.choose-columns .data-table-wrapper > table > tbody > tr > td:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-wrapper > table > tbody > tr > td:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-column-wrapper > table > tbody > tr > td:last-child,
.component-react-table .table-wrapper.choose-columns .frozen-table-header-column-wrapper > table > tbody > tr > td:last-child {
  padding-right: 46px;
}
.component-react-table .table-wrapper.freeze-header.vertical-scrollbar, .component-react-table .table-wrapper.freeze-columns.vertical-scrollbar, .component-react-table .table-wrapper.frozen-header-column-table.vertical-scrollbar {
  overflow-y: hidden;
  height: 100%;
}
.component-react-table .table-wrapper.freeze-header.horizontal-scrollbar, .component-react-table .table-wrapper.freeze-columns.horizontal-scrollbar, .component-react-table .table-wrapper.frozen-header-column-table.horizontal-scrollbar {
  overflow-x: hidden;
  width: 100%;
}
.component-react-table .table-wrapper.freeze-header .frozen-table-wrapper,
.component-react-table .table-wrapper.freeze-header .frozen-table-header-column-wrapper, .component-react-table .table-wrapper.freeze-columns .frozen-table-wrapper,
.component-react-table .table-wrapper.freeze-columns .frozen-table-header-column-wrapper, .component-react-table .table-wrapper.frozen-header-column-table .frozen-table-wrapper,
.component-react-table .table-wrapper.frozen-header-column-table .frozen-table-header-column-wrapper {
  position: relative;
}
.component-react-table .table-wrapper.freeze-header .frozen-table-wrapper > table,
.component-react-table .table-wrapper.freeze-header .frozen-table-header-column-wrapper > table, .component-react-table .table-wrapper.freeze-columns .frozen-table-wrapper > table,
.component-react-table .table-wrapper.freeze-columns .frozen-table-header-column-wrapper > table, .component-react-table .table-wrapper.frozen-header-column-table .frozen-table-wrapper > table,
.component-react-table .table-wrapper.frozen-header-column-table .frozen-table-header-column-wrapper > table {
  position: absolute;
  z-index: 1;
  background: #fff;
}
.component-react-table .table-wrapper.freeze-header .data-table-wrapper, .component-react-table .table-wrapper.freeze-columns .data-table-wrapper, .component-react-table .table-wrapper.frozen-header-column-table .data-table-wrapper {
  height: 100%;
}
.component-react-table .table-wrapper.freeze-header.vertical-scrollbar .data-table-wrapper, .component-react-table .table-wrapper.freeze-columns.vertical-scrollbar .data-table-wrapper, .component-react-table .table-wrapper.frozen-header-column-table.vertical-scrollbar .data-table-wrapper {
  overflow-y: auto;
}
.component-react-table .table-wrapper.freeze-header.horizontal-scrollbar .data-table-wrapper, .component-react-table .table-wrapper.freeze-columns.horizontal-scrollbar .data-table-wrapper, .component-react-table .table-wrapper.frozen-header-column-table.horizontal-scrollbar .data-table-wrapper {
  overflow-x: auto;
  width: 100%;
}
.component-react-table .table-wrapper.freeze-header .data-table-wrapper > table > thead {
  opacity: 0;
  visibility: hidden;
}
.component-react-table .table-wrapper.freeze-columns .frozen-table-column-wrapper,
.component-react-table .table-wrapper.freeze-columns .frozen-table-header-column-wrapper, .component-react-table .table-wrapper.freeze-header-columns .frozen-table-column-wrapper,
.component-react-table .table-wrapper.freeze-header-columns .frozen-table-header-column-wrapper {
  position: relative;
}
.component-react-table .table-wrapper.freeze-columns .frozen-table-column-wrapper > table,
.component-react-table .table-wrapper.freeze-columns .frozen-table-header-column-wrapper > table, .component-react-table .table-wrapper.freeze-header-columns .frozen-table-column-wrapper > table,
.component-react-table .table-wrapper.freeze-header-columns .frozen-table-header-column-wrapper > table {
  position: absolute;
  z-index: 1;
  width: auto;
  background: #fff;
  border-right: none;
}
.component-react-table .table-wrapper.freeze-columns .frozen-table-column-wrapper, .component-react-table .table-wrapper.freeze-header-columns .frozen-table-column-wrapper {
  overflow: hidden;
  position: absolute;
  max-width: initial;
  min-width: initial;
}
.component-react-table .table-wrapper .columns {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 2;
}
.component-react-table .table-wrapper .columns .columns-trigger {
  color: #555;
  background: #fff;
}
.component-react-table .table-wrapper .columns .columns-trigger:hover {
  color: #a0c2ff;
}
.component-react-table .table-wrapper .columns .columns-trigger .trigger-icon {
  font-size: 22px;
  padding-left: 16px;
  padding-right: 8px;
}
.component-react-table .table-wrapper .columns .columns-wrapper {
  position: relative;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 4;
  top: 10px;
  right: 0;
  width: 180px;
  max-height: 350px;
  padding: 8px;
  margin-bottom: 0;
  color: #333;
  background-color: #f9f9f9;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: -5px 5px 6px #bbb;
  cursor: default;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list .column-item {
  background: none;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list .column-item .checkbox {
  margin-top: 0;
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list .column-item .checkbox .column-label {
  width: 100%;
  cursor: pointer;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list .column-item .checkbox .column-label .ember-checkbox {
  margin-top: 2px;
}
.component-react-table .table-wrapper .columns .columns-wrapper .columns-list .column-item:hover {
  color: #333;
  background: #eaf2f5;
}

