.common-qc-report-echarts{
    .trend-group-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        .group-item {
            flex: 1;
            padding: 20px 15px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-top:none;
            &.group-item-active {
                color: #fff;
                background-color: #00cbac;
                border: none;
            }
            &+.group-item {
                border-left: none;
            }
            .group-item-content-item {
                width: 100%;
            }
        }
    }
}