.qa-page-admin-systom-index {
    .form-group {
        .control-label {
            text-align: right;
            min-height: 30px;
            height: auto;
        }
    }
    .udesk-qa-list-item {
        .udesk-qa-ui-form-builder-container {
            flex: 1;
            background: rgba(0, 0, 0, 0.03);
            padding: 16px;
            max-width: 440px;
        }
    }
}
