.call-text-list-container {
    .call-text-list {
        padding: 15px 4px;
        min-width: 250px;

        .call-text-list-item {
            margin-bottom: 2px;
            display: flex;
            padding: 4px 8px;
            border-radius: 3px;
            width: fit-content;

            &:hover {
                background-color: #e6f4ff;
            }

            .call-text-list-item-title {
                display: inline-block;
                padding-right: 12px;
                width: 110px;
                text-align: right;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;

                &::after {
                    content: ':';
                    right: 6px;
                    position: absolute;
                }
            }

            .call-text-list-item-content {
                display: inline-block;
                vertical-align: top;
                // max-width: calc(100% - 110px);
                flex: 1;
                word-wrap: break-word;
                word-break: break-all;

                .call-text-list-item-content-with-copy {
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}

.udesk-qa-component-element[lang='en'] {
    .call-text-list-item-title {
        width: 180px !important;
    }
}
