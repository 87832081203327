.approve-reject-button {

    .udesk-qa-ui-popover-inner-content {
        padding: 0;
    }

    .udesk-qa-ui-popover-message-title {
        padding: 0;
    }

    .udesk-qa-ui-popover-buttons {
        padding: 0 10px 10px;
    }
    .udesk-qa-ui-col.udesk-qa-ui-form-item-label {
        padding: 2px 10px 6px;
        border-bottom: 1px solid #eee;
    }
    .udesk-qa-ui-col.udesk-qa-ui-form-item-control {
        padding: 20px 10px 0 10px;
    }
}