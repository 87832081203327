$react-alerts-header-height:58px !default;
$react-alerts-header-fontSize:18px !default;
$react-alerts-header-fontColor:#fff !default;
$react-alerts-header-confirm-backgroundColor:#00B38B !default;
$react-alerts-header-warning-backgroundColor:#df8a13 !default;
$react-alerts-header-error-backgroundColor: #d9534f !default;
$react-alerts-header-paddingLeft:20px !default;
$react-alerts-header-paddingRight:20px !default;
$react-alerts-header-icon-bi-close-hoverColor:#5b6875 !default;
$react-alerts-content-minHeight:80px !default;
$react-alerts-content-padding:20px !default;
$react-alerts-footer-height:64px !default;
$react-alerts-footer-backgroundColor:#F4F5F9 !default;
$react-alerts-footer-btn-width:106px !default;
$react-alerts-footer-btn-margin:0 15px !default;
$react-alerts-footer-btn-cancel-color:#666666 !default;
$react-alerts-footer-btn-cancel-borderColor:#E5E5E5 !default;
$react-alerts-footer-btn-cancel-backgroundColor:#fff !default;
$react-alerts-footer-btn-cancel-hoverBorderColor:#c2c2c2 !default;
$react-alerts-footer-btn-cancel-hoverBackgroundColor:#e6e6e6 !default;
$react-alerts-footer-btn-ok-color:#fff !default;
$react-alerts-footer-confirm-btn-ok-backgroundColor:#00B38B !default;
$react-alerts-footer-confirm-btn-ok-borderColor:#00B38B !default;
$react-alerts-footer-confirm-btn-ok-hoverBorderColor:darken($react-alerts-footer-confirm-btn-ok-backgroundColor, 15%) !default;
$react-alerts-footer-confirm-btn-ok-hoverBackgroundColor:darken($react-alerts-footer-confirm-btn-ok-borderColor, 15%) !default;
$react-alerts-footer-warning-btn-ok-backgroundColor:#df8a13 !default;
$react-alerts-footer-warning-btn-ok-borderColor:#df8a13 !default;
$react-alerts-footer-warning-btn-ok-hoverBorderColor:darken($react-alerts-footer-warning-btn-ok-backgroundColor, 15%) !default;
$react-alerts-footer-warning-btn-ok-hoverBackgroundColor:darken($react-alerts-footer-warning-btn-ok-borderColor, 15%) !default;
$react-alerts-footer-error-btn-ok-backgroundColor:#d9534f !default;
$react-alerts-footer-error-btn-ok-borderColor:#d9534f !default;
$react-alerts-footer-error-btn-ok-hoverBorderColor:darken($react-alerts-footer-error-btn-ok-backgroundColor, 15%) !default;
$react-alerts-footer-error-btn-ok-hoverBackgroundColor:darken($react-alerts-footer-error-btn-ok-borderColor, 15%) !default;
@mixin display-flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.ant-modal {
    &.ant-modal-confirm {
        &.ant-modal-confirm-confirm,
        &.ant-modal-confirm-warning,
        &.ant-modal-confirm-success,
        &.ant-modal-confirm-info,
        &.ant-modal-confirm-error {
            &.react-alerts {
                .ant-modal-content {
                    .ant-modal-body {
                        padding: 0;
                        .ant-modal-confirm-body-wrapper {
                            .ant-modal-confirm-body {
                                .anticon {
                                    display: none;
                                }
                                .ant-modal-confirm-title {
                                    .react-alerts-header {
                                        overflow: hidden;
                                        height: $react-alerts-header-height;
                                        padding-left: $react-alerts-header-paddingLeft;
                                        padding-right: $react-alerts-header-paddingRight;
                                        font-size: $react-alerts-header-fontSize;
                                        color: $react-alerts-header-fontColor;
                                        line-height: $react-alerts-header-height;
                                        background-color: $react-alerts-header-confirm-backgroundColor;
                                        .icon-udesk-react-close {
                                            float: right;
                                            font-size: 13px;
                                            font-weight: 700;
                                            transition: color .1s ease-in;
                                            cursor: pointer;
                                            &:hover {
                                                color: $react-alerts-header-icon-bi-close-hoverColor;
                                            }
                                        }
                                    }
                                }
                                .ant-modal-confirm-content {
                                    margin: 0;
                                    padding: 0;
                                    .react-alerts-content {
                                        @include display-flex-center;
                                        min-height: $react-alerts-content-minHeight;
                                        padding: $react-alerts-content-padding;
                                        text-align: center;
                                    }
                                    .react-alerts-footer {
                                        @include display-flex-center;
                                        height: $react-alerts-footer-height;
                                        background-color: $react-alerts-footer-backgroundColor;
                                        .react-alerts-footer-btn {
                                            margin: $react-alerts-footer-btn-margin; // font-size: 13px;
                                            width: $react-alerts-footer-btn-width;
                                            line-height: 1;
                                            &.react-alerts-footer-btn-cancel {
                                                color: $react-alerts-footer-btn-cancel-color;
                                                background-color: $react-alerts-footer-btn-cancel-backgroundColor;
                                                border: 1px solid $react-alerts-footer-btn-cancel-borderColor;
                                                text-shadow: 0 1px 1px rgba(255, 255, 255, .6);
                                                &:hover {
                                                    background-color: $react-alerts-footer-btn-cancel-hoverBackgroundColor;
                                                    border-color: $react-alerts-footer-btn-cancel-hoverBorderColor;
                                                }
                                            }
                                            &.react-alerts-footer-btn-ok {
                                                color: $react-alerts-footer-btn-ok-color;
                                                background-color: $react-alerts-footer-confirm-btn-ok-backgroundColor;
                                                border-color: $react-alerts-footer-confirm-btn-ok-borderColor;
                                                text-shadow: 0 -1px 1px rgba(0, 0, 0, .2);
                                                &:hover {
                                                    background-color: $react-alerts-footer-confirm-btn-ok-hoverBackgroundColor;
                                                    border-color: $react-alerts-footer-confirm-btn-ok-hoverBorderColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .ant-modal-confirm-btns {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &.ant-modal-confirm-error {
            &.react-alerts {
                .ant-modal-content {
                    .ant-modal-body {
                        .ant-modal-confirm-body-wrapper {
                            .ant-modal-confirm-body {
                                .ant-modal-confirm-title {
                                    .react-alerts-header {
                                        background-color: $react-alerts-header-error-backgroundColor;
                                    }
                                }
                                .ant-modal-confirm-content {
                                    .react-alerts-footer {
                                        .react-alerts-footer-btn {
                                            &.react-alerts-footer-btn-ok {
                                                background-color: $react-alerts-footer-error-btn-ok-backgroundColor;
                                                border-color: $react-alerts-footer-error-btn-ok-borderColor;
                                                &:hover {
                                                    background-color: $react-alerts-footer-error-btn-ok-hoverBackgroundColor;
                                                    border-color: $react-alerts-footer-error-btn-ok-hoverBorderColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.ant-modal-confirm-warning,
        &.ant-modal-confirm-info {
            &.react-alerts {
                .ant-modal-content {
                    .ant-modal-body {
                        .ant-modal-confirm-body-wrapper {
                            .ant-modal-confirm-body {
                                .ant-modal-confirm-title {
                                    .react-alerts-header {
                                        background-color: $react-alerts-header-warning-backgroundColor;
                                    }
                                }
                                .ant-modal-confirm-content {
                                    .react-alerts-footer {
                                        .react-alerts-footer-btn {
                                            &.react-alerts-footer-btn-ok {
                                                background-color: $react-alerts-footer-warning-btn-ok-backgroundColor;
                                                border-color: $react-alerts-footer-warning-btn-ok-borderColor;
                                                &:hover {
                                                    background-color: $react-alerts-footer-warning-btn-ok-hoverBackgroundColor;
                                                    border-color: $react-alerts-footer-warning-btn-ok-hoverBorderColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}