.audio-call-info-component {
    height: 100%;
    border: 1px solid #d7d7d7;
    overflow: hidden;

    .audio-call-info-navs {
        height: 100%;
        display: flex;
        justify-content: center;

        .nav-item {
            flex-grow: 1;
            height: 44px;
            cursor: pointer;
            line-height: 44px;
            display: inline-block;
            text-align: center;
            border-bottom: 1px solid #d7d7d7;
            border-right: 1px solid #d7d7d7;

            &:last-child {
                border-right: none;
            }

            &.active {
                color: #00b18d;
                border-bottom: 0px solid #d7d7d7;
            }
        }

        .udesk-qa-ui-tabs-bar {
            margin: 0px;
        }
    }

    .audio-text-view {
        height: 350px;

        .audio-text-list-container {
            height: 100%;

            .audio-text-list {
                height: calc(100% - 48px);
                overflow-x: hidden;
                overflow-y: auto;
            }
        }

        .work-order-number-href {
            color: #2878ff;
        }
    }
}

.case-base-audio-detail {
    .case-base-audio-text-list-detail {
        position: relative;
        .aduio-text-case-base-agent,
        .aduio-text-case-base-robot_agent {
            position: absolute;
            left: 0px;
            bottom: -25px;
            cursor: pointer;
            color: #185abd;
            i {
                margin-right: 3px;
            }
            .case-audio-add {
                margin-right: 10px;
            }
            .audio-detail-okText {
                margin-right: 5px;
            }
        }
        .aduio-text-case-base-customer {
            position: absolute;
            right: 0px;
            bottom: -25px;
            cursor: pointer;
            color: #185abd;
            i {
                margin-right: 3px;
            }
            .case-audio-add {
                margin-right: 10px;
            }
            .audio-detail-okText {
                margin-right: 5px;
            }
        }
        .icon-udesk-qa-react-web-yitianjiaanli {
            color: #36864a;
            margin: 0px 10px;
        }
    }
    .udesk-qa-ui-tabs-extra-content {
        line-height: 55px !important;
    }
    .audio-case-base-saveButton {
        margin-right: 10px;
    }
}
