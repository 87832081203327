.record-upload-operating-page {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
    height: 100%;
    .udesk-qa-ui-spin-nested-loading {
        width: 100%;
        height: 100%;
        .udesk-qa-ui-spin-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
        }
    }
    &-steps {
        max-width: 680px;
        width: 100%;
    }
    &-steps-content {
        padding: 40px;
        width: 100%;
        display: flex;
        flex: 1;
        height: calc(100% - 90px);
    }
    &-footer {
        padding: 12px;
        display: flex;
        justify-content: center;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        .udesk-qa-ui-row {
            width: 100%;
        }
    }
    .record-upload-operating-page-steps-content {
        overflow-y: auto;
    }
    .udesk-qa-ui-steps-finish-icon {
        color: #0FBA26;
    }
    .udesk-qa-ui-steps-item-finish {
        .udesk-qa-ui-steps-item-icon {
            border-color: #0FBA26 !important;
        }
    }
}
