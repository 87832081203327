.udesk-qa-comment-component-user-select {
    .udesk-qa-ui-select-selector {
        border: 1px solid #fff !important;
        &:hover {
            border-color: #458fff !important;
        }
    }
    .udesk-qa-ui-select-selection-overflow-item {
        position: relative;
        margin-right: -10px;
        &:nth-child(1){
            z-index: 10;
        }
        &:nth-child(2){
            z-index: 9;
        }
        &:nth-child(3){
            z-index: 8;
        }
        &:nth-child(4){
            z-index: 7;
        }
        &:nth-child(5){
            z-index: 6;
        }
        &:nth-child(6){
            z-index: 5;
        }
        &:nth-child(7){
            z-index: 4;
        }
        &:nth-child(8){
            z-index: 3;
        }
        &:last-child {
            margin-right: 0;
        }
        &:nth-last-child(2) {
            margin-right: 0;
        }
    }
    .udesk-qa-ui-select-selection-overflow-item-rest {
        margin-right: 0 !important;
    }
}