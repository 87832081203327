.udesk-coach-web-learning-center-record-detail {
    height: 100%;
    .udesk-qa-web-page-content {
        display: flex;
        .udesk-coach-web-learning-center-record-detail-left {
            width: 40%;
            background-color: #fff;
            padding: 16px;
            margin-right: 12px;
            // height: fit-content;
            border-radius: 8px;
            overflow-y: auto;
        }
        .udesk-coach-web-learning-center-record-detail-right {
            flex: 1;
            background: #fff;
            padding: 9px 16px 16px;
            border-radius: 8px;
            position: relative;

            .udesk-qa-ui-tabs {
                height: 100%;
                .udesk-qa-ui-tabs-content-holder {
                    flex: 1;
                }
                .udesk-qa-ui-tabs-content {
                    height: 100%;
                    .udesk-qa-ui-tabs-tabpane {
                        overflow: auto;
                        .udesk-coach-components-dialogue-wrap {
                            height: unset;
                            overflow: unset;
                        }
                    }
                }
            }
            .udesk-qa-ui-tag-blue,
            .udesk-qa-ui-tag-green {
                color: #1a6eff;
                background: #e6f3ff;
                border-color: #e6f3ff;
            }
            .udesk-qa-ui-tag-red {
                color: #fb5127;
                background: #fff5f0;
                border-color: #fff5f0;
            }
            .udesk-qa-ui-tag-gold {
                background: #fffbe6;
                border-color: #fffbe6;
            }

            .udesk-coach-web-learning-center-record-detail-right-wrapper {
                position: absolute;
                width: calc(100% - 32px);
                height: calc(100% - 32px);
            }
        }
    }

    .font-blue {
        color: #1a6eff;
    }

    .font-gold {
        color: #d48806;
    }

    .font-red {
        color: #fb5127;
    }

    .font-green {
        color: #0fba26;
    }

    .font-black {
        color: rgba(0, 0, 0, 0.85);
    }

    .font-grey {
        color: rgba(0, 0, 0, 0.45);
    }
}

.udesk-coach-components-dialogue-tab {
    position: relative;
}
.udesk-coach-components-dialogue-tools {
    position: absolute;
    left: -8px;
    z-index: 9999;

    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        display: inline-flex;
        flex-flow: row-reverse;
        align-items: center;
        color: #1b6dff;
    }
}
