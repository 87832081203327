.columns-manage-page-row-dragging {
    background: #fafafa;
    border: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    font-size: 14px;
}

.columns-manage-page-row-dragging td {
    padding: 3px 8px;
}

.columns-manage-page-row-dragging .drag-visible {
    visibility: visible;
}

.columns-manage-page-index {
    // height: 100%;
    .udesk-qa-ui-modal-body {
        >div {
            display: flex;
            height: 500px;    
        }
        .columns-manage-page-left {
            width: 60%;
            margin-right: 16px;
            display: flex;
            flex-direction: column;
            .udesk-qa-ui-checkbox-group {
                display: block;
                overflow-y: auto;
                margin-top: 16px;
                .udesk-qa-ui-checkbox-inner::after {
                    left: 20%;
                }
            }
        }
        .columns-manage-page-right {
            width: 40%;
            display: flex;
            flex-direction: column;
            .udesk-qa-auto-size-table-container {
                flex: 1;
            }
        }    
    }
}