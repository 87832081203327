.input-use-entity-component {
    position: relative;

    .input-use-entity {}

    .information-entities-container {
        position: absolute;
        background: #fff;
        border: 1px solid #ddd;
        width: 100%;
        z-index: 2;

        .information-entity-item {
            padding: 3px 5px;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}