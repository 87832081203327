.udesk-coach-web-ai-textarea {
    position: relative;

    > div {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;

        button:hover {
            color: #0a61fc;
        }
    }
}
