.system-integration-pages {
    padding: 16px;
    .udesk-qa-ui-page-header {
        padding: 0 8px;
        .udesk-qa-ui-page-header-heading-title {
            font-size: 18px;
        }
    }
    .system-integration-pages-block {
        .system-integration-pages-block-tips {
            margin-bottom: 16px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            .udesk-qa-ui-btn {
                padding-left: 8px;
                &:hover {
                    color: #458fff;
                }
            }
        }
        .system-integration-pages-block-form {
            margin-bottom: 16px;
            max-width: 800px;
        }
    }
}