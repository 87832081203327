@mixin for-date-range-picker() {
    .date-range-picker {
        padding-top: 0;
        padding-left: 0;
        label {
            display: inline;
            margin-bottom: 0;
        }
        .form-control {
            height: 30px;
        }
    }
}
