.real-time-analysis-page-detail {
    .udesk-qa-form-score-unit {
        padding: 4px 12px;
        background: rgba(0, 0, 0, 0.03);
        margin-left: -8px;
        border-radius: 0px 2px 2px 0px;
        border: 1px solid #D9D9D9;
        border-left: unset;
    }
    .massage-push-configuration-wechat {
        margin-top: 5px;
        .udesk-qa-ui-select {
            margin: 8px 0px 16px;
        }
    }
    .push-config-switch {
        .udesk-qa-ui-row {
            margin-bottom: 0;
            margin-top: 16px;
        }
    }
}