.list-edit-menu-wrapper {
  background-color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.list-edit-menu-wrapper .item-col {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.list-edit-menu-wrapper .item-col .name-span {
  margin-top: 10px;
}

