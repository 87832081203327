.udesk-qa-table-component-with-columns-transfer {
    background-color: #fff;
    position: relative;
}


.udesk-qa-table-component-columns-transfer-item {
  position: relative;
  padding: 1px 10px;
  overflow-y: auto;
  height: 32px;
  .udesk-qa-ui-checkbox-wrapper {
    position: absolute;
    left: 0;
    overflow-y: hidden !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 15px;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }
  }
  .icon-unordered-list {
    position: absolute;
    right: 0;
    height: 30px;
    line-height: 30px;
  }
  // .customer-service-ranklist-index-page-columns-transfer-item-hidden {
  //   display: none;
  // }
}

.udesk-qa-table-component-columns-transfer-popover {
  width: 500px;
  height: 378px;
  left: unset !important;
  top: -16px !important;
  right: 10px;
  .udesk-qa-ui-popover-arrow {
    display: none !important;
  }
  .udesk-qa-ui-transfer-list {
    height: 346px !important;
  }
  .udesk-qa-ui-transfer-operation {
    .udesk-qa-ui-btn-icon-only {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  .udesk-qa-ui-transfer-list-content {
    height: 260px !important;
    padding-top: 6px;
    .udesk-qa-ui-transfer-list-content-item {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 197px;
      max-width: 197px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  .udesk-qa-ui-transfer-list-body-customize-wrapper {
    padding: 6px 0px !important;
    .transfer-list {
      overflow: auto;
      height: 242px;
      padding: 0 12px;
    }
  }
}
