.business-drawer {
    .udesk-qa-ui-timeline-item-tail {
        border-left-style: dashed;
    }
    .udesk-qa-ui-drawer-body {
        padding: 56px 0 0 0;
        height: 100%;
    }
    .udesk-qa-ui-tabs-content {
        padding: 20px;
    }
    .udesk-qa-ui-tabs {
        height: 100%;
    }
    .udesk-qa-ui-tabs-content-holder {
        height: 100%;
        overflow-y: auto;
    }
    .udesk-qa-ui-tabs-nav {
        &:after {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            border-bottom: 1px solid #f0f0f0;
            content: '';
        }
        .udesk-qa-ui-tabs-nav-list {
            width: 100%;
        }
        .udesk-qa-ui-tabs-tab {
            flex: 1;
            z-index: 1000;
            justify-content: center;
        }
        .udesk-qa-ui-tabs-ink-bar {
            height: 100%;
            z-index: 0;
        }

        .udesk-qa-ui-tabs-tab.udesk-qa-ui-tabs-tab-active .udesk-qa-ui-tabs-tab-btn {
            color: #fff;
        }
    }

    .button-link {
        display: inline-flex;

        &.reverse {
            flex-flow: row-reverse;

            > span {
                margin-left: 0;
            }
            > span[role='img'] {
                margin-left: 8px;
            }
        }

        > span[role='img'] {
            top: 3px;
            position: relative;
        }
    }

    .call-top {
        > div:first-child {
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }
        > div:last-child {
            display: flex;
            justify-content: space-between;
        }
    }

    .call-detail {
        padding-top: 20px;
        > div {
            height: auto !important;
        }
    }

    .chatroom-detail {
        .chatroom-detail-btn {
            text-align: center;
        }
        .chatroom-detail-container {
            overflow-y: auto;
            height: 400px;
            margin: 35px 10px;
            background-color: #f5f6f8;
        }
    }
}

.time-event {
    font-size: 12px;

    > div:first-child {
        color: #ccc;
    }
    > div:last-child {
        color: #555;
    }

    .admin-label {
        color: #eb9b25;
    }

    .udesk-qa-ui-btn-link {
        padding: 4px;
        font-size: inherit;
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;