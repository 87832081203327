.udesk-qa-customize-pagination {
    padding-top: 8px;
    text-align: end;
    .anticon {
        // vertical-align: unset;
    }
    .quality-inspection-list-index-page-pagination {
        text-align: end;
        padding-right: 7px;
    }
}
