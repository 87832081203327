.audio-stickers-talk {
    .audio-sticker-view {
        width: 100%;
        height: 4px;
        margin-bottom: 3px;
        position: relative;
        .audio-sticker-item {
            height: 100%;
            position: absolute;
        }
    }
}
