.udesk-qa-risk-setting-rule-list {
    // background-color: rgba(0, 0, 0, 0.03);
    // padding: 16px 8px 8px;
    .udesk-qa-risk-setting-rule-item {
        .udesk-qa-risk-setting-rule-item-top {
            padding: 8px;
        }
        .udesk-qa-risk-setting-rule-item-bottom {
            background-color: rgba(0, 0, 0, 0.03);
            padding: 8px;
            height: 50px;
            display: flex;
            align-items: center;
        }
        .filter-wrapper {
            display: flex;
            align-items: center;
        }
        .operator-selector {
            height: unset !important;
        }
        .field-selector {
            height: unset !important;
        }
    }
    .udesk-qa-list-item {
        justify-content: space-between;
    }
}
