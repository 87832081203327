.udesk-coach-web-config-drawer .udesk-qa-ui-drawer-body .udesk-qa-ui-spin-nested-loading {
    min-height: 100%;
    height: auto;
}

.udesk-coach-web-config-drawer
    .udesk-qa-ui-drawer-body
    .udesk-qa-ui-spin-nested-loading
    .udesk-qa-ui-spin-container {
    min-height: 100%;
    height: auto;
}
