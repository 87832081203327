.wechat-qa-tasks-push-setting {
    display: flex;
    flex-direction: column;
    padding: 16px;
    .wechat-qa-tasks-push-setting-toolsbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 8px;
        margin-top: -8px;
    }
}
.wechat-qa-tasks-push-setting-modal {
    .udesk-qa-ui-modal-body {
        padding: 16px;
        overflow-y: auto;
        max-height: 60vh;
        .udesk-qa-ui-form {
            width: 753px;
        }
        .udesk-qa-ui-input-value-count {
            bottom: 5px;
        }
    }
    .massage-push-configuration-row {
        margin-left: 59px;
    }
}