.training-history-index-page {
    font-size: 14px;
    padding-bottom: 1px;
    color: rgba(0, 0, 0, 0.85);
    background-color: rgba(240, 242, 245, 1);
    .training-history-index-page-body {
        background-color: #fff;
        padding: 16px;
        .training-history-index-page-body-top-date {
            display: flex;
            .training-history-index-page-body-top-date-des {
                line-height: 32px;
            }
            .udesk-qa-ui-btn-primary {
                color: #fff;
                background-color: #1890ff;
                border-color: #1890ff;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            }
        }
        .training-history-index-page-body-table {
            margin-top: 16px;
            // .udesk-qa-ui-pagination {
            //     width: 100%;
            //     text-align: right;
            //     margin-bottom: 0;
            //     .udesk-qa-ui-pagination-total-text {
            //         position: absolute;
            //         left: 100px;
            //         color: rgba(0, 0, 0, 0.45);
            //     }
            // }
            // .udesk-qa-ui-pagination-options {
            //     text-align: center;
            //     position: absolute;
            //     left: 0px;
            //     color: rgba(0, 0, 0, 0.45);
            // }
        }
    }
}
