.select-time-range {
    position: relative;
    margin: 0 10px 10px 0;
    display: inline-block;

    a.btn-sm {
        border-radius: 0;
    }

    a.btn-md {
        border-radius: 0;
    }

    .dropdown-menu {
        border-radius: 0px;
        margin: -2px 0 0;
        font-size: 13px;
        padding: 12px 10px;

        li {
            width: 160px;
            color: #333;
            margin: 5px 0;

            input.lg {
                padding: 3px 10px;
                width: 110px;
                padding-right: 30px;
            }

            .seconds {
                min-width: 30px;
                height: 30px;
                border: 1px solid #ccc;
                border-left: none;
                text-align: center;
                line-height: 28px;
                display: inline-block;
                padding: 0px;
            }

            button {
                margin-right: 5px;
            }
        }
    }
}