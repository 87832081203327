.wechat-conversational-analysis-page {

    .qa-react-inspection-detail-page .qa-react-inspection-detail-content {
        height: calc(100vh - 122px);

        .qa-react-inspection-detail-content-point {
            flex: 0 auto;
            border-left: 0;
        }
    }

    .audio-call-basic-info-page-v2 {
        .udesk-qa-ui-tabs-content {
            height: calc(100% - 10px);
        }
    }

    .udesk-qa-web-page-body-root-spin .udesk-qa-ui-spin-container .udesk-qa-web-page-content {
        padding: 16px 16px 0px 16px;
    }
}
