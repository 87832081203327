.favorite-config-table.dissent {
    display: flex;
    height: 100%;
    width: 100%;

    .favorite-config-table-left {
        width: 250px;
        border-right: 1px solid #eee;
        padding: 16px 0;

        .udesk-qa-ui-table-wrapper,
        .udesk-qa-ui-spin-nested-loading,
        .udesk-qa-ui-spin-container,
        .udesk-qa-ui-table-container {
            height: 100%;
        }
        .udesk-qa-ui-table-fixed-header {
            flex: 1;
            height: 100%;
        }
        .udesk-qa-ui-table-body {
            overflow-y: auto!important;

            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected {
                td::after {
                    content: '';
                    display: inline-block;
                    height: 43px;
                    width: 2px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #1A6EFF;
                }
            }
            .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
                border-bottom-color: transparent;
            }
        }

        .udesk-qa-ui-table-thead {
            display: none;
        }

        .table-row-item {
            width: 222px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
            padding-left: 8px;
        }
    }

    .favorite-config-table-right {
        width: 520px;
        padding: 20px 0;

        .smart-list {
            margin-top: 5px;
            text-align: left;
            max-height: 323px;
            overflow: auto;

            > div {
                border: 1px solid #ccc;
                padding: 7px 0px 7px 10px;
                color: #777;

                + div {
                    margin-top: 5px;
                }

                >div {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &:hover {
                    color: #555;
                    background-color: #E8F4FF;
                }
            }
        }

        .udesk-qa-ui-spin-container {
            height: 100%;
            overflow-y: auto;
        }
    }
}
