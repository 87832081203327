.smart-tools-sider-bar-index-page {
    .smart-tools-sider-bar-index-page-drawer {
        .smart-tools-sider-bar-index-page-drawer-body {
            .smart-tools-sider-bar-index-page-drawer-body-smart-tags-index {
                // transform-style: preserve-3d;
                // transform: translate3d(0,0,0);
                position: relative;
                transition: 0.6s;
                transform-style: preserve-3d;
                height: 100%;
                perspective: 1000;
                .card {
                    height: 100%;
                    position: relative;
                    transition: all 600ms;
                    transform-style: preserve-3d;
                    .front {
                        backface-visibility: hidden;
                        z-index: 2;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #fff;
                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-search-bar {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            padding: 8px 16px 0;
                        }
                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-loading {
                            height: 100%;
                            .udesk-qa-ui-spin-container {
                                height: 100%;
                            }
                            .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list {
                                overflow-y: auto;
                                height: calc(100% - 96px);
                                margin: 16px 0;
    
                                .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item {
                                    content-visibility: auto;
                                    contain-intrinsic-size: 60px;
                                    height: 60px;
                                    display: flex;
                                    color: rgba(0, 0, 0, 0.65);
                                    padding-right: 16px;
                                    padding-left: 14px;
                                    line-height: 22px;
                                    border-left: 2px solid #fff;
                                    cursor: pointer;
                                    align-items: center;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                    &:hover {
                                        background: #E8F4FF;
                                        transition: all 0.2s linear;
                                        border-left: 2px solid #E8F4FF;
                                    }
                                    .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-hit {
                                        width: 58px;
                                        margin-right: 16px;
                                        margin-left: 20px;
                                    }
                                    .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content {
                                        flex: 1;
                                        padding: 8px 0;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content-title {
                                            width: 100%;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-content-sentence {
                                            width: 100%;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                                .smart-tools-sider-bar-index-page-drawer-body-smart-tags-list-item-active {
                                    border-left: 2px solid #1B6DFF;
                                    background: #E8F4FF;
                                    &:hover {
                                        border-left: 2px solid #1B6DFF;
                                    }
                                }
                            }    
                        }
                    }
                    .back {
                        backface-visibility: hidden;
                        transform: rotateY(180deg);
                        position: absolute;
                        top: 0;
                        background-color: #fff;
                        left: 0;
                        width: 100%;
                        height: calc(100% - 40px);
                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-loading {
                            height: 100%;
                            .udesk-qa-ui-spin-container {
                                height: 100%;
                            }
                        }
                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail {
                            padding: 0px 16px 8px;
                            height: calc(100% - 88px);
                            overflow-y: auto;    
                            .smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-label {
                                margin-top: 8px;
                            }
                            .smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-delete {
                                padding-left: 9px;
                                cursor: pointer;
                                color: rgba(0, 0, 0, 0.3);
                            }
                            .smart-tools-sider-bar-index-page-drawer-body-smart-tags-detail-form-rules {
                                padding: 4px 0;
                                display: inline-block;
                            }
                        }
                        .smart-tools-sider-bar-index-page-drawer-body-smart-tags-footer {
                            border-top: 1px solid rgba(0, 0, 0, 0.1);
                            height: 48px;
                            padding: 8px 16px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }    
                }

                .flipme {
                    transform: rotateY(-180deg);
                }

            }
        }
    }
}



