.custom-tooltip {
    max-width: 320px;
    max-height: 200px;

    ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
    }
    ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 0.25);
    }
    ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        // background: rgba(0,0,0,0.1);
    }

    .udesk-qa-ui-tooltip-inner {
        padding: 0;
    }
    .custom-tooltip-title,
    .custom-tooltip-content {
        min-width: 200px;
    }

    .custom-tooltip-content {
        padding-bottom: 10px;
        color: #ffffffa6;
        font-size: 12px;
        max-height: 360px;

        &:hover {
            overflow-y: auto;
        }

        .time {
            position: relative;
            color: #fff;
            padding-left: 7px;

            &::before {
                position: absolute;
                left: 0;
                top: 5px;
                width: 2px;
                height: 10px;
                background-color: #ffffffd9;
                content: '';
            }
        }

        > div ~ div {
            margin-top: 8px;
        }

        .custom-tooltip-content-item {
            padding: 0 10px;
        }
    }
    .custom-tooltip-title {
        padding: 7px 10px 0 10px;
        font-size: 14px;
        display: flex;
        line-height: 32px;
        justify-content: space-between;

        button {
            color: #fff;
        }
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;