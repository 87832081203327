.wrong-question-exercise-other-panel {
  .flow-panel {
    height: 100%;
    display: flex;
    flex-flow: column;

    .flow-title {
      padding: 16px;
      display: flex;
      justify-content: space-between;

      > div {
        position: relative;
        display: inline;

        > span {
          position: relative;
          font-size: 15px;
          font-weight: 600;
          position: relative;
          z-index: 1000;
        }

        &::after {
          position: absolute;
          content: '';
          height: 6px;
          left: 0;
          right: 0;
          bottom: 3px;
          z-index: 1;
          border-radius: 6px;
          background-color: #bddeff;
        }
      }

      >span {
        >span:nth-child(1) {
          color: #555;
        }
        >span:nth-child(2) {
          color: #999;
        }
      }
    }

    .flow-list {
      display: block;
      overflow: auto;
      padding: 2px 16px 0 16px;

      > button {
        padding-left: 0px;
        padding-right: 15px;
        white-space: break-spaces;
        text-align: left;
      }

      .flow-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .active {
          color: #1b6dff;

          &:hover {
            color: #458fff;
          }
        }

        .score {
          >span:nth-child(1) {
            color: #555;
          }
          >span:nth-child(2) {
            color: #999;
          }
        }

        > div:nth-child(1) {
          width: calc(100% - 42px);
        }
      }
    }
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;