.grade-task-index-page-body {
    display: flex;
    flex-direction: column;

    .grade-list-header {
        border-bottom: 1px solid #e4e4e4;
        padding: 8px 10px 5px 10px;
        margin-bottom: 10px;
        height: 48px;
    }
    .grade-page-btn-add {
        border: 1px solid #0a85ff;
        background: #fff;
        padding: 4px 22px;
        color: #0a85ff;
        cursor: pointer;
        float: left;
    }
    .grade-page-btn-close {
        border: 1px solid #999;
        background: #fff;
        padding: 4px 22px;
        cursor: pointer;
        width: 100px;
        text-align: center;
        float: right;
    }
    .grade-page-btn-save {
        border: 1px solid #0a85ff;
        background: #0a85ff;
        padding: 4px 22px;
        color: #fff;
        cursor: pointer;
        width: 100px;
        float: right;
        text-align: center;
        margin-left: 10px;
    }
    //   .udesk-qa-ui-collapse-extra {
    //     position: absolute;
    //     top: 50%;
    //     right: 30px;
    //     transform: translateY(-50%);
    //     a {
    //       color: #2878ff;
    //       margin-right: 10px;
    //       text-decoration: underline;
    //     }
    //     a:hover {
    //       color: blue;
    //     }
    //   }
    .index-management-custom-filter {
        margin-left: 200px;
        margin-bottom: 10px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.03);
        max-width: 800px;
        width: auto;
        max-height: 400px;
        overflow-y: auto;
        .index-management-custom-filter-edit {
            text-align: right;
            span {
                cursor: pointer;
                color: rgba(27, 109, 255, 1);
            }
        }
        .index-management-custom-filter-Info {
            display: flex;
        }
        .component-udesk-react-radio-btn .radio-btn-item {
            width: auto;
        }
    }
    .index-management-custom-filter-width-other {
        margin-left: 0;
    }
    .tasks-manage-template-basic-info-index-conduct {
        height: 48px;
        padding: 0 16px 0 20px;
        line-height: 24px;
        background: #fff;
        border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
        display: flex;
        justify-content: space-between;
        .tasks-manage-template-basic-info-index-conduct-left {
            flex: 1;
        }
        .tasks-manage-template-basic-info-index-conduct-right {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.45);
            line-height: 48px;
            margin-left: 10px;
        }
    }
    .udesk-qa-ui-checkbox-wrapper {
        display: inline-flex;
    }
    .tasks-manage-template-basic-info-index-footer {
        height: 48px;
        padding: 8px 40px 0px;
    }

}
