$component-react-tree-child-nodes-indent: 16px;
$component-react-tree-node-actions-indent: 10px;
$component-react-tree-heading-actions-indent: 10px !default;
$component-react-tree-heading-actions-gap: 10px;
$component-react-tree-node-actions-gap: 10px;
$component-react-tree-heading-action-size: 18px !default;
$component-react-tree-node-action-size: 18px !default;
$component-react-tree-node-toggle-size: 20px !default;
$component-react-tree-node-name-transition: background-color .1s linear,
color .1s linear !default;
$component-react-tree-node-action-transition: opacity .3s linear;
$component-react-tree-font-color: #444 !default;
$component-react-tree-add-icon-color: #5cb85c !default;
$component-react-tree-edit-icon-color: #df8a13 !default;
$component-react-tree-delete-icon-color: #d9534f !default;
$component-react-tree-clickable-node-hover-color: inherit !default;
$component-react-tree-clickable-node-hover-bgcolor: #d3f1eb !default;
$component-react-tree-active-node-color: #006b42 !default;
$component-react-tree-active-node-bgcolor: #e9f8f5 !default;
$component-react-tree-active-node-hover-color: #006b42 !default;
$component-react-tree-active-node-hover-bgcolor: #e9f8f5 !default;
$component-react-tree-checked-node-color: #00404c !default;
$component-react-tree-checked-node-bgcolor: #e9f8f5 !default;
$component-react-tree-checkable-node-color: #00404c !default;
$component-react-tree-checkable-node-bgcolor: #e9f8f5 !default;
$component-react-tree-dropable-node-positioncolor: #baf5e9 !default;
