.udesk-coach-web-config-drawer {
    .udesk-qa-ui-drawer-body {
        padding: 0 0 16px;

        label {
            line-height: 1.5715;
        }
        .udesk-qa-ui-spin-nested-loading {
            height: 100%;
            .udesk-qa-ui-spin-container {
                height: 100%;
                .udesk-qa-ui-form-builder-container {
                    height: calc(100% - 40px);
                    overflow-y: auto;
                    padding: 16px 20px 0;

                    .udesk-qa-ui-space-item {
                        .udesk-qa-ui-form-item {
                            margin-bottom: 0;
                        }
                    }
                }
                > .udesk-qa-ui-btn {
                    margin-top: 8px;
                    margin-left: 16px;
                }
            }
        }

        .ppt {
            .udesk-coach-user-node-left {
                max-width: calc(100% - 36px);
            }

            .score-detail-card.right{
                padding-left: 0!important;
            }

            .score-detail-card-open-btn > button:last-child {
                display: none;
            }
        }
    }
}

.udesk-qa-audition-button > span{
    margin-left: 0 !important;
    width: auto !important;
}

.udesk-coach-flow-config-drawer-tabs {
    height: 100%;

    .udesk-qa-ui-tabs-content {
        height: 100%;
    }

    .udesk-qa-ui-tabs-nav-wrap {
        padding-left: 24px;
    }
}

.udesk-coach-ppt-image-list > div > label{
    display: flex;
    justify-content: space-between;
}