.audio-call-content-info-v2 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .audio-call-content-info-control {
        // height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .udesk-qa-ui-input-group-addon {
            background-color: #fff;
        }
    }
    .audio-call-content-info-record {
        flex: 1;
        // min-height: calc(100% - 50px);
        background-color: rgba(0, 0, 0, 0.03);
    }
}
