.role-index-page {
    .role-index-page-header {
        .role-index-page-header-title {}
    }

    .role-index-page-body {
        .role-index-page-body-search-container {}

        .role-index-page-body-content-container {}
    }
    .qa-react-role-page-body-content-table-actions {
        margin-left: -8px;
    }
}