.task-exercise-message-modal{
    .test-exit-desc {
        color: #aaa;

        span.num {
            color: #1b6dff;
        }
    }

    .not-prompted {
        padding-top: 20px;

        .udesk-qa-ui-checkbox-wrapper {
            color: #777;
        }
    }
}

.space-block {
    display: flex;

    > div{
        width: 100%;
    }
}

.suspend-detail-chart {
    // > div:nth-child(1) {
    //     width: auto;
    // }
    .total-part-wrap {
        width: 100%;
        .udesk-qa-ui-space-item {
            width: 100%;
            .total-part {
                width: 100%;
                display: flex;
                justify-content: center;

                .total {
                    font-size: 18px;
                    font-weight: 600;
                }
                .part {
                    margin-top: 4px;
                }
            }
        }
    }
    .content {
        background-color: #fafafa;
        padding: 16px;
    }

    .bottom-info {
        display: flex;
        justify-content: space-between;
    }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;