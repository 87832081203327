.ant-modal-wrap .ant-modal {
  top: 70px;
  width: 60%;
  min-width: 550px;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header {
  position: relative;
  background-color: #00b38b;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header .ant-modal-title .close-modal {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-header .ant-modal-title .close-modal:hover {
  color: #ccc;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body {
  padding: 20px;
}
.ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-footer {
  padding: 15px;
  background-color: #f4f5f9;
}

