.related-details-component {
    border: 1px solid #e6e6e6;
    background: #fff;
    margin-bottom: 6px;

    .related-details-title {
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        background: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid #e6e6e6;
    }

    .related-details-content {
        padding: 5px 10px;
        // height: calc(100% - 41px);
        overflow-y: auto;

        .related-details-item {
            display: inline-flex;
            margin: 4px;
            cursor: pointer;
            background: #e8f4ff;
            border: 1px solid #1b6dff;
            color: rgba(0, 0, 0, 0.65);
            border-radius: 2px;
            align-items: center;
            max-width: 50%;

            .related-details-item-name {
                // border: 1px solid #ddd;
                // background: #2878ff;
                color: rgba(0, 0, 0, 0.65);
                padding: 2px 8px;
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .related-details-item-score {
                padding: 0px 8px;
                margin: 2px 0;
                border-left: 1px solid rgba(0, 0, 0, 0.2);
            }
            .related-details-item-grade {
                // border: 1px solid #ddd;
                // border-left: none;
                padding: 2px 8px;
                background: #1b6dff;
                color: #ffffff;
            }
        }
    }
}
