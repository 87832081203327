.call-review-detail-page-index-wrap {
    padding-bottom: 0 !important;
    .call-review-detail-page-index {
        height: 100%;
        .udesk-qa-web-page-content {
            display: flex;
            width: 100%;
        }
        .udesk-qa-ui-menu-inline {
            margin-right: 0;
        }
        .call-review-detail-page-index-list {
            margin-right: 16px;
            background-color: #fff;
            margin-top: -15px;
            margin-left: -16px;
            // width: 372px;
            position: relative;
            max-width: 30%;
            overflow: hidden;
            &.open {
                .udesk-qa-ui-tabs {
                    .udesk-qa-ui-tabs-content-holder {
                        width: 305px;
                    }
                }
            }
            .udesk-qa-ui-tabs {
                height: 100%;
                .udesk-qa-ui-tabs-tabpane {
                    padding: 16px;
                    height: 100%;
                }
                .udesk-qa-ui-tabs-tab {
                    padding: 12px 24px;
                    margin: 0;
                }
                .udesk-qa-ui-tabs-content-holder {
                    width: 0;
                    transition: width 1s;
                    .udesk-qa-ui-tabs-content {
                        height: 100%;
                    }
                }
            }
            .audio-text-view {
                height: calc(100vh - 156px);
                overflow-y: auto;
            }
            .call-review-detail-page-index-list-item {
                border-radius: 2px;
                margin-top: 8px;
                margin-bottom: 8px;
                // width: 272px;
                padding: 8px;
                width: 100%;
                .call-review-detail-page-index-list-item-header {
                    display: flex;
                    justify-content: space-between;
                    color: rgba(0, 0, 0, 0.85);
                    font-weight: 500;
                    font-size: 14px;
                    .anticon {
                        color: #ff7a52;
                        margin-right: -4px;
                    }
                }
                .call-review-detail-page-index-list-item-content {
                    white-space: pre-wrap;
                    margin-top: 4px;
                    font-size: 12px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
            .udesk-qa-ui-menu-inline {
                border-right: 1px solid #fff;
                .udesk-qa-ui-menu-item {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    margin: 0;
                    height: unset;
                    line-height: 22px;
                    padding: 0 !important;
                    .anticon {
                        margin-right: 0;
                    }
                    &::after {
                        border-right: none;
                    }
                    &.udesk-qa-ui-menu-item-selected {
                        background-color: #fff;
                        .call-review-detail-page-index-list-item {
                            background: #e6f3ff;
                        }
                    }
                }
            }
        }
        .call-review-detail-page-index-content {
            flex: 1;
            background-color: #fff;
            padding: 16px;
            margin-bottom: 16px;
            display: flex;
            width: 100%;
            overflow-x: hidden;
            .call-review-detail-page-index-content-right {
                width: 100%;
                overflow: hidden;
                height: 100%;
                margin-left: 16px;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                
                .udesk-qa-ui-tabs.udesk-qa-ui-tabs-top,
                .udesk-qa-ui-tabs-content.udesk-qa-ui-tabs-content-top {
                    height: 100%;
                }

                .udesk-qa-ui-timeline-item {
                    white-space: pre-wrap;
                }

                .udesk-qa-ui-timeline-item.last >div.udesk-qa-ui-timeline-item-tail{
                    visibility: hidden;
                }

                .udesk-qa-ui-btn .anticon {
                    margin-left: initial;
                }

                .flow-session {
                    height: 100%;
                    overflow: auto;
                    
                    > div.udesk-qa-ui-spin-container {
                        flex-direction: column!important;

                        > ul.udesk-qa-ui-timeline {
                            overflow: auto;
                            padding: 16px 24px 0 24px;
                            margin-bottom: 16px;
                        }
                        > div {
                            padding: 0 24px;
                        }
                    }
                }
            }
            // .call-review-detail-page-index-content-comment {
            //     width: 100%;
            //     padding-left: 16px;
            //     margin-left: 16px;
            //     border-left: 1px solid rgba(0, 0, 0, 0.1);
            //     display: flex;
            //     flex-direction: column;
            //     position: relative;
            //     overflow: hidden;
            //     // max-width: 40%;
            //     // .udesk-qa-chat-record-item-right {
            //     //     max-width: 100%;
            //     // }
            //     .udesk-qa-chat-record-item-chat-bubble {
            //         max-width: 95%;
            //     }
            //     .udesk-qa-ui-drawer {
            //         .udesk-qa-ui-select-selector {
            //             padding: 0 7px 0 0;
            //         }
            //         .udesk-qa-ui-drawer-close {
            //             padding-left: 0;
            //         }
            //     }
            //     .call-review-detail-page-index-content-comment-title {
            //         color: rgba(0, 0, 0, 0.85);
            //         font-weight: 500;
            //         font-size: 16px;
            //         line-height: 24px;
            //         padding-bottom: 16px;
            //         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            //     }
            //     .call-review-detail-page-index-content-comment-input {
            //         flex: 1;
            //         max-height: calc(100% - 30px);
            //     }
            //     .comment-component {
            //         .child-comments {
            //             .comment-display-item-body-reply {
            //                 margin-bottom: 0;
            //                 padding: 8px 0 0;
            //             }
            //         }
            //         .comment-display-item-body-reply {
            //             background: #f9f9f9;
            //             border-radius: 4px;
            //             padding: 8px;
            //             margin-bottom: 8px;
            //         }
            //         .reply-wrap-box {
            //             background: #f9f9f9;
            //             border-radius: 4px;
            //             padding: 8px;
            //             margin-bottom: 8px;
            //             &:last-child {
            //                 margin-bottom: 0;
            //             }
            //             .avatar {
            //                 line-height: 22px;
            //                 > img {
            //                     height: 24px;
            //                     width: 24px;
            //                 }
            //             }
            //         }
            //     }
            // }
            .call-review-detail-page-index-content-audio {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-x: hidden;
                overflow-y: hidden;
                position: relative;
                .udesk-qa-chat-record-item-chat-bubble {
                    max-width: 100%;
                }
                .udesk-qa-chat-record-list {
                    height: 100%;
                    background: #f5f6f8;
                    // margin-bottom: 16px;
                }
                .wave-audio-component-talk-v3 {
                    margin-top: 10px;
                    // min-height: 166px;
                    position: relative;
                }
                .audio-control-view {
                    // margin-top: 12px;
                }
            }
        }
        .call-review-detail-page-index-content-comment {
            width: 100%;
            // padding-left: 16px;
            // margin-left: 16px;
            // border-left: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            position: relative;
            // overflow: hidden;
            // max-width: 40%;
            // .udesk-qa-chat-record-item-right {
            //     max-width: 100%;
            // }
            .udesk-qa-chat-record-item-chat-bubble {
                max-width: 95%;
            }
            .udesk-qa-ui-drawer {
                .udesk-qa-ui-select-selector {
                    padding: 0 7px 0 0;
                }
                .udesk-qa-ui-drawer-close {
                    padding-left: 0;
                }
            }
            .call-review-detail-page-index-content-comment-title {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                padding-bottom: 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .call-review-detail-page-index-content-comment-input {
                flex: 1;
                max-height: calc(100% - 30px);
            }
            .comment-component {
                .child-comments {
                    .comment-display-item-body-reply {
                        margin-bottom: 0;
                        padding: 8px 0 0;
                    }
                }
                .comment-display-item-body-reply {
                    background: #f9f9f9;
                    border-radius: 4px;
                    padding: 8px;
                    margin-bottom: 8px;
                }
                .reply-wrap-box {
                    background: #f9f9f9;
                    border-radius: 4px;
                    padding: 8px;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .avatar {
                        line-height: 22px;
                        > img {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }
    .udesk-qa-ui-spin-container {
        flex-direction: row !important;
    }
}
