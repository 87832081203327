.business-analysis-page-list {
    background: rgb(242, 242, 244);
    >div {
        border-radius: 3px;
    }
    .business-analysis-page-list-filter {
        background: #fff;
    }
    .qa-react-list-connenct-by-converstaion-custome-filter {
        padding: 20px;
        background: #fff;
        overflow-y: auto;
        overflow-x: auto;
        max-height: 360px;
        .custome-filter-driver-box {
            position: relative;
            .udesk-qa-ui-select {
                min-width: 130px;
            }
            .customer-filter-driver-circle-add {
                position: absolute;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 1);
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.15);
                text-align: center;
                line-height: 21px;
                top: calc(50% + 55px);
                left: -84px;
                cursor: pointer;
                i {
                    font-size: 12px;
                    color: #1b6dff;
                }
            }
            .customer-filter-driver-circle-add-none {
                position: absolute;
                left: 0px;
                top: -17px;
                bottom: 0px;
            }
            .customer-filter-driver-circle-add-block {
                position: absolute;
                left: 15px;
                top: 5px;
                bottom: 0px;
                z-index: 100;
            }
            .custome-filter-dirver-first-box {
                position: relative;
                &:before {
                    position: absolute;
                    width: 1px;
                    height: 36px;
                    background: rgba(0, 0, 0, 0.1);
                    top: -21px;
                    left: -74px;
                    // transform: translateY(-50%);
                    content: '';
                }
            }
            .custome-filter-friver-flag-box {
                &:before {
                    position: absolute;
                    width: 1px;
                    height: 0px;
                    background: rgba(0, 0, 0, 0.1);
                    top: -21px;
                    left: -74px;
                    // transform: translateY(-50%);
                    content: '';
                }
            }
            .custome-filter-friver-next-box {
                position: relative;
                padding-left: 100px;
                &:before {
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    top: 15px;
                    left: 26px;
                    // transform: translateY(-50%);
                    content: '';
                }
                .customer-filter-driver-circle-add {
                    left: 15px;
                    top: calc(100% - 30px);
                }
            }
            .custome-filter-friver-next-box-flag {
                &:before {
                    position: absolute;
                    width: 1px;
                    height: 0px;
                    background: rgba(0, 0, 0, 0.1);
                    top: 15px;
                    left: 26px;
                    // transform: translateY(-50%);
                    content: '';
                }
            }
            .customer-filter-driver-company {
                margin-right: 5px;
                input {
                    width: 100px;
                    margin-right: 5px;
                }
                span {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .custome-filter-driver-box-or {
                position: absolute;
                width: 48px;
                height: 32px;
                background: rgba(255, 255, 255, 1);
                border-radius: 16px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                text-align: center;
                line-height: 28px;
                color: #1b6dff;
                font-size: 16px;
                top: 50%;
                transform: translateY(-25%);
                left: -97px;
                z-index: 1;
            }
            .custome-filter-driver-one-box {
                position: relative;
                padding-bottom: 20px;
                width: 820px;

                .customer-filter-driver-circle-add {
                    top: 40px;
                }
                .custome-filter-driver-one {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 32px;
                    &:before {
                        position: absolute;
                        width: 42px;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.1);
                        top: 50%;
                        left: -72px;
                        transform: translateY(-50%);
                        content: '';
                    }

                    .custome-filter-driver-circle-add-one {
                        .customer-filter-driver-circle-add {
                            top: 4px;
                            left: -30px;
                        }
                    }
                    .customer-filter-driver-circle-del {
                        width: 24px;
                        height: 24px;
                        background: rgba(255, 255, 255, 1);
                        border-radius: 50%;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        text-align: center;
                        line-height: 21px;
                        cursor: pointer;
                        i {
                            font-size: 12px;
                            color: #1b6dff;
                        }
                    }
                }

                .custome-filter-driver-two-box {
                    position: relative;
                    margin-left: 72px;
                    padding: 20px 0;
                    &:before {
                        position: absolute;
                        width: 1px;
                        height: calc(100% - 20px);
                        background: rgba(0, 0, 0, 0.1);
                        top: 0px;
                        left: -63px;
                        // transform: translateY(-50%);
                        content: '';
                    }
                    .customer-filter-driver-circle-add {
                        top: calc(100% - 20px);
                        left: -73px;
                    }
                    .customer-filter-driver-three-box {
                        position: relative;
                        margin-left: 50px;
                        &:before {
                            position: absolute;
                            width: 50px;
                            height: 1px;
                            background: rgba(0, 0, 0, 0.1);
                            top: 50%;
                            left: -112px;
                            transform: translateY(-50%);
                            content: '';
                        }
                        &:after {
                            position: absolute;
                            width: 1px;
                            height: calc(100% - 20px);
                            background: rgba(0, 0, 0, 0.1);
                            top: 20px;
                            left: -63px;
                            // transform: translateY(-50%);
                            content: '';
                        }
                        .customer-filter-driver-circle-add {
                            top: 100%;
                        }
                        .custome-filter-driver-box-and {
                            position: absolute;
                            width: 48px;
                            height: 32px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 16px;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            line-height: 28px;
                            color: #1b6dff;
                            font-size: 16px;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -85px;
                            z-index: 1;
                        }
                    }
                    .custome-filter-driver-two {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 5px 0px;
                        height: 42px;
                        &:before {
                            position: absolute;
                            width: 62px;
                            height: 1px;
                            background: rgba(0, 0, 0, 0.1);
                            top: 50%;
                            left: -62px;
                            transform: translateY(-50%);
                            content: '';
                        }
                        .customer-filter-driver-circle-del {
                            width: 24px;
                            height: 24px;
                            background: rgba(255, 255, 255, 1);
                            border-radius: 50%;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            text-align: center;
                            line-height: 21px;
                            cursor: pointer;
                            i {
                                font-size: 12px;
                                color: #1b6dff;
                            }
                        }
                    }
                }
                .custome-filter-driver-two-box-driver-length {
                    &:before {
                        height: 50%;
                    }
                }
            }
            .custome-filter-driver-one-box-length {
                height: 40px;
                &:before {
                    height: 40px;
                }
                .custome-filter-driver-two-box {
                    margin-bottom: 100px;
                }
            }
        }
    }
    .business-analysis-page-list-line {
        margin: 10px 0;
        height: 480px;
        padding: 10px;
        background: #fff;
        position: relative;

        .business-analysis-page-list-line-time {
            position: absolute;
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: rgba(255, 255, 255, 1);
            border-radius: 3px 0px 0px 3px;
            top: 10px;
            right: 10px;
            z-index: 100;
            cursor: pointer;
            div {
                cursor: pointer;
                padding: 7px 16px;
                border-right: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
        .timeColor-echars {
            background: rgba(232, 244, 255, 1);
            border: 1px solid rgba(27, 109, 255, 1);
            border-right: 1px solid rgba(27, 109, 255, 1) !important;
        }
    }
    .bussiness-analysis-page-list-echarts-list {
        display: flex;
        justify-content: space-between;
        >div {
            border-radius: 3px;
        }
        .business-analysis-page-list-sankey {
            width: calc(50% - 5px);
            height: 500px;
            background: #fff;
            padding: 10px;
        }
        .business-analysis-page-list-sankey {
            width: calc(50% - 5px);
            height: 500px;
            background: #fff;
            padding: 10px;
        }
    }
    .echarts-data-none {
        width: 100%;
        height: 100%;
    }
}
.custome-filter-tootip {
    .udesk-qa-ui-tooltip-inner {
        background: #fff;
        color: #1b6dff;
        text-align: center;
        .custome-filter-tootip-button {
            width: 100%;
            p {
                cursor: pointer;
            }
        }
    }
}
