.general-inspection-report-page {
    background: rgb(242, 242, 244);
    .general-inspection-report-page-filter {
        padding: 10px;
        background: #fff;
        border-radius: 3px;
    }
    .general-inspection-report-page-data {
        margin-top: 16px;
        padding: 10px;
        background: #fff;
        border-radius: 3px;
        .general-list-title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
        }
        .general-inspection-report-page-data-list {
            margin-top: 16px;
            padding: 16px 80px;
            .general-inspection-report-page-data-list-info {
                display: flex;
                justify-content: row;

                .general-inspection-report-page-data-list-info-left {
                    width: 40%;
                    h2 {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.85);
                        font-weight: 500;
                    }
                    div {
                        padding-top: 24px;
                        font-size: 40px;
                        color: rgba(27, 109, 255, 1);
                    }
                }
                .info-text {
                    div {
                        color: rgba(30, 148, 148, 1);
                    }
                }
                .general-inspection-report-page-data-list-info-right {
                    width: 15%;
                    h2 {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.65);
                        font-weight: 400;
                    }
                    div {
                        padding-top: 10px;
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.85);
                        font-weight: 500;
                    }
                    p {
                        padding-top: 10px;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.65);
                        font-weight: 400;
                    }
                }
            }
        }
        .general-inspection-report-page-data-line {
            height: 350px;
            position: relative;
            .general-inspection-echarsType {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 100;
                display: flex;
                div {
                    padding: 0 5px;
                    min-width: 60px;
                    height: 32px;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
                    border-radius: 0px 3px 3px 0px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    text-align: center;
                    line-height: 34px;
                    cursor: pointer;
                }
                .general-inspection-echarsType-active {
                    background: rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
                    border-radius: 3px 0px 0px 3px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                }
            }
        }
    }
    .general-inspection-report-page-effect {
        margin-top: 16px;
        padding: 10px;
        background: #fff;
        border-radius: 3px;
        .general-list-title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
        }
        .general-inspection-report-page-effect-list {
            margin-top: 16px;
            padding-bottom: 16px;
            .general-inspection-report-page-effect-list-info {
                padding: 16px 80px;
                display: flex;
                .general-inpsection-report-page-effect-list-info-field {
                    width: 25%;
                    h2 {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.85);
                        font-weight: 500;
                        height: 15px;
                    }
                    p {
                        padding-top: 10px;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.65);
                        font-weight: 400;
                    }
                    .field-big-effect-sp {
                        padding-top: 24px;
                        font-size: 40px;
                        color: rgba(27, 109, 255, 1);
                    }
                    .field-big-effect-rew {
                        padding-top: 24px;
                        font-size: 40px;
                        color: rgba(30, 148, 148, 1);
                    }
                    .field-small-effect {
                        padding-top: 10px;
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.85);
                        font-weight: 500;
                    }
                    .field-top-p-style1 {
                        padding-top: 24px;
                    }
                    .field-top-p-style2 {
                        padding-top: 39px;
                    }
                }
            }
        }
        .general-inspection-report-page-effect-line {
            height: 350px;
        }
        .general-inspection-report-page-effect-table {
            padding: 0 80px;
            height: 460px;
            .general-effect-button-good {
                font-weight: 400;
                color: rgba(27, 109, 255, 1);
                cursor: pointer;
            }
        }
    }
    .general-inspection-report-page-result {
        margin-top: 16px;
        padding: 10px;
        background: #fff;
        border-radius: 3px;
        .general-list-title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
        }
        .result-list-more {
            margin-top: 10px;
            text-align: center;
            span {
                font-weight: 400;
                color: rgba(27, 109, 255, 1);
                cursor: pointer;
            }
        }
        .general-inpection-report-page-result-list {
            margin-top: 16px;
            padding: 16px 80px;
            .general-inspection-report-page-result-list-info {
                width: 100%;
                padding: 16px;
                background: rgb(242, 242, 244);
                margin-top: 16px;
                position: relative;
                .result-info-more {
                    display: none;
                }
                .result-list-info-more {
                    position: absolute;
                    bottom: -18px;
                    width: 100%;
                    left: 0px;
                    text-align: center;
                    i {
                        font-size: 44px;
                        cursor: pointer;
                        color: rgba(27, 109, 255, 1);
                    }
                }
                .result-list-info-title {
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 0;
                    border-bottom: 1px solid #d8d8d8;
                    h2 {
                        color: rgba(0, 0, 0, 0.65);
                        font-size: 14px;
                    }
                    .result-list-info-title-name {
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                    }
                    .result-list-info-title-margin {
                        margin-left: 10px;
                    }
                }
                .result-list-info-list-data {
                    margin-top: 16px;

                    .udesk-qa-ui-card-body {
                        padding-right: 0px;
                        padding-bottom: 0px;
                        position: relative;
                    }
                    .result-list-info-list-data-Card {
                        padding-right: 10px;
                        height: 167px;
                        overflow-y: auto;

                        .result-list-info-list-dat-Card-absolute {
                            position: absolute;
                            background: rgba(255, 255, 255, 0.65);
                            bottom: 0px;
                            height: 10px;
                            width: 100%;
                            left: 0;
                        }
                        .result-list-info-list-data-Card-info {
                            padding-bottom: 10px;
                            display: flex;
                            justify-content: space-between;
                            .result-list-info-list-data-info-left {
                                width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
            .result-list-info {
                display: none;
            }
        }
    }
    .general-inspection-report-page-agent {
        margin-top: 16px;
        padding: 10px;
        background: #fff;
        border-radius: 3px;
        .general-list-title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
        }
        .general-inspection-report-page-agent-list {
            margin-top: 16px;
            padding: 16px 80px;
            .general-inspection-report-page-agent-list-table {
                height: 460px;
                width: 100%;
            }
        }
    }
    .general-inspection-report-page-proposal {
        margin-top: 16px;
        padding: 10px;
        background: #fff;
        border-radius: 3px;
        .general-list-title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
        }
        .general-inspection-report-page-proposal-list {
            margin-top: 16px;
            padding: 16px 80px;
            display: flex;
            .general-inspection-report-page-proposal-list-textarea {
                width: 40%;
                .proposal-list-button {
                    margin-top: 10px;
                }
            }
            .general-inspection-report-page-proposal-list-history {
                width: 60%;
                padding-left: 40px;
                .general-inspection-report-page-proposal-list-history-list {
                    border-radius: 3px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    height: 440px;
                    .general-inspection-report-page-proposal-list-history-list-title {
                        height: 40px;
                        background: rgba(247, 247, 247, 1);
                        border-radius: 3px 3px 0px 0px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        line-height: 41px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        padding: 0 10px;
                    }
                    .general-inspection-report-page-proposal-list-history-list-info {
                        padding: 0 10px;
                        height: 400px;
                        overflow-y: auto;
                        .proposal-list-history-list-info-field {
                            padding: 10px 0;
                            .field-title {
                                font-weight: 400;
                                color: rgba(0, 0, 0, 0.85);
                                font-size: 14px;
                            }
                            .field-time {
                                font-weight: 400;
                                color: rgba(0, 0, 0, 0.45);
                                font-size: 14px;
                                margin-left: 10px;
                            }
                            .field-info-list {
                                margin-top: 5px;
                                .field-info {
                                    font-weight: 400;
                                    color: rgba(0, 0, 0, 0.65);
                                    margin-top: 5px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
