.role-detail-task-permissions-page {
    height: 100%;
    .udesk-qa-web-page-content {
        display: flex;
        flex-direction: column;
    }
    .role-detail-data-permissions-page-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
    }
    .role-detail-task-permissions-page-content {
        // height: calc(100vh - 250px);
        flex: 1;
        overflow-y: auto;

        .role-detail-task-permissions-page-title-row {
            .role-detail-task-permissions-page-title {
                margin-right: 10px;
                text-transform: capitalize;
            }
        }

        .role-detail-task-permissions-page-tree-row {
            padding-left: 30px;

            .react-tree.default {
                .tree-node {
                    &.active-node {
                        .node-name {
                            background-color: #fff;
                            &:hover {
                                background-color: #fff;
                            }
                        }
                    }
                    &.checked-node {
                        .node-name {
                            background-color: #fff;
                        }
                    }
                    &.checkable-node:not(.checked-node) {
                        .node-name:hover {
                            background-color: #fff;
                        }
                    }
                    &.clickable-node:not(.active-node) {
                        .node-name:hover {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }

    .role-detail-task-permissions-page-button-row {
        margin-top: 20px;
        text-align: center;
    }
}
