.check-point-operator-list-gather-component {
    .operator-list-item-associate-model {
        width: 280px;
        height: 340px;
        overflow-y: auto;
        .operator-list-item-associate-model-item {
            margin-top: 4px;
            cursor: pointer;
            &:hover {
                background-color: rgba(232, 244, 255, 1);
                // color: #1b6dff;
                border-radius: 2px;
            }
        }
        .operator-list-item-associate-model-operator {
            display: flex;
            padding: 4px;
            margin-top: 8px;
            cursor: pointer;
            .operator-list-item-associate-model-operator-icon {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
            }
            .operator-list-item-associate-model-operator-content {
                flex: 1;
                .operator-list-item-associate-model-operator-title {
                    color: rgba(0, 0, 0, 0.85);
                    line-height: 20px;
                    font-size: 12px;
                }
                .operator-list-item-associate-model-operator-des {
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 20px;
                    font-size: 12px;
                }
            }
            &:hover {
                background-color: rgba(232, 244, 255, 1);
                // color: #1b6dff;
                border-radius: 2px;
            }
        }
    }
}