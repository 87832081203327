.task-center-detail-container {
    // height: 100%;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    .udesk-qa-web-page-body-root-spin {
        flex: 1;
        display: flex;
        flex-direction: column;
        .udesk-qa-ui-spin-container {
            flex: 1;
        }
    }
    .udesk-qa-web-page-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .udesk-qa-ui-tabs {
            overflow: unset;
            flex: 1;
        }
        .udesk-qa-ui-tabs-nav {
            margin-bottom: 0;
        }
        .udesk-qa-ui-tabs-content {
            height: 100%;
            .udesk-qa-ui-standardize-table {
                > div {
                    padding: 0;
                }
                .udesk-qa-ui-common-filter {
                    margin-bottom: -12px;
                }
            }
        }
    }
    .tcd-header-wrapper {
        width: 100%;
        // padding: 0 30px;
        // border-bottom: 10px solid #f2f2f2;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #fff;
        .tcd-header-title {
            width: 100%;
            padding: 12px 2px;
            font-size: 16px;
            p {
                display: inline-block;
                &:last-child {
                    margin-left: 12px;
                }
            }
        }
        >.udesk-qa-ui-spin-nested-loading {
            width: 40%;
        }
        .tcd-header-filter-box {
            background: #fff;
            width: 100%;
            padding: 15px;
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            .tcd-header-filter-box-left {

            }
            .tcd-header-filter-box-right {
                button {
                    margin-left: 8px;
                }
            }
        }
        .no-border {
            margin-top: 12px;
            border: none !important;
            .echarts-title {
                margin-left: 12px;
                width: 50%;
                font-size: 18px;
                font-weight: blod;
            }
        }
        .tcd-header-echarts {
            display: flex;
            overflow: auto;
            flex: 1;
            // justify-content: space-around;
            margin-left: 8px;
            border-bottom: 1px solid #ddd;
            // >div {
            //     margin-left: 8px;
            //     flex: 1;
            //     overflow: hidden;
            // }
            .tcd-header-echarts-item {
                display: flex;
                .tcd-header-echarts-item-left {

                }
                .tcd-header-echarts-item-right {
                    
                }
            }
            .Illegal-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .Illegal-title {
                    font-size: 16px;
                    font-weight: bold;
                }
                .Illegal-content {
                    margin-top: 8px;
                    .udesk-qa-ui-tag {
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                }
            }
        }
        // overflow-x: auto;
        .tcd-header-base-info-item {
            font-size: 14px;
            color: #000;
            .tcd-header-base-info-item-title {
                &:after {
                    content: ':';
                }
            }
            &.organization-member-select {
                width: 100%;
                &:last-child {
                    overflow-x: hidden;
                }
            }
        }
    }
    .tcd-rate-charts-container {
        width: 200px;
        height: 200px;
    }
    .echarts-wrap {
        margin-top: 20px;
        height: 570px;
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;