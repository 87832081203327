$bg-custom: rgba(251,81,39,0.5);
$bg-user: rgba(26,110,255,0.7);
$bg-size: #1b6dff;
$bg-common-color: #EFEFEF;

.pages-gong-sale-client-center-detail-container {
    .bg-size-color {
        color: $bg-size;
    }
    .bg-size-color.active {
        color: #8C8C8C;
    }
    .udesk-qa-ui-btn-link.udesk-qa-ui-btn {
        padding: 4px 0;
    }

    .bg-custom,
    .bg-user {
        position: relative;
        
        > div {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: inline-flex;
            flex-flow: column;
            justify-content: center;
            color: #fff;
            border-radius: 50%;
        }
        &:hover {
            z-index: 2000;
        }
    }

}