.client-center-root-page-index {
    .udesk-qa-ui-page-header {
        display: none;
    }
    .udesk-qa-web-page {
        padding-bottom: 0px !important;
        .udesk-qa-web-page-body {
            padding: 0px !important;
            overflow-y: unset !important;
            .udesk-qa-web-page-body-root {
                &>div {
                    padding: 0px !important;
                }
            }
        }
    }
    .tasks-manage-template-automatic-add-page {
        background-color: #fff !important;
        .tasks-automatic-add-card {
            width: 100%;
        }
    }
}