.wave-audio-component-talk-v3 {
    .wave-audio-container {
        canvas {
            top: -43px !important;
        }
    }
    .call-detail-wave-audio-player-tips {
        margin-top: 16px;
        width: 100%;
        white-space: nowrap;
        display: flex;
        h3 {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 22px;
        }
        span {
            color: #F09A00;
            font-size: 12px;
            line-height: 20px;
            background: #F09A001a;
            border: 1px solid #F09A00;
            border-radius: 17px;
            padding: 2px 8px;
            margin-right: 8px;
            cursor: pointer;
        }
        span.active {
            color: #fff;
            background: #F09A00;
        }
        ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
        ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
            background: #e0e5eb;
        }
        ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            // background: rgba(0,0,0,0.1);
        }
    }
    .call-detail-wave-audio-player-tips-events {
        margin-top: 16px;
        width: 100%;
        // white-space:nowrap;
        display: flex;
        h3 {
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 22px;
        }
        span {
            font-size: 12px;
            line-height: 20px;
            border-radius: 17px;
            padding: 2px 8px;
            margin-right: 8px;
            cursor: pointer;
        }
        ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
        ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
            background: #e0e5eb;
        }
        ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            // background: rgba(0,0,0,0.1);
        }
    }
}
