.result-analysis-container {
    width: 100%;
    padding: 16px 0;
    .result-analysis-top-wrapper {
        display: flex;
        overflow-x: auto;
        height: 150px;
        .result-card-content-wrapper {
            // height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .score-content {
                font-size: 40px;
                font-weight: bold;
                color: #257aed;
            }
            .top-list-item {
                display: flex;
                align-items: center;
                // margin: 2px 10px;
            }
        }
    }
    .result-analysis-content-wrapper {
        // margin-top: 16px;
        display: flex;
        .detail-score-standard-words {
            padding: 12px 16px;
            background: #F5F6F8;
            border-radius: 2px;
            color: rgba(0, 0, 0, 0.65);

            > div:nth-child(1) {
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;