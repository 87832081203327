@media (max-width: 1366px) {
    .check-point-condition-item {
        width: 860px;
        display: flex;
    }
}
@media (min-width: 1367px) {
    .check-point-condition-item {
        min-height: 112px;
        width: 100%;
        min-width: 902px;
        display: flex;
    }
}

.check-point-condition-item {
    // min-height: 112px;
    // width: 100%;
    // min-width: 982px;
    // display: flex;
    .check-point-condition-item-color {
        width: 8px;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .check-point-condition-item-content {
        width: calc(100% - 10px);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-left: none;
        .check-point-condition-item-content-top {
            min-height: 64px;
            line-height: 64px;
            display: flex;
            .check-point-condition-item-content-rule-code {
                margin: 0 8px;
                width: 27px;
            }

            .check-point-condition-item-content-rule-info {
                margin-right: 8px;
            }

            .check-point-condition-item-content-rule-type {
                margin-right: 8px;
            }

            .check-point-condition-item-content-test-mode {
                margin-left: 24px;
            }
        }
        .check-point-condition-item-content-bottom {
            // flex: 1;
            min-height: 48px;
            background-color: rgba(0, 0, 0, 0.03);
            line-height: 48px;
        }
    }
}
