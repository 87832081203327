.indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);

    svg polyline {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;

        &#back {
            stroke: rgba(59, 211, 171, 0.3);
        }

        &#front {
            stroke: #3bd3ab;
            stroke-dasharray: 12, 36;
            stroke-dashoffset: 48;
            animation: udesk-loading-animation-dash 1s linear infinite;
        }
    }
}

.cta {
    position: fixed;
    bottom: 20px;
    right: 30px;
    color: #222;
    font-weight: bold;
}

@keyframes udesk-loading-animation-dash {
    62.5% {
        opacity: 0;
    }

    to {
        stroke-dashoffset: 0;
    }
}
