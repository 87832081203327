.page-file-case-base-container {
    padding-bottom: 0 !important;
    .udesk-qa-web-page-body {
        padding: 0 !important;
    }
    .page-file-case-base-body {
        padding: 0 !important;
    }
}
.page-folder-files-case-base-container {
    display: flex;
    height: calc(100vh - 64px);
    .folder-file-search {
        height: 100%;
        overflow-y: auto;
        padding: 16px 8px;
        box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1), -2px 0px 8px rgba(0, 0, 0, 0.05), -4px 0px 14px rgba(0, 0, 0, 0.05);
        // .common-component-files-tree-container {
        //     margin-top: 16px;
        // }
        .collects-case-base-tab {
            margin-top: 16px;
            line-height: 24px;
            margin-bottom: 4px;
            margin-left: 24px;
            padding-left: 4px;
            cursor: pointer;
            &.active {
                background: #E6F3FF;
                color: #1A6EFF;
            }
        }
    }
    .folder-file-container {
        flex: 1;
        .folder-back {
            display: flex;
            align-items: center;
            height: 60px;
            margin-left: 16px;
            h3 {
                line-height: 28px;
                font-size: 20px;
                font-weight: 700;
            }
        }
        .folder-file-content {
            height: calc(100vh - 124px);
            padding: 16px;
            overflow-y: auto;
            border: 16px solid #F5F6F8;
            & > p {
                font-size: 12px;
                line-height: 20px;
            }
            .folder-content {
                display: flex;
                flex-wrap: wrap;
                .component-common-folder-item-container {
                    margin-top: 24px;
                    margin-right: 16px
                }
            }
            .file-content {
                margin-top: 33px;
                .component-common-file-item-container {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.pages-case-base-modal-move-folder-container {
    // .common-component-files-tree-container {
    //     max-height: calc(100vh - 360px);
    //     overflow-y: auto;
    // }
    .udesk-qa-ui-modal-body {
        max-height: 400px;
        overflow-y: auto;
    }
}