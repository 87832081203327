.audio-text-list-container {
    .audio-text-list{
        .udesk-image-viewer {
            display: block;
        }
        .audio-text-list-item {
            display: flex;
            .audio-text-list-item-appeal-container {
                align-self: center;
            }
        }
    }

    .audio-text-search-container {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        .audio-text-search-input {
            margin-left: 10px;
            width: 40%;
        }

        .audio-text-search-button {
            margin-left: 5px;
            height: 26px;
            width: 26px;
            padding: 0;
            border: 1px solid #cccccc;
        }
    }

    .audio-text-highlight-navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30px;
        height: 100px;
        position: absolute;
        left: 25px;
        top: 40%;
        z-index: 3;

        .audio-text-highlight-navigation-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            cursor: pointer;
            margin-bottom: 5px;
            text-align: center;
            font-size: 10px;
            background-color: #ccc;
        }

        .button-up {
            border-top-right-radius: 13px;
            border-top-left-radius: 13px;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .button-down {
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            border-bottom-right-radius: 13px;
            border-bottom-left-radius: 13px;
        }

        .audio-text-highlight-navigation-text {
            text-align: center;
        }

        .audio-text-highlight-navigation-close {
            height: 20px;
            cursor: pointer;
        }
    }
}