.check-point-condition-list-gather {
    display: flex;
    flex-direction: column;
    // @media (max-width:1366px) {
    //     .check-point-condition-list-item {
    //         width: 860px;
    //     }
    // }
    // @media (min-width:1367px) {
    //     .check-point-condition-list-item {
    //         min-width: 902px;
    //     }
    // }
    .check-point-condition-list-item {
        margin-top: 16px;
        min-height: 112px;
        // min-width: 860px;
        width: 100%;
        // min-width: 917px;
        display: flex;
        border-radius: 3px;
        &:first-child {
            margin-top: 0px;
        }
        .tasks-manage-template-configuration-detail-rule-item-delete {
            line-height: 112px;
            margin-left: 8px;
        }
    }
    .check-point-condition-list-add {
        display: flex;
        margin-top: 24px;
        .check-point-condition-list-add-btn {
            min-width: 76px;
            height: 32px;
            line-height: 32px;
            margin-right: 9px;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            cursor: pointer;
            text-align: center;
        }
    }
}
