.massage-configuration {
    min-width: 655px;
    width: calc(100% - 63px);
    padding: 24px;
    background: rgba(0, 0, 0, 0.03);
    .massage-configuration-row {
        display: flex;
        margin-bottom: 24px;
        // align-items: baseline;
        .massage-configuration-row-label {
            min-width: 70px;
            height: 24px;
            line-height: 24px;
        }
        
        .massage-configuration-row-content {
            flex: 1;
            .udesk-qa-ui-checkbox-group {
                .udesk-qa-ui-checkbox-group-item {
                    height: 24px;
                    line-height: 24px;
                }
            }
            .auto-add-filter-edit-item-row-filter {
                background: rgba(0, 0, 0, 0.03);
                border-radius: 3px;
                width: 100%;
                padding: 16px;
                display: flex;
                flex-direction: column;
                line-height: 22px;
                // margin-bottom: 16px;
                cursor: pointer;
                .auto-add-filter-edit-item-row-filter-title {
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 16px;
                    display: flex;
                    justify-content: space-between;
                }
                .auto-add-filter-edit-item-row-filter-rules {
                    display: flex;
                    flex-direction: column;
            
                    .auto-add-filter-edit-item-row-filter-title {
                        color: rgba(0, 0, 0, 0.85);
                        margin-bottom: 16px;
                    }
                    .auto-add-filter-edit-item-row-content {
                        width: calc(100% - 80px);
                        color: rgba(0, 0, 0, 0.65);
                    }
                }
                .index-management-custom-filter-Info {
                    display: flex;
                    margin-bottom: 16px;
                    .auto-add-filter-edit-item-row-filter-title {
                        color: rgba(0, 0, 0, 0.85);
                    }
                    .auto-add-filter-edit-item-row-filter-rules {
                        color: rgba(0, 0, 0, 0.65);
                        .auto-add-filter-edit-item-row-filter-title {
                            color: rgba(0, 0, 0, 0.85);
                        }
                        .auto-add-filter-edit-item-row-content {
                            width: calc(100% - 80px);
                            color: rgba(0, 0, 0, 0.65);
                        }
            
                    }
                }
            }
        }
    }
    .massage-name {
        align-items: baseline;
    }
    .udesk-qa-list-btn-icon {
        margin-right: 4px;
        font-size: 14px;
    }
    .massage-configuration-last-row {
        margin-bottom: unset;
    }
}