.business-records-list-container {
    .business-records-list {
        padding: 15px 4px;

        .business-records-list-item {
            margin-bottom: 2px;
            display: flex;
            padding: 4px 8px;
            border-radius: 3px;

            &:hover {
                background-color: #e6f4ff;
            }

            .business-records-list-item-title {
                display: inline-block;
                padding-right: 12px;
                width: 110px;
                text-align: right;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;

                &::after {
                    content: ':';
                    right: 6px;
                    position: absolute;
                }
            }

            .business-records-list-item-content {
                display: inline-block;
                vertical-align: top;
                // max-width: calc(100% - 110px);
                flex: 1;
                word-wrap: break-word;
                word-break: break-all;

                .business-records-custom-field {
                    display: block;

                    .business-records-custom-field-name {
                    }

                    .business-records-custom-field-value {
                    }
                }
            }
        }
    }
}

.udesk-qa-component-element[lang='en'] {
    .business-records-list-item-title {
        width: 180px !important;
    }
}
