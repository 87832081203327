.client-center-root-page-index {
    .udesk-qa-ui-page-header {
        display: none;
    }
    .udesk-qa-web-page {
        padding-bottom: 0px !important;
        .udesk-qa-web-page-body {
            padding: 0px !important;
            overflow-y: unset !important;
            .udesk-qa-web-page-body-root {
                &>div {
                    padding: 0px !important;
                }
            }
        }
    }
    .tasks-manage-template-automatic-add-page {
        background-color: #fff !important;
        .tasks-automatic-add-card {
            width: 100%;
        }
    }
}

.page-qa-message-center-notice-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .comment-display-item {
        flex: 1;
    }
    .message-center-audio-message {
        display: flex;
        align-items: center;
        background: #E6F3FF;
        padding: 16px;
        border-radius: 2px;
        border: 1px solid #BDDEFF;
        margin-left: 34px;
        cursor: pointer;
        .message-center-audio-message-text {
            flex: 1;
            margin-right: 16px;
            & > h3 {
                color: rgba(0, 0, 0, 0.65);
                font-size: 16px;
                margin-bottom: 20px;
                font-weight: 500;
                line-height: 24px;
            }
            & > p {
                color: rgba(0, 0, 0, 0.65);
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}


.page-qa-message-center-notice-item.study {
    display: block;
    width: 100%;

    .comment-display-item {
        width: 100%;
        
        .comment-display-item-body {
            display: flex;
            width: calc(100% - 40px);

            >.content {
                display: inline-block;
                width: calc(100% - 220px);
                line-height: 40px;
                margin-bottom: 0;
                margin-right: 20px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
    
            >.child-comments {
                flex: 0 0 220px;
            }
        }
    }
}