.wechat-docking-page-index {
    .udesk-qa-ui-card-head-title {
        font-weight: 500;
    }
    .udesk-qa-ui-form-item-control-input-content {
        .udesk-qa-ui-space {
            width: 100%;
            >.udesk-qa-ui-space-item:first-child {
                flex: 1;
            }
        }
    }
    .udesk-qa-ui-card-head {
        border-left: 4px solid #1A6EFF;
    }
}