.task-condition-gather-component {
    .condition-gather-component-item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .rule-item-rule-filter-btn {
            width: max-content;
            height: 30px;
            line-height: 30px;
            margin-top: 24px;
            display: block;
            cursor: pointer;
            margin-right: 5px;
            color: rgba(27, 109, 255, 1);
        }
        .condition-gather-component-item-add {
            height: 30px;
            line-height: 30px;
            margin-top: 24px;
            width: 100px;
            display: block;
            cursor: pointer;
            margin-right: 5px;
            color: rgba(27, 109, 255, 1);
        }

        .condition-gather-component-sampling-frequency {
            display: inline-block;
            width: 75px;
            margin-right: 5px;
        }

        .condition-gather-component-sampling-time-container {
            display: inline-block;

            .condition-gather-component-sampling-weekday {
                display: inline-block;
                width: 75px;
                margin-right: 5px;
            }
        }

        .condition-gather-component-sampling-time-range-container {
            display: inline-block;

            .condition-gather-component-sampling-time-range-title {
                display: inline-block;
                height: 30px;
                line-height: 30px;
            }

            .condition-gather-component-sampling-time-range {
                display: inline-block;
                width: 75px;
                margin-right: 5px;
            }
        }

        .condition-gather-component-sampling-type-container {
            display: inline-block;

            .condition-gather-component-sampling-type {
                display: inline-block;
                width: 100px;
                margin-right: 5px;
            }

            .condition-gather-component-sampling-number {
                display: inline-block;
                width: 100px;
                margin-right: 5px;
            }

            .condition-gather-component-sampling-number-unit {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
            }
        }

        .condition-gather-component-delete-icon-container {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            margin-left: 8px;
            color: #1b6dff;
            white-space: nowrap;
        }
    }

    .condition-gather-component-add-icon-container {
        height: 30px;
        line-height: 30px;

        .condition-gather-component-add-icon {
            display: inline-block;
            cursor: pointer;
            margin-right: 5px;
            color: #1b6dff;
            white-space: nowrap;
        }
    }
}
