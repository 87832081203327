.intelligent-tags-detail-page-body {
    .intelligent-tags-detail-page-body-select {
        max-width: 80%;
    }

    .intelligent-tags-detail-page-body-edit-action {
        display: inline-block;
        color: #2878ff;
        cursor: pointer;
    }

    .intelligent-tags-detail-page-radios {
        .intelligent-tags-detail-page-radio-item {
            display: inline-block;
            margin-right: 10px;
            width: 100px;
        }
    }
    .tasks-manage-template-configuration-detail-logic-rule {
        margin-top: 4px;
    }
    .data-preprocess-custom {
        position: relative;
        .index-manmagement-custom-drag-tarsk {
          position: absolute;
          text-align: center;
          top: -41px;
          z-index: 1;
          padding: 10px;
          background: rgba(0, 0, 0, 0.5);
          left: 50%;
          width: 200px;
          margin-left: -100px;
        }
        .data-preprocess-custom-drap-title {
          padding: 8px 0;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
        .data-preprocess-cutom-drap-info {
          display: flex;
          width: calc(100% - 42px);
          .deleted-item {
              background-color: #ffa39e !important;
          }
        }
        display: flex;
    }
    .warn-form-item-box {
        .next-form-item-control {
            display: flex;
        }
    }
}

.label-tabs-list-page {
    border: 1px solid rgba(0, 0, 0, 0.1);
    ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
    }
    ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        background: #e0e5eb;
    }
    ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 0;
        // background: rgba(0,0,0,0.1);
    }
    .label-tabs-list-page-field {
        display: inline-block;
        width: 170px;
        padding-right: 16px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.03);
        height: 278px;
        vertical-align: top;
        position: relative;
        overflow-y: auto;
        .label-field-target-drag {
            position: absolute;
            bottom: 5px;
            right: 2px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.25);
            cursor: col-resize;
        }
        .label-tabs-list-page-field-drag {
            min-height: 278px;
            height: auto;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 8px 0;
            .label-field-info {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 24px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 8px;
            }
            .label-field-info-active {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 24px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 8px;
                background: rgba(27, 109, 255, 1);
                color: #fff;
            }
            .label-field-info-active-parent {
                background: rgba(232, 244, 255, 1);
            }
            .label-field-info-add {
                font-size: 12px;
                font-weight: 400;
                color: rgba(27, 109, 255, 1);
                line-height: 24px;
                padding: 0 8px;
                cursor: pointer;
            }
        }
    }
}
