.check-point-operator-list-gather-component {
    display: flex;
    width: calc(100% - 153px);
    flex-wrap: wrap;
    .operator-list-item-type {
        display: inline-block;
        width: 128px;
    }

    .operator-list-item-entity-info-container {
        // display: flex;
        display: inline-block;
        .operator-list-item-entity-operator-container {
            display: inline-block;
            margin-left: 8px;
            .operator-list-item-entity-operator {
                width: 114px;
            }
        }
        .operator-list-item-semantic-tags-search-container {
            display: inline-block;
            width: 200px;
            margin-left: 8px;
        }
        .operator-list-item-entity-field-container {
            display: inline-block;
            margin-left: 8px;
        }
        .operator-list-item-entity-value-container {
            display: inline-block;
        }
    }
    .operator-list-item-operator {
        display: inline-block;
        margin-left: 8px;
        width: 114px;
    }
    .operator-list-item-keyword {
        display: inline-block;
        margin-left: 8px;
        width: 320px;
    }
    .operator-list-item-questions-and-answers {
        display: flex;
        align-items: center;
        margin-left: 8px;
        margin-right: 16px;
        width: 320px;
    }
    .operator-list-item-semantic-tags-container {
        display: inline-block;
        .operator-list-item-semantic-tags-search-container {
            display: inline-block;
            // width: 350px;
            margin-left: 8px;
            .operator-list-item-semantic-tags-sentence {
                display: inline-block;
                width: 200px;
            }
            .operator-list-item-button {
                display: inline-block;
                margin-left: 8px;
            }
        }
    }
    .operator-list-item-semantic-tags-result-container {
        // width: calc(100% - 486px);
        height: 64px;
        .operator-list-item-semantic-tags-result-tag {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 25px;
            line-height: 32px;
        }
        .operator-list-item-semantic-tags-result-sentence {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 32px;
            line-height: 32px;
        }
    }
    .operator-list-item-grab-detection-box {
        display: inline-block;
        margin-left: 8px;
    }
    .operator-list-item-interval-second-box {
        display: inline-block;
        margin-left: 8px;
    }
    .operator-list-item-entity-operator {
        width: 114px;
        margin-left: 8px;
    }
    .operator-list-item-word-per-minute-box {
        display: inline-block;
        margin-left: 8px;
        .operator-list-item-word-per-minute {
            margin-left: 8px;
        }
    }
    .operator-list-item-volume-detection-way {
        width: 150px;
        margin-left: 8px;
    }
    .operator-list-item-volume-detection-value-box {
        display: inline-block;
        margin-left: 8px;
    }
    .operator-list-item-volume-detection-operator {
        margin-left: 8px;
        width: 150px;
    }
}
.operator-list-item-operator-rugelar-list {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    line-height: 32px;
}
