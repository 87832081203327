.related-records-list-page {
  padding: 10px;
  h2 {
    font-weight: bold;
    font-size: 14px;
  }
  .related-records-list-page-content {
    padding: 10px 0;
    .related-records-list-page-content-list {
      display: flex;
      flex-direction: row;
      border-top: 1px solid #ddd;
      padding: 10px 0;
      .related-records-list-page-content-list-title {
        color: rgb(146, 146, 146);
      }
      .related-records-list-page-content-list-one {
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        .related-records-list-page-content-list-one-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 25%;
          padding-bottom: 10px;
        }
      }
      .related-records-list-page-content-list-three {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 30%;
        .related-records-list-page-content-list-title {
          width: 60px;
        }
        .related-records-list-page-content-list-three-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          .related-records-list-page-content-list-three-card {
            padding: 3px;
            display: block;
            background: rgb(0, 120, 250);
            color: #fff;
            border-radius: 3px;
            margin-left: 10px;
            margin-bottom: 5px;
            cursor: pointer;
            .related-records-list-page-content-list-three-card-price {
              display: inline-block;
              padding: 2px 5px;
              background: #fff;
              color: rgb(116, 116, 118);
              border-radius: 3px;
              margin-left: 5px;
            }
          }
          .related-records-list-page-content-list-three-card-none {
            margin-left: 10px;
          }
        }
      }
      .related-records-list-page-content-list-four {
        width: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          color: rgb(0, 120, 250);
          cursor: pointer;
        }
        & span:hover {
          text-decoration: underline;
        }
      }
    }
    .related-records-list-page-content-list-active {
      background: #ddd;
    }
  }
  .related-records-list-page-footer {
    padding: 10px 0;
    border-top: 1px solid #ddd;
    text-align: center;
  }
}
