.call-list-item-common {
    flex-direction: column;
    cursor: pointer;
    .all-cal-item-image {
        position: relative;
        width: 120px;
        height: 64px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f6f8;
        border: 1px solid #f0f2f5;
        border-radius: 2px;
        & > .time-durtion {
            background: #fff;
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
            padding: 0 4px;
        }
    }
    .call-title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        white-space: nowrap;
    }
    .call-icon {
        border: 1px solid #1a6eff;
        background: #e6f3ff;
        color: #e5f3ff;
        border-radius: 2px;
        color: #1a6eff;
        font-size: 12px;
        line-height: 20px;
        cursor: default;
    }
    .call-commont {
        display: flex;
        align-items: center;
    }
    .call-time,
    .call-commont > span {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        // line-height: 22px;
    }
    .call-commont > span {
        margin-left: 6px;
    }
    .call-space {
        width: 100%;
        display: flex;
        align-items: flex-start;
    }
}

.call-list-item-common-wanan {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2px;
    .item-base-info {
        width: 330px;
        overflow-x: hidden;
    }
    .item-flex {
        display: flex;
        align-items: center;
    }
    .item {
        margin: 0 10px;
        .time-wanna {
            display: flex;
        }
        .content-desc {
            // max-width: 250px;
            // min-width:250px;
            // height:56px;
            // overflow-y: scroll;
            margin-top: 8px;
            // background-color: red;
        }
        .content-desc-flex {
            display: flex;
            justify-content: center;
        }
        .title-wanna {
            color: rgba(0, 0, 0, 0.65);
            // font-size: 12px;
            span {
                margin-right: 3px;
            }
        }
        .all-cal-item-images {
            width: 120px;
            height: 100%;
            margin-right: 16px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f5f6f8;
            border: 1px solid #f0f2f5;
            border-radius: 2px;
            & > .time-durtion {
                background: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.65);
                padding: 0 4px;
            }
        }
        .call-title {
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap;
        }
        .call-icon {
            border: 1px solid #1a6eff;
            background: #e6f3ff;
            color: #e5f3ff;
            border-radius: 2px;
            color: #1a6eff;
            font-size: 12px;
            line-height: 20px;
            cursor: default;
        }
        .call-commont {
            display: flex;
            align-items: center;
        }
        .call-time,
        .call-commont > span {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            // line-height: 22px;
            margin-right: 7px;
        }
        .call-commont > span {
            margin-left: 6px;
        }
        .call-space {
            width: 100%;
            display: flex;
            align-items: flex-start;
        }
        .desc-wana {
        }
    }
}
