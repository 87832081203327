.gear-option-list-component {
    .gear-option-list-item {
        position: relative;
        margin-bottom: 10px;

        .gear-option-list-item-col-text {
            display: inline-block;
            // width: 18%;
            text-align: center;
        }

        .gear-option-list-item-col-input {
            display: inline-block;
            width: 25%;
            margin: 0 5px;
        }

        .gear-option-list-item-delete-icon {
            position: absolute;
            right: -20px;
            top: 5px;
        }
    }
}
