@import './variables';
.react-tree {
    &.text-ellipsis {
        .tree-node {
            width: 250px;
            .node-toggle {
                width: 15px;
            }
            .node-body {
                position: relative;
                width: 230px;
                line-height: 20px;
                .node-name {
                    display: inline-block;
                    max-width: 110px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                }
                .node-actions {
                    vertical-align: middle;
                    width: 90px;
                }
            }
        }
    }
    .node-heading {
        .tree-name {
            display: inline-block;
        }
        .tree-actions {
            margin-left: $component-react-tree-heading-actions-indent;
            display: inline-block;
            .tree-action-item {
                padding-right: $component-react-tree-heading-actions-gap;
                .action-icon {
                    font-size: $component-react-tree-heading-action-size;
                }
            }
        }
    }
    .tree-node {
        padding: 5px 0 5px 100px;
        .invisible {
            visibility: hidden;
        }
        .node-toggle {
            margin-left: -100px;
            display: inline-block;
            .toggle-link {
                vertical-align: middle;
                display: inline-block;
                line-height: 1;
                i.toggle-icon {
                    font-size: $component-react-tree-node-toggle-size;
                }
            }
        }
        .node-check {
            display: inline-block;
            vertical-align: middle;
        }
        .node-body {
            width: 100%;
            display: inline-block;
            vertical-align: middle;
            border: 5px solid transparent;
            .node-name {
                display: inline-block;
                cursor: pointer;
                margin-bottom: 0;
                transition: $component-react-tree-node-name-transition;
                .node-name-text {}
            }
            .node-actions {
                display: inline-block;
                margin-left: $component-react-tree-node-actions-indent;
                opacity: 0;
                transition: $component-react-tree-node-action-transition;
                .node-action-item {
                    padding-right: $component-react-tree-node-actions-gap;
                    .action-icon {
                        font-size: $component-react-tree-node-action-size;
                    }
                }
            }
            .sort-button {
                position: absolute;
                cursor: pointer;
                right: 0;
            }
        }
        &:hover {
            .node-actions {
                opacity: 1;
            }
        }
        &.dragOvering-node {
            &.drop-top {
                border-top-color: $component-react-tree-dropable-node-positioncolor;
                border-top: 2px solid $component-react-tree-dropable-node-positioncolor;
            }
            &.drop-bottom {
                border-top-color: $component-react-tree-dropable-node-positioncolor;
                border-bottom: 2px solid $component-react-tree-dropable-node-positioncolor;
            }
            &.drop-inner {
                background: $component-react-tree-dropable-node-positioncolor;
                .node-body {
                    .node-name {
                        .node-name-text {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .child-tree {
        margin-left: $component-react-tree-child-nodes-indent;
    }
}
.react-tree.default {
    .node-heading {
        .tree-actions {
            .tree-action-item {
                &.add-node {
                    color: $component-react-tree-add-icon-color;
                    &:hover {
                        color: lighten($component-react-tree-add-icon-color, 10%);
                    }
                }
            }
        }
    }
    .tree-node {
        .node-toggle {
            .toggle-link {
                color: lighten($component-react-tree-font-color, 20%);
                &:hover {
                    color: lighten($component-react-tree-font-color, 10%);
                }
            }
        }
        .node-body {
            .node-actions {
                .node-action-item {
                    &.add-node {
                        color: $component-react-tree-add-icon-color;
                        &:hover {
                            color: lighten($component-react-tree-add-icon-color, 10%);
                        }
                    }
                    &.edit-node {
                        color: $component-react-tree-edit-icon-color;
                        &:hover {
                            color: lighten($component-react-tree-edit-icon-color, 10%);
                        }
                    }
                    &.delete-node {
                        color: $component-react-tree-delete-icon-color;
                        &:hover {
                            color: lighten($component-react-tree-delete-icon-color, 10%);
                        }
                    }
                }
            }
        }
        &.active-node {
            .node-name {
                font-weight: bold;
                color: $component-react-tree-active-node-color;
                background-color: $component-react-tree-active-node-bgcolor;
                &:hover {
                    color: $component-react-tree-active-node-hover-color;
                    background-color: $component-react-tree-active-node-hover-bgcolor;
                }
            }
        }
        &.checked-node {
            .node-name {
                color: $component-react-tree-checked-node-color;
                background-color: $component-react-tree-checked-node-bgcolor;
            }
        }
        &.checkable-node:not(.checked-node) {
            .node-name:hover {
                color: $component-react-tree-checkable-node-color;
                background-color: $component-react-tree-checkable-node-bgcolor;
            }
        }
        &.clickable-node:not(.active-node) {
            .node-name:hover {
                color: $component-react-tree-clickable-node-hover-color;
                background-color: $component-react-tree-clickable-node-hover-bgcolor;
            }
        }
    }
    .react-tree-dragging.tree-node {
        opacity: 0.3;
        .node-toggle {
            .toggle-link {
                display: none;
            }
        }
        .node-body {
            position: inherit;
        }
    }
}
