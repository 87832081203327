.component-audio-stickers {
    .audio-sticker-view {
        width: 100%;
        height: 13px;
        margin-bottom: 10px;
        position: relative;
        background: #f2f2f2;

        .audio-sticker-item {
            height: 100%;
            position: absolute;
        }
    }
}