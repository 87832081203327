.learning-center-tasks {
  .udesk-qa-web-page-body-root,
  .page-content,
  .learning-center-tasks-table {
    height: 100%;
  }

  .learning-center-tasks-table {
    .task-item {
      text-align: left;
      display: inline-flex;

      .course-logo {
        position: relative;
        max-width: 250px;
        height: 150px;

        &.pad {
          width: 150px;
        }
      }

      .course-info {
        flex: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        padding: 0 16px;

        > div:nth-child(1) {
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 700;
          font-size: 16px;
        }

        > div:nth-child(2) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-top: 10px;
          max-width: 600px;
        }
      }
    }

    .task-btns {
      display: inline-flex;
      justify-content: flex-end;

      &.column {
        flex-direction: column;
        align-items: baseline;
      }
    }

    .task-btn-pause {
      position: absolute;
      top:5px;
      right: 16px;

      button {
        >span:nth-child(1) {
          width: auto;
          height: auto;
          font-size: 24px;
          vertical-align: middle;
          margin-right: 3px;

          svg {
            width: auto;
            height: auto;
          }
        }
        > span:nth-child(2) {
          margin-right: 8px;
          color: #555;
        }
      }
    }
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;