.customer-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;

    .customer-cover {
      padding: 24px;
      text-align: center;
      height: 148px;
      background: linear-gradient(90deg, #1A6EFF 0%, #4FAAFF 100%);
      
      .udesk-qa-ui-select-selector {
        background-color: transparent;
        border: none;
      }
      .udesk-qa-ui-select-arrow {
        margin-top: -10px;
      }

      span.udesk-qa-ui-avatar {
        outline: 3px solid #fff;
      }

      span {
        font-size: 16px;
        color: #fff;
      }
    }

    .customer-desc,
    .customer-tags {
      padding: 24px 16px;

      .title {
        position: relative;
        display: inline-block;

        > span {
          font-size: 15px;
          font-weight: 600;
          position: relative;
          z-index: 1000;
        }

        &::after {
          position: absolute;
          content: '';
          height: 6px;
          left: 0;
          right: 0;
          bottom: 3px;
          z-index: 1;
          border-radius: 6px;
          background-color: #ffeba3;
        }
      }

      .content {
        display: block;
        padding-top: 12px;
        white-space: pre-wrap
      }
    }

    .customer-tags {
      .title::after {
        background-color: #bddeff;
      }
    }
  }
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;