.intelligent-collection-detail-page {
    height: 100%;
    .form-card {
        background-color: #fff;
        border-radius: 2px;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0px;
        }
        .form-card-title {
            width: 100%;
            padding: 12px 16px;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .form-card-content {
            padding: 40px 0px;
        }
    }
}