.intelligent-extractors-page-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    
    > div {
        width: 250px;
        flex: 0 0 250px;

        &:last-child {
            flex: 1;
            width: calc(100% - 500px);
        }
    }
    .udesk-qa-ui-table-wrapper,
    .udesk-qa-ui-spin-nested-loading,
    .udesk-qa-ui-table-container {
        height: 100%;
    }
    .udesk-qa-ui-table-fixed-header {
        flex: 1;
        height: calc(100% - 56px);
    }
}

.intelligent-extractors-page-left {
    overflow: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .table-row-item {
        cursor: pointer;
        display: flex;
        padding: 0 0 0 6px;
        height: 34px;
        justify-content: space-between;
        align-items: center;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .toolkit-button {
            white-space: nowrap;
            align-items: center;
            display: flex;

            > button {
                display: none;
            }
        }

        &:hover {
            .toolkit-button > span {
                display: none;
            }

            .toolkit-button > button {
                display: block;
            }
        }
    }

    .udesk-qa-ui-table-thead .udesk-qa-ui-table-selection-column {
        height: 46px;
        padding-left: 14px;
        text-align: left;
        background-color: #fff;
    }
    .udesk-qa-ui-table-body {
        overflow-y: auto!important;

        .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row-selected {
            td::after {
                content: '';
                display: inline-block;
                height: 42px;
                width: 2px;
                position: absolute;
                right: 0;
                top: 0;
                background-color: #1A6EFF;
            }
        }
        .udesk-qa-ui-table-tbody tr.udesk-qa-ui-table-row td {
            padding: 4px 6px 4px 16px;
            border-bottom: 0;
        }
    }
}
