.user-portrait-select-drop-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .user-portrait-select-drop-icon {
        width: 20px;
        height: 20px;
    }
    .user-portrait-select-drop-label {
        margin-left: 8px;
        font-size: 14px;
    }
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;