.learning-center-record-body {
  height: 100%;

  > div,
  > div> div,
  > div> div> div,
  > div> div> div> div,
  > div> div> div> div> div {
    height: 100%
  }

  .task-item {
    text-align: left;
    display: flex;
    justify-content: space-between;

    .course-logo {
      width: 250px;
      height: 150px;
    }

    .course-info {
      flex: auto;
      min-width: 480px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding: 0 10px;

      .text-nowrap {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      > div:nth-child(1) {
        > span:last-child {
          font-weight: 700;
          font-size: 16px;
        }
      }

      > div:nth-child(2) {
        font-size: 14px;
        height: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 10px 0;
        box-sizing: border-box;
      }

      > div:nth-child(3) {
        font-size: 12px;
        color: #aaa;
      }
    }
  }

  .table-row-button {
    cursor: pointer;
    flex-direction: column;
    display: inline-flex;
    align-items: center;
    color: rgb(35, 110, 251);

    &:hover {
      > span {
        background-color: rgb(140 183 248);
      }
      > div {
        color: rgb(140 183 248);
      }
    }

    &.hidden-statistics > div {
      visibility: hidden;
    }

    &.disabled {
      cursor:auto;
      
      > span {
        background-color: #ccc;
      }
      > div {
        color: #ccc;
      }
    }

    > div {
      font-size: 12px;
      color: rgb(66 136 243);
    }

    > span {
      background-color: rgb(66 136 243);
    }

    .has-evaluated {
      background-color: rgb(31, 205, 107);
      &:hover {
         background-color: rgb(31, 205, 107);
      }
    }
  }
}
@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;