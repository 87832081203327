.audio-call-content-info-v3 {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    padding: 8px 0;

    .audio-call-content-info-control {
        // height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .udesk-qa-ui-input-group-addon {
            background-color: #fff;
        }
    }

    .audio-call-content-info-record {
        flex: 1;
        // min-height: calc(100% - 50px);
        background-color: transparent !important;
    }

    .udesk-qa-chat-record-item .hit-highlight {
        background-color: orange;
    }

    .udesk-qa-chat-record-item .udesk-qa-chat-record-item-chat-bubble {
        max-width: 80%;
        width: 80%;
    }

    .udesk-qa-chat-record-item .right-bubble .udesk-qa-chat-record-item-chat-bubble-content {
        background-color: #fff !important;
        border: 1px solid rgb(69, 108, 239);
        border-radius: 4px;
        color: rgb(69, 108, 239);
        font-size: 12px;
    }

    .udesk-qa-chat-record-item .left-bubble .udesk-qa-chat-record-item-chat-bubble-content {
        background-color: rgb(69, 108, 239) !important;
        border: 1px solid rgb(69, 108, 239);
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
    }

    .udesk-qa-chat-record-item {
        padding: 0px 0px 8px;
    }

    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical {
        width: 4px !important;
    }

    .udesk-qa-chat-record-item .udesk-qa-chat-record-item-chat-bubble-content {
        max-width: 100%;
    }
}
