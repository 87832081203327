.smart-words-library-page-content {
    padding: 16px;
    .smart-words-library-nav-item {
        .nav-item-header {
            // padding-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .nav-item-header-search {
            }

            .nav-item-header-button {
                display: flex;

                .nav-item-header-create,
                .nav-item-header-batching-import {
                    height: 28px;
                    border: 1px solid #0a85ff;
                    background: white;
                    padding: 4px 22px;
                    color: #0a85ff;
                    cursor: pointer;
                }
                .nav-item-header-create,
                .nav-item-header-batching-export {
                    border: 1px solid #0a85ff;
                    background: white;
                    padding: 4px 22px;
                    color: #0a85ff;
                    cursor: pointer;
                    margin-left: 10px;
                }
                .nav-item-header-create {
                    margin-right: 10px;
                }
            }
        }

        .nav-item-content {
            .component-react-table {
                .table-wrapper {
                    .columns {
                        z-index: 4;
                    }
                }
            }
        }
    }

    .smart-words-library-operation-edit,
    .smart-words-library-operation-delete {
        font-size: 14px;
        color: #4285ff;
        cursor: pointer;
    }
    .smart-words-library-operation-edit {
        margin-right: 10px;
    }
    .component-react-table {
        .header-row {
            .header-col {
                .header-cell-wrapper {
                    margin: 0 auto;
                }
            }
        }
        .data-table-wrapper tbody tr .data-col {
            text-align: center;
        }
        .columns {
            top: 4px;
        }
    }
}

.smart-words-library-page {
}
.udesk-qa-web-page-header {
    .udesk-qa-ui-page-header-heading-extra {
        .smart-words-library-page-header-right {
            .smart-words-library-page-header-nav-item {
                color: #333;
                padding: 13px 10px 13px;
                border-left: 0;
                text-transform: capitalize;
            }
            .smart-words-library-page-header-nav-item-active {
                color: #4285ff;
                border-bottom: 2px solid #4285ff;
            }
        }
    }
}
