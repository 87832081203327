.template-components-list-page {
    padding: 16px;
    .template-components-list-page-search {
        padding: 5px 0;
    }
}
.column-ellipsis {
    white-space: nowrap; /* 保持文本在一行显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 显示省略号 */
    width: 100%; /* 宽度可以根据需要设置 */
}
