.comment-reply-component {
    height: 100%;
    display: flex;
    flex-direction: column;
    .avatar {
        margin-right: 12px;
        > img {
            margin-top: 0;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            object-fit: cover;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
    .currentTime-button {
        padding: 1px 8px;
        background: #e6f3ff;
        border-radius: 2px;
        color: #1a6eff;
        .udesk-qa-ui-btn {
            display: none;
        }
        &:hover {
            .udesk-qa-ui-btn {
                display: inline-block;
            }
        }
    }

    .mention {
        color: #4a85bb;
        text-decoration: none;
        background-color: #e6f3ff;
    }
    .ql-mention-list-container {
        max-width: 100%;
    }
    .ql-mention-list {
        max-height: 100px;
        overflow-y: auto;
    }
    .ql-editor {
        padding: 0;
        overflow-y: unset;
    }
    .ql-mention-list-item {
        line-height: 26px;
    }
    .ql-editor.ql-blank::before{
        left: 0;
        font-style: normal;
    }

    .mentionSuggestions {
        border-top: 1px solid #eee;
        background: #fff;
        border-radius: 2px;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        transform-origin: 50% 0%;
        transform: scaleY(0);
        margin: -16px;
    }

    .mentionSuggestionsEntryContainer {
        display: table;
        width: 100%;
    }

    .mentionSuggestionsEntryContainerLeft,
    .mentionSuggestionsEntryContainerRight {
        display: table-cell;
        vertical-align: middle;
    }

    .mentionSuggestionsEntryContainerRight {
        width: 100%;
        padding-left: 8px;
    }

    .mentionSuggestionsEntry {
        padding: 7px 10px 3px 10px;
        transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
    }

    .mentionSuggestionsEntry:active {
        background-color: #cce7ff;
    }

    .mentionSuggestionsEntryFocused {
        padding: 7px 10px 3px 10px;
        transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
        background-color: #e6f3ff;
    }

    .mentionSuggestionsEntryText,
    .mentionSuggestionsEntryTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mentionSuggestionsEntryText {
    }

    .mentionSuggestionsEntryTitle {
        font-size: 80%;
        color: #a7a7a7;
    }

    .mentionSuggestionsEntryAvatar {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}
