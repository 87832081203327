.call-manage-page {
    display: flex;
    .call-manage-page-left-part {
        // flex: 1;
    }
    .call-manage-page-right-part {
        flex: 1;
        padding: 0 16px;
        overflow-x: hidden;
    }
}