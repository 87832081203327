.words-mining-favorite-page-left.dissent {
    .table-row-item {
        display: flex;
        justify-content: space-between;

        > span:first-child {
            padding-left: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
        }

        > .toolkit-button {
            position: absolute;
            right: 7px;

            > button {
                display: none;
                width: 22px;
                height: 22px;
            }
        }

        &:hover {
            > span:first-child {
                width: 185px;
            }
            > span.udesk-qa-ui-tag {
                display: none;
            }
            > .toolkit-button > button {
                display: inline-flex;
            }
        } 
    }
    .column-header {
        text-align: left;
        padding-left: 8px;
        
        >h1 {
            font-size: 16px;
            margin-top: 15px;
        }
        >button {
            padding: 0;
        }
    }
}

.words-mining-favorite-page-right {
    .column-header {
        display: flex;
    }
    .message {
        display: flex;

        > span {
            flex: 0 0 30px;
        }

        > p {
            flex: 1;
            padding: 4px 15px;
        }
    }
    .user-type {
        display: inline-block;
        height: 30px;
        width: 30px;
        margin: 0 auto;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        color: #0f9715 ;
        background-color: #d3f7e1;

        &.customer {
            color: #1552f3 ;
            background-color: #e1f4fa;

            + p{
                color: #aaa;
            }
        }
    }

}

.words-mining-favorite-edit-dialog {
    .ok {
        background-color: #fff;
        border-color: #d9d9d9!important;
    }
    .hidden-delete-button .udesk-qa-list-item {
        > button {
            display: none;
        }
    }
    .udesk-qa-list-item .udesk-qa-ui-input-textarea {
        flex: 1;
    }
}