.udesk-qa-group-test-detail {
    padding-top: 40px;
    flex: 1;

    .offset50 {
        >div:last-child {
            margin: 50px 0 0 -40px;
        }

        .udesk-qa-ui-row.udesk-qa-ui-form-item {
            > div {
                width: 90px;
                > label > span[role=img]{
                    position: relative;
                    top: 8px;
                }
                > label::before {
                    color: transparent;
                }
            }
        }
    }
}

.group-test-page-body.detail {
    height: auto;
}