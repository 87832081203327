$login-page-background: rgba(46, 62, 78, 1);
$login-page-title: #eee;
$system-font-color3: #999;

@keyframes fade-in-left {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left-delay {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    20% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.udesk-qa-web-login-page {
    margin-top: 0;
    // height: 100vh;
    height: 100%;
    background: $login-page-background;
    display: flex;
    .udesk-qa-ui-form-item-label {
        label {
            font-weight: 600;
        }
    }
    .udesk-qa-ui-btn-block,
    .udesk-qa-ui-input,
    .udesk-qa-ui-input-password {
        border-radius: 8px;
    }
    .left {
        background: #fff;
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        .udesk-qa-ui-form-builder-container {
            // max-width: 45%;
            // padding: 0 20%;
            box-sizing: content-box;
            // margin-left: auto;
            flex: 1;
            width: 45%;
            animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        .title {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 8px;
            animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        .desc {
            color: rgba(0, 0, 0, 0.25);
            margin-bottom: 16px;
            font-size: 16px;
            animation: fade-in-left-delay 0.8s cubic-bezier(0.25, 0.575, 0.565, 1) both;
        }
    }
    .right {
        flex: 1;
        background-size: cover;
        background-position: center;
        background-color: #fff;
        background-repeat: no-repeat;
        // background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #B2FCFF calc(40% + 1px), #B2FCFF 60%, #5EDFFF calc(60% + 1px), #5EDFFF 72%, #3E64FF calc(72% + 1px), #3E64FF 100%);
    }
    .navbar {
        margin-top: 6rem;
    }
    .has-error {
        color: red;
    }
    .forgetPwd {
        margin: 15px 0;
    }
    .form-contianer {
        overflow: hidden;
    }
    .title {
        h4 {
            text-align: center;
            font-weight: 700;
            color: #eee;
            font-size: 18px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: inherit;
            line-height: 1.1;
            color: $login-page-title;
        }

        .title-image {
            margin-right: 6px;
        }
    }
    .login-btn {
        margin-bottom: 2rem;
    }
    .forget-pwd {
        text-align: center;
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        font-size: 14px;
        font-weight: normal;
    }
    .md-check {
        padding-left: 1.25rem;
        margin: 0;
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 19px;
        line-height: 19px;

        .md-check-label-text {
            line-height: 19px;
            color: $system-font-color3;
        }
    }
}
