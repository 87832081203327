.udesk-qa-chat-record-item {
    padding: 0 8px 16px;
    display: flex;

    .udesk-qa-chat-record-item-avatar {
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
        margin: 6px 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .udesk-qa-chat-record-item-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 80%;
        .udesk-qa-chat-record-item-name,
        .udesk-qa-chat-record-item-time {
            margin-bottom: 4px;
            color: rgba(0,0,0,.45);
            font-size: 12px;
            .audio-text-list-item-text-hit-status {
                background: #ffe59f;
                color: rgba(0, 0, 0, 0.85);
                .current-highlight-item {
                    background-color: #ff8552;
                }
            }    
        }
    }

    .udesk-qa-chat-record-item-chat-bubble {
        font-size: 14px;
        color: rgba(0,0,0,.85);
        word-break: break-all;
        border-radius: 3px;
        max-width: calc(100% - 104px);
        // display: inline-block;
        display: inline-flex;
        flex-direction: column;
        .udesk-qa-chat-record-item-bottom-buttons {
            display: flex;
            align-items: center;
        }
        .message-tools-bar {
            overflow: hidden;
            flex: 1;
        }
        .message-tools-bar-remark {
            background: rgba(0,0,0,.05);
            border-radius: 3px;
            padding: 2px 16px;
            cursor: pointer;
            color: rgba(0,0,0,.45);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            margin-top: 4px;
            max-width: 350px;
        }
    }
    
    .right-bubble {
        .udesk-qa-chat-record-item-chat-bubble-content {
            background:#bfdfff!important;
            .icon-udesk-qa-react-web-ic-play-circle-bofang {
                margin-right: 8px;
                &:hover {
                    color: #1b6dff;
                }
            }
        }
        text-align: end;
        align-self: flex-end;
        * {
            text-align: start;
        }
        .qa-sales-debug-info {
            align-self: end;
        }
    }

    .left-bubble {
        .udesk-qa-chat-record-item-chat-bubble-content {
            background: #FFFFFF!important;
            flex-direction: row-reverse;
            .icon-udesk-qa-react-web-ic-play-circle-bofang {
                margin-left: 8px;
                &:hover {
                    color: #1b6dff;
                }
            }
            .icon-udesk-qa-react-web-ic-pause-circle-zanting {
                margin-left: 8px;
                &:hover {
                    color: #1b6dff;
                }
            }
        }
        align-self: flex-start;
    }
}
.silence-box {
    display: flex;
    justify-content: center;
    color: #33333382;
    .current-silence-line {
        width: 35%;
        border-bottom: 1px dotted #c3bbbb;
        margin-bottom: 10px;
    }
    .current-silence-text {
        width: 20%;
        text-align: center;
    }
}