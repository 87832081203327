.quality-check-score-talk-body-content {
    .udesk-qa-ui-collapse-content > .udesk-qa-ui-collapse-content-box {
        padding: 0;
    }

    .udesk-qa-ui-collapse > .udesk-qa-ui-collapse-item > .udesk-qa-ui-collapse-header {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .udesk-qa-ui-collapse
        > .udesk-qa-ui-collapse-item
        > .udesk-qa-ui-collapse-header
        .udesk-qa-ui-collapse-arrow {
        padding-top: 6px;
    }

    .udesk-qa-ui-collapse-ghost
        > .udesk-qa-ui-collapse-item
        > .udesk-qa-ui-collapse-content
        > .udesk-qa-ui-collapse-content-box {
        padding-top: 0;
    }
}
