.wechat-qa-tasks-check-point {
    display: flex;
    padding: 16px;
    height: 100%;
    .wechat-qa-tasks-check-point-left {
        // padding: 16px;
        // border: 1px solid #e8e8e8;
        width: 210px;
        height: 100%;
        overflow-y: auto;
        // .udesk-qa-ui-menu-inline {
            // border-right: none!important;
        // }
        .wechat-qa-tasks-check-point-classification-item {
            display: flex;
            justify-content: space-between;
            .wechat-qa-tasks-check-point-classification-item-title {
                width: 100px;
                overflow: hidden;    
                text-overflow:ellipsis;    
                white-space: nowrap;
            }
        }
        .udesk-qa-ui-menu-item {
            padding-right: 0;
        }
        .udesk-qa-ui-menu-item-selected {
            &:after {
                opacity: 0;
            }
        }
    }
    .wechat-qa-tasks-check-point-right {
        flex: 1;
        width: calc(100% - 200px);
        padding-left: 16px;
        .udesk-qa-ui-page-header {
            padding: 0;
        }
        .udesk-qa-ui-page-header-heading {
            flex-wrap: nowrap;
        }
        .udesk-qa-ui-page-header-heading-sub-title {
            flex: 1;
        }
        .wechat-qa-tasks-check-point-toolsbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #f0f2f5;
            padding: 8px 0;
        }
        .udesk-qa-web-route-entry {
            a {
                &:hover {
                    color: #458fff !important;
                }
            }
        }
    }
}