.appeal-checking-task-page-new {
    height: 100%;
    .appeal-checking-task-page-filter {
        padding: 16px 16px 8px;
        margin-bottom: 16px;
        background-color: #fff;
        .udesk-qa-ui-form-inline {
            display: flex;
            flex-wrap: wrap;
            >.udesk-qa-ui-form-item {
                margin-bottom: 8px;
                flex: none;
                flex-wrap: nowrap;
                margin-right: 16px;
                .udesk-qa-ui-form-item-label {
                    >label::after {
                        content: ':';
                        position: relative;
                        top: -0.5px;
                        margin: 0 8px 0 2px;
                    }
                }
            }
        }
    }
    .appeal-checking-task-page-body {
        display: flex;
        min-height: 100%;
        background-color: #fff;
        .appeal-checking-task-page-left {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            // background-color:rgb(238, 209, 209);
            height: calc(100vh - 114px);
            width: 200px;
            display: flex;
            flex-direction: column;
            .udesk-qa-ui-spin-nested-loading {
                height: calc(100% - 56px);
            }
            .appeal-checking-task-page-left-list {
                flex: 1;
                overflow-y: auto;
            }
            .appeal-checking-task-page-left-page {
                padding: 8px 0 16px;
            }
            .udesk-qa-ui-menu {
                .udesk-qa-ui-menu-item {
                    height: auto;
                    padding: 12px 16px;
                    margin: 0;
                    &:hover {
                        color: unset;
                    }
                }
                .udesk-qa-ui-menu-item-selected {
                    color: unset;
                }
            }
            .appeal-checking-task-page-list-item {
                .appeal-checking-task-page-list-item-row {
                    display: flex;
                    justify-content: space-between;
                    &.has-margin {
                        margin-bottom: 4px;
                    }
                    .appeal-checking-task-page-list-item-row-left {
                        flex: 1;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-right: 8px;
                    }
                    .appeal-checking-task-page-list-item-row-right {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
        .appeal-checking-task-page-right {
            // background-color: rgb(179, 243, 211);
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            .udesk-qa-ui-alert {
                margin-bottom: 8px;
                margin-top: 16px;
            }
        }
    }    
}
.udesk-qa-ui-page-header-footer {
    .appeal-checking-task-page-task-type-tabs {
        .udesk-qa-ui-tabs-tab {
            font-size: 14px;
            // padding-top: 0;
        }
    }
}

.appeal-checking-task-page-new {
    .udesk-qa-ui-tabs-content-holder {
        .udesk-qa-ui-tabs-content {
            height: calc(100%);
        }
    }
}