.customer-service-ranklist-index-page {
    font-size: 14px;
    padding-bottom: 1px;
    color: rgba(0, 0, 0, 0.85);
    background-color: rgba(240, 242, 245, 1);
    height: 100%;
    .customer-service-ranklist-index-page-body {
        padding: 16px 16px 0;
        height: 100%;
        .customer-service-ranklist-index-page-body-context {
            background-color: #fff;
            height: 100%;
            .customer-service-ranklist-index-page-body-top {
                display: flex;
                padding-bottom: 16px;
                flex-wrap: wrap;
                .customer-service-ranklist-index-page-body-top-date {
                    display: flex;
                    width: 316px;
                    .customer-service-ranklist-index-page-body-top-date-des {
                        line-height: 32px;
                        width: 70px;
                    }
                    .udesk-qa-ui-select {
                        flex: 1;
                    }
                }
                .customer-service-ranklist-index-page-body-top-group {
                    display: flex;
                    min-width: 286px;
                    margin-left: 16px;
                    max-width: calc(100% - 332px);
                    .customer-service-ranklist-index-page-body-top-group-des {
                        line-height: 32px;
                    }
                    .udesk-qa-ui-select {
                        flex: 1;
                    }
                }
                .customer-service-ranklist-index-page-body-top-customer {
                    display: flex;
                    width: 200px;
                    margin-left: 16px;
                    .customer-service-ranklist-index-page-body-top-customer-des {
                        line-height: 32px;
                        width: 44px;
                    }
                    .udesk-qa-ui-select {
                        flex: 1;
                    }
                }
                .customer-service-ranklist-index-page-body-top-btns {
                    display: flex;
                    justify-content: space-between;
                    flex: 1;
                    .customer-service-ranklist-index-page-body-top-btns-left {
                        display: flex;
                        margin-left: 16px;
                        .udesk-qa-ui-btn-primary {
                            color: #fff;
                            background-color: #1890ff;
                            border-color: #1890ff;
                            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                        }
                    }
                    .customer-service-ranklist-index-page-body-top-btns-right {
                        display: flex;
                    }
                }
            }
            .customer-service-ranklist-index-page-body-bottom {
                width: 100%;
                height: calc(100% - 63px);
                .customer-service-ranklist-index-page-body-bottom-table {
                    height: 100%;
                }
            }
        }
    }
}
.customer-service-ranklist-index-page-body-compared-popover {
    .udesk-qa-ui-popover-inner-content {
        width: 177px;
        div {
            display: flex;
            flex-direction: column;
            .udesk-qa-ui-tag {
                flex: 1;
                margin-right: 0px;
                position: relative;
                .anticon-close {
                    position: absolute;
                    right: 7px;
                    top: 2px;
                }
            }
        }
    }
}
.customer-service-ranklist-index-page-body-popover {
    width: 500px;
    height: 378px;
    .udesk-qa-ui-popover-arrow {
        display: none !important;
    }
    .udesk-qa-ui-transfer-list {
        height: 346px !important;
    }
    .udesk-qa-ui-transfer-operation {
        .udesk-qa-ui-btn-icon-only {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }
    }
    .udesk-qa-ui-transfer-list-content {
        height: 260px !important;
        .udesk-qa-ui-transfer-list-content-item {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 180px;
        }
    }
    .udesk-qa-ui-transfer-list-body-customize-wrapper {
        padding: 6px 0px !important;
        .transfer-list {
            overflow: auto;
            height: 254px;
            padding: 0 12px;
        }
    }
}
