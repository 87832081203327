$component-react-tool-content-background: #fff !default;
$component-react-tool-main-width: 13px !default;
$component-react-tool-main-height: 13px !default;
$component-react-tool-container-width:600px !default;
$component-react-tool-container-maxWidth:800px !default;
$component-react-tool-container-maxHeight:350px !default;
$component-react-tool-content-item-hoverBackgroundColor:#f0f4fa !default;
.component-react-tool {
    position: relative;
    display: inline-block;
    font-size: 0;
    .component-react-tool-main {
        min-width: $component-react-tool-main-width;
        min-height: $component-react-tool-main-height;
        color: #00B38B;
        &:hover {
            color: #006750;
        }
    }
    .component-react-tool-container {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 10px;
        z-index: 1050;
        width: $component-react-tool-container-width;
        max-width: $component-react-tool-container-maxWidth;
        max-height: $component-react-tool-container-maxHeight;
        line-height: 1.42857143;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        line-break: auto;
        background: $component-react-tool-content-background;
        border: 1px solid #CCC;
        &.component-react-tool-container-show {
            display: block;
        }
        .component-react-tool-content {
            padding: 0;
            .component-react-tool-content-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
                justify-content: flex-start;
                font-size: 13px;
                line-height: 25px;
                text-align: left;
                .component-react-tool-content-item-title {
                    overflow: hidden;
                    width: 100px;
                    padding-left: 10px;
                    color: #666666;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .component-react-tool-content-item-content {
                    flex: 1;
                    color: #333333;
                }
                &:hover {
                    background-color: $component-react-tool-content-item-hoverBackgroundColor;
                }
            }
        }
    }
}