$page-layout-primary-color: #2878ff;
$page-layout-disabled-color: #bbb;

.speech-recognition-page {
    padding: 16px;

    .speech-recognition-page-body {
        .speech-recognition-page-body-title-row {
            width: 100%;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .speech-recognition-page-body-title {
                width: 100px;
            }

            .speech-recognition-page-body-content {
                min-width: 500px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .udesk-qa-ui-switch-checked {
                    background-color: $page-layout-primary-color;
                }

                .remind-icon {
                    margin-left: 16px;
                    font-size: 15px;
                    line-height: 15px;
                    color: $page-layout-primary-color;
                    vertical-align: middle;
                }

                a {
                    color: $page-layout-primary-color;
                }
            }
        }

        .speech-recognition-page-body-config-row {
            widows: 100%;
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .speech-recognition-page-body-config-title {
                width: 100px;
                min-width: 100px;
                padding-top: 10px;
            }

            .speech-recognition-page-body-config {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                .speech-recognition-page-body-config-item {
                    min-width: 500px;
                    width: 95%;
                    padding: 20px;
                    background-color: #eee;
                }
                .udesk-custom-filters-react {
                    .filter-wrapper {
                        .filter-actions {
                            .add-condition {
                                .filter-icon-add {
                                    .filter-icon {
                                        color: $page-layout-disabled-color;
                                    }
                                }
                            }
                        }

                        .filter-conditions {
                            .filter-body {
                                .condition-row {
                                    .condition-item.condition-removal {
                                        .filter-icon-removal {
                                            .filter-icon {
                                                color: $page-layout-disabled-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .customer-filters-judgeStrategy-radios {
                    .customer-filters-judgeStrategy-radio-item {
                        display: inline-block;
                        width: auto;
                        margin-right: 10px;

                        .radio-btn-decorate {
                            background-color: $page-layout-primary-color;
                        }
                    }
                }

                .button-list {
                    margin-top: 8px;
                    button {
                        margin-right: 8px;
                    }

                    .udesk-qa-ui-btn-primary {
                        background-color: $page-layout-primary-color;
                        border-color: $page-layout-primary-color;
                        color: #fff;
                    }
                }
            }
        }
    }
}
