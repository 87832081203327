@import './components/_index';

.quality-checking-task {
    background: #fff;

    .appeal-checking-task-page {
        margin: 0;
        padding: 20px 0;

        .appeal-checking-task-source-container {
            margin-bottom: 10px;

            .appeal-checking-task-source-button {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background: #2878FF;
                    color: #fff;
                }
            }
        }

        .appeal-checking-task-list-container {

            .appeal-checking-task-list-box {
                height: calc(100vh - 150px);
                min-height: 600px;
                border: 1px solid #ddd;
                position: relative;

                .appeal-checking-task-list-header {
                    border-bottom: 1px solid #ddd;
                    padding: 10px;
                    height: 130px;

                    &.with-agent-select {
                        height: 165px;
                    }

                    .appeal-checking-task-list-title-box {
                        margin-bottom: 10px;
                        height: 32px;
                        line-height: 32px;
                        display: flex;
                        justify-content: space-between;

                        .appeal-checikng-task-list-title {
                            display: inline-block;

                            .appeal-checking-task-source-button {
                                margin-right: 5px;
                                padding: 5px 10px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                &.active {
                                    background: #2878FF;
                                    color: #fff;
                                }
                            }
                        }

                        .appeal-checking-task-list-refresh {
                            color: #2878FF;
                            cursor: pointer;
                        }
                    }

                    .appeal-checking-task-list-filter-box {
                        margin-bottom: 5px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .appeal-checking-task-list-status-box {
                        display: flex;
                        flex-wrap: wrap;

                        .appeal-checking-task-list-status {
                            border: 1px solid #ddd;
                            padding: 2px 4px;
                            // margin-right: 5px;
                            cursor: pointer;
                            border-radius: 5px;
                            // float: left;
                            margin: 0 5px 5px 0;

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        .appeal-checking-task-list-status.active {
                            border: 1px solid #2878FF;
                            background: #2878FF;
                            color: #fff;
                        }
                    }
                }

                .appeal-checking-task-list-body {
                    height: calc(100vh - 150px - 130px - 50px);  // 不带客服选择框，过滤条件130px；分页50px
                    min-height: calc(600px - 130px - 50px);
                    overflow-y: auto;

                    &.with-agent-select {
                        height: calc(100vh - 150px - 165px - 50px);  // 带客服选择框，过滤条件165px；分页50px
                        min-height: calc(600px - 165px - 50px);
                    }

                    .appeal-checking-task-list-item {
                        padding: 5px 10px;
                        border-bottom: 1px solid #ddd;
                        cursor: pointer;

                        &:hover {
                            background: #eee;
                        }

                        &.active {
                            background: #2878FF;
                            color: #fff;
                        }

                        .appeal-checking-task-list-item-top-part {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 5px;
                        }

                        .appeal-checking-task-list-item-bottom-part {
                            display: flex;
                            justify-content: space-between;

                            .appeal-checking-task-list-item-status {
                                .appeal-checking-task-list-item-status-icon {
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 5px;
                                    border: 1px solid #ddd;
                                    margin-right: 5px;

                                    &.unread {
                                        border: 1px solid red;
                                        background: red;
                                    }

                                    &.inComplaint {
                                        border: 1px solid orange;
                                        background: orange;
                                    }

                                    &.reviewed {
                                        border: 1px solid green;
                                        background: green;
                                    }

                                    &.filed {
                                        border: 1px solid gray;
                                        background: gray;
                                    }

                                    &.readed {
                                        border: 1px solid gray;
                                        background: gray;
                                    }
                                }

                                &.unread {
                                    color: red;
                                }

                                &.inComplaint {
                                    color: orange;
                                }

                                &.reviewed {
                                    color: green;
                                }

                                &.filed {
                                    color: gray;
                                }

                                &.readed {
                                    color: gray;
                                }
                            }
                        }
                    }

                    .appeal-checking-task-list-none {
                        text-align: center;
                        padding: 30px 10px;
                    }
                }

                .appeal-checikng-task-list-footer {
                    height: 50px;
                    line-height: 50px;
                    padding: 0 10px;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
            }
        }

        .appeal-checking-task-detail-container {

            .appeal-checking-task-detail-box {
                height: calc(100vh - 150px);
                min-height: 600px;
                border: 1px solid #ddd;
                padding: 0 10px;

                .appeal-checking-task-detail-title {
                    height: 40px;
                    line-height: 40px;
                    border-bottom: 1px solid #ddd;
                    margin-bottom: 10px;
                }

                .selected-points {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .selected-points-warning {
                        width: 425px;
                    }

                    .selected-points-container {
                        .selected-pointes-item {
                            display: inline-block;
                            border: 1px solid #ddd;
                            padding: 5px 10px;
                            margin-right: 5px;
                            cursor: pointer;
    
                            &.active {
                                background: #2878FF;
                                color: #fff;
                            }
    
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .appeal-checking-task-detail-info {
                    height: calc(100vh - 150px - 50px);  // 标题50px
                    min-height: calc(600px - 50px);

                    &.with-audio {
                        height: calc(100vh - 150px - 50px - 46px - 180px);  // 标题50px；声道组件46px；播放组件180px
                        min-height: calc(600px - 50px - 46px - 180px);

                        &.with-select-point {
                            height: calc(100vh - 150px - 50px - 46px - 180px - 42px);  // 标题50px；声道组件46px；播放组件180px；选择质检点42px
                            min-height: calc(600px - 50px - 46px - 180px - 42px);
                        }
                    }

                    &.with-select-point {
                        height: calc(100vh - 150px - 50px - 42px);  // 标题50px；选择质检点42px
                        min-height: calc(600px - 50px - 42px);
                    }

                    .appeal-checking-task-detail-call-info {
                        margin: 10px 0;
                        border: 1px solid #ddd;
                        height: calc(100% - 20px - 2px);
                        position: relative;

                        .appeal-checking-task-detail-call-info-navs {
                            display: flex;
                            justify-content: center;
                            background: #fff;
                            width: 100%;

                            .nav-item {
                                display: inline-block;
                                width: 50%;
                                text-align: center;
                                height: 40px;
                                line-height: 40px;
                                cursor: pointer;
                                border-bottom: 1px solid #ddd;

                                &:first-child {
                                    border-right: 1px solid #ddd;
                                }

                                &:hover {
                                    background: #eee;
                                }

                                &.active {
                                    border-bottom: none;
                                }
                            }
                        }

                        .audio-text-view {
                            height: calc(100% - 40px);
                            overflow: auto;

                            .audio-text-list-container {
                                height: 100%;

                                .audio-text-list{
                                    height: calc(100% - 48px);
                                    overflow-x: hidden;
                                    overflow-y: auto;
                                }
                            }
                        }

                        .call-info-view {
                            height: calc(100% - 40px);
                            overflow: auto;
                        }
                    }
                }

                .appeal-checking-task-detail-none {
                    text-align: center;
                    padding: 50px 10px;
                }

                .smart-tags-container {
                    border: 1px solid #ddd;
                    margin: 5px 0;

                    .smart-tags-title {
                        height: 30px;
                        line-height: 30px;
                        padding: 0 10px;
                    }

                    .smart-tags-content {
                        min-height: 50px;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;

                        .smart-tag-item {
                            padding: 5px 10px;
                            border: 1px solid #ddd;
                            margin: 0 10px 5px 10px;
                        }
                    }
                }
            }

            .appeal-checking-task-change-list-box {
                border: 1px solid #ddd;
                margin-top: 30px;
            }
        }
    }
}