.case-base-page-review-list-body {
    top: 60px;
    width: 100%;
    padding: 16px;
    height: 100%;
    .udesk-qa-ui-tabs {
        height: 100%;
    }
    .case-base-export {
        text-align: right;
        margin-bottom: 10px;
        .export-button {
            width: 60px;
            height: 32px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
    .udesk-qa-ui-drawer-right {
        padding-top: 60px;
    }
    .udesk-qa-ui-drawer-header {
        padding: 16px 10px;
    }
    .udesk-qa-ui-drawer-body {
        padding: 0;
    }
    .udesk-qa-ui-drawer-content-wrapper {
        width: 520px !important;
    }
    .case-base-draw-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .draw-title-page-left {
            text-align: left;
            cursor: pointer;
        }
        .draw-title-page-center {
            text-align: center;
        }
        .draw-title-page-right {
            text-align: right;
            cursor: pointer;
        }
    }
    .case-base-page-draw-body-content {
        padding: 10px;
        .case-base-audio {
            margin-bottom: 10px;
            audio {
                height: 40px;
                width: 100%;
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px;
            }
        }
        .case-base-draw-body-talk {
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            max-height: 500px;
            margin-bottom: 10px;
            overflow-y: auto;
            padding: 16px;
            .case-base-draw-body-talk-content {
                max-width: 70%;
            }
            .case-base-draw-body-talk-icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.65);
                text-align: center;
                line-height: 40px;
            }
            .case-base-draw-body-talk-info {
                max-width: 320px;
                padding: 8px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
            .case-base-draw-body-talk-customer {
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                .case-base-draw-body-talk-icon {
                    background: rgba(235, 241, 252, 1);
                    margin-right: 10px;
                }
                .case-base-draw-body-talk-info {
                    background: rgba(235, 241, 252, 1);
                }
                .message-tools-bar-remark {
                    margin-top: 4px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 3px;
                    min-width: 250px;
                    padding: 2px 16px;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.45);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }
            }
            .case-base-draw-body-talk-call {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-bottom: 20px;
                .case-base-draw-body-talk-icon {
                    background: rgba(0, 0, 0, 0.05);
                    margin-left: 10px;
                }
                .case-base-draw-body-talk-info {
                    background: rgba(0, 0, 0, 0.05);
                }
                .message-tools-bar-remark {
                    margin-top: 4px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 3px;
                    min-width: 250px;
                    padding: 2px 16px;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.45);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }
            }
        }
        .case-base-draw-body-remarks {
            .case-base-draw-remarks-textarea {
                min-height: 80px;
                // background: rgba(255, 255, 255, 1);
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                // padding: 4px;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
            }
        }
    }
    .case-base-modal-sort {
        .udesk-qa-ui-modal-body {
            padding: 10px 0 !important;

            .case-base-modal-add {
                margin: 0 0 10px 10px;
                cursor: pointer;
                color: rgba(24, 90, 189, 1);
                width: 94px;
                i {
                    margin-right: 5px;
                }
            }
            .transfer-list {
                max-height: 400px;
                overflow-y: auto;
            }
            .transfer-item {
                display: flex;
                flex-direction: row;
                padding: 1px 10px;
                .icon-unordered-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .case-base-field-list {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    width: 100%;
                    padding-left: 10px;
                    .case-base-field-list-left {
                        text-align: left;
                        width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                    .case-base-field-list-right {
                        display: flex;
                        margin-left: 15px;
                        justify-content: space-between;
                        width: 45px;
                        cursor: pointer;
                    }
                }
            }
            .transfer-item:hover {
                background: rgba(235, 245, 252, 1);
                .icon-udesk-qa-react-web-bianji,
                .icon-udesk-qa-react-web-baocun {
                    color: rgba(24, 90, 189, 1);
                }
                .icon-udesk-qa-react-web-shanchu {
                    color: rgba(220, 56, 56, 1);
                }
            }
        }
    }
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus {
        z-index: 1;
    }
}
.case-base-draw-body-talk-info {
    img {
        width: 100% !important;
        height: auto !important;
    }
}
