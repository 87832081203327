.mini-list-manage-component-container {
    // border-right: 1px solid #ddd;
    // height: calc(100vh - 64px);
    height: 100%;
    .mini-list-manage-component-header {
        overflow: hidden;
        height: 58px;
        line-height: 58px;
        padding: 0 5px;
        border-bottom: 1px solid #ddd;

        .mini-list-manage-component-header-title {
            color: #888;
        }
    }

    .mini-list-manage-component-body {
        height: calc(100% - 58px - 50px);
        min-height: 240px;
        overflow-y: auto;
        margin-bottom: 10px;
        .mini-list-manage-component-body-has-value {
            .mini-list-manage-component-item {
                border-bottom: 1px solid #ddd;
                padding: 5px;
                cursor: pointer;
                overflow: hidden;

                // &:first-child {
                //     border-top: 1px solid #ddd;
                // }

                &:hover {
                    background: #eee;
                }

                &.active {
                    color: #fff;
                    background: #2878ff;
                }
            }
        }

        .mini-list-manage-component-body-no-value {
            margin-top: 10px;
            text-align: center;
        }
    }

    .mini-list-manage-component-footer {
        padding: 0 5px;
        .udesk-qa-customize-pagination {
            padding-top: 0px;
        }
    }
}
