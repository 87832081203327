@import '../../fonts/iconfont.css';
.component-model-loading-text,
.component-model-error {
  position: relative;
  min-width: 100%;
  min-height: 100px;
}
.component-model-loading-text .text-content,
.component-model-error .text-content {
  position: absolute;
}
.component-model-loading-text.left .text-content,
.component-model-error.left .text-content {
  left: 0;
}
.component-model-loading-text.center .text-content,
.component-model-error.center .text-content {
  left: 50%;
  transform: translateX(-50%);
}
.component-model-loading-text.right .text-content,
.component-model-error.right .text-content {
  right: 0;
}
.component-model-loading-text.middle .text-content,
.component-model-error.middle .text-content {
  top: 50%;
  transform: translateY(-50%);
}
.component-model-loading-text.bottom .text-content,
.component-model-error.bottom .text-content {
  bottom: 0;
}
.component-model-loading-text.center.middle .text-content,
.component-model-error.center.middle .text-content {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.component-model-loading-animation {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 200px;
  min-height: 200px;
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body {
  padding: 0;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon {
  display: none;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header {
  overflow: hidden;
  height: 58px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #fff;
  line-height: 58px;
  background-color: #00B38B;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close {
  float: right;
  font-size: 13px;
  font-weight: 700;
  transition: color 0.1s ease-in;
  cursor: pointer;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header .icon-udesk-react-close:hover {
  color: #5b6875;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
  padding: 0;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-content, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  padding: 20px;
  text-align: center;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 64px;
  background-color: #F4F5F9;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn {
  margin: 0 15px;
  width: 106px;
  line-height: 1;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel {
  color: #666666;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-cancel:hover {
  background-color: #e6e6e6;
  border-color: #c2c2c2;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok {
  color: #fff;
  background-color: #00B38B;
  border-color: #00B38B;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover {
  background-color: #006750;
  border-color: #006750;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns, .ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns, .ant-modal.ant-modal-confirm.ant-modal-confirm-success.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns, .ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  display: none;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header {
  background-color: #d9534f;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok {
  background-color: #d9534f;
  border-color: #d9534f;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-error.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover {
  background-color: #b52b27;
  border-color: #b52b27;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title .react-alerts-header {
  background-color: #df8a13;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok {
  background-color: #df8a13;
  border-color: #df8a13;
}
.ant-modal.ant-modal-confirm.ant-modal-confirm-warning.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover, .ant-modal.ant-modal-confirm.ant-modal-confirm-info.react-alerts .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content .react-alerts-footer .react-alerts-footer-btn.react-alerts-footer-btn-ok:hover {
  background-color: #995e0d;
  border-color: #995e0d;
}

