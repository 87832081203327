.udesk-qa-web-page-header {
    .udesk-qa-ui-page-header-heading-extra {
        .role-detail-page-header-title-right-part {
            .role-detail-page-header-nav-item {
                color: #333;
                margin: 10px 0;
                padding: 0 10px;
            }

            .role-detail-page-header-nav-item-active {
                color: #4285ff;
            }
        }
    }
}
