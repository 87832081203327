.massage-push-configuration {
    .udesk-qa-ui-collapse {
        border: unset!important;
    }
    .udesk-qa-ui-collapse-item {
        background-color: #fff;
        margin-bottom: 8px;
        border: 1px solid #d9d9d9;
    }
    .udesk-qa-ui-collapse-header {
        padding: 5px 16px !important;
    }
    .udesk-qa-ui-collapse-content {
        overflow: unset;
    }
    .mce-tinymce {
        border: 1px solid rgba(0, 0, 0, 0.05) !important;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    }
    .massage-push-configuration-row {
        display: flex;
        align-items: baseline;
        margin-bottom: 16px;
        .massage-push-configuration-row-label {
            min-width: 70px;
            height: 24px;
            line-height: 24px;
        }
        .massage-push-configuration-row-content {
            flex: 1;
            .massage-push-configuration-child-row {
                .massage-push-configuration-child-row-label {
                    min-width: 70px;
                }
                .massage-push-configuration-child-row-content {
                    flex: 1;
                }
            }
        }
        .text-area-content {
            display: flex;
            .operator-list-item-associate-model {
                height: 306px;
                overflow: auto;
                .operator-list-item-associate-model-item {
                    padding-left: 16px;
                }
            }
        }
    }
    .not-baseline {
        align-items: unset;
    }
    .massage-push-configuration-last-row {
        margin-bottom: 0px;
    }
    .bf-content {
        min-height: 200px;
        max-height: 200px;
        height: unset;
    }
}