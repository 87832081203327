.udesk-qa-chat-record-list {
    height: 100%;
    .load-more {
        text-align: center;
    }
    .udesk-qa-ui-spin-nested-loading {
        min-height: 50%;
    }
    .udesk-qa-ui-empty {
        margin: 32px auto;
    }
}