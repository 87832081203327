.pages-modal-sale-client-center-container {
    .pages-modal-sale-client-center-call-staus-num {
        background: #fff;
        padding: 16px;
        .title {
            margin-bottom: 16px;
            display: flex;
            h3 {
                margin: 0;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
            }
            span {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                line-height: 20px;
            }
            .rect-marks {
                width: 12px;
                height: 12px;
                background: #5E92F6;
                border-radius: 2px;
            }
        }
    }
    .udesk-qa-ui-modal-body {
        // height: calc(100vh - 230px);
        // overflow-y: auto;
        background: #F0F2F5;
        padding: 24px 20px;
    }
    .modal-bottom-content {
        display: flex;
        margin-top: 16px;
    }
    .top-hots-text {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin-top: -12px;
        margin-bottom: 8px;
    }
}