.words-mining-dissent-page-left {
    .table-row-item {
        display: flex;
        justify-content: space-between;
        padding: 0 6px 0 16px;

        > span:first-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            overflow: hidden;
            cursor: pointer;
        }

        > .toolkit-button {
            .udesk-qa-ui-scroll-number {
                box-shadow: none;
            }
            .udesk-qa-ui-scroll-number-only-unit {
                color: #8f8f8f;
            }
        }
    }

    .column-header {
        text-align: left;
        padding: 16px 6px 0 16px;

        >span {
            margin-top: 15px;
        }

        .udesk-qa-ui-space-item:first-child {
            width: 175px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.dissent-menu-dropdown {
    > ul {
        max-height: 300px; 
        width: 350px; 
        overflow: auto;

        > li {
            display: inline-block;
            width: 345px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}