.task-center-list-body {
    height: 100%;

    // > div,
    // > div > div,
    // > div > div > div,
    // > div > div > div > div,
    // > div > div > div > div > div {
    //     height: 100%;
    // }
}

.udesk-qa-ui-transfer-list-body-search-wrapper + .udesk-qa-ui-transfer-list-body-customize-wrapper,
.udesk-qa-ui-transfer-list-body-search-wrapper + .udesk-qa-ui-transfer-list-content {
    overflow: auto;
    max-height: 300px;
}

@ant-prefix: udesk-qa-ui;@primary-color: #1b6dff;