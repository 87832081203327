.tasks-manage-template-role-config-index-content {
    display: flex;
    flex-direction: column;
    .tasks-manage-template-basic-info-index-conduct {
        height: 48px;
        padding: 0 16px 0 20px;
        line-height: 24px;
        background: #fff;
        border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
        display: flex;
        justify-content: space-between;
        .tasks-manage-template-basic-info-index-conduct-left {
            flex: 1;
        }
        .tasks-manage-template-basic-info-index-conduct-right {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.45);
            line-height: 48px;
            margin-left: 10px;
        }
    }
    .tasks-manage-template-role-config-index-content-body {
        flex: 1;
    }
    .tasks-manage-template-basic-info-index-footer {
        padding: 16px;;
    }
}
