.component-input-contain {
    margin: 0 10px 10px 0;

    .component-input-contain-dropdown-menu {
        padding: 5px 10px;

        input {
            margin-bottom: 5px;
        }
    }
}