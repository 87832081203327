$page-layout-primary-color: #2878ff;
$page-layout-disabled-color: #bbb;

.data-migration-page {
    .data-migration-page-header {
        .data-migration-page-header-title {
        }
    }

    .data-migration-page-body {
        .data-migration-page-body-title-row {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .data-migration-page-body-title {
                width: 80px;
            }
            .data-migration-body-title-top {
                align-items: none;
                flex: none;
            }
            .data-migration-body-load {
                border: 1px solid #0a85ff;
                background: white;
                padding: 4px 22px;
                color: #0a85ff;
                cursor: pointer;
                width: 102px;
            }
            .data-migration-page-body-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                .udesk-qa-ui-switch-checked {
                    background-color: $page-layout-primary-color;
                }

                .remind-icon {
                    margin-left: 16px;
                    font-size: 15px;
                    line-height: 15px;
                    color: $page-layout-primary-color;
                    vertical-align: middle;
                }

                a {
                    color: $page-layout-primary-color;
                }
            }
        }
        .data-migration-body-row-top {
            align-items: normal;
        }
        .data-migration-page-body-config-row {
            widows: 100%;
            margin-bottom: 16px;

            .data-migration-page-body-config {
                min-width: 500px;
                margin-left: 80px;

                .udesk-custom-filters-react {
                    .filter-wrapper {
                        .filter-actions {
                            .add-condition {
                                .filter-icon-add {
                                    .filter-icon {
                                        color: $page-layout-disabled-color;
                                    }
                                }
                            }
                        }

                        .filter-conditions {
                            .filter-body {
                                .condition-row {
                                    .condition-item.condition-removal {
                                        .filter-icon-removal {
                                            .filter-icon {
                                                color: $page-layout-disabled-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .customer-filters-judgeStrategy-radios {
                    .customer-filters-judgeStrategy-radio-item {
                        display: inline-block;
                        width: auto;
                        margin-right: 10px;

                        .radio-btn-decorate {
                            background-color: $page-layout-primary-color;
                        }
                    }
                }

                .button-list {
                    margin-top: 8px;
                    button {
                        margin-right: 8px;
                    }

                    .udesk-qa-ui-btn-primary {
                        background-color: $page-layout-primary-color;
                        border-color: $page-layout-primary-color;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.udesk-qa-data-migration-modal-header {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
}
