.semantic-tags-detail-page {
    overflow-x: hidden;
    padding: 16px 0;
    .semantic-tags-detail-page-header {
    }

    .semantic-tags-detail-page-body {
        .semantic-tags-detail-input-list {
            display: block;

            .input-list-item {
                .input-box {
                    width: 100%;
                }
            }
        }
        .udesk-qa-ui-form-explain {
            display: none;
        }
        .udesk-qa-ui-legacy-form-explain {
            display: none;
        }
        .udesk-qa-ui-legacy-form-item-with-help {
            margin-bottom: 24px;
            .udesk-qa-ui-legacy-form-item-children {
                .udesk-qa-ui-input {
                    border-color: #ff4d4f;
                }
            }
        }
        .semantic-tags-dnd-card:hover span.semantic-tags-dnd-card-icon {
            display: block !important;
        }
        .semantic-tags-detail-page-body-standard-sentences {
            position: relative;

            .next-form-item-control {
                position: relative;

                .information-entities-container {
                    position: absolute;
                    background: #fff;
                    border: 1px solid #ddd;
                    width: 100%;
                    z-index: 2;

                    .information-entity-item {
                        padding: 3px 5px;
                        cursor: pointer;

                        &:hover {
                            background: #eee;
                        }
                    }
                }
            }
        }

        .semantic-tags-detail-page-body-standard-sentences-tip {
            margin: 0;
        }

        .intelligent-recommend-similar-contents-container {
            .recommend-title-container {
                margin-bottom: 10px;

                .recommend-title {
                    cursor: pointer;
                    color: #2878ff;
                }
            }

            .recommend-content-container {
            }
        }
        .semantic-tags-detail-page-body-tabs {
            margin: 20px 30px;
            border: 1px solid #e8e8e8;
            min-height: 500px;
            width: 100%;
            max-width: 1200px;
            .udesk-qa-ui-btn-link {
                color: #1890ff;
                background-color: transparent;
                border-color: transparent;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            .semantic-tags-detail-page-body-tabs-list {
                padding: 10px 30px;
                display: flex;
                flex-direction: row;
                width: 100%;

                .udesk-qa-ui-legacy-form-item {
                    margin-bottom: 0px;
                }
                h2 {
                    margin-right: 10px;
                    height: 40px;
                    line-height: 40px;
                    width: 80px;
                }
                .semantic-tags-detail-page-body-tabs-list-limit-generalization {
                    display: flex;
                    flex-direction: column;
                    .semantic-tags-detail-tabs-form-item-training-info {
                        color: rgba(0, 0, 0, 0.45);
                    }
                    .semantic-tags-detail-page-body-tabs-list-form {
                        display: flex;
                        flex-direction: row;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
                .semantic-tags-detail-page-body-tabs-list-limit {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .semantic-tags-detail-page-body-tabs-list-simimlar {
                        margin: 0px 5px;
                    }
                }
                .semantic-tags-detail-tabs-form-item-button {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: #fff;
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    text-align: center;
                    line-height: 21px;
                    cursor: pointer;
                    margin-left: 5px;
                }
                .semantic-tags-detail-page-body-list-info {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    .semantic-tags-detail-page-body-list-info-tips {
                        display: flex;
                        flex-direction: row;
                        font-size: 12px;
                        margin-left: 10px;
                        margin-top: 3px;
                        .icon-udesk-qa-react-web-xiazaishibai {
                            font-size: 14px;
                            color: rgb(161, 187, 249);
                            margin-right: 5px;
                        }
                    }
                }
            }
            .semantic-tags-detail-page-body-list-train {
                padding: 10px;

                .semantic-tags-detail-page-body-list-train-range {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0 10px;
                }
                .semantic-tags-detail-page-body-list-train-range-list {
                    margin-top: 20px;
                    padding: 0 10px;
                    .semantic-tags-detail-page-body-list-train-range-list-field {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-left: 5px;
                        margin-bottom: 10px;
                        .semantic-tags-detail-page-body-list-train-range-list-field-info {
                            width: 300px;
                            text-overflow: ellipsis;
                            margin-right: 15px;
                        }
                    }
                }
            }
            .semantic-tags-detail-page-body-tabs-test {
                padding: 10px 30px;
                .semantic-tags-detail-page-body-tabs-test-hit {
                    display: flex;
                    flex-direction: row;

                    h2 {
                        margin-right: 10px;
                        height: 32px;
                        line-height: 32px;
                        width: 80px;
                    }
                    .semantic-tags-detail-page-body-tabs-test-hit-limit {
                        width: 100%;
                        max-width: 500px;
                        .semantic-tags-detail-page-body-tabs-test-hit-limit-button {
                            margin-top: 10px;
                            .semantic-tags-detail-page-body-tabs-test-hit-limit-button-info {
                                margin-left: 10px;
                                .icon-udesk-qa-react-web-yixuanzhong {
                                    color: rgb(134, 191, 82);
                                }
                                .icon-udesk-qa-react-web-xiazaishibai {
                                    color: rgb(214, 90, 87);
                                }
                            }
                        }
                    }
                }
                .semantic-tags-detail-page-body-tabs-test-optimization {
                    align-items: center;
                    h2 {
                        margin-top: 10px;
                    }
                }
                .semantic-tags-detail-page-body-tabs-test-content {
                    margin-left: 80px;
                    border-top: 1px solid #e8e8e8;
                    width: 100%;
                    max-width: 900px;
                    margin-top: 20px;
                    .semantic-tags-detail-page-body-tabs-test-content-title {
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        border-bottom: 1px solid #e8e8e8;
                        .semantic-tags-detail-page-body-tabs-test-content-title-left {
                            width: 15%;
                            text-align: center;
                        }
                        .semantic-tags-detail-page-body-tabs-test-content-title-center {
                            width: 70%;
                            text-align: center;
                        }
                        .semantic-tags-detail-page-body-tabs-test-content-title-right {
                            width: 15%;
                            text-align: center;
                        }
                    }
                    .semantic-tags-detail-page-body-tabs-test-content-list {
                        .semantic-tags-detail-page-body-tabs-test-content-title-center {
                            text-align: left;
                        }
                    }
                }
            }
            .semantic-tags-detail-page-body-tabs-senior {
                padding: 10px 30px;
                .udesk-qa-ui-legacy-form-item {
                    margin-bottom: 0px;
                }
                .semantic-tags-detail-page-body-tabs-senior-title {
                    .icon-udesk-qa-react-web-xiazaishibai {
                        color: rgb(161, 187, 249);
                        margin-right: 5px;
                    }
                }
                .semantic-tags-detail-page-body-tabs-senior-list {
                    display: flex;
                    flex-direction: row;
                    margin-top: 20px;
                    h2 {
                        margin-right: 10px;
                        height: 32px;
                        line-height: 32px;
                        width: 80px;
                    }
                    .semantic-tags-detail-page-body-tabs-senior-list-info {
                        .semantic-tags-detail-page-body-tabs-senior-list-info-field {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .icon-udesk-qa-react-web-xiazaishibai {
                                color: rgb(161, 187, 249);
                                margin: 0px 5px;
                            }
                        }
                    }
                }
                .semantic-tags-detail-page-body-tabs-senior-newh2 {
                    h2 {
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
            .semantic-tags-detail-page-body-list-rule {
                padding: 10px 30px;
                .react-dnd-input-button-style {
                    width: 40px;
                    border: 0;
                    height: 15px;
                    border-bottom: 1px solid rgb(215, 88, 87);
                }
                .semantic-tags-detail-page-body-tabs-senior-title {
                    margin-bottom: 10px;
                    .icon-udesk-qa-react-web-xiazaishibai {
                        color: rgb(161, 187, 249);
                        margin-right: 5px;
                    }
                }
                .semantic-tags-detail-page-body-list-rule-field {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;
                    .udesk-qa-ui-legacy-form-item {
                        margin-bottom: 0px;
                    }
                    h2 {
                        margin-right: 10px;
                        // height: 40px;
                        // line-height: 40px;
                        width: 80px;
                    }
                    .semantic-tags-detail-page-body-list-rule-field-info {
                        width: 100%;
                    }
                }
            }
        }
    }
}
