.qa-react-list-page-body-task-center-list {
    .qa-react-list-page-body-uploadDownload {
        height: auto !important;
        .qa-react-list-page-content {
            height: auto !important;
        }
        .uploadDownloadHover {
            color: #2878ff !important;
            margin: 0 3px;
        }
        .uploadDownloadHover:hover {
            text-decoration: underline !important;
            color: blue !important;
        }
    }
    .udesk-qa-ui-drawer-content-wrapper {
        min-width: 480px !important;
    }
    .udesk-qa-ui-drawer-wrapper-body {
        padding-top: 60px;
    }
    .task-center-list-field-detail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 13px;
        margin: 5px 0;
        .task-center-list-field-left {
            width: 100px;
            text-align: right;
            padding-right: 10px;
        }
    }
    .udesk-qa-customize-pagination {
        padding-top: 0;
    }
}
