.test-set-list-page {
    position: relative;

    .test-set-list-header {
        padding: 0 20px;
    }
    .test-set-list-accuracy {
        display: inline-block;
        width: 64px;
    }
    .test-set-list-evaluation-information {
        display: flex;
        flex-wrap: row;
        justify-content: space-between;
        padding-bottom: 10px;
        .evaluation-result {
            width: 460px;
            height: 80px;
            padding: 10px 20px 10px 10px;
            border: 1px solid #dcdcdc;

            .test-set-list-result-time {
                margin-bottom: 5px;
                .test-set-list-price-title {
                    font-weight: 700;
                    margin-right: 5px;
                }
            }
            .test-set-list-result-info {
                display: flex;
                flex-wrap: row;
                justify-content: space-between;
                .test-set-list-price {
                    display: flex;
                    flex-wrap: row;
                    .test-set-list-price-title {
                        font-weight: 700;
                        margin-right: 5px;
                    }
                    .test-set-list-price-number {
                        text-align: right;
                    }
                }
            }
        }
        .evaluation-information-button {
            display: flex;
            flex-wrap: row;
            justify-content: space-around;
            align-items: center;
            .button-checkbox-evaluation {
                margin-right: 20px;
                input {
                    margin-right: 3px;
                }
            }
            .btn-test-set-list {
                border: 1px solid #0a85ff;
                background: #fff;
                padding: 4px 22px;
                color: #0a85ff;
                cursor: pointer;
                text-align: center;
                margin-left: 10px;
                height: 28px;
            }
        }
    }
    .test-set-list-table {
        position: absolute;
        width: 100%;
        padding-right: 20px;
    }

    .component-react-table .table-wrapper .columns {
        z-index: 5;
    }
}
