.highlight-count-groups {
    background-color: #fff;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    padding: 4px 0;
    .highlight-count-group-content {
        text-align: center;
        &.split {
            color: rgba(0, 0, 0, .6);
        }
    }
}
.high-light-controls-component {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    .udesk-qa-ui-btn-icon-only {
        .anticon {
            margin-left: 0px;
        }
    }
}
.udesk-qa-chat-record-list-component {
    .udesk-qa-ui-checkbox-wrapper {
        width: 100%;
        align-items: center;
        >.udesk-qa-ui-checkbox {
            margin: 0 -8px 0 8px;
        }
        .udesk-qa-ui-checkbox + span {
            padding-right: 0;
        }
        >:last-child {
            width: 100%;
        }
        .udesk-qa-chat-record-item {
            width: 100%;
        }
    }
}