.udesk-qa-web-home-page {
    height: 100vh;

    .udesk-qa-web-home-page-sider {
        box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.1);
        z-index: 530;
        .udesk-qa-ui-badge {
            width: 100%;
        }
        .udesk-qa-ui-menu-item {
            display: flex;
            .menu-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
                width: calc(100% - 20px);
                display: inline-block;
            }
        }
        .title {
            height: 64px;
            padding: 16px;
            display: flex;
            align-items: center;

            .logo {
                width: 32px;
                min-width: 32px;
                height: 32px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .name {
                width: 124px;
                height: 24px;
                margin-left: 12px;
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .udesk-qa-ui-layout-sider-children {
            .udesk-qa-ui-menu-inline-collapsed {
                width: 64px;
                .udesk-qa-ui-menu-item {
                    padding: 0 22px;
                }
            }
        }
        .udesk-qa-ui-menu {
            height: calc(100% - 64px);
            overflow-y: auto;
            overflow-x: hidden;
        }

        // 滚动背景
        ::-webkit-scrollbar {
            width: 0.3rem;
            height: 0.3rem;
            background-color: rgba(0, 0, 0, 0.1);
        }
        ::-webkit-scrollbar-track {
            border-radius: 0;
        }
        // 滚动条
        ::-webkit-scrollbar-thumb {
            border-radius: 0;
            background-color: rgba(27, 109, 255, 0.5);
            transition: all 0.2s;
            border-radius: 0.5rem;
        }
    }

    .udesk-qa-web-home-page-header {
        height: 64px;
        padding: 0 16px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        z-index: 509;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .collapsed-icon {
            font-size: 20px;
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            transition: color 0.3s;
        }

        .collapsed-icon:hover {
            color: #1890ff;
        }

        .collapsed-icon:focus {
            outline: -webkit-focus-ring-color auto 0;
        }
    }
}

.udesk-qa-ui-tooltip {
    .udesk-qa-ui-badge {
        color: #fff;
        .udesk-qa-ui-badge-dot {
            display: none;
        }
    }
}

.udesk-qa-sub-app-entries-popover {
    .udesk-qa-ui-popover-arrow {
        border-left-color: rgba(225, 232, 245, 0.9) !important;
        border-bottom-color: rgba(225, 232, 245, 0.9) !important;
    }
    .udesk-qa-ui-popover-inner {
        background: rgba(225, 232, 245, 0.9);
        border-radius: 8px;
    }
    .udesk-qa-ui-popover-inner-content {
        padding: 0;
    }
}
.udesk-qa-ui-tooltip-inner {
    width: fit-content;
    white-space: nowrap;
}
.udesk-qa-ui-table-wrapper {
    .udesk-qa-ui-spin-nested-loading {
        height: 100%;
    }
}
